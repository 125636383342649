/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateYearlyActivationAction } from "redux/Dashboard/actions";

function WinterizationCheckBtn({ details }) {
  const dispatch = useDispatch();
  const desiredType = "winterization";
  const activationObj = details?.yearlyActivations?.find(
    (obj) => obj.activation_type === desiredType
  );
  const handleCheck = () => {
    const currentYear = new Date().getFullYear();
    if (activationObj === undefined) {
      dispatch(
        updateYearlyActivationAction({
          customer_id: details?._id,
          year: currentYear,
          activation_type: "winterization",
          status: true,
        })
      );
    } else if (activationObj.status) {
      dispatch(
        updateYearlyActivationAction({
          customer_id: details?._id,
          year: currentYear,
          activation_type: "winterization",
          status: false,
        })
      );
    } else {
      dispatch(
        updateYearlyActivationAction({
          customer_id: details?._id,
          year: currentYear,
          activation_type: "winterization",
          status: true,
        })
      );
    }
  };
  const isChecked = activationObj ? activationObj.status : false;
  return (
    <div>
      <FormControlLabel
        onClick={handleCheck}
        control={<Checkbox checked={isChecked} />}
        label="COMPLETED"
      />
    </div>
  );
}

export default WinterizationCheckBtn;
