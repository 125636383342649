/* eslint-disable */
// @mui material components
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Overview page components
import workingSVG from "assets/images/svg/working.svg";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

function ComingSoon() {
  return (
    <DashboardLayout>
      <MDBox mb={2} />
      <Card sx={{ boxShadow: "none" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh", display: "flex", justifyContent: "center", width:'100%' }}
        >
          <Grid item xs={3}>
            <div>
              <img style={{ width: "600px" }} src={workingSVG} />
              <h3 style={{ "text-align": "center" }}>Work In Progress</h3>
            </div>
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
}

export default ComingSoon;
