/* eslint-disable no-unused-expressions */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
// import { DatePicker } from "@mui/lab";
import { Autocomplete, Card, Grid, TextField, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCrewListAction } from "redux/Crew/actions";
import { fetchServices } from "redux/RouteManagement/Services/actions";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import DeleteIcon from "@mui/icons-material/Delete";
import {
  createAppointment,
  getSlotsAction,
  getSingleAppointmentAction,
  clearSingleDataAction,
  updateAppointmentAction,
} from "redux/Appointment/actions";
import { listCustomers } from "redux/Customers/actions";
import { weekOffDataAction, listHolidaysAction } from "redux/Holidays/actions";
import { useNavigate, useParams } from "react-router-dom";
import CustomSpinner from "examples/CustomLoader";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import messageImg from "../../../assets/images/undraw_schedule_meeting_52nu.svg";

const timeWindow = [
  { label: "30 Minutes", value: 30 },
  { label: "1 Hour", value: 1 },
  { label: "2 Hours", value: 2 },
  { label: "3 Hours", value: 3 },
  { label: "4 Hours", value: 4 },
  { label: "5 Hours", value: 5 },
  { label: "6 Hours", value: 6 },
  { label: "7 Hours", value: 7 },
  { label: "8 Hours", value: 8 },
  { label: "9 Hours", value: 9 },
];
function AddAndEditAppointments() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paramsId = useParams();
  const { slotsData, singleAppointmentData } = useSelector((state) => state.AppointmentReducer);
  const { crewsData } = useSelector((state) => state.CrewReducer);
  const { servicesData } = useSelector((state) => state.RouteManagement);
  const { originalCustomerList, loading } = useSelector((state) => state.Customers);
  const { weekOffData, settingsData } = useSelector((state) => state.SettingsReducer);
  const [crewName, setCrewName] = useState([]);
  const [serviceName, setServiceName] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [dataForAppointment, setDataForAppointment] = useState({});
  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [customerValue, setcustomerValue] = useState({});
  const [editableData, setEditableData] = useState({});
  const [notesInput, setNotesInput] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const addCustomerWrapper = {
    width: "50%",
  };
  const createAppointmentWrapper = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: "3rem",
  };
  const appointmentImageWrap = {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  if (windowWidth <= 920) {
    addCustomerWrapper.width = "100%";
    createAppointmentWrapper.flexDirection = "column";
    appointmentImageWrap.display = "none";
  }
  // const handleAddNote = () => {
  //   if (notesInput[notesInput.length - 1] !== "") {
  //     setNotesInput([...notesInput, ""]); // Add an empty note to the array
  //   }
  // };
  const handleNoteChange = (index, value) => {
    const updatedNotes = [...notesInput];
    updatedNotes[index] = value;
    setNotesInput(updatedNotes);
  };
  // const handleRemoveNote = (index) => {
  //   const newArray = notesInput.filter((item, i) => i !== index);
  //   setNotesInput(newArray);
  // };
  const handleSubmit = (e) => {
    const date = new Date();
    const today = date.toLocaleDateString("en-CA").split("/").reverse().join("-");
    e.preventDefault();
    if (paramsId.id) {
      dispatch(
        updateAppointmentAction({
          dataSet: {
            customer_id: editableData.customerData.customer_id,
            crew_id: editableData.crewData.crew_id,
            service_type_id: editableData.serviceType.service_id,
            time_window: editableData.timeWindow.value,
            date: editableData.date,
            timeSlot: editableData.timeSlot.value,
            note: notesInput,
          },
          id: paramsId.id,
        })
      );
      if (editableData.date === today) {
        navigate("/appointment", { state: { path: "todayAppointment" } });
      } else {
        navigate("/appointment", { state: { path: "upcomingAppointment" } });
      }
    } else {
      dispatch(createAppointment(dataForAppointment));
      if (dataForAppointment.date === today) {
        navigate("/appointment", { state: { path: "todayAppointment" } });
      } else {
        navigate("/appointment", { state: { path: "upcomingAppointment" } });
      }
    }
  };
  const handleChange = (event, value) => {
    if (paramsId.id) {
      setEditableData({ ...editableData, crewData: value });
    } else {
      setDataForAppointment({ ...dataForAppointment, crew_id: value?.crew_id });
    }
  };
  const handleDateChange = (data) => {
    const year = data.$d.getFullYear();
    const month = String(data.$d.getMonth() + 1).padStart(2, "0");
    const day = String(data.$d.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    if (paramsId.id) {
      setEditableData({ ...editableData, date: formattedDate });
    } else {
      setDataForAppointment({ ...dataForAppointment, date: formattedDate });
    }
  };
  const changeTimeWindow = (event, value) => {
    if (paramsId.id) {
      setEditableData({
        ...editableData,
        timeWindow: value,
      });
    } else {
      setDataForAppointment({ ...dataForAppointment, time_window: value.value });
    }
  };

  const handleCustomerDetailsAdd = (event, value) => {
    // eslint-disable-next-line no-unused-expressions
    if (paramsId.id) {
      setEditableData({ ...editableData, customerData: value });
    } else {
      setDataForAppointment({ ...dataForAppointment, customer_id: value.custmerId });
    }
    if (value) {
      // eslint-disable-next-line no-undef
      setcustomerValue({
        name: value.label,
        address: value.address,
        time_window: value.customerTimeWindow,
      });
    } else {
      setcustomerValue({});
    }
  };
  const disabledDays = {
    friday: weekOffData.friday,
    monday: weekOffData.monday,
    saturday: weekOffData.saturday,
    sunday: weekOffData.sunday,
    thursday: weekOffData.thursday,
    tuesday: weekOffData.tuesday,
    wednesday: weekOffData.wednesday,
  };
  // eslint-disable-next-line consistent-return
  const shouldDisableDate = (date) => {
    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const day = daysOfWeek[date.$d.getDay()];
    if (disabledDays[day]) {
      return true;
    }
    if (settingsData.data) {
      const dateStr = date.format("YYYY-MM-DD");
      // eslint-disable-next-line no-restricted-syntax, no-unsafe-optional-chaining
      for (const holiday of settingsData?.data) {
        if (dateStr >= holiday.start_date && dateStr <= holiday.end_date) {
          return true;
        }
        if (dateStr === holiday?.start_date?.slice(0, 10)) {
          return true;
        }
        if (dateStr === holiday?.end_date?.slice(0, 10)) {
          return true;
        }
      }
      const currentDate = new Date().toISOString().slice(0, 10);
      if (dateStr < currentDate) {
        return true;
      }

      return false;
    }
  };
  useEffect(() => {
    dispatch(getCrewListAction());
    dispatch(fetchServices());
    dispatch(weekOffDataAction());
    dispatch(listHolidaysAction({ path: { tab: "holiday" } }));
    dispatch(listCustomers({ page: 1, pageSize: 10000 }));
  }, []);
  useEffect(() => {
    // eslint-disable-next-line camelcase
    const newData = crewsData?.data?.map(({ _id, crew_title }) => ({
      // eslint-disable-next-line camelcase
      label: crew_title,
      crew_id: _id,
    }));
    setCrewName(newData);
  }, [crewsData]);
  useEffect(() => {
    // eslint-disable-next-line camelcase
    const servicesOption = servicesData?.data?.map(({ _id, service_title }) => ({
      // eslint-disable-next-line camelcase
      label: service_title,
      service_id: _id,
    }));
    setServiceName(servicesOption);
  }, [servicesData]);

  useEffect(() => {
    const settingCustomerList = originalCustomerList?.data?.map(
      ({ _id, first_name, last_name, service_call_time_window, property }) => ({
        label: `${first_name} ${last_name}`,
        custmerId: _id,
        customerTimeWindow: service_call_time_window,
        address: `${property.address} ${property.city} ${property.state} ${property.zip}`,
      })
    );
    setCustomerDetails(settingCustomerList);
  }, [originalCustomerList]);

  useEffect(() => {
    if (dataForAppointment.crew_id && dataForAppointment.time_window && dataForAppointment.date) {
      dispatch(
        getSlotsAction({
          crew_id: dataForAppointment?.crew_id,
          timeWindow: dataForAppointment?.time_window,
          date: dataForAppointment?.date,
        })
      );
    }
  }, [dataForAppointment]);

  useEffect(() => {
    if (paramsId.id) {
      dispatch(getSingleAppointmentAction([{ appointment_id: paramsId.id }]));
    }
  }, [paramsId.id]);

  useEffect(() => {
    if (singleAppointmentData?.data) {
      setNotesInput(singleAppointmentData?.data[0].note);
    }
  }, [singleAppointmentData]);

  useEffect(() => {
    if (singleAppointmentData?.data) {
      const formattedSlots = (slot) => {
        const [start, end] = slot.split(" to ");
        const startTime = parseInt(start, 10);
        const endTime = parseInt(end, 10);

        const [startHours, startMinutes] = start.split(":");
        const [endHours, endMinutes] = end.split(":");

        const startTotalMinutes = parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);
        const endTotalMinutes = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10);

        const timeDifference = endTotalMinutes - startTotalMinutes;

        let label = "";
        if (timeDifference === 30) {
          let formattedStartHour = String(startTime);
          const formattedStartMinutes = String(startMinutes);
          let formattedEndHour = String(endTime);
          const formattedEndMinutes = String(endMinutes);
          const endPeriodEnd = endTime >= 12 ? "pm" : "am";
          const endPeriodStart = startTime >= 12 ? "pm" : "am";
          formattedStartHour = formattedStartHour % 12 || 12;
          formattedEndHour = formattedEndHour % 12 || 12;
          label = `${formattedStartHour}${
            formattedStartMinutes === "00" ? "" : `:${formattedStartMinutes}`
          }${endPeriodStart} to ${formattedEndHour}${
            formattedEndMinutes === "00" ? "" : `:${formattedEndMinutes}`
          }${endPeriodEnd}`;
        } else if (startTime >= 6 && endTime < 12) {
          label = `${startTime}am to ${endTime}am`;
        } else if (startTime >= 6 && endTime === 12) {
          label = `${startTime}am to ${endTime}pm`;
        } else if (startTime >= 6 && startTime < 12 && endTime <= 18) {
          label = `${startTime}am to ${endTime - 12}pm`;
        } else if (startTime >= 12 && endTime <= 18) {
          if (startTime === 12) {
            label = `${startTime}pm to ${endTime - 12}pm`;
          } else {
            label = `${startTime - 12}pm to ${endTime - 12}pm`;
          }
        }
        return { label, value: slot };
      };

      let timeWindowText;
      const timeWindowValue = singleAppointmentData.data[0].time_window;

      if (timeWindowValue === 30) {
        timeWindowText = "Minutes";
      } else if (timeWindowValue === 1) {
        timeWindowText = "Hour";
      } else {
        timeWindowText = "Hours";
      }
      const timeWindowLabel = `${timeWindowValue} ${timeWindowText}`;
      setcustomerValue({
        name: singleAppointmentData.data[0].customerName,
        address: singleAppointmentData.data[0].customerName,
        time_window: singleAppointmentData.data[0].customerName,
      });
      setEditableData({
        customerData: {
          label: singleAppointmentData.data[0].customerName,
          customer_id: singleAppointmentData.data[0].id_customer,
        },
        crewData: {
          label: singleAppointmentData.data[0].crewName,
          crew_id: singleAppointmentData.data[0].id_crew,
        },
        serviceType: {
          label: singleAppointmentData.data[0].serviceType,
          service_id: singleAppointmentData.data[0].id_service,
        },
        timeWindow: {
          label: timeWindowLabel,
          value: timeWindowValue,
        },
        timeSlot: formattedSlots(singleAppointmentData.data[0].slot),
        note: singleAppointmentData.data[0].note,
        date: singleAppointmentData.data[0].date,
      });
    }
  }, [singleAppointmentData]);

  useEffect(() => {
    if (editableData) {
      dispatch(
        getSlotsAction({
          crew_id: editableData?.crewData?.crew_id,
          timeWindow: editableData?.timeWindow?.value,
          date: editableData?.date,
        })
      );
    }
  }, [singleAppointmentData, editableData]);

  useEffect(() => {
    if (!paramsId.id) {
      setcustomerValue({});
      setEditableData({});
      dispatch(clearSingleDataAction());
    }
  }, []);

  useEffect(() => {
    if (slotsData.data) {
      const formattedSlots = slotsData.data.map((slot) => {
        const [start, end] = slot.split(" to ");
        const startTime = parseInt(start, 10);
        const endTime = parseInt(end, 10);

        const [startHours, startMinutes] = start.split(":");
        const [endHours, endMinutes] = end.split(":");

        const startTotalMinutes = parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);
        const endTotalMinutes = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10);

        const timeDifference = endTotalMinutes - startTotalMinutes;

        let label = "";
        if (timeDifference === 30) {
          let formattedStartHour = String(startTime);
          const formattedStartMinutes = String(startMinutes);
          let formattedEndHour = String(endTime);
          const formattedEndMinutes = String(endMinutes);
          const endPeriodEnd = endTime >= 12 ? "pm" : "am";
          const endPeriodStart = startTime >= 12 ? "pm" : "am";
          formattedStartHour = formattedStartHour % 12 || 12;
          formattedEndHour = formattedEndHour % 12 || 12;
          label = `${formattedStartHour}${
            formattedStartMinutes === "00" ? "" : `:${formattedStartMinutes}`
          }${endPeriodStart} to ${formattedEndHour}${
            formattedEndMinutes === "00" ? "" : `:${formattedEndMinutes}`
          }${endPeriodEnd}`;
        } else if (startTime >= 6 && endTime < 12) {
          label = `${startTime}am to ${endTime}am`;
        } else if (startTime >= 6 && endTime === 12) {
          label = `${startTime}am to ${endTime}pm`;
        } else if (startTime >= 6 && startTime < 12 && endTime <= 18) {
          label = `${startTime}am to ${endTime - 12}pm`;
        } else if (startTime >= 12 && endTime <= 18) {
          if (startTime === 12) {
            label = `${startTime}pm to ${endTime - 12}pm`;
          } else {
            label = `${startTime - 12}pm to ${endTime - 12}pm`;
          }
        }
        return { label, value: slot };
      });

      setTimeSlotsData(formattedSlots);
    }
  }, [slotsData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <DashboardLayout>
      <MDBox mb={3}>
        <Card>
          <Grid component="form" role="form" onSubmit={handleSubmit} md={12} padding={6}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" component="h2">
                {paramsId.id ? (
                  <>{windowWidth >= 758 ? "Edit Appointments" : "Edit Appt"}</>
                ) : (
                  <>{windowWidth >= 758 ? "Schedule Appointments" : "Schedule Appt"}</>
                )}
              </Typography>
              <MDBox display="flex" justifyContent="space=between">
                <MDButton
                  // to={action.route}
                  variant="gradient"
                  color="error"
                  style={{ marginInline: "1rem" }}
                >
                  Delete
                </MDButton>
                <SideNavActionBtn />
              </MDBox>
            </MDBox>
            <br />
            <MDBox
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
              }}
            >
              {loading ? (
                <MDBox
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <CustomSpinner />
                </MDBox>
              ) : (
                <MDBox style={createAppointmentWrapper}>
                  <MDBox style={addCustomerWrapper}>
                    <Typography mb={1} variant="h6" component="h1">
                      Customer
                    </Typography>
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      onChange={handleCustomerDetailsAdd}
                      options={customerDetails}
                      value={paramsId?.id && editableData?.customerData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Customer Name"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                          required
                        />
                      )}
                    />
                    {customerValue?.address && (
                      <MDBox style={{ padding: "7px" }}>
                        <Typography variant="h6" component="p" fontSize={16}>
                          Customer Details
                        </Typography>
                        <Typography variant="p" component="p" fontSize={14}>
                          Customer Name : {customerValue.name}
                        </Typography>
                        <Typography variant="p" component="p" fontSize={14}>
                          Address : {customerValue.address}
                        </Typography>
                        <Typography variant="p" component="p" fontSize={14}>
                          Time Window : {customerValue.time_window}
                        </Typography>
                      </MDBox>
                    )}
                    <br />
                    <Typography mb={1} variant="h6" component="h1">
                      Crew
                    </Typography>
                    <Autocomplete
                      onChange={handleChange}
                      disablePortal
                      id="combo-box-demo"
                      fullWidth
                      options={crewName}
                      value={editableData.crewData}
                      renderInput={(params) => <TextField {...params} label="Crew" required />}
                    />
                    <br />
                    <Typography mb={1} variant="h6" component="h1">
                      Service Type
                    </Typography>
                    <Autocomplete
                      value={editableData.serviceType}
                      onChange={(e, value) => {
                        if (paramsId.id) {
                          setEditableData({ ...editableData, serviceType: value });
                        } else {
                          setDataForAppointment({
                            ...dataForAppointment,
                            service_type_id: value?.service_id,
                          });
                        }
                      }}
                      disablePortal
                      id="combo-box-demo"
                      fullWidth
                      options={serviceName}
                      renderInput={(params) => (
                        <TextField {...params} label="Service Type" required />
                      )}
                    />
                    <br />
                    <Typography mb={1} variant="h6" component="h1">
                      Time Window
                    </Typography>
                    <Autocomplete
                      onChange={changeTimeWindow}
                      value={editableData?.timeWindow && editableData?.timeWindow}
                      disablePortal
                      id="combo-box-demo"
                      fullWidth
                      options={timeWindow}
                      renderInput={(params) => <TextField {...params} label="Time" required />}
                    />
                    <br />
                    <Typography variant="h6" component="h1">
                      Date
                    </Typography>
                    {!paramsId.id ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Date"
                            shouldDisableDate={shouldDisableDate}
                            onChange={handleDateChange}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    ) : (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            orientation="landscape"
                            onChange={handleDateChange}
                            value={dayjs(editableData.date)}
                            label="Start Date"
                            shouldDisableDate={shouldDisableDate}
                            slotProps={{
                              actionBar: {
                                actions: [""],
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}
                    <Typography mb={1} mt="2rem" variant="h6" component="h1">
                      Time Slot
                    </Typography>
                    <Autocomplete
                      value={paramsId?.id && editableData?.timeSlot}
                      disablePortal
                      onChange={(e, value) => {
                        paramsId?.id
                          ? setEditableData({ ...editableData, timeSlot: value })
                          : setDataForAppointment({ ...dataForAppointment, slot: value.value });
                      }}
                      id="combo-box-demo"
                      fullWidth
                      options={timeSlotsData}
                      renderInput={(params) => <TextField {...params} label="Time" required />}
                    />
                    <br />
                    <Typography mb={1} variant="h6" component="h1">
                      Appointment Notes
                    </Typography>
                    {paramsId.id ? (
                      <div>
                        {notesInput.map((note, index) => (
                          <div
                            style={{
                              marginTop: "1rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <TextField
                              mt={3}
                              key={index}
                              value={note}
                              onChange={(e) => handleNoteChange(index, e.target.value)}
                              fullWidth
                              id={`outlined-multiline-static-${index}`}
                              label={`Note ${index + 1}`}
                              multiline
                              rows={6}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <TextField
                        value={editableData.note}
                        onChange={(e) => {
                          paramsId?.id
                            ? setEditableData({ ...editableData, note: e.target.value })
                            : setDataForAppointment({
                                ...dataForAppointment,
                                note: e.target.value,
                              });
                        }}
                        fullWidth
                        id="outlined-multiline-static"
                        label="Notes"
                        multiline
                        rows={6}
                        required
                      />
                    )}
                    <br />
                    <br />
                    <MDBox style={{ display: "flex", justifyContent: "center" }}>
                      <MDButton type="submit" color="primary">
                        {paramsId.id ? "Update" : "Save"}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                  <MDBox style={appointmentImageWrap}>
                    <img
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        objectFit: "contain",
                        width: "50%",
                      }}
                      src={messageImg}
                      loading="lazy"
                      alt=""
                    />
                  </MDBox>
                </MDBox>
              )}
            </MDBox>
          </Grid>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default AddAndEditAppointments;
