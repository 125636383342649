export const LIST_SERVICES = "LIST_SERVICES";
export const LIST_SERVICES_SUCCESS = "LIST_SERVICES_SUCCESS";
export const LIST_SERVICES_FAILED = "LIST_SERVICES_FAILED";

export const ADD_SERVICES = "ADD_SERVICES";
export const ADD_SERVICES_SUCCESS = "ADD_SERVICES_SUCCESS";
export const ADD_SERVICES_FAILED = "ADD_SERVICES_FAILED";

export const DELETE_SERVICES = "DELETE_SERVICES";
export const DELETE_SERVICES_SUCCESS = "DELETE_SERVICES_SUCCESS";
export const DELETE_SERVICES_FAILED = "DELETE_SERVICES_FAILED";

export const EDIT_SERVICES = "EDIT_SERVICES";
export const EDIT_SERVICES_SUCCESS = "EDIT_SERVICES_SUCCESS";
export const EDIT_SERVICES_FAILED = "EDIT_SERVICES_FAILED";

export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAILED = "GET_SERVICE_FAILED";

export const CLEAR_SERVICE = "CLEAR_SERVICE";

export const SET_ROUTE_MANAGEMENT_LOADING = "SET_ROUTE_MANAGEMENT_LOADING";
