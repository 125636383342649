/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, Card, Checkbox, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCustomers } from "redux/actions";
import { postMessageAction } from "redux/Messages/actions";
import messageImg from "../../../assets/images/unnamed.jpg";

function BulkSendSms() {
  const dispatch = useDispatch();
  const { originalCustomerList } = useSelector((state) => state.Customers);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [numbers, setNumber] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const inputWrapper = {
    width: "50%",
  };

  if (windowWidth <= 768) {
    inputWrapper.width = "100%";
  }

  const handleAddCustomer = (event, value) => {
    if (value !== null) {
      if (value.some((item) => item.title === "Select All")) {
        if (selectedOptions.length === customerDetails.length) {
          setSelectedOptions([]);
          setNumber([]);
        } else {
          setSelectedOptions(customerDetails);
          const phoneNumberArray = customerDetails.map((item) => item.phoneNumber);
          setNumber(phoneNumberArray);
        }
      } else {
        const phoneNumberArray = value.map((item) => item.phoneNumber);
        setNumber(phoneNumberArray);
        setSelectedOptions(value);
      }
    } else {
      setNumber([]);
      setSelectedOptions([]);
    }
  };
  // const handleManualText = (e) => {
  //   if (e && e.target && e.target.value !== "") {
  //     const inputValue = e.target.value;

  //     if (inputValue.includes(",")) {
  //       const numbersArray = inputValue
  //         .split(",")
  //         .map((number) => ({ title: number.trim(), phoneNumber: number.trim() }))
  //         .filter((obj) => obj.title !== "" && /^\d+$/.test(obj.phoneNumber));

  //       // setNumber(numbersArray.map((obj) => obj.title));
  //       setSelectedOptions([...selectedOptions, ...numbersArray]);
  //       e.target.value = ""; // Clear the input field after processing
  //     }
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (numbers.length - 1 >= 0) {
      dispatch(postMessageAction({ toNumbers: numbers, message }));
      setMessage("");
      setNumber([]);
      setSelectedOptions([]);
    }
  };
  useEffect(() => {
    if (selectedOptions.length > 0) {
      const numberStrings = selectedOptions.map((obj) => obj.phoneNumber);
      setNumber(numberStrings);
    }
  }, [selectedOptions]);
  useEffect(() => {
    // eslint-disable-next-line camelcase
    const settingCustomerList = originalCustomerList?.data?.map(
      // eslint-disable-next-line camelcase
      ({ _id, first_name, last_name, phone }) => ({
        // eslint-disable-next-line camelcase
        title: `${first_name} ${last_name}`,
        custmerId: _id,
        phoneNumber: phone,
      })
    );
    const filterdCustomer = settingCustomerList?.filter((item) => item.phoneNumber !== "");
    setCustomerDetails(filterdCustomer);
  }, [originalCustomerList]);
  useEffect(() => {
    dispatch(listCustomers({ page: 1, pageSize: 10000 }));
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Card style={{ marginTop: "1.3rem" }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        bgColor="black"
        borderRadius="lg"
        coloredShadow="info"
        display="flex"
        justify-content="space-between"
        align-items="center"
      >
        <MDBox style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <MDTypography style={{ textTransform: "capitalize" }} color="light">
            Bulk Send SMS
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        width="100%"
        display="flex"
        justifyContent="space-between"
        component="form"
        role="form"
        onSubmit={handleSubmit}
        p={3}
      >
        <MDBox style={inputWrapper}>
          <MDBox fullWidth>
            <MDTypography variant="h6">Select Customers</MDTypography>
            {customerDetails && (
              <Autocomplete
                multiple
                limitTags={4}
                disableClearable
                id="checkboxes-tags-demo"
                options={[{ title: "Select All" }, ...customerDetails]}
                onChange={handleAddCustomer}
                // onInputChange={handleManualText}
                value={selectedOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.title}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} placeholder="Select Customers" />}
              />
            )}
          </MDBox>
          <br />
          <MDBox fullWidth>
            <MDTypography variant="h6">Message</MDTypography>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              placeholder="Add text"
              multiline
              rows={6}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </MDBox>
          <br />
          <MDButton type="submit" variant="gradient" color="info" ml={2}>
            Send Message
          </MDButton>
        </MDBox>
        {windowWidth >= 768 && (
          <MDBox width="50%" display="flex" justifyContent="center">
            <img
              style={{ width: "50%", objectFit: "contain" }}
              src={messageImg}
              loading="lazy"
              alt=""
            />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

export default BulkSendSms;
