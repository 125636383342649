/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { Chip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomSpinner from "examples/CustomLoader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActivityAction } from "redux/Activities/actions";

const activityListStyle = {
  listStyleType: "none",
  margin: 0,
  padding: 0,
  fontSize: 14,
  color: "#7b809a",
};

const activityItemStyle = {
  width: "100%",
  borderBottom: "1px solid #7B809A",
  marginBottom: "15px",
  padddingBottom: "15px",
};

function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

function Activitys({ customerId }) {
  const dispatch = useDispatch();
  const { activityData, loading } = useSelector((state) => state.ActivitysReducer);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const activityWrapper = {
    display: "flex",
  };
  const activityDate = { marginRight: 25 };
  if (windowWidth <= 768) {
    activityWrapper.flexDirection = "column";
    activityDate.display = "flex";
    // activityDate.justifyContent = "end";
    activityDate.marginBottom = "5px";
  }
  useEffect(() => {
    dispatch(getActivityAction(customerId));
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {loading ? (
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CustomSpinner />
        </MDBox>
      ) : (
        <MDBox>
          {activityData.data?.length <= 0 ? (
            <MDBox
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <img
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
                src="https://img.freepik.com/free-vector/empty-concept-illustration_114360-1253.jpg?w=2000"
                loading="lazy"
                alt=""
              />
            </MDBox>
          ) : (
            <ul style={activityListStyle}>
              {activityData?.data?.map((item, index) => {
                const words = item?.description?.split(" ");
                const firstWord = words ? words[0] : ""; // handling case when words is null or undefined
                const restOfTheWords = words ? words.slice(1).join(" ") : ""; // handling case when words is null or undefined
                return (
                  <li style={activityItemStyle} key={index._id}>
                    <MDTypography
                      variant="subtitle2"
                      fontWeight="medium"
                      color="text"
                      style={activityWrapper}
                    >
                      <div style={activityDate}>{dateFormatted(item.updatedAt)}</div>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        {firstWord && (
                          <Chip
                            badgeContent={firstWord}
                            label={<span style={{ textTransform: "capitalize" }}>{firstWord}</span>}
                            style={{
                              color: "#fff",
                              borderRadius: "0.375rem",
                              fontSize: ".765rem",
                              height: "1.6rem",
                              background:
                                firstWord === "Invoice"
                                  ? "#14A44D"
                                  : firstWord === "Payment"
                                  ? "#3B71CA"
                                  : "#54B4D3",
                            }}
                          />
                        )}
                        :<div>{restOfTheWords}</div>
                      </div>
                    </MDTypography>
                  </li>
                );
              })}
            </ul>
          )}
        </MDBox>
      )}
    </>
  );
}

export default Activitys;
