import API from "services/axiosapi";
import { toast } from "react-toastify";
import { put, takeLatest, all } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";

function* listCrewSaga() {
  try {
    yield put({
      type: actionTypes.SET_CREW_LOADING,
    });
    const { data } = yield API.get("api/crew");
    if (data.success) {
      yield put({
        type: actionTypes.LIST_CREW_SUCCESS,
        payload: data,
      });
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.LIST_CREW_FAILED,
        errorMessage: "Unable to Fetch data!",
      });
    }
  } catch (error) {
    toast.error("Unable to Fetch data!");
    yield put({
      type: actionTypes.LIST_CREW_FAILED,
      errorMessage: "Unable to Fetch data!",
    });
  }
}

function* createCrewSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_CREW_LOADING });
    const { data } = yield API.post("api/crew", { ...obj.newData });
    if (data.success) {
      toast.success("success");
      yield put({ type: actionTypes.CREATE_CREW_SUCCESS });
      yield put({ type: actionTypes.LIST_CREW });
    } else {
      toast.error(data.message);
      yield put({ type: actionTypes.CREATE_CREW_FAILED, errorMessage: "Unable to add crew!" });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({ type: actionTypes.CREATE_CREW_FAILED, errorMessage: "Unable to add crew!" });
  }
}

function* deleteCrewSaga(id) {
  try {
    yield put({ type: actionTypes.SET_CREW_LOADING });
    const { data } = yield API.delete(`/api/crew/${id.id}`);
    if (data.success) {
      toast.success("Crew deleted success!");
      yield put({ type: actionTypes.DELETE_CREW_SUCCESS });
      yield put({ type: actionTypes.LIST_CREW });
    } else {
      yield put({ type: actionTypes.DELETE_CREW_FAILED, errorMessage: "Unable delete this else!" });
      toast.error("Unable to delete this else!");
    }
  } catch (error) {
    yield put({ type: actionTypes.DELETE_CREW_FAILED, errorMessage: "Unable delete this catch!" });
    toast.error("Unable to delete this catch1!");
  }
}

function* getCrewDetailsSaga({ id }) {
  try {
    const { data } = yield API.get(`/api/crew/${id}`);
    if (data.status) {
      yield put({ type: actionTypes.GET_CREW_SUCCESS, payload: data.data });
    } else {
      yield put({ type: actionTypes.GET_CREW_FAILED, errorMessage: "Unable to Fetch!" });
      toast.error("Unable to Fetch");
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_CREW_FAILED, errorMessage: "Unable to Fetch!" });
    toast.error("Unable to Fetch");
  }
}

// eslint-disable-next-line require-yield
function* updateCrewSaga(payload) {
  const { id } = payload;
  const { obj } = payload;
  try {
    const { data } = yield API.put(`/api/crew/${id}/update`, { ...obj });
    if (data.status) {
      yield put({ type: actionTypes.UPDATE_CREW_SUCCESS });
      yield put({ type: actionTypes.LIST_CREW });
      toast.success("Update successfully");
    } else {
      toast.error("Unable to update!");
      yield put({ type: actionTypes.UPDATE_CREW_FAILED, errorMessage: "Unable to update!" });
    }
  } catch (error) {
    toast.error("Unable to update!");
    yield put({ type: actionTypes.UPDATE_CREW_FAILED, errorMessage: "Unable to update!" });
  }
}

function* CrewSaga() {
  yield all([
    yield takeLatest(actionTypes.CREATE_CREW, createCrewSaga),
    yield takeLatest(actionTypes.LIST_CREW, listCrewSaga),
    yield takeLatest(actionTypes.DELETE_CREW, deleteCrewSaga),
    yield takeLatest(actionTypes.GET_CREW, getCrewDetailsSaga),
    yield takeLatest(actionTypes.UPDATE_CREW, updateCrewSaga),
  ]);
}

export default CrewSaga;
