/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import CustomSpinner from "examples/CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { filterInvoiceAction } from "redux/invoices/actions";
import { Card, Chip } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import CustomTablePagination from "examples/CustomTablePagination/CustomTablePagination";
import authorsTableData from "../dataList/authorsTableData";
import Action from "../dataList/Action";

function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

// eslint-disable-next-line react/prop-types
function AllInvoice({ status, filteringData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invoiceData, loading } = useSelector((state) => state.InvoiceReducer);
  // const [draftData, setDraftData] = useState([]);
  const [invoiceList, setinvoiceList] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line prettier/prettier, object-shorthand
  const [query, setQuery] = useState({ page: page, limit: pageSize, status, ...filteringData });
  // status: payload.status, page: payload.page, limit: payload.pageLimit
  const handleChange = (event, value) => {
    // eslint-disable-next-line object-shorthand
    setQuery({ ...query, page: value });
    dispatch(filterInvoiceAction({ ...query, page: value, ...filteringData }));
    setPage(value);
  };
  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    // eslint-disable-next-line object-shorthand
    setQuery({ ...query, limit: event.target.value, page: 1, ...filteringData });
    dispatch(
      filterInvoiceAction({ ...query, ...filteringData, page: 1, limit: event.target.value })
    );
  };
  useEffect(() => {
    if (Array.isArray(invoiceList.data)) {
      const { columnData, rowData } = authorsTableData(invoiceList);
      setColumns(columnData);
      setRows(rowData);
    }
  }, [invoiceList]);
  useEffect(() => {
    // eslint-disable-next-line object-shorthand
    dispatch(filterInvoiceAction({ ...query }));
  }, [query]);
  useEffect(() => {
    setPage(invoiceData?.currentPage);
  });
  useEffect(() => {
    // eslint-disable-next-line object-shorthand
    setQuery({ ...query, status: status, ...filteringData });
  }, [status, filteringData]);
  useEffect(() => {
    if (invoiceData.data) {
      const updatedData = invoiceData.data.map((item) => ({
        ...item,
        tabStatus: query.status, // Add the "tabStatus" field with a value of 'active'
      }));
      setinvoiceList({ ...invoiceData, data: updatedData });
    }
  }, [invoiceData]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <MDBox>
      {loading ? (
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CustomSpinner />
        </MDBox>
      ) : (
        <MDBox>
          {invoiceData?.data?.length === 0 ? (
            <MDBox style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ height: "20%", width: "20%", objectFit: "contain" }}
                src="https://img.freepik.com/free-vector/empty-concept-illustration_114360-1253.jpg?w=2000"
                loading="lazy"
                alt=""
              />
            </MDBox>
          ) : (
            <>
              {windowWidth >= 768 ? (
                <DataTable
                  table={{ columns: columns || [], rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  rowData={invoiceData}
                />
              ) : (
                <div>
                  {invoiceData?.data?.map((row) => (
                    <MDBox p="8px 16px 16px">
                      <Card
                        style={{
                          padding: "3%",
                          border: "1px solid black",
                        }}
                      >
                        <div>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Chip
                              style={{
                                color: "#fff",
                                borderRadius: "0.375rem",
                                fontSize: ".765rem",
                                height: "1.6rem",
                                background:
                                  row.status === "draft"
                                    ? "linear-gradient(45deg, rgb(91 91 91), rgb(137 137 137))"
                                    : row.status === "sent"
                                    ? "linear-gradient(45deg, #0089ff, rgb(118 184 255))"
                                    : row.status === "paid"
                                    ? "linear-gradient(45deg, #5db568, #52af3b)"
                                    : row.status === "viewed"
                                    ? "linear-gradient(45deg, #d57700, #e3b87f);"
                                    : row.status === "overdue"
                                    ? "linear-gradient(45deg, #e31000, #ff8989)"
                                    : row.status === "partial"
                                    ? "linear-gradient(45deg, #d0ff1e, #b5dd00)"
                                    : "linear-gradient(45deg, rgb(52, 71, 103), rgb(117 117 117))",
                              }}
                              label={
                                <span style={{ textTransform: "capitalize" }}>{row.status}</span>
                              }
                            />
                            <div>
                              <MDTypography
                                display="block"
                                variant="button"
                                fontWeight="medium"
                                color="text"
                                fontSize=".70rem"
                              >
                                {dateFormatted(row.invoice_date)}
                              </MDTypography>
                              <MDTypography
                                display="flex"
                                justifyContent="end"
                                variant="button"
                                fontWeight="medium"
                                color="text"
                                fontSize=".70rem"
                              >
                                {row.invoice_number}
                              </MDTypography>
                            </div>
                          </div>
                          <MDTypography
                            style={{ fontWeight: "400" }}
                            display="flex"
                            alignItems="center"
                            variant="h6"
                            fontWeight="medium"
                            onClick={() => navigate(`/view_invoice/${row._id}`)}
                          >
                            {row.customer_id.first_name} {row.customer_id.last_name}
                          </MDTypography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <MDTypography
                                style={{ fontSize: "12px", margin: "0" }}
                                display="flex"
                                alignItems="center"
                                variant="h6"
                                fontWeight="medium"
                              >
                                Balance
                              </MDTypography>
                              <MDTypography
                                display="block"
                                variant="button"
                                fontWeight="medium"
                                color="text"
                                fontSize=".70rem"
                              >
                                {row.balance < 0 ? (
                                  <Chip
                                    style={{
                                      color: "#fff",
                                      borderRadius: "0.375rem",
                                      fontSize: ".65rem",
                                      height: "1rem",
                                    }}
                                    color="error"
                                    label={row.balance.toLocaleString(undefined, {
                                      style: "currency",
                                      currency: "USD",
                                    })}
                                  />
                                ) : (
                                  <MDTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="medium"
                                  >
                                    {row.balance.toLocaleString(undefined, {
                                      style: "currency",
                                      currency: "USD",
                                    })}
                                  </MDTypography>
                                )}
                              </MDTypography>
                            </div>
                            <div>
                              <MDTypography
                                style={{ fontSize: "12px", margin: "0" }}
                                display="flex"
                                alignItems="center"
                                variant="h6"
                                fontWeight="medium"
                              >
                                Total
                              </MDTypography>
                              <MDTypography
                                display="block"
                                variant="button"
                                fontWeight="medium"
                                color="text"
                                fontSize=".70rem"
                              >
                                {row.total_price.toLocaleString(undefined, {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </MDTypography>
                            </div>
                          </div>
                          <MDBox display="flex" justifyContent="center" mt="1rem">
                            <Action
                              actionColor="#333745"
                              status={row.status}
                              id={row._id}
                              singleInvoice={row}
                            />
                          </MDBox>
                        </div>
                      </Card>
                    </MDBox>
                  ))}
                </div>
              )}
              <CustomTablePagination
                count={invoiceData?.totalPages}
                page={page}
                onChangePage={handleChange}
                value={pageSize}
                onChangeRowPerPage={handleChangePageSize}
                pageSizeOptions={[10, 20, 30, 40, 50]}
              />
            </>
          )}
        </MDBox>
      )}
    </MDBox>
  );
}

export default AllInvoice;
