/* eslint-disable */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { InputAdornment } from "@mui/material";

// @mui material components
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
// import { InputAdornment } from "@mui/material";

function PlatformSettings({
  data,
  isEditableModeOn,
  onFieldChange,
  which,
  validationStatus,
  setValidationStatus,
  isNew,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const wrapper = {
    display: "flex",
    alignItems: "center"
  }
  const textInputStyle = {
    width:"60%",
  }
  const lableStyle = {
    width: "40%",
  }
  if(windowWidth <= 768){
    wrapper.flexDirection = "column";
    wrapper.alignItems = "";
    textInputStyle.width = "100%";
    lableStyle.width = "100%";
  }
  const handleBlur = (label, value) => {
    const isRequiredFieldEmpty =
      value.trim() === "" && data.find((item) => item.label === label)?.required;
    let validationStatus = "success";

    if (label === "Email") {
      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!emailPattern.test(value)) {
        setValidationStatus((prevStatus) => ({
          ...prevStatus,
          [label]: "error",
        }));
      } else {
        setValidationStatus((prevStatus) => ({
          ...prevStatus,
          [label]: "success",
        }));
      }
    } else {
      setValidationStatus((prevStatus) => ({
        ...prevStatus,
        [label]: isRequiredFieldEmpty ? "error" : validationStatus,
      }));
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Card sx={{ boxShadow: "none", padding: "1rem" }}>
      <MDBox lineHeight={1.25}>
        {data?.map(({ label, value, required, type }) => {
          return (
            <MDBox style={wrapper} mb={0.5}>
              <MDBox style={lableStyle}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {label}
                </MDTypography>
                {isEditableModeOn && required !== false && (
                  <sup style={{ color: "red", fontSize: "10px", marginLeft: "3px" }}>*</sup>
                )}{" "}
              </MDBox>
              <MDBox mt={0.5} style={textInputStyle}>
                <TextField
                  fullWidth
                  value={value}
                  disabled={!isEditableModeOn}
                  onChange={(e) => onFieldChange(which, label, e.target.value)}
                  required={required !== false}
                  type={label === "Phone" ? "number" : "text"}
                  onBlur={(e) => required !== false && handleBlur(label, e.target.value)}
                  error={required !== false && isNew && validationStatus[label] === "error"}
                  helperText={
                    required !== false && isNew && validationStatus[label] === "error"
                      ? label === "Email"
                        ? "Please enter a valid email address"
                        : "This field is required."
                      : ""
                  }
                />
              </MDBox>
            </MDBox>
          );
        })}
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
