/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign, no-underscore-dangle */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import { formatPhoneNumber } from "utils";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Outstanding from "./Outstanding";

const handlePhoneClick = (d) => {
  if (d && d.phone) {
    window.location.href = `tel:${d.phone}`;
  }
};
const handleEmailClick = (d) => {
  if (d && d.email) {
    window.location.href = `mailto:${d.email}`;
  }
};

export default function data(tableData) {
  return {
    columnData: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Address", accessor: "address", align: "left" },
      { Header: "Phone", accessor: "phone", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Outstanding", accessor: "balance", align: "left" },
      { Header: "Past Due", accessor: "overdue", align: "left" },
    ],

    rowData: tableData?.data.map((d) => ({
      name: (
        <Tooltip title="Customer Profile" placement="top">
          <Link to={`/profile/${d._id}`}>
            <MDTypography
              style={{
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "350px",
                textDecoration: "underline",
              }}
              display="block"
              variant="button"
              fontWeight="medium"
            >
              <u>
                {d?.first_name} {d.last_name}
              </u>
            </MDTypography>
          </Link>
        </Tooltip>
      ),
      address: (
        <MDTypography
          display="block"
          variant="button"
          fontWeight="medium"
          style={{
            display: "block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "350px",
          }}
        >
          {d?.property.address} {d?.property.city} {d?.property.state} {d?.property.zip}
        </MDTypography>
      ),
      phone: (
        <Tooltip title="Call" placement="top">
          <MDTypography
            display="block"
            variant="button"
            fontWeight="medium"
            onClick={() => handlePhoneClick(d)}
          >
            <u>{d?.phone && formatPhoneNumber(d?.phone)}</u>
          </MDTypography>
        </Tooltip>
      ),
      email: (
        <Tooltip title="Send Mail" placement="top">
          <MDTypography
            display="block"
            variant="button"
            fontWeight="medium"
            onClick={() => handleEmailClick(d)}
          >
            <u>{d?.email}</u>
          </MDTypography>
        </Tooltip>
      ),
      balance: <Outstanding data={d} />,
      overdue: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          ${d?.overdues || 0}
        </MDTypography>
      ),
    })),
  };
}
