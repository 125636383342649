/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
// import API from "services/axiosapi";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
// import Badge from "@mui/material/Badge";
// import { LOGOUT_USER } from "redux/auth/login/actionTypes";
import { logoutUser } from "redux/actions";
import Tooltip from "@mui/material/Tooltip";
import { getRoleLabel } from "layouts/settings/staff/list/data/authorsTableData";
import makeItRainIcon from "../../assets/images/mail.proton.me-2023-3-1-20-57-48.png";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatched] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const collapseName = location.pathname.replace("/", "");
  const { isUserAuthenticated } = useSelector((state) => state.Login);

  const logoutfun = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("userData");
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (
      (!isUserAuthenticated || isUserAuthenticated === "loggedOut") &&
      !localStorage.getItem("token")
    ) {
      navigate("/login");
    }
  }, [isUserAuthenticated]);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);
  const onClickHandle = () => {
    setMiniSidenav(dispatched, !miniSidenav);
  };
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }
    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const userName = localStorage.getItem("userData");
  const userRole = localStorage.getItem("role");
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let avatarColor = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      avatarColor += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return avatarColor;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0]?.toUpperCase()}`,
    };
  }

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route} onClick={onClickHandle}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });
  const handleTest = () => {
    setMiniSidenav(dispatched, !miniSidenav);
  };
  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox textAlign="center" display="flex" justifyContent="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={-7}
          right={-6}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer", zIndex: "999" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }} onClick={handleTest}>
              {miniSidenav ? "menu" : "close"}
            </Icon>
          </MDTypography>
        </MDBox>
        {/* <MDBox display="flex" alignItems="center" justifyContent="space-between" width="100%">
          {brand && (
            <MDBox
              onClick={handleTest}
              component="img"
              src={makeItRainIcon}
              alt="Brand"
              width="2rem"
            />
          )}
          <MDBox
            component={NavLink}
            to="/"
            // width={!brandName && "100%"}
            width="100%"
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox> */}
        <MDBox style={{ height: "100%", paddingTop: "1rem" }}>
          <SidenavCollapse
            name={
              <div
                style={{
                  overflowX: "hidden",
                  height: "2rem",
                  display: "flex",
                  alignItems: "center",
                  textOverflow: "ellipsis",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "800",
                  }}
                >
                  <MDBox
                    component={NavLink}
                    to="/"
                    // width={!brandName && "100%"}
                    width="100%"
                    sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                  >
                    <MDTypography
                      component="h6"
                      variant="button"
                      fontWeight="medium"
                      color={textColor}
                    >
                      {brandName}
                    </MDTypography>
                  </MDBox>
                </span>
              </div>
            }
            icon={
              <MDBox
                onClick={handleTest}
                component="img"
                src={makeItRainIcon}
                alt="Brand"
                width="2rem"
              />
            }
            // eslint-disable-next-line react/jsx-boolean-value
            noCollapse={true}
          />
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <MDBox>
          {renderRoutes}
          <Button fullWidth onClick={logoutfun} style={{ padding: "0" }}>
            <SidenavCollapse
              style={{ marginLeft: "-9px", marginTop: "0" }}
              name="Logout"
              icon={<Icon fontSize="small">login</Icon>}
              // eslint-disable-next-line react/jsx-boolean-value
              noCollapse={true}
            />
          </Button>
        </MDBox>

        <MDBox
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            paddingBlock: "1rem",
          }}
        >
          {userName && (
            <MDBox
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <SidenavCollapse
                name={
                  <Tooltip title={getRoleLabel(userRole)} placement="top">
                    <div
                      style={{
                        overflowX: "hidden",
                        height: "2rem",
                        display: "flex",
                        alignItems: "center",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "800",
                        }}
                      >
                        {userName && userName}
                      </span>
                    </div>
                  </Tooltip>
                }
                icon={<Avatar {...stringAvatar(userName)} />}
                // eslint-disable-next-line react/jsx-boolean-value
                noCollapse={true}
              />
            </MDBox>
          )}
        </MDBox>
      </List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
