/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import CardMedia from "@mui/material/CardMedia";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import CustomSpinner from "examples/CustomLoader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Overview page components
import PlatformSettings from "layouts/customer/profile/components/PlatformSettings";
import FileInput from "layouts/customer/fileInput";

// Data
import { createCustomer } from "redux/actions";

// Images
import defaultImg from "assets/images/default_img.png";
import pdf from "assets/images/PDF_file_icon.svg.webp";
import { Autocomplete, Card, IconButton, Input } from "@mui/material";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
// import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";

function Overview() {
  const names = ["South", "South1", "North", "North1", "West", "East"];
  const ITEM_HEIGHT = 20;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        fontSize: 12,
        width: 250,
      },
    },
  };

  const editor = useRef(null);

  const [timeWindow, setTimeWindow] = useState("");
  const [primaryData, setPrimaryData] = useState([]);
  const [secondaryData, setSecondaryData] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [billingData, setBillingData] = useState([]);
  const [isBillingAddressSame, setBillingAddressSame] = useState(false);
  const [irrigationData, setIrrigationData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [isEditableModeOn, setEditableModeOn] = useState(false);
  const [propertyPic, setPropertyPic] = useState(null);
  const [bluePrintPic, setBluePrintPic] = useState(null);
  const [zone, setZone] = useState([]);
  const [zoneModel, setZoneModel] = useState([""]);
  const [isPropertyBlurred, setIsPropertyBlurred] = useState(false);
  const [isBlueprintBlurred, setIsBlueprintBlurred] = useState(false);
  const [validationStatus, setValidationStatus] = useState({});
  const [serviceCallNote, setServiceCallNote] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleBlur = () => {
    setIsPropertyBlurred(true);
  };
  const handleBuleprintBlur = () => {
    setIsBlueprintBlurred(true);
  };
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { customerData, createCustomerSuccess, loading } = useSelector((state) => state.Customers);

  const handleNoPath = (ev) => {
    ev.currentTarget.src = defaultImg;
    ev.currentTarget.onError = null;
  };
  useEffect(() => {
    setOriginalData();
  }, []);

  useEffect(() => {
    if (createCustomerSuccess) {
      setTimeout(() => navigate("/customers"), 1500);
    }
  }, [createCustomerSuccess]);
  const handleSelect = (e, v) => {
    if (v !== null) {
      setTimeWindow(v.value);
    } else {
      setTimeWindow("");
    }
  };
  const handleMultiSelect = (event) => {
    const {
      target: { value },
    } = event;
    setZone(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getApiField = (which, label) => {
    let model;
    if (which === "BUSINESS") {
      model = businessData;
    } else if (which === "IRRIGATION") {
      model = irrigationData;
    } else if (which === "PRIMARY") {
      model = primaryData;
    } else if (which === "SECONDARY") {
      model = secondaryData;
    } else if (which === "PROPERTY") {
      model = propertyData;
    } else if (which === "BILLING") {
      model = billingData;
    }
    let apiField = model.find((data) => {
      return data.label === label;
    });
    return apiField.value;
  };

  const getZone = () => {
    return zoneModel.filter((zone) => zone !== "");
  };

  const getStaticCustomer = () => {
    return {
      business_name: getApiField("BUSINESS", "Business"),
      first_name: getApiField("PRIMARY", "First Name"),
      last_name: getApiField("PRIMARY", "Last Name"),
      email: getApiField("PRIMARY", "Email"),
      phone: getApiField("PRIMARY", "Phone"),
      secondary_contact: {
        first_name: getApiField("SECONDARY", "First Name"),
        last_name: getApiField("SECONDARY", "Last Name"),
        email: getApiField("SECONDARY", "Email"),
        phone: getApiField("SECONDARY", "Phone"),
      },
      property: {
        address: getApiField("PROPERTY", "Address"),
        city: getApiField("PROPERTY", "City"),
        state: getApiField("PROPERTY", "State"),
        zip: getApiField("PROPERTY", "Zip"),
      },
      billing: {
        address: getApiField("BILLING", "Address"),
        city: getApiField("BILLING", "City"),
        state: getApiField("BILLING", "State"),
        zip: getApiField("BILLING", "Zip"),
      },
      property_image: propertyPic,
      blueprint_image: bluePrintPic,
      zones: getZone(),
      irrigation_system: {
        main_location: getApiField("IRRIGATION", "Main Location"),
        controller: getApiField("IRRIGATION", "Controller"),
        pump_or_shut_off: getApiField("IRRIGATION", "Pump / Shut-Off"),
        miscellaneous: getApiField("IRRIGATION", "Miscellaneous"),
      },
      service_call_time_window: timeWindow,
      service_call_note: serviceCallNote,
    };
  };

  const setOriginalData = () => {
    setBusinessData([{ label: "Business", value: "", required: true }]);
    setPrimaryData([
      {
        label: "First Name",
        value: "",
        required: true,
      },
      { label: "Last Name", value: "", required: true },
      { label: "Email", value: "", required: true },
      { label: "Company", value: "", required: false },
      { label: "Phone", value: "", required: true },
    ]);

    setSecondaryData([
      {
        label: "First Name",
        value: "",
        required: false,
      },
      { label: "Last Name", value: "", required: false },
      { label: "Email", value: "", required: false },
      { label: "Phone", value: "", required: false },
    ]);

    setPropertyData([
      {
        label: "Address",
        value: "",
        required: true,
      },
      { label: "City", value: "", required: true },
      { label: "State", value: "", required: true },
      { label: "Zip", value: "", required: true },
    ]);

    setBillingData([
      {
        label: "Address",
        value: "",
        required: true,
      },
      { label: "City", value: "", required: true },
      { label: "State", value: "", required: true },
      { label: "Zip", value: "", required: true },
    ]);
    setIrrigationData([
      {
        label: "Main Location",
        value: "",
        required: false,
      },
      { label: "Controller", value: "", required: false },
      {
        label: "Pump / Shut-Off",
        value: "",
        required: false,
      },
      {
        label: "Miscellaneous",
        value: "",
        required: false,
      },
    ]);
  };

  const removeNullProperties = (obj) => {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (value !== null) {
        newObj[key] = value;
      }
    }
    return newObj;
  };

  const onClickAdd = (e) => {
    e.preventDefault();
    const customer = getStaticCustomer();
    const cleanedCustomer = removeNullProperties(customer);
    if (Object.values(validationStatus).every((status) => status === "success")) {
      if (!isPropertyBlurred && !isBlueprintBlurred) {
        dispatch(createCustomer(cleanedCustomer));
        navigate("/customers");
      }
    }
  };

  const onFieldChange = (which, label, value) => {
    if (which === "BUSINESS") {
      setBusinessData(
        businessData.map((business) => {
          if (business.label === label) {
            return { ...business, value };
          } else return business;
        })
      );
    }

    if (which === "IRRIGATION") {
      setIrrigationData(
        irrigationData.map((irrigation) => {
          if (irrigation.label === label) {
            return { ...irrigation, value };
          } else return irrigation;
        })
      );
    }

    if (which === "BILLING") {
      setBillingData(
        billingData.map((billing) => {
          if (billing.label === label) {
            return { ...billing, value };
          } else return billing;
        })
      );
    }
    if (isBillingAddressSame) {
      setBillingData(
        propertyData.map((property) => {
          if (property.label === label) {
            return { ...property, value };
          } else return property;
        })
      );
    }
    if (which === "PRIMARY") {
      setPrimaryData(
        primaryData.map((primary) => {
          if (primary.label === label) {
            return { ...primary, value };
          } else return primary;
        })
      );
    }

    if (which === "SECONDARY") {
      setSecondaryData(
        secondaryData.map((secondary) => {
          if (secondary.label === label) {
            return { ...secondary, value };
          } else return secondary;
        })
      );
    }

    if (which === "PROPERTY") {
      setPropertyData(
        propertyData.map((property) => {
          if (property.label === label) {
            return { ...property, value };
          } else return property;
        })
      );
    }
  };

  const onPropertyPicChange = (e) => {
    const file = e.target.value;
    const allowedExtensions = ["png", "jpg", "jpeg", "pdf", "webp"];
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(extension)) {
        setIsPropertyBlurred(false);
        setPropertyPic(e.target.value);
      } else {
        setIsPropertyBlurred(true);
        // toast.error("Only PNG or JPG files are allowed.");
      }
    }
  };
  // const onPropertyPicChange = (e) => {
  //   const file = e.target.value; // Get the selected file
  //   const allowedExtensions = ["png", "jpg", "jpeg", "pdf", "webp"];
  //   const maxSize = 1 * 1024 * 1024; // 5MB in bytes

  //   if (file) {
  //     const extension = file.name.split(".").pop().toLowerCase();

  //     if (allowedExtensions.includes(extension) && file.size <= maxSize) {
  //       setIsPropertyBlurred(false);
  //       setPropertyPic(e.target.value);
  //     } else {
  //       setIsPropertyBlurred(true);
  //       // toast.error("Only PNG, JPG, PDF, or WebP files up to 5MB are allowed.");
  //     }
  //   }
  // };

  const onBluePrintChange = (e) => {
    const file = e.target.value;
    const allowedExtensions = ["png", "jpg", "jpeg", "pdf", "webp"];

    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(extension)) {
        setIsBlueprintBlurred(false);
        setBluePrintPic(e.target.value);
      } else {
        setIsBlueprintBlurred(true);
        // toast.error("Only PNG or JPG files are allowed.");
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index === zoneModel.length - 1 && zoneModel[zoneModel.length - 1] !== "") {
        setZoneModel([...zoneModel, ""]);
      }
    }
  };

  const handleAddZone = (event) => {
    event.preventDefault();
    if (zoneModel[zoneModel.length - 1] !== "") {
      setZoneModel([...zoneModel, ""]);
    }
  };

  const handleZoneChange = (e, index) => {
    setZoneModel(
      zoneModel.map((zone, i) => {
        if (index === i) {
          return e.target.value;
        }
        return zone;
      })
    );
  };

  const onDeleteZone = (index) => {
    setZoneModel(zoneModel.filter((zone, i) => index !== i));
  };
  useEffect(() => {
    if (isBillingAddressSame) {
      setBillingData(propertyData);
    } else {
      setBillingData([
        {
          label: "Address",
          value: "",
          required: true,
        },
        { label: "City", value: "", required: true },
        { label: "State", value: "", required: true },
        { label: "Zip", value: "", required: true },
      ]);
    }
  }, [isBillingAddressSame]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return loading ? (
    <CustomSpinner />
  ) : (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox display="flex" justifyContent="space-between">
        <MDTypography variant="h2">Add New Customer</MDTypography>
        <SideNavActionBtn />
      </MDBox>
      <MDBox component="form" role="form" autoComplete="no-autofill" onSubmit={onClickAdd}>
        <MDBox mb={3}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            flexDirection={windowWidth <= 768 && "column"}
          >
            <MDBox width={windowWidth <= 768 ? "100%" : "45%"}>
              <MDTypography variant="h6" fontWeight="medium" mb={2} mt={3}>
                Primary Contact
              </MDTypography>
              <PlatformSettings
                validationStatus={validationStatus}
                setValidationStatus={setValidationStatus}
                data={primaryData}
                isEditableModeOn={true}
                onFieldChange={onFieldChange}
                which={"PRIMARY"}
                isNew={true}
              />
            </MDBox>
            <MDBox width={windowWidth <= 768 ? "100%" : "45%"}>
              <MDTypography variant="h6" fontWeight="medium" mb={2} mt={3}>
                Secondary Contact
              </MDTypography>
              <PlatformSettings
                validationStatus={validationStatus}
                setValidationStatus={setValidationStatus}
                data={secondaryData}
                isEditableModeOn={true}
                onFieldChange={onFieldChange}
                which={"SECONDARY"}
                isNew={true}
              />
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            flexDirection={windowWidth <= 768 && "column"}
          >
            <MDBox width={windowWidth <= 768 ? "100%" : "45%"}>
              <MDTypography variant="h6" fontWeight="medium" mb={2} mt={3}>
                Property Address
              </MDTypography>
              <PlatformSettings
                validationStatus={validationStatus}
                setValidationStatus={setValidationStatus}
                data={propertyData}
                isEditableModeOn={true}
                onFieldChange={onFieldChange}
                which={"PROPERTY"}
                isNew={true}
              />
              <FormControlLabel
                sx={{ my: 3 }}
                control={
                  <Checkbox
                    checked={isBillingAddressSame}
                    onChange={() => setBillingAddressSame(!isBillingAddressSame)}
                  />
                }
                label="billing address same as property address"
              />
            </MDBox>
            <MDBox width={windowWidth <= 768 ? "100%" : "45%"}>
              <MDTypography variant="h6" fontWeight="medium" mb={2} mt={3}>
                Billing Address
              </MDTypography>
              <PlatformSettings
                validationStatus={validationStatus}
                setValidationStatus={setValidationStatus}
                data={isBillingAddressSame ? propertyData : billingData}
                isEditableModeOn={!isBillingAddressSame}
                onFieldChange={onFieldChange}
                which={"BILLING"}
                isNew={true}
              />
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            flexDirection={windowWidth <= 768 && "column"}
          >
            <MDBox width={windowWidth <= 768 ? "100%" : "45%"}>
              <MDTypography variant="h6" fontWeight="medium" mb={2}>
                Photo Of Property
              </MDTypography>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                  marginBottom: "20px",
                }}
              >
                {propertyPic &&
                propertyPic instanceof File &&
                propertyPic.type === "application/pdf" ? (
                  <CardMedia
                    src={pdf}
                    alt="Priview"
                    component="img"
                    height="300"
                    onError={handleNoPath}
                    elementType="Div"
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { md } }) => md,
                      objectFit: "cover",
                      objectPosition: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                ) : (
                  <>
                    {propertyPic && propertyPic instanceof File && (
                      <CardMedia
                        src={URL.createObjectURL(propertyPic)}
                        alt="Priview"
                        component="img"
                        height="300"
                        elementType="Div"
                        onError={handleNoPath}
                        sx={{
                          maxWidth: "100%",
                          margin: 0,
                          boxShadow: ({ boxShadows: { md } }) => md,
                          objectFit: "cover",
                          objectPosition: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              {isPropertyBlurred && (
                <MDTypography variant="h6" color="error" fontSize=".85rem">
                  Only PNG, JPG/JPEG, PDF, and WEBP files are allowed for upload.
                </MDTypography>
              )}
              <div style={{ display: "flex", justifyContent: "left" }}>
                <FileInput
                  id="file-input"
                  style={{ display: "none" }}
                  onChange={onPropertyPicChange}
                  onBlur={handleBlur}
                />
              </div>
            </MDBox>
            <MDBox width={windowWidth <= 768 ? "100%" : "45%"}>
              <MDTypography variant="h6" fontWeight="medium" mb={2}>
                Blueprint Of Irrigation System
              </MDTypography>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                  marginBottom: "20px",
                }}
              >
                {bluePrintPic &&
                bluePrintPic instanceof File &&
                bluePrintPic.type === "application/pdf" ? (
                  <CardMedia
                    src={pdf}
                    alt="Priview"
                    component="img"
                    onError={handleNoPath}
                    height="300"
                    elementType="Div"
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { md } }) => md,
                      objectFit: "cover",
                      objectPosition: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                ) : (
                  <>
                    {bluePrintPic && bluePrintPic instanceof File && (
                      <CardMedia
                        src={URL.createObjectURL(bluePrintPic)}
                        component="img"
                        height="300"
                        elementType="Div"
                        onError={handleNoPath}
                        sx={{
                          maxWidth: "100%",
                          margin: 0,
                          boxShadow: ({ boxShadows: { md } }) => md,
                          objectFit: "cover",
                          objectPosition: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              {isBlueprintBlurred && (
                <MDTypography variant="h6" color="error" fontSize=".85rem">
                  Only PNG, JPG/JPEG, PDF, and WEBP files are allowed for upload.
                </MDTypography>
              )}
              <div style={{ display: "flex", justifyContent: "left" }}>
                <FileInput
                  label="Click here to upload file"
                  onChange={onBluePrintChange}
                  onBlur={handleBuleprintBlur}
                />
              </div>
            </MDBox>
          </MDBox>
          {/* <MDBox
            display="flex"
            justifyContent="space-between"
            width="100%"
            flexDirection={windowWidth <= 768 && "column"}
          > */}
          {/* <MDBox width={windowWidth <= 768 ? "100%" : "45%"}>
              <MDTypography variant="h6" fontWeight="medium" mt={3} mb={1}>
                Service call time window
              </MDTypography>
              <Card style={{ padding: "1rem" }}>
                <MDBox>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleSelect}
                    options={[
                      { label: "2:30am to 6:30pm", value: 10 },
                      { label: "12:30am to 5:30pm", value: 20 },
                      { label: "1:30am to 7:30pm", value: 30 },
                    ]}
                    sx={{ padding: "0 1rem 0 1rem" }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </MDBox>
              </Card>
            </MDBox> */}

          {/* </MDBox> */}
          <MDBox
            display="flex"
            justifyContent="space-between"
            width="100%"
            flexDirection={windowWidth <= 768 && "column"}
          >
            <MDBox width={windowWidth <= 768 ? "100%" : "45%"}>
              <MDTypography variant="h6" fontWeight="medium" mb={2} mt={3}>
                Irrigation System Info
              </MDTypography>
              <Card>
                <MDBox>
                  <PlatformSettings
                    validationStatus={validationStatus}
                    setValidationStatus={setValidationStatus}
                    data={irrigationData}
                    isEditableModeOn={true}
                    onFieldChange={onFieldChange}
                    which={"IRRIGATION"}
                    isNew={true}
                  />
                </MDBox>
              </Card>
            </MDBox>
            <MDBox width={windowWidth <= 768 ? "100%" : "45%"}>
              <MDTypography variant="h6" fontWeight="medium" mb={2} mt={3}>
                Service call notes
              </MDTypography>
              <Card>
                <MDBox p={3}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label="Service call notes"
                    multiline
                    rows={6}
                    onChange={(e) => setServiceCallNote(e.target.value)}
                  />
                </MDBox>
              </Card>
            </MDBox>
          </MDBox>
          <MDBox width={windowWidth <= 768 ? "100%" : "45%"}>
            <MDBox>
              <Typography variant="h6" sx={{ mt: 3 }}>
                Irrigation zones
              </Typography>
            </MDBox>
            <Card style={{ padding: "1rem" }}>
              <MDBox width="100%" display="flex" flexDirection="column">
                {zoneModel.map((zone, index) => (
                  <MDBox display="flex" alignItems="center" key={index}>
                    <TextField
                      fullWidth
                      id="outlined-size-normal"
                      placeholder="Click enter to add another zone"
                      type="text"
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onChange={(e) => handleZoneChange(e, index)}
                      value={zoneModel[index]}
                      md={3}
                      style={{ paddingTop: "10px" }}
                    />
                    {index === 0 && (
                      <IconButton
                        onClick={handleAddZone}
                        aria-label="add"
                        size="small"
                        style={{ marginLeft: "5px" }}
                      >
                        <AddIcon color="info" fontSize="small" />
                      </IconButton>
                    )}
                    {index > 0 && (
                      <>
                        <IconButton
                          onClick={() => onDeleteZone(index)}
                          aria-label="delete"
                          size="small"
                        >
                          <DeleteIcon color="error" fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </MDBox>
                ))}
              </MDBox>
            </Card>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item style={{ width: "100%" }} />
            <MDBox width="100%" display="flex" justifyContent="center" mt={3}>
              {" "}
              <MDButton type="submit" variant="gradient" color="info" style={{ marginTop: "24px" }}>
                Save
              </MDButton>
            </MDBox>
          </Grid>
          <ToastContainer style={{ fontSize: "15px" }} />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
