import API from "services/axiosapi";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";

const { takeLatest, all, put } = require("redux-saga/effects");

function* getSendMessageSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_MESSAGE_LOADING });
    const { data } = yield API.get("/api/messages/find-messages", { params: payload });
    if (data.success) {
      yield put({ type: actionTypes.GET_MESSAGES_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_MESSAGES_FAILDE,
        errorMessage: "Unable to Fetch data!",
      });
      toast.error("Unable to Fetch data!");
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_MESSAGES_FAILDE,
      errorMessage: "Unable to Fetch data!",
    });
    toast.error("Unable to Fetch data!");
  }
}

function* postMessageSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_MESSAGE_LOADING });
    const { data } = yield API.post("/api/messages", obj);
    if (data.success) {
      yield put({ type: actionTypes.POST_MESSAGE_SUCCESS });
      toast.success("Send message Successfull!");
    } else {
      yield put({ type: actionTypes.POST_MESSAGE_FAILED, errorMessage: "Unabel to send message!" });
      toast.error("Unabel to send message!");
    }
  } catch (error) {
    yield put({ type: actionTypes.POST_MESSAGE_FAILED, errorMessage: "Unabel to send message!" });
    toast.error("Unabel to send message!");
  }
}

function* MessageSaga() {
  yield all([yield takeLatest(actionTypes.GET_MESSAGES, getSendMessageSaga)]);
  yield all([yield takeLatest(actionTypes.POST_MESSAGE, postMessageSaga)]);
}

export default MessageSaga;
