import axios from "axios";
import Cookies from "js-cookie";

export const BASE_URL = "https://api.makeitrainct.site/";
// "https://coup.aldebaranmedia.com/admin/"
// export const BASE_URL = "http://192.168.29.90:3000/";
// export const BASE_URL = "http://192.168.29.160:3000/";
const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use((request) => {
  if (request.baseURL.endsWith("login")) {
    return request;
  }
  request.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
  if (!request.headers["Content-Type"]) {
    request.headers["Content-Type"] = "application/json";
  }
  return request;
});

API.interceptors.response.use(
  (response) => {
    if (response.config.url === "/api/login" && response.data.token) {
      Cookies.set("token", response.data.token);
      localStorage.setItem("token", response.data.token);
    }
    if (response.config.url === "admin/auth/logout") {
      Cookies.remove("token");
      localStorage.clear();
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default API;
