/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign, no-underscore-dangle */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
// import { CiStreamOn } from "react-icons/ci";
import CircleIcon from "@mui/icons-material/Circle";
import SendMessageBtn from "./SendMessageBtn";
import WinterizationCheckBtn from "./WinterizationCheckBtn";
import SpringActCheckBtn from "./SpringActCheckBtn";
// import Customer from "./Customer";

function formatTimeSlot(timeSlot) {
  if (!timeSlot) {
    return "";
  }

  const [startTime, endTime] = timeSlot.split(" to ");

  const startHour = parseInt(startTime, 10) % 12 || 12;
  const endHour = parseInt(endTime, 10) % 12 || 12;

  const startPeriod = parseInt(startTime, 10) < 12 ? "AM" : "PM";
  const endPeriod = parseInt(endTime, 10) < 12 ? "AM" : "PM";

  const formattedStartTime = `${startHour} ${startPeriod}`;
  const formattedEndTime = `${endHour} ${endPeriod}`;

  return `${formattedStartTime} to ${formattedEndTime}`;
}

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const date = String(currentDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${date}`;
}
function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(inputDate);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export default function data(tableData) {
  return {
    columnData: [
      { Header: "City Code", accessor: "city_code", align: "left" },
      { Header: "Address", accessor: "address", align: "left" },
      { Header: "Customer", accessor: "customer", align: "left" },
      { Header: "Upcoming Appointment", accessor: "date_time", align: "left" },
      { Header: "Message", accessor: "message", align: "left" },
      { Header: "Winterization", accessor: "winterization", align: "left" },
      { Header: "Spring Activation", accessor: "spring_activation", align: "left" },
    ],

    rowData: tableData?.map((d) => ({
      city_code: (
        <MDTypography variant="button" fontWeight="medium" width="60px">
          {d.property?.zip}
        </MDTypography>
      ),
      address: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d.property?.address} {d.property?.city} {d.property?.state} {d.property?.zip}
        </MDTypography>
      ),
      customer: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d.first_name} {d.last_name}
        </MDTypography>
      ),
      date_time: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          <MDBox display="flex" alignItems="center" gap="3px">
            {d.appointments[0]?.date === getCurrentDate() && <CircleIcon color="success" />}
            {dateFormatted(d.appointments[0]?.date)}
          </MDBox>
          <MDBox>{formatTimeSlot(d.appointments[0]?.slot)}</MDBox>
        </MDTypography>
      ),
      message: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          <SendMessageBtn details={d} />
        </MDTypography>
      ),
      winterization: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          <WinterizationCheckBtn details={d} />
        </MDTypography>
      ),
      spring_activation: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          <SpringActCheckBtn details={d} />
        </MDTypography>
      ),
    })),
  };
}
