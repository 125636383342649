import API from "services/axiosapi";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";

const { takeLatest, all, put } = require("redux-saga/effects");

function* getPermissionEmployeeList() {
  try {
    yield put({
      type: actionTypes.SET_PERMISSION_LOADING,
    });
    const { data } = yield API.get("api/employee/employeePermission");
    if (data.success) {
      yield put({
        type: actionTypes.GET_PERMISSION_EMPLOYEE_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.GET_PERMISSION_EMPLOYEE_LIST_FAILED,
        errorMessage: "Unable to Fetch data!",
      });
    }
  } catch (error) {
    toast.error("Unable to Fetch data!");
    yield put({
      type: actionTypes.GET_PERMISSION_EMPLOYEE_LIST_FAILED,
      errorMessage: "Unable to Fetch data!",
    });
  }
}

function* updateEmployeePermissionRoleSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_PERMISSION_LOADING });
    const { data } = yield API.put(`/api/employee/employeePermission/${obj.id}/update`, {
      role_name: obj.role_name,
    });
    if (data.success) {
      yield put({ type: actionTypes.UPDATE_EMPLOYEE_PERMISSION_ROLE_SUCCESS });
      yield put({ type: actionTypes.GET_PERMISSION_EMPLOYEE_LIST });
      toast.success("Updated success!");
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.UPDATE_EMPLOYEE_PERMISSION_ROLE_FAILED,
        errorMessage: "Unable to delete from here!",
      });
    }
  } catch (error) {
    toast.error("Unable to delete from here!");
    yield put({
      type: actionTypes.UPDATE_EMPLOYEE_PERMISSION_ROLE_FAILED,
      errorMessage: "Unable to delete from here!",
    });
  }
}

function* PermissionSaga() {
  yield all([
    yield takeLatest(actionTypes.GET_PERMISSION_EMPLOYEE_LIST, getPermissionEmployeeList),
    yield takeLatest(actionTypes.UPDATE_EMPLOYEE_PERMISSION_ROLE, updateEmployeePermissionRoleSaga),
  ]);
}

export default PermissionSaga;
