/* eslint-disable */
// @mui material components
// Material Dashboard 2 React components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import CustomSpinner from "examples/CustomLoader";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupsIcon from "@mui/icons-material/Groups";
import DeleteIcon from "@mui/icons-material/Delete";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Pagination,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
// import { addDays } from "date-fns";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "./holidays/data/authorsTableData";
import {
  weekOffDataAction,
  addHolidaysAction,
  updateWeekOffAction,
  listHolidaysAction,
} from "redux/Holidays/actions";
import Staff from "./staff/Staff";
import Crews from "./crews/Crews";
import Permission from "./permission/Permission";
import { toast } from "react-toastify";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import BadgeIcon from "@mui/icons-material/Badge";
import MDTypography from "components/MDTypography";
import Swal from "sweetalert2";
import { deleteHolidaysAction } from "redux/Holidays/actions";
import CustomTablePagination from "examples/CustomTablePagination/CustomTablePagination";
import Tooltip from "@mui/material/Tooltip";

const INITIAL_ADD_CHANGES = {
  description: "",
  end_date: "",
  start_date: "",
};
function formatDateToYYYYMMDD(dateString) {
  const originalDate = new Date(dateString);
  const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${originalDate.getDate().toString().padStart(2, "0")}`;
  return formattedDate;
}
function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}
function Settings() {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const { loading, settingsData, weekOffData } = useSelector((state) => state.SettingsReducer);
  const [tab, setTab] = useState("holiday");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [addChanges, setAddChanges] = useState(INITIAL_ADD_CHANGES);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [daterange, setRanges] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);
  const [validationError, setValidationError] = useState({
    startDateError: false,
    endDateError: false,
    description: false,
  });
  const addHolidayBtn = { width: "25%" };
  if (windowWidth <= 768) {
    addHolidayBtn.width = "100%";
    addHolidayBtn.display = "flex";
    addHolidayBtn.justifyContent = "center";
  }
  const days = [
    { label: "Monday", value: weekOffData?.monday || false },
    { label: "Tuesday", value: weekOffData?.tuesday || false },
    { label: "Wednesday", value: weekOffData?.wednesday || false },
    { label: "Thursday", value: weekOffData?.thursday || false },
    { label: "Friday", value: weekOffData?.friday || false },
    { label: "Saturday", value: weekOffData?.saturday || false },
    { label: "Sunday", value: weekOffData?.sunday || false },
  ];
  const updateWeekOffInitial = {
    monday: weekOffData.monday,
    friday: weekOffData.friday,
    saturday: weekOffData.saturday,
    sunday: weekOffData.sunday,
    thursday: weekOffData.thursday,
    tuesday: weekOffData.tuesday,
    wednesday: weekOffData.wednesday,
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleCheck = (label, value) => {
    if (value === true) {
      value = false;
    } else {
      value = true;
    }
    const day = label.toLowerCase();
    const userInput = {
      [day]: value,
    };
    for (const key in userInput) {
      if (updateWeekOffInitial.hasOwnProperty(key)) {
        updateWeekOffInitial[key] = userInput[key];
      }
    }
    dispatch(updateWeekOffAction(updateWeekOffInitial));
  };
  const dateHandler = (range) => {
    const formattedStartDate = formatDateToYYYYMMDD(range.selection.startDate);
    const formattedEndDate = formatDateToYYYYMMDD(range.selection.endDate);
    setAddChanges({ ...addChanges, start_date: formattedStartDate, end_date: formattedEndDate });
    setRanges([range.selection]);
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#a5bd4b",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteHolidaysAction({ id }));
      }
    });
  };
  const handleReasonAdd = (e) => {
    const inputValue = e.target.value;
    const reasone = inputValue.replace(/\s+/g, " ").trim();
    if (reasone.length > 0) {
      setValidationError({ ...validationError, description: false });
    } else {
      setValidationError({ ...validationError, description: true });
    }
    setAddChanges({ ...addChanges, description: reasone });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      addChanges.description === "" ||
      addChanges.end_date === "" ||
      addChanges.start_date === ""
    ) {
      let errorMessage = "Please fill all required fields: ";
      if (addChanges.description === "") {
        errorMessage += "Description";
        setValidationError({ ...validationError, description: true });
      } else {
        setValidationError({ ...validationError, description: false });
      }
      if (addChanges.start_date === "") {
        errorMessage += " Start Date ";
        setValidationError({ ...validationError, startDateError: true });
      } else {
        setValidationError({ ...validationError, startDateError: false });
      }
      if (addChanges.end_date === "") {
        errorMessage += " End Date ";
        setValidationError({ ...validationError, endDateError: true });
      } else {
        setValidationError({ ...validationError, endDateError: false });
      }
      toast.error(errorMessage);
    } else if (
      validationError.startDateError ||
      validationError.endDateError ||
      validationError.description
    ) {
      toast.error(errorMessage);
    } else if (addChanges.start_date > addChanges.end_date) {
      toast.error("Start date should be greater than end_date");
    } else {
      dispatch(addHolidaysAction(addChanges));
      setRanges([
        {
          startDate: "",
          endDate: "",
          key: "selection",
        },
      ]);
    }
  };

  const handleChange = (event, value) => {
    dispatch(
      listHolidaysAction({ path: { tab }, paginationData: { page: value, limit: pageSize } })
    );
    setPage(value);
  };
  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(1);
    dispatch(
      listHolidaysAction({
        path: { tab },
        paginationData: { page: 1, limit: event.target.value },
      })
    );
  };
  useEffect(() => {
    if (settingsData?.data) {
      const pageCount = Math.ceil(settingsData.total / pageSize);
      setPageCount(pageCount);
    }
  }, [settingsData]);
  useEffect(() => {
    if (Array.isArray(settingsData?.data)) {
      const { columnData, rowData } = authorsTableData(settingsData);
      setColumns(columnData);
      setRows(rowData);
    }
  }, [settingsData]);
  useEffect(() => {
    if (tab !== "permission") {
      dispatch(
        listHolidaysAction({ path: { tab }, paginationData: { page: page, limit: pageSize } })
      );
    }
    if (!settingsData.data) {
      if (tab === "holiday") {
        dispatch(weekOffDataAction());
      }
    }
  }, [tab]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target))
        setShowCalendar(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <DashboardLayout>
      {" "}
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <MDTypography variant="h2">Settings</MDTypography>
        <SideNavActionBtn />
      </MDBox>
      <TabContext value={tab} mt={1}>
        <MDBox>
          <Tabs
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                transition: "none",
              },
            }}
            value={tab}
          >
            <Tab
              sx={{
                backgroundColor: tab === "holiday" ? "#333745" : "white",
              }}
              label={
                windowWidth <= 768 ? (
                  <Tooltip title="Holiday" placement="top">
                    <ContentPasteOffIcon />
                  </Tooltip>
                ) : (
                  "Holiday"
                )
              }
              value={"holiday"}
            />{" "}
            <Tab
              sx={{
                backgroundColor: tab === "employee" ? "#333745" : "white",
              }}
              label={
                windowWidth <= 768 ? (
                  <Tooltip title="Employee" placement="top">
                    <BadgeIcon />
                  </Tooltip>
                ) : (
                  "Staff"
                )
              }
              value={"employee"}
            />{" "}
            <Tab
              sx={{
                backgroundColor: tab === "crew" ? "#333745" : "white",
              }}
              label={
                windowWidth <= 768 ? (
                  <Tooltip title="Crew" placement="top">
                    <GroupsIcon />
                  </Tooltip>
                ) : (
                  "Crew(s)"
                )
              }
              value={"crew"}
            />{" "}
            <Tab
              sx={{
                backgroundColor: tab === "permission" ? "#333745" : "white",
              }}
              label={
                windowWidth <= 768 ? (
                  <Tooltip title="Permission" placement="top">
                    <AdminPanelSettingsIcon />
                  </Tooltip>
                ) : (
                  "Role(s)"
                )
              }
              value={"permission"}
            />{" "}
          </Tabs>{" "}
        </MDBox>{" "}
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={0}
              direction="column"
              style={{
                minHeight: "77vh",
                minWidth: "70%",
              }}
            >
              <TabPanel value={"holiday"} style={{ padding: "0" }}>
                <MDBox sx={{ minWidth: "70%", minHeight: "70%" }}>
                  <>
                    <Card style={{ width: "100%" }}>
                      <p style={{ padding: "20px 20px 0 20px" }}>
                        <b>Not Open For Business</b>
                      </p>
                      {Object.keys(weekOffData).length !== 0 && (
                        <FormGroup
                          aria-label="position"
                          row
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "20px 20px 0 20px",
                            flexDirection: windowWidth <= 768 && "column",
                          }}
                        >
                          {days.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              value={item.label}
                              control={<Checkbox />}
                              checked={item.value}
                              label={item.label}
                              onClick={() => handleCheck(item.label, item.value)}
                            />
                          ))}
                        </FormGroup>
                      )}
                    </Card>
                    <br />
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CustomSpinner />
                      </div>
                    ) : (
                      <Card>
                        <p style={{ padding: "20px 20px 0 20px" }}>
                          <b>Holidays</b>
                        </p>
                        <MDBox
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "20px",
                            flexDirection: windowWidth <= 768 ? "column" : "",
                          }}
                        >
                          <Grid
                            component="form"
                            role="form"
                            onSubmit={handleSubmit}
                            container
                            rowSpacing={1}
                            sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                          >
                            <Grid md={9} className="relative-class">
                              <Grid md={4}>
                                <TextField
                                  fullWidth
                                  label="Click to select dates"
                                  required
                                  onClick={() => setShowCalendar(!showCalendar)}
                                  value={
                                    daterange[0].startDate
                                      ? `${daterange[0].startDate.toDateString()} - ${
                                          daterange[0].endDate
                                            ? daterange[0].endDate.toDateString()
                                            : ""
                                        }`
                                      : ""
                                  }
                                  autoComplete="off"
                                />
                                {showCalendar && (
                                  <div ref={calendarRef} className="date-range-picker-wrapper">
                                    <DateRangePicker
                                      startDatePlaceholder="Start Date"
                                      endDatePlaceholder="End Date"
                                      ranges={daterange}
                                      onChange={(ranges) => dateHandler(ranges)}
                                      inputRanges={[]}
                                    ></DateRangePicker>
                                  </div>
                                )}
                              </Grid>
                              <Grid md={8}>
                                <TextField
                                  sx={{ width: "98%" }}
                                  id="outlined-basic"
                                  label="Reason"
                                  variant="outlined"
                                  onChange={handleReasonAdd}
                                  required
                                  error={validationError.description}
                                  helperText={
                                    validationError.description ? "This field is required" : ""
                                  }
                                />
                              </Grid>
                            </Grid>
                            <MDBox mt={windowWidth <= 768 && "1rem"} style={addHolidayBtn}>
                              <MDButton
                                type="submit"
                                sx={{ width: "100%" }}
                                variant="gradient"
                                color="info"
                              >
                                Add{" "}
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </MDBox>
                        {windowWidth >= 768 ? (
                          <DataTable
                            table={{ columns: columns || [], rows }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                            rowData={settingsData}
                            onRowClick={(id) => {}}
                          />
                        ) : (
                          <div>
                            {settingsData?.data?.map((row) => (
                              <MDBox p="8px 16px 16px">
                                <Card
                                  style={{
                                    padding: "3%",
                                    border: "1px solid black",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <DeleteIcon
                                        // eslint-disable-next-line no-shadow
                                        onClick={() => handleDelete(row._id)}
                                        fontSize="medium"
                                        color="error"
                                      />
                                    </div>
                                    <MDTypography
                                      style={{ fontSize: "12px", margin: "0" }}
                                      display="flex"
                                      alignItems="center"
                                      variant="h6"
                                      fontWeight="medium"
                                    >
                                      Reason
                                    </MDTypography>
                                    <MDTypography
                                      display="block"
                                      variant="button"
                                      fontWeight="medium"
                                      color="text"
                                      fontSize=".70rem"
                                    >
                                      {row.description}
                                    </MDTypography>
                                    <div
                                      style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                      <div>
                                        <MDTypography
                                          style={{ fontSize: "12px", marginTop: "1rem" }}
                                          display="flex"
                                          alignItems="center"
                                          variant="h6"
                                          fontWeight="medium"
                                        >
                                          Start Date
                                        </MDTypography>
                                        <MDTypography
                                          display="block"
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                          fontSize=".70rem"
                                        >
                                          {row.start_date && dateFormatted(row?.start_date)}
                                        </MDTypography>
                                      </div>
                                      <div>
                                        <MDTypography
                                          style={{ fontSize: "12px", marginTop: "1rem" }}
                                          display="flex"
                                          alignItems="center"
                                          variant="h6"
                                          fontWeight="medium"
                                        >
                                          End Date
                                        </MDTypography>
                                        <MDTypography
                                          display="block"
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                          fontSize=".70rem"
                                        >
                                          {row.start_date && dateFormatted(row?.end_date)}
                                        </MDTypography>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </MDBox>
                            ))}
                          </div>
                        )}
                        <CustomTablePagination
                          count={pageCount}
                          page={page}
                          onChangePage={handleChange}
                          value={pageSize}
                          onChangeRowPerPage={handleChangePageSize}
                          pageSizeOptions={[5, 10, 15, 20]}
                        />
                      </Card>
                    )}
                  </>
                </MDBox>{" "}
              </TabPanel>{" "}
              <TabPanel value={"employee"} style={{ padding: "0", width: "100%" }}>
                <Staff />{" "}
              </TabPanel>{" "}
              <TabPanel value={"crew"} style={{ padding: "0", width: "100%" }}>
                <Crews />
              </TabPanel>{" "}
              <TabPanel
                value={"permission"}
                style={{ padding: "0", maxHeight: "100vh", width: "100%" }}
              >
                <Permission />
              </TabPanel>{" "}
            </Grid>{" "}
          </Grid>{" "}
        </Grid>{" "}
      </TabContext>{" "}
    </DashboardLayout>
  );
}

export default Settings;
