/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
// react-router-dom components
import { useNavigate, Link } from "react-router-dom";
// import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
// import { ToastContainer } from "react-toastify";
// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/login-bg.jpg";
import { loginUser } from "../../../redux/actions";
import { Icon, IconButton, InputAdornment } from "@mui/material";

function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isUserAuthenticated } = useSelector((state) => state.Login);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const validateEmail = () => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!pattern.test(email)) {
      setEmailError("Invalid email");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = () => {
    if (password.length < 4) {
      setPasswordError("Password must be at least 4 characters long");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (emailError || passwordError) {
      console.log("Invalid email or password. Please check your input.");
    } else {
      dispatch(loginUser({ email, password }, navigate));
    }
  };

  useEffect(() => {
    if (
      (localStorage.getItem("token") && isUserAuthenticated) ||
      localStorage.getItem("rememberMe")
    ) {
      navigate("/dashboard");
    }
  }, [isUserAuthenticated]);
  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     // navigate("/dashboard");
  //   }
  // }, []);
  // async function login() {
  //   // console.log(email, password);
  //   const item = { email, password };
  //   let result = await fetch("https://projects.xcitech.in/make-it-rain-api/api/login", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     body: JSON.stringify(item),
  //     // setNavigate(value:"true")
  //   });
  //   result = await result.json();
  //   // console.log(result);
  //   if (result.success) {
  //     toast.success("Username && password is right");
  //   } else {
  //     toast.error("Oops username && password id wrong");
  //   }

  //   if (result.token) {
  //     localStorage.setItem("token", JSON.stringify(result.token));
  //     navigate("/dashboard");
  //   }
  // }
  // console.log(errors);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        {/* <MDBox
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        > */}
        {/* <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography> */}
        {/* </MDBox> */}
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <MDBox component="form" role="form" onSubmit={handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Username"
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  required
                  onBlur={validateEmail}
                />
                {emailError && (
                  <MDTypography variant="h6" fontSize=".85rem" color="error">
                    {emailError}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type={showPassword === true ? "text" : "password"}
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  required
                  onBlur={validatePassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={password.length === 0 ? true : false}
                          size="small"
                          onClick={() => {
                            setShowPassword((prev) => !prev);
                          }}
                        >
                          {showPassword === true ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {passwordError && (
                  <MDTypography variant="h6" fontSize=".85rem" color="error">
                    {passwordError}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton type="submit" color="primary" fullWidth>
                  login
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                <Link to="/forget">Forgot Password?</Link>
                <MDTypography
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                ></MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}
export default Basic;
