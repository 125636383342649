/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import {
  // Autocomplete,
  Card,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  // MenuItem,
  // Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FileInput from "layouts/customer/fileInput";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { editCustomer } from "redux/actions";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE_URL } from "utils/Constants";
import defaultImg from "assets/images/default_img.png";
import dropIcons from "assets/images/water-drop-icon.png";
import pdf from "assets/images/PDF_file_icon.svg.webp";
import PlatformSettings from "../components/PlatformSettings";

const listStyle = {
  listStyleType: "none",
  margin: 0,
  padding: 0,
  fontSize: 12,
  color: "#7b809a",
};

const itemStyle = {
  borderBottom: "0.5px solid #ccc",
  marginBottom: "15px",
  width: "100%",
};

// eslint-disable-next-line react/prop-types
function Property({
  customerId,
  isEditableModeOn,
  setEditableModeOn,
  propertyPic,
  setPropertyPic,
  customerData,
  customersData,
  setCustomersData,
  zoneData,
  setZoneData,
  irrigationData,
  setIrrigationData,
  proprtyData,
  setPropertyData,
  secondaryContact,
  setSecondaryContact,
  billingAddress,
  setBillingAddress,
  bluePrintPic,
  setBluePrintPic,
  note,
  setNote,
  // serviceCallTimeWindow,
  // setServiceCallTimeWindow,
}) {
  const dispatch = useDispatch();
  const [isPropertyBlurred, setIsPropertyBlurred] = useState(false);
  const [isBlueprintBlurred, setIsBlueprintBlurred] = useState(false);
  const [validationStatus, setValidationStatus] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const platformStyle = {
    display: "flex",
    justifyContent: "space-between",
  };
  const platformSubWrap = {
    width: "45%",
  };
  const zoneBtn = { marginTop: "1rem", width: "20%" };
  if (windowWidth <= 768) {
    platformStyle.flexDirection = "column";
    platformSubWrap.width = "100%";
    zoneBtn.width = "10%";
    platformSubWrap.marginTop = "1rem";
  }
  const handleBuleprintBlur = () => {
    setIsBlueprintBlurred(true);
  };
  const handleBlur = () => {
    setIsPropertyBlurred(true);
  };
  const onPropertyPicChange = (e) => {
    const file = e.target.value;
    const allowedExtensions = ["png", "jpg", "jpeg", "pdf", "webp"];
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(extension)) {
        setIsPropertyBlurred(false);
        setPropertyPic(e.target.value);
      } else {
        setIsPropertyBlurred(true);
        // toast.error("Only PNG or JPG files are allowed.");
      }
    }
  };
  const onBluePrintChange = (e) => {
    const file = e.target.value;
    const allowedExtensions = ["png", "jpg", "jpeg", "pdf", "webp"];

    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(extension)) {
        setIsBlueprintBlurred(false);
        setBluePrintPic(e.target.value);
      } else {
        setIsBlueprintBlurred(true);
        // toast.error("Only PNG or JPG files are allowed.");
      }
    }
  };
  const onFieldChange = (which, label, value) => {
    if (which === "CUSTOMER") {
      setCustomersData(
        customersData.map((customer) => {
          if (customer.label === label) {
            return { label, value };
          }
          return customer;
        })
      );
    }

    if (which === "IRRIGATION") {
      setIrrigationData(
        irrigationData.map((irrigation) => {
          if (irrigation.label === label) {
            return { label, value };
          }
          return irrigation;
        })
      );
    }

    if (which === "PROPERTY") {
      setPropertyData(
        proprtyData.map((property) => {
          if (property.label === label) {
            return { label, value };
          }
          return property;
        })
      );
    }

    if (which === "BILLING") {
      setBillingAddress(
        billingAddress.map((billing) => {
          if (billing.label === label) {
            return { label, value };
          }
          return billing;
        })
      );
    }

    if (which === "SECONDARYCONTACT") {
      setSecondaryContact(
        secondaryContact.map((secondary) => {
          if (secondary.label === label) {
            return { label, value };
          }
          return secondary;
        })
      );
    }
  };
  const onSubmitCustomer = (e) => {
    e.preventDefault();
    if (Object.values(validationStatus).every((status) => status === "success")) {
      if (!isBlueprintBlurred && !isPropertyBlurred) {
        dispatch(editCustomer(customerId, getStaticCustomer()));
        setZoneData(getZone(zoneModel));
        setEditableModeOn(false);
      } else {
        toast.error("Only PNG, JPG/JPEG, PDF, and WEBP files are allowed for upload.");
      }
    } else {
      toast.error("");
    }
  };
  const getStaticCustomer = () => {
    const customerPayload = {
      first_name: getApiField("CUSTOMER", "Customer Name").split(" ")[0],
      last_name: getApiField("CUSTOMER", "Customer Name").split(" ")[1],
      email: getApiField("CUSTOMER", "Email").split(" ")[0],
      phone: getApiField("CUSTOMER", "Telephone"),
      zones: getZone(),
      irrigation_system: {
        main_location: getApiField("IRRIGATION", "Main Location"),
        controller: getApiField("IRRIGATION", "Controller"),
        pump_or_shut_off: getApiField("IRRIGATION", "Pump / Shut-Off"),
        miscellaneous: getApiField("IRRIGATION", "Miscellaneous"),
      },
      property: {
        address: getApiField("PROPERTY", "Address"),
        city: getApiField("PROPERTY", "City"),
        state: getApiField("PROPERTY", "State"),
        zip: getApiField("PROPERTY", "Zip"),
      },
      secondary_contact: {
        phone: getApiField("SECONDARYCONTACT", "Phone"),
        first_name: getApiField("SECONDARYCONTACT", "First Name"),
        last_name: getApiField("SECONDARYCONTACT", "Last Name"),
        email: getApiField("SECONDARYCONTACT", "Email"),
      },
      billing: {
        address: getApiField("BILLING", "Address"),
        city: getApiField("BILLING", "City"),
        state: getApiField("BILLING", "State"),
        zip: getApiField("BILLING", "Zip"),
      },
      // service_call_time_window: getApiField("CUSTOMER", "Time Window"),
      service_call_note: note,
    };
    if (propertyPic) {
      customerPayload.property_image = propertyPic;
    }
    if (bluePrintPic) {
      customerPayload.blueprint_image = bluePrintPic;
    }
    return customerPayload;
  };
  const getApiField = (which, label) => {
    let model;
    if (which === "CUSTOMER") {
      model = customersData;
    } else if (which === "IRRIGATION") {
      model = irrigationData;
    } else if (which === "PROPERTY") {
      model = proprtyData;
    } else if (which === "BILLING") {
      model = billingAddress;
    } else if (which === "SECONDARYCONTACT") {
      model = secondaryContact;
    }
    const apiField = model.find((data) => data.label === label);
    return apiField.value;
    // Zone Related Function
  };
  const [zoneModel, setZoneModel] = useState([""]);
  // const [formatedTimeWindow, setFormatedTimeWindow] = useState({});
  const handleKeyDown = (event, index) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index === zoneModel.length - 1 && zoneModel[zoneModel.length - 1] !== "") {
        setZoneModel([...zoneModel, ""]);
      }
    }
  };
  const handleAddZone = (event) => {
    event.preventDefault();
    if (zoneModel[zoneModel.length - 1] !== "") {
      setZoneModel([...zoneModel, ""]);
    }
  };
  const handleZoneChange = (e, index) => {
    setZoneModel(
      zoneModel.map((zone, i) => {
        if (index === i) {
          return e.target.value;
        }
        return zone;
      })
    );
  };
  const onDeleteZone = (index) => {
    setZoneModel(zoneModel.filter((zone, i) => index !== i));
  };
  const getZone = () => zoneModel.filter((zone) => zone !== "");
  const handleNoPath = (ev) => {
    ev.currentTarget.src = defaultImg;
    ev.currentTarget.onError = null;
  };
  useEffect(() => {
    setZoneModel(zoneData ?? [""]);
  }, [zoneData]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <MDBox component="form" role="form" onSubmit={onSubmitCustomer}>
      <MDBox mt={2} mb={3}>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginBottom: "20px",
            flexWrap: "wrap",
          }}
        > */}
        <MDBox style={platformStyle}>
          <MDBox style={platformSubWrap}>
            <MDTypography variant="h6" fontWeight="medium">
              Property
            </MDTypography>
            {propertyPic &&
            propertyPic instanceof File &&
            propertyPic.type === "application/pdf" ? (
              <>
                <CardMedia
                  src={pdf}
                  alt="Priview"
                  component="img"
                  onError={handleNoPath}
                  height="300"
                  elementType="Div"
                  sx={{
                    width: "100%",
                    margin: 0,
                    boxShadow: ({ boxShadows: { md } }) => md,
                    objectFit: "cover",
                    objectPosition: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                <MDBox width="100%" display="flex" justifyContent="center">
                  <MDButton>download pdf</MDButton>
                </MDBox>
              </>
            ) : (
              <CardMedia
                src={
                  propertyPic
                    ? URL.createObjectURL(propertyPic)
                    : customerData?.property?.property_image
                    ? BASE_URL + customerData?.property?.property_image
                    : defaultImg
                }
                component="img"
                height="250"
                onError={handleNoPath}
                elementType="Div"
                sx={{
                  width: "100%",
                  margin: 0,
                  boxShadow: ({ boxShadows: { md } }) => md,
                  objectFit: "cover",
                  objectPosition: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            )}
            <div>
              {isEditableModeOn && (
                <>
                  <MDBox>
                    {isPropertyBlurred && (
                      <MDTypography variant="h6" color="error" fontSize=".85rem">
                        Only PNG, JPG/JPEG, PDF, and WEBP files are allowed for upload.
                      </MDTypography>
                    )}
                  </MDBox>
                  <div style={{ display: "flex", justifyContent: "left", marginTop: "1rem" }}>
                    <FileInput
                      label="Click here to upload file"
                      onChange={onPropertyPicChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </>
              )}
            </div>
          </MDBox>
          <MDBox style={platformSubWrap}>
            <MDTypography variant="h6" fontWeight="medium">
              Irrigation System Blueprint
            </MDTypography>
            {bluePrintPic &&
            bluePrintPic instanceof File &&
            bluePrintPic.type === "application/pdf" ? (
              <>
                <CardMedia
                  src={pdf}
                  alt="Priview"
                  component="img"
                  height="300"
                  onError={handleNoPath}
                  elementType="Div"
                  sx={{
                    width: "100%",
                    margin: 0,
                    boxShadow: ({ boxShadows: { md } }) => md,
                    objectFit: "cover",
                    objectPosition: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                <MDBox width="100%" display="flex" justifyContent="center">
                  <MDButton>download pdf</MDButton>
                </MDBox>
              </>
            ) : (
              <CardMedia
                src={
                  bluePrintPic
                    ? URL.createObjectURL(bluePrintPic)
                    : customerData?.irrigation_system?.blueprint_image
                    ? BASE_URL + customerData?.irrigation_system?.blueprint_image
                    : defaultImg
                }
                alt="No image found in this path"
                onError={handleNoPath}
                component="img"
                height="250"
                elementType="Div"
                sx={{
                  width: "100%",
                  margin: 0,
                  boxShadow: ({ boxShadows: { md } }) => md,
                  objectFit: "cover",
                  objectPosition: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            )}
            <div>
              {isEditableModeOn && (
                <>
                  <MDBox>
                    {isBlueprintBlurred && (
                      <MDTypography variant="h6" color="error" fontSize=".85rem">
                        Only PNG, JPG/JPEG, PDF, and WEBP files are allowed for upload.
                      </MDTypography>
                    )}
                  </MDBox>
                  <div style={{ display: "flex", justifyContent: "left", marginTop: "1rem" }}>
                    <FileInput
                      label="Click here to upload file"
                      onChange={onBluePrintChange}
                      onBlur={handleBuleprintBlur}
                    />
                  </div>
                </>
              )}
            </div>
          </MDBox>
        </MDBox>
        {!isEditableModeOn && (
          <MDBox display="flex" justifyContent="center" mt={2}>
            {customerData?.property?.city} {customerData?.property?.state}{" "}
            {customerData?.property?.zip}
          </MDBox>
        )}
      </MDBox>
      <MDBox>
        <MDBox container spacing={2} alignItems="center" justifyContent="center">
          <MDBox style={platformStyle}>
            <MDBox style={platformSubWrap}>
              <Card style={{ boxShadow: windowWidth >= 768 && "none" }}>
                <MDTypography mb={0} ml={1} mt={1} variant="h6" fontWeight="medium">
                  Customer Information{" "}
                  {isEditableModeOn && (
                    <sup style={{ color: "red", fontSize: "10px", marginLeft: "3px" }}>*</sup>
                  )}{" "}
                </MDTypography>
                <PlatformSettings
                  validationStatus={validationStatus}
                  setValidationStatus={setValidationStatus}
                  data={customersData}
                  isEditableModeOn={isEditableModeOn}
                  onFieldChange={onFieldChange}
                  which="CUSTOMER"
                  isNew
                />
              </Card>
            </MDBox>
            <MDBox style={platformSubWrap}>
              <Card style={{ boxShadow: windowWidth >= 768 && "none" }}>
                <MDTypography mb={0} ml={1} mt={1} variant="h6" fontWeight="medium">
                  Irrigation System Info{" "}
                </MDTypography>
                <PlatformSettings
                  validationStatus={validationStatus}
                  setValidationStatus={setValidationStatus}
                  data={irrigationData}
                  isEditableModeOn={isEditableModeOn}
                  onFieldChange={onFieldChange}
                  which="IRRIGATION"
                  isNew
                />
              </Card>
            </MDBox>
          </MDBox>
          <MDBox style={platformStyle}>
            <MDBox style={platformSubWrap}>
              <Card style={{ boxShadow: windowWidth >= 768 && "none" }}>
                <MDTypography mb={0} ml={1} mt={1} variant="h6" fontWeight="medium">
                  Property Address{" "}
                  {isEditableModeOn && (
                    <sup style={{ color: "red", fontSize: "10px", marginLeft: "3px" }}>*</sup>
                  )}{" "}
                </MDTypography>
                <PlatformSettings
                  validationStatus={validationStatus}
                  setValidationStatus={setValidationStatus}
                  data={proprtyData}
                  isEditableModeOn={isEditableModeOn}
                  onFieldChange={onFieldChange}
                  which="PROPERTY"
                  isNew
                />
              </Card>
            </MDBox>
            <MDBox style={platformSubWrap}>
              <Card style={{ boxShadow: windowWidth >= 768 && "none" }}>
                <MDTypography mb={0} ml={1} mt={1} variant="h6" fontWeight="medium">
                  Billing Address{" "}
                  {isEditableModeOn && (
                    <sup style={{ color: "red", fontSize: "10px", marginLeft: "3px" }}>*</sup>
                  )}{" "}
                </MDTypography>
                <PlatformSettings
                  validationStatus={validationStatus}
                  setValidationStatus={setValidationStatus}
                  data={billingAddress}
                  isEditableModeOn={isEditableModeOn}
                  onFieldChange={onFieldChange}
                  which="BILLING"
                  isNew
                />
              </Card>
            </MDBox>
          </MDBox>
          <MDBox style={platformStyle}>
            <MDBox style={platformSubWrap}>
              <Card style={{ boxShadow: windowWidth >= 768 && "none" }}>
                <MDTypography mb={0} ml={1} mt={1} variant="h6" fontWeight="medium">
                  Secondary Contact{" "}
                  {isEditableModeOn && (
                    <sup style={{ color: "red", fontSize: "10px", marginLeft: "3px" }}>*</sup>
                  )}{" "}
                </MDTypography>
                <PlatformSettings
                  validationStatus={validationStatus}
                  setValidationStatus={setValidationStatus}
                  data={secondaryContact}
                  isEditableModeOn={isEditableModeOn}
                  onFieldChange={onFieldChange}
                  which="SECONDARYCONTACT"
                  isNew
                />
              </Card>
            </MDBox>
            <MDBox style={platformSubWrap}>
              <Card style={{ boxShadow: windowWidth >= 768 && "none" }}>
                <MDTypography mb={0} ml={1} mt={1} variant="h6" fontWeight="medium">
                  Service call notes
                </MDTypography>
                <MDBox m={2}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    multiline
                    rows={6}
                    value={note}
                    InputProps={{
                      readOnly: !isEditableModeOn,
                    }}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </MDBox>
              </Card>
            </MDBox>
          </MDBox>
          <MDBox style={platformStyle}>
            {/* <MDBox style={platformSubWrap}>
              <Card style={{ boxShadow: windowWidth >= 768 && "none" }}>
                <MDTypography mb={0} ml={1} mt={1} variant="h6" fontWeight="medium">
                  Service call time window{" "}
                  {isEditableModeOn && (
                    <sup style={{ color: "red", fontSize: "10px", marginLeft: "3px" }}>*</sup>
                  )}{" "}
                </MDTypography>
                <MDBox mb={2}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    readOnly={!isEditableModeOn}
                    id="combo-box-demo"
                    value={
                      serviceCallTimeWindow === 10
                        ? "2:30am to 6:30pm"
                        : serviceCallTimeWindow === 20
                        ? "12:30am to 5:30pm"
                        : "1:30am to 7:30pm"
                    }
                    options={[
                      { label: "2:30am to 6:30pm", value: 10 },
                      { label: "12:30am to 5:30pm", value: 20 },
                      { label: "1:30am to 7:30pm", value: 30 },
                    ]}
                    sx={{ padding: "0 1rem 0 1rem" }}
                    renderInput={(params) => <TextField {...params} label="" />}
                    onChange={(e, v) => {
                      setServiceCallTimeWindow(v.value);
                    }}
                  />
                </MDBox>
              </Card>
            </MDBox> */}
            {!isEditableModeOn && zoneData?.length <= 0 ? (
              ""
            ) : (
              <MDBox>
                <Card
                  style={{
                    boxShadow: windowWidth >= 768 && "none",

                    width: "100%",
                  }}
                >
                  <MDTypography mb={0} ml={1} mt={1} variant="h6" fontWeight="medium">
                    Zones{" "}
                  </MDTypography>

                  <FormControl sx={{ m: 1, width: "50%" }}>
                    {!isEditableModeOn && (
                      <ul style={listStyle}>
                        {zoneData?.map((item, k) => (
                          <li key={k} style={itemStyle}>
                            <img src={dropIcons} width="20px" />

                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {item}
                            </MDTypography>
                          </li>
                        ))}
                      </ul>
                    )}
                    {isEditableModeOn && zoneData && (
                      <>
                        <div>
                          {zoneModel.map((zone, index) => (
                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <TextField
                                id="outlined-size-normal"
                                placeholder="click enter to add another zone"
                                type="text"
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onChange={(e) => handleZoneChange(e, index)}
                                value={zoneModel[index]}
                                md={3}
                                style={{ paddingTop: "10px", width: "70%" }}
                              />
                              {index >= 0 && (
                                <IconButton
                                  onClick={() => onDeleteZone(index)}
                                  aria-label="delete"
                                  size="small"
                                >
                                  <DeleteIcon color="error" fontSize="small" />
                                </IconButton>
                              )}
                            </Grid>
                          ))}
                        </div>
                        <MDButton onClick={handleAddZone} style={zoneBtn} color="info">
                          Add
                        </MDButton>
                      </>
                    )}
                  </FormControl>
                </Card>
              </MDBox>
            )}
          </MDBox>
          <MDBox style={{ textAlign: "center" }}>
            {isEditableModeOn && (
              <MDButton type="submit" variant="gradient" color="info" style={{ marginTop: "9px" }}>
                Update
              </MDButton>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}
// {/* <FormControl fullWidth style={{ padding: "0 1rem 0 1rem" }}>
// <Select
//   labelId="demo-simple-select-label"
//   required
//   id="demo-simple-select"
//   // value={timeWindow}
//   // label="Select estimated time window"
//   // onChange={handleSelect}
//   style={{ height: "3.4em" }}
// >
//   <MenuItem value={10}>2:30am to 6:30pm</MenuItem>
//   <MenuItem value={20}>12:30am to 5:30pm</MenuItem>
//   <MenuItem value={30}>1:30am to 7:30pm</MenuItem>
// </Select>
// </FormControl> */}
export default Property;
