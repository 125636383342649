export const LIST_HOLIDAYS = "LIST_HOLIDAYS";
export const LIST_HOLIDAYS_SUCCESS = "LIST_HOLIDAYS_ SUCCESS";
export const LIST_HOLIDAYS_FAILED = "LIST_HOLIDAYS_FAILED";

export const WEEK_OFF_LIST = "WEEK_OFF_LIST";
export const WEEK_OFF_LIST_SUCCESS = "WEEK_OFF_LIST_SUCCESS";
export const WEEK_OFF_LIST_FAILED = "WEEK_OFF_LIST_FAILED";

export const ADD_HOLIDAYS = "ADD_HOLIDAYS";
export const ADD_HOLIDAYS_SUCCESS = "ADD_HOLIDAYS_SUCCESS";
export const ADD_HOLIDAYS_FAILED = "ADD_HOLIDAYS_FAILED";

export const UPDATE_WEEK_OFF = "UPDATE_WEEK_OFF";
export const UPDATE_WEEK_OFF_SUCCESS = "UPDATE_WEEK_OFF_SUCCESS";
export const UPDATE_WEEK_OFF_FAILED = "UPDATE_WEEK_OFF_FAILED";

export const DELETE_HOLIDAYS = "DELETE_HOLIDAYS";
export const DELETE_HOLIDAYS_SUCCESS = "DELETE_HOLIDAYS_SUCCESS";
export const DELETE_HOLIDAYS_FAILED = "DELETE_HOLIDAYS_FAILED";

export const SET_SETTINGS_LOADING = "SET_SETTINGS_LOADING";
