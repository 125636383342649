/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "utils";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Button, FormControl, MenuItem, Pagination, Select, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import { Button } from "@mui/material";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import CustomSpinner from "examples/CustomLoader";
// Data
import authorsTableData from "layouts/customer/list/data/authorsTableData";
import { listCustomersModule, searchCustomer } from "redux/actions";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import CustomTablePagination from "examples/CustomTablePagination/CustomTablePagination";
// import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";

// import projectsTableData from "layouts/tables/data/projectsTableData";

const Search = styled("div")(({ theme, isMobileView }) => ({
  position: "relative",
  borderRadius: isMobileView ? "10px" : theme.shape.borderRadius,
  backgroundColor: isMobileView
    ? alpha(theme.palette.common.white, 0.55)
    : alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: isMobileView
      ? alpha(theme.palette.common.white, 0.65)
      : alpha(theme.palette.common.white, 0.25),
  },
  color: isMobileView ? "black" : "white",
  fontSize: "1.15rem !important",
  marginLeft: 0,
  width: "140%",
  height: "40px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "390px",
  },
  display: "flex",
  alignItems: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "13rem",
    [theme.breakpoints.up("sm")]: {
      width: "23rem",
    },
    height: "40px",
    boxSizing: "border-box !important",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

function List() {
  const dispatch = useDispatch();
  const { customerListModule, loading } = useSelector((state) => state.Customers);
  const UserRole = localStorage.getItem("role");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleChange = (event, value) => {
    dispatch(listCustomersModule({ page: value, pageSize: pageSize }));
    setPage(value);
  };
  const customerListHeader = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexWrap: "noWrap",
  };
  const headerLeftWrapper = {
    display: "flex",
    alignItems: "center",
    gap: ".35rem",
    maxWidth: "100%",
  };
  const addButton = {};
  const searchInputStyle = {};
  const customerListFooter = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1.5rem",
    flexWrap: "wrap",
  };
  const searchButton = { backgroundColor: "#e2e2e2", color: "black" };
  const perPageCount = { minWidth: 50, marginRight: "3rem" };
  if (windowWidth <= 768) {
    customerListHeader.flexDirection = "column";
    searchButton.width = "30%";
    searchInputStyle.width = "70%";
    customerListFooter.flexDirection = "column";
    perPageCount.display = "flex";
    perPageCount.justifyContent = "end";
    perPageCount.marginRight = "15px";
  }

  const handlePhoneClick = (d) => {
    if (d && d.phone) {
      window.location.href = `tel:${d.phone}`;
    }
  };
  const handleEmailClick = (d) => {
    if (d && d.email) {
      window.location.href = `mailto:${d.email}`;
    }
  };
  const navigate = useNavigate();

  const onAddCustomerClick = () => {
    navigate(`/create-customer`);
  };
  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(1);
    dispatch(listCustomersModule({ page: 1, pageSize: event.target.value }));
  };
  const onSearchHandle = (event) => {
    event.preventDefault();
    if (searchValue !== "") {
      dispatch(searchCustomer(searchValue));
    }
  };

  const onChangeInput = (e) => {
    setSearchValue(e.target.value);
    if (windowWidth <= 768) {
      if (e.target.value.length > 2) {
        dispatch(searchCustomer(e.target.value));
      }
    }
    if (e.key === "Backspace") {
      if (e.target.value.length <= 1) {
        dispatch(listCustomersModule({ page: page, pageSize: pageSize }));
      }
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    // if (!customerListModule?.data) {
    dispatch(listCustomersModule({ page: page, pageSize: pageSize }));
    // }
  }, []);

  useEffect(() => {
    if (customerListModule?.data) {
      const pageCount = Math.ceil(customerListModule.totalCount / pageSize);
      setPageCount(pageCount);
    }
  }, [customerListModule]);
  useEffect(() => {
    if (Array.isArray(customerListModule?.data)) {
      const { columnData, rowData } = authorsTableData(customerListModule);
      setColumns(columnData);
      setRows(rowData);
    }
  }, [customerListModule]);

  useEffect(() => {
    if (searchValue === "") {
      dispatch(listCustomersModule({ page: page, pageSize: pageSize }));
    }
  }, [searchValue]);

  return (
    <DashboardLayout>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <MDTypography variant="h2">Customers</MDTypography>
        <SideNavActionBtn />
      </MDBox>
      <MDBox pt={2.6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {windowWidth >= 768 ? (
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  bgColor="black"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDBox style={customerListHeader}>
                    <div>
                      <MDBox
                        style={headerLeftWrapper}
                        component="form"
                        role="form"
                        onSubmit={onSearchHandle}
                      >
                        <MDBox style={searchInputStyle}>
                          <Search>
                            <SearchIconWrapper>
                              <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                              key={"searchInput"}
                              placeholder={"search by name"}
                              inputProps={{ "aria-label": "search" }}
                              value={searchValue}
                              onChange={onChangeInput}
                            />
                          </Search>
                        </MDBox>
                        <MDButton type="submit" variant="gradient" color="info">
                          <SearchIcon />
                        </MDButton>
                      </MDBox>
                    </div>
                    {UserRole === "owner" && (
                      <MDButton
                        style={{ textWrap: "noWrap" }}
                        variant="gradient"
                        color="info"
                        onClick={onAddCustomerClick}
                      >
                        Add Customer
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
                {loading ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" width="100%">
                    <CustomSpinner />
                  </MDBox>
                ) : (
                  <>
                    <MDBox pt={3}>
                      <DataTable
                        table={{ columns: columns || [], rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                        rowData={customerListModule}
                        // onRowClick={(id) => navigate(`/profile/${id}`)}
                      />
                    </MDBox>
                    <CustomTablePagination
                      count={pageCount}
                      page={page}
                      onChangePage={handleChange}
                      value={pageSize}
                      onChangeRowPerPage={handleChangePageSize}
                      pageSizeOptions={[10, 20, 30, 40, 50]}
                    />
                  </>
                )}
              </Card>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // border: "solid red",
                  }}
                >
                  <div style={{ width: "85%" }}>
                    <MDBox component="form" role="form" onSubmit={onSearchHandle}>
                      <MDBox style={searchInputStyle}>
                        <Search isMobileView={true}>
                          <SearchIconWrapper>
                            <SearchIcon />
                          </SearchIconWrapper>
                          <StyledInputBase
                            key={"searchInput"}
                            placeholder={"Search"}
                            inputProps={{ "aria-label": "search" }}
                            value={searchValue}
                            onChange={onChangeInput}
                          />
                        </Search>
                      </MDBox>
                    </MDBox>
                  </div>
                  <div
                    style={{
                      width: "15%",
                      // border: "solid blue",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <MDBox style={{ width: "35px" }}>
                      {UserRole === "owner" && (
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "rgba(169, 187, 90, 1)",
                            padding: "12px",
                            color: "#fff",
                            cursor: "pointer",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={onAddCustomerClick}
                        >
                          <AddIcon />
                        </button>
                      )}
                    </MDBox>
                  </div>
                </div>
                {loading ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" width="100%">
                    <CustomSpinner />
                  </MDBox>
                ) : (
                  <>
                    <MDBox pt={3}>
                      {customerListModule?.data?.map((row) => (
                        <MDBox mb={2}>
                          <Card
                            style={{
                              padding: "3%",
                            }}
                          >
                            <div>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <MDTypography
                                  style={{ fontSize: "12px" }}
                                  display="flex"
                                  alignItems="center"
                                  variant="h6"
                                  fontWeight="bold"
                                  onClick={() => navigate(`/profile/${row._id}`)}
                                >
                                  {row.first_name} {row.last_name}
                                </MDTypography>
                                <MDTypography
                                  display="flex"
                                  alignItems="center"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                  onClick={() => handleEmailClick(row)}
                                >
                                  {row?.email && <EmailIcon />}
                                  {row.email}
                                </MDTypography>
                              </div>
                              <MDBox display="flex" justifyContent="space-between">
                                <MDTypography
                                  display="block"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <LocationOnIcon />
                                  {/* {row.property.address} {row.property.city}
                                  {row.property.state} {row.property.zip} */}
                                  <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                      `${row.property.address} ${row.property.city} ${row.property.state} ${row.property.zip}`
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#7b809a" }}
                                  >
                                    {row.property.address} {row.property.city}
                                    {row.property.state} {row.property.zip}
                                  </a>
                                </MDTypography>
                                <MDTypography
                                  display="block"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                  onClick={() => handlePhoneClick(row)}
                                >
                                  {row?.phone && (
                                    <>
                                      <LocalPhoneIcon />
                                      {formatPhoneNumber(row?.phone)}
                                    </>
                                  )}
                                </MDTypography>
                              </MDBox>
                            </div>
                          </Card>
                        </MDBox>
                      ))}
                    </MDBox>
                    <CustomTablePagination
                      count={pageCount}
                      page={page}
                      onChangePage={handleChange}
                      value={pageSize}
                      onChangeRowPerPage={handleChangePageSize}
                      pageSizeOptions={[10, 20, 30, 40, 50]}
                    />
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default List;
