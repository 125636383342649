import {
  ADD_HOLIDAYS,
  DELETE_HOLIDAYS,
  LIST_HOLIDAYS,
  UPDATE_WEEK_OFF,
  WEEK_OFF_LIST,
} from "./actionTypes";

// eslint-disable-next-line import/prefer-default-export
export const listHolidaysAction = (payload) => ({
  type: LIST_HOLIDAYS,
  payload,
});

export const weekOffDataAction = () => ({
  type: WEEK_OFF_LIST,
});

export const addHolidaysAction = (obj) => ({
  type: ADD_HOLIDAYS,
  obj,
});

export const updateWeekOffAction = (obj) => ({
  type: UPDATE_WEEK_OFF,
  obj,
});

export const deleteHolidaysAction = (Id) => ({
  type: DELETE_HOLIDAYS,
  Id,
});
