/* eslint-disable import/prefer-default-export */
export const SET_MESSAGE_LOADING = "SET_MESSAGE_LOADING";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAILDE = "GET_MESSAGES_FAILDE";

export const POST_MESSAGE = "POST_MESSAGE";
export const POST_MESSAGE_SUCCESS = "POST_MESSAGE_SUCCESS";
export const POST_MESSAGE_FAILED = "POST_MESSAGE_FAILED";
