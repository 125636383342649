import { all } from "redux-saga/effects";

import LoginSaga from "./auth/login/saga";
import CustomerSaga from "./Customers/saga";
import HolidaysSaga from "./Holidays/saga";
import ServicesSaga from "./RouteManagement/Services/saga";
import StaffSaga from "./Staff/saga";
import CrewSaga from "./Crew/saga";
import PermissionSaga from "./Permission/saga";
import CitySaga from "./RouteManagement/City/saga";
import RoutesSaga from "./RouteManagement/Route/saga";
import AppointmentSaga from "./Appointment/saga";
import InvoiceSaga from "./invoices/saga";
import DashboardSaga from "./Dashboard/saga";
import MessageSaga from "./Messages/saga";
import ActivitiesSaga from "./Activities/saga";

export default function* rootSaga() {
  yield all([
    LoginSaga(),
    CustomerSaga(),
    HolidaysSaga(),
    ServicesSaga(),
    StaffSaga(),
    CrewSaga(),
    PermissionSaga(),
    CitySaga(),
    RoutesSaga(),
    AppointmentSaga(),
    InvoiceSaga(),
    DashboardSaga(),
    MessageSaga(),
    ActivitiesSaga(),
  ]);
}
