/* eslint-disable no-duplicate-case */
import {
  CLEAR_NEW_INVOICE,
  CLEAR_SESSION_DATA,
  CREATE_INVOICE_FAILED,
  CREATE_INVOICE_SUCCESS,
  DELETE_REMINDER_FAILED,
  DELETE_REMINDER_SUCCESS,
  FILTER_INVOICE_FAILED,
  FILTER_INVOICE_SUCCESS,
  GET_INVOICE_LIST_FAILED,
  GET_INVOICE_LIST_SUCCESS,
  GET_LAST_INVOICE_NUMBER_SUCCESS,
  GET_OVERDUE_AND_DUEWITH_FAILED,
  GET_OVERDUE_AND_DUEWITH_SUCCESS,
  GET_SINGLE_INVOICE_FAILED,
  GET_SINGLE_INVOICE_SUCCESS,
  PAYMENT_CHECK_OUT_SUCCESS,
  SEND_INVOICE_FAILED,
  SEND_INVOICE_SUCCESS,
  SET_INVOICE_LOADING,
  SET_REMINDER_FAILED,
  SET_REMINDER_SUCCESS,
  SUCCESS_CHECK_OUT_UPDATION_FAILED,
  SUCCESS_CHECK_OUT_UPDATION_SUCCESS,
  UPDATE_INVOICE_FAILED,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILED,
  UPDATE_PAYMENT_METHOD_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  invoiceData: [],
  invoiceList: [],
  avgTimeData: "",
  singleInvoice: {},
  successInvoiceSend: {},
  lastInvoiceNumber: "",
  checkOutDetails: {},
  overDueAndDueWith: {},
  newInvoiceId: {},
};

const InvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case GET_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        invoiceList: action.payload,
        singleInvoice: {},
      };
    case GET_INVOICE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case GET_SINGLE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        singleInvoice: action.payload.data,
      };
    case GET_SINGLE_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case FILTER_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        invoiceData: action.payload,
        singleInvoice: {},
      };
    case FILTER_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case SEND_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        successInvoiceSend: action.payload,
      };
    case SEND_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        successInvoiceSend: {},
      };
    case UPDATE_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case GET_LAST_INVOICE_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        lastInvoiceNumber: action.payload,
      };
    case PAYMENT_CHECK_OUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        checkOutDetails: action.payload,
      };
    case SET_REMINDER_SUCCESS:
    case DELETE_REMINDER_SUCCESS:
    case SUCCESS_CHECK_OUT_UPDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case SET_REMINDER_FAILED:
    case DELETE_REMINDER_FAILED:
    case SUCCESS_CHECK_OUT_UPDATION_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case CLEAR_SESSION_DATA:
      return {
        ...state,
        checkOutDetails: {},
      };
    case GET_OVERDUE_AND_DUEWITH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        overDueAndDueWith: action.payload,
      };
    case GET_OVERDUE_AND_DUEWITH_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case UPDATE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        newInvoiceId: action.payload,
      };
    case CREATE_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case CLEAR_NEW_INVOICE:
      return {
        ...state,
        newInvoiceId: {},
      };
    default:
      return state;
  }
};

export default InvoiceReducer;
