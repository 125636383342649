/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import { Chip } from "@mui/material";
import MDTypography from "components/MDTypography";
// import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateEmployeeAction } from "redux/Staff/actions";

// eslint-disable-next-line react/prop-types
function StatusAction({ status, obj }) {
  const dispatch = useDispatch();
  const handleUpdate = () => {
    if (status) {
      dispatch(
        updateEmployeeAction(
          {
            role_name: obj.role_name,
            email: obj.email,
            first_name: obj.first_name,
            last_name: obj.last_name,
            status: false,
          },
          obj?._id
        )
      );
    } else {
      dispatch(
        updateEmployeeAction(
          {
            role_name: obj.role_name,
            email: obj.email,
            first_name: obj.first_name,
            last_name: obj.last_name,
            status: true,
          },
          obj?._id
        )
      );
    }
  };
  return (
    <MDTypography
      display="block"
      variant="button"
      fontWeight="medium"
      onClick={() => handleUpdate()}
    >
      {status === true ? (
        <Chip label="Active" color="success" style={{ color: "#fff", cursor: "pointer" }} />
      ) : (
        <Chip label="Disabled" color="error" style={{ color: "#fff", cursor: "pointer" }} />
      )}
    </MDTypography>
  );
}

export default StatusAction;
