const { ACTIVITY_LOADING, GET_ACTIVITY_SUCCESS, GET_ACTIVITY_FAILED } = require("./actionTypes");

/* eslint-disable no-unused-vars */
const initialState = {
  loading: false,
  error: false,
  errorMessage: undefined,
  activityData: {},
};

const ActivitysReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        activityData: action.payload,
      };
    case GET_ACTIVITY_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        editCustomerMessage: action.payload,
      };
    default:
      return state;
  }
};

export default ActivitysReducer;
