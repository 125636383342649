import API from "services/axiosapi";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";

const { takeLatest, all, put } = require("redux-saga/effects");

function* getStaffList({ payload }) {
  try {
    yield put({ type: actionTypes.SET_STAFF_LOADING });
    if (payload === undefined) {
      yield put({
        type: actionTypes.SET_STAFF_LOADING,
      });
    }
    const { data } = yield API.get("api/employee", { params: { ...payload } });
    if (data.success) {
      yield put({
        type: actionTypes.LIST_STAFF_SUCCESS,
        payload: data,
      });
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.LIST__STAFF_FAILED,
        errorMessage: "Unable to Fetch data!",
      });
    }
  } catch (error) {
    toast.error("Unable to Fetch data!");
    yield put({
      type: actionTypes.LIST__STAFF_FAILED,
      errorMessage: "Unable to Fetch data!",
    });
  }
}

function* getStaffSaga({ id }) {
  try {
    // yield put({ type: actionTypes.SET_STAFF_LOADING });
    const { data } = yield API.get(`api/employee/${id}`);
    if (data.success) {
      yield put({ type: actionTypes.GET_STAFF_SUCCESS, payload: data.data });
    } else {
      yield put({ type: actionTypes.GET_STAFF_FAILED, errorMessage: "Unable to fetch!" });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_STAFF_FAILED, errorMessage: "Unable to fetch!" });
    toast.error(error.response.data.message);
  }
}

function* creatStaffSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_STAFF_LOADING });
    const { data } = yield API.post("api/employee", { ...obj });
    if (data.success) {
      toast.success(data.message);
      yield put({ type: actionTypes.CREATE_STAFF_SUCCESS });
      yield put({ type: actionTypes.LIST_STAFF });
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.CREATE_STAFF_FAILED,
        errorMessage: "Unable to create employee",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_STAFF_FAILED,
      errorMessage: "Unable to create employee",
    });
    toast.error(error.response.data.message);
  }
}

function* updateEmployeeSaga(payload) {
  const { id } = payload;
  const { obj } = payload;
  try {
    const { data } = yield API.put(`/api/employee/${id}/update`, { ...obj });
    if (data.success) {
      yield put({ type: actionTypes.UPDATE_EMPLOYEE_SUCCESS });
      yield put({ type: actionTypes.LIST_STAFF });
      toast.success(data.message);
    } else {
      toast.error(data.message);
      yield put({ type: actionTypes.UPDATE_EMPLOYEE_FAILED, errorMessage: "Unable to update!" });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({ type: actionTypes.UPDATE_EMPLOYEE_FAILED, errorMessage: "Unable to update!" });
  }
}

function* deleteEmployeeSaga(id) {
  try {
    yield put({ type: actionTypes.SET_STAFF_LOADING });
    const { data } = yield API.delete(`api/employee/${id.id}`);
    if (data.success) {
      yield put({ type: actionTypes.DELETE_EMPLOYEE_SUCCESS });
      yield put({ type: actionTypes.LIST_STAFF });
      toast.success(data.message);
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.DELETE_EMPLOYEE_FAILED,
        errorMessage: "Unable to delete employee",
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: actionTypes.DELETE_EMPLOYEE_FAILED,
      errorMessage: "Unable to delete employee",
    });
  }
}

function* StaffSaga() {
  yield all([
    yield takeLatest(actionTypes.LIST_STAFF, getStaffList),
    yield takeLatest(actionTypes.GET_STAFF, getStaffSaga),
    yield takeLatest(actionTypes.CREATE_STAFF, creatStaffSaga),
    yield takeLatest(actionTypes.UPDATE_EMPLOYEE, updateEmployeeSaga),
    yield takeLatest(actionTypes.DELETE_EMPLOYEE, deleteEmployeeSaga),
  ]);
}

export default StaffSaga;
