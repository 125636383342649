/* eslint-disable */

import { takeEvery, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import API from "../../services/axiosapi";

// import { GET_USER } from "../../user/actionTypes";
import {
  LIST_CUSTOMERS,
  LIST_CUSTOMERS_FAILED,
  LIST_CUSTOMERS_SUCCESS,
  SET_LOADING,
  API_ERROR,
  CREATE_CUSTOMERS,
  CREATE_CUSTOMERS_SUCCESS,
  CREATE_CUSTOMERS_FAILED,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILED,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILED,
  EDIT_CUSTOMER,
  SEARCH_CUSTOMER,
  SEARCH_CUSTOMER_SUCCESS,
  SEARCH_CUSTOMER_FAILED,
  // GET_ACTIVITY,
} from "./actionTypes";
// import { searchCustomer } from "./actions";
import * as actionTypes from "./actionTypes";

const prepareFileData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (
      typeof data[key] === "object" &&
      data[key] !== null &&
      key !== "property_image" &&
      key !== "blueprint_image" &&
      key !== "zones"
    ) {
      for (let subKey in data[key]) {
        formData.append(`${key}[${subKey}]`, data[key][subKey]);
      }
    } else {
      if (key === "zones") {
        data[key].forEach((val) => formData.append("zones[]", val));
      } else {
        formData.append(key, data[key]);
      }
    }
  }
  return formData;
};

function* listCustomerSaga({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const response = yield API.get("api/customers", {
      params: { page: payload.page || 1, pageSize: payload.pageSize || 10 },
    });
    if (response.data.success === true) {
      yield put({ type: LIST_CUSTOMERS_SUCCESS, payload: response.data });
    } else {
      toast.error(response.data.message);
      yield put({ type: LIST_CUSTOMERS_FAILED, payload: response.data.message });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: API_ERROR,
      payload: { errorMessage: "Invalid Params" },
    });
  }
}

function* listCustomerModulea({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const response = yield API.get("api/customers/customer-module", {
      params: { page: payload.page, pageSize: payload.pageSize },
    });
    if (response.data.success === true) {
      yield put({ type: actionTypes.LIST_CUSTOMERS_SUCCESS_MODULE, payload: response.data });
    } else {
      toast.error(response.data.message);
      yield put({ type: actionTypes.LIST_CUSTOMERS_FAILED_MODULE, payload: response.data.message });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: API_ERROR,
      payload: { errorMessage: "Invalid Params" },
    });
  }
}

function* createCustomerSaga({ data }) {
  try {
    yield put({ type: SET_LOADING });
    let formData = prepareFileData(data);
    const response = yield API.post("api/customers", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.success === true) {
      toast.success(response.data.message);
      yield put({ type: CREATE_CUSTOMERS_SUCCESS, payload: response.data });
    } else {
      toast.error(response.data.message);
      yield put({ type: CREATE_CUSTOMERS_FAILED, payload: response.data.message });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: API_ERROR,
      payload: { errorMessage: "Invalid Params" },
    });
  }
}

function* getCustomerSaga({ id }) {
  try {
    yield put({ type: SET_LOADING });
    const response = yield API.get(`api/customers/${id}`);
    if (response.data.success === true) {
      yield put({ type: GET_CUSTOMER_SUCCESS, payload: response.data });
    } else {
      toast.error(response.data.message);
      yield put({ type: GET_CUSTOMER_FAILED, payload: response.data.message });
    }
  } catch (error) {
    yield put({
      type: GET_CUSTOMER_FAILED,
      payload: { errorMessage: "Invalid Params" },
    });
    toast.error(error.response.data.message);
  }
}

function* editCustomerSaga({ id, data }) {
  try {
    yield put({ type: SET_LOADING });
    let formData = prepareFileData(data);
    const response = yield API.put(`/api/customers/${id}/update`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.success === true) {
      toast.success(response.data.message);
      yield put({ type: EDIT_CUSTOMER_SUCCESS, payload: response.data.message });
    } else {
      toast.error(response.data.message);
      yield put({ type: EDIT_CUSTOMER_FAILED, payload: response.data.message });
    }
  } catch (error) {
    yield put({
      type: API_ERROR,
      payload: { errorMessage: "Invalid Params" },
    });
  }
}

function* searchCustomerSaga({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get("/api/customers/find-customer", {
      params: { keyword: payload },
    });
    if (data.success) {
      yield put({ type: SEARCH_CUSTOMER_SUCCESS, payload: data });
    } else {
      yield put({ type: SEARCH_CUSTOMER_FAILED, errorMessage: "Unable to find!" });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: API_ERROR,
      payload: { errorMessage: "Invalid Params" },
    });
    toast.error(error.response.data.message);
  }
}

function* deleteCustomerSaga({ payload }) {
  const navigate = payload?.navigate;
  try {
    yield put({ type: actionTypes.SET_LOADING });
    const { data } = yield API.delete(`api/customers/${payload?.customerId}`);
    if (data.success) {
      yield put({ type: actionTypes.DELETE_CUSTOMER_SUCCESS });
      navigate("/customers");
      toast.success(data.message);
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.DELETE_CUSTOMER_FAILED,
        errorMessage: "Unable to delete Customer",
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: actionTypes.DELETE_CUSTOMER_FAILED,
      errorMessage: "Unable to delete Customer",
    });
  }
}

function* customerSaga() {
  yield all([
    yield takeEvery(LIST_CUSTOMERS, listCustomerSaga),
    yield takeEvery(CREATE_CUSTOMERS, createCustomerSaga),
    yield takeEvery(GET_CUSTOMER, getCustomerSaga),
    yield takeEvery(EDIT_CUSTOMER, editCustomerSaga),
    yield takeEvery(SEARCH_CUSTOMER, searchCustomerSaga),
    yield takeEvery(actionTypes.LIST_CUSTOMERS_MODULE, listCustomerModulea),
    yield takeEvery(actionTypes.DELETE_CUSTOMER, deleteCustomerSaga),
  ]);
}

export default customerSaga;
