import {
  GET_SINGLE_ROUTE_FAILED,
  GET_SINGLE_ROUTE_SUCCESS,
  LIST_ROUTES_FAILED,
  LIST_ROUTES_SUCCESS,
  SET_LOADING_ROUTES,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  routeData: [],
  singleRouteData: {},
};
const RouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_ROUTES:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case LIST_ROUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        routeData: action.payload.data,
      };
    case LIST_ROUTES_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    case GET_SINGLE_ROUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        singleRouteData: action.payload.data,
      };
    case GET_SINGLE_ROUTE_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default RouteReducer;
