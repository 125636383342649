/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable new-cap */
/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import {
  Alert,
  Card,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  Menu,
  Button,
  TextField,
  MenuItem,
  ListItemIcon,
  Typography,
  Autocomplete,
} from "@mui/material";
import { formatPhoneNumber } from "utils";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getSingleInvoiceAction,
  updateInvoiceAction,
  sendInvoicAction,
  deleteInvoiceAction,
  updateInvoicePaymentAction,
  ReminderAPIAction,
  deleteRemainderAction,
} from "redux/invoices/actions";
import { AiFillFileText } from "react-icons/ai";
import { BsCashCoin, BsCashStack, BsFillSendFill } from "react-icons/bs";
import { FaCcStripe, FaMoneyCheckAlt } from "react-icons/fa";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { formatDistanceToNow } from "date-fns";
import CustomSpinner from "examples/CustomLoader";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import NotificationItem from "examples/Items/NotificationItem";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import makeitrainlogo from "../assets/makeitrainlogo.png";
import greenCheckMark from "../assets/green-check-mark-icon-with-circle.webp";
// eslint-disable-next-line import/no-named-as-default
import SentInvoiceDialog from "../sentInvoiceDialog/SentInvoiceDialog";

const item = [
  { label: "3 day before", value: -3 },
  { label: "1 day before", value: -1 },
  { label: "On due date", value: 0 },
  { label: "3 days after", value: 3 },
  { label: "7 days after", value: 7 },
  { label: "14 days after", value: 14 },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const paymentOptions = [
  { label: "Cash", value: "cash", icon: <BsCashStack /> },
  { label: "Cheque", value: "cheque", icon: <FaMoneyCheckAlt /> },
  { label: "Credit Card", value: "credit_card", icon: <BsCashCoin /> },
];

function ViewInvoice() {
  const location = useLocation();
  const pdfRef = useRef();
  const paramsId = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { singleInvoice, loading, successInvoiceSend } = useSelector(
    (state) => state.InvoiceReducer
  );
  const [dateInvoice, setDateInvoice] = useState({});
  const [dayAgo, setDayAgo] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [dilogOpen, setDilogOpen] = React.useState(false);
  const [recordPayment, setRecordPayment] = useState({ payment_method: "manually" });
  const [successPop, setSuccessPop] = useState(false);
  const [toNumber, setToNumber] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [invoiceReminder, setInvoiceReminder] = useState([]);
  const [paymentSate, setPaymentSate] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const viewInvoiceHeadWrapper = {
    padding: "3% 0 0 3%",
    display: "flex",
    justifyContent: "space-between",
  };
  const createInvoiceCard = {
    display: "flex",
    justifyContent: "space-between",
    gap: "5px",
  };
  if (windowWidth <= 768) {
    viewInvoiceHeadWrapper.flexDirection = "column";
    createInvoiceCard.flexDirection = "column";
    // createInvoiceCard.marginRigth = "1rem";
  }
  const generateTransactionId = () => {
    const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, ""); // Get the current date in the format "YYYYMMDD"
    const randomNumber = Math.floor(Math.random() * 100000); // Generate a random number between 0 and 99999
    const transactionId = `${currentDate}${randomNumber.toString().padStart(5, "0")}`; // Format the transaction ID
    return transactionId;
  };
  const randomNumber = generateTransactionId();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateInvoicePaymentAction({
        tabStatus: null,
        id: singleInvoice._id,
        obj: {
          payment: [
            {
              ...recordPayment,
              payment_date: new Date().toISOString().substring(0, 10),
              transaction_id: randomNumber,
            },
          ],
        },
      })
    );
    setDilogOpen(false);
    setPaymentSuccess(true);
  };
  const handleCloseDilog = () => {
    setDilogOpen(false);
  };
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleClickOpen = () => {
    setDilogOpen(true);
    setOpenMenu(false);
  };
  const handleSkipSending = () => {
    dispatch(
      updateInvoiceAction({
        id: singleInvoice._id,
        obj: { ...singleInvoice, status: "sent" },
      })
    );
  };
  const handleChecked = async (value) => {
    try {
      if (invoiceReminder.includes(value.value)) {
        await dispatch(
          deleteRemainderAction({
            id: singleInvoice._id,
            obj: {
              scheduled_period: value.value,
            },
          })
        );
        setInvoiceReminder((prevReminder) => prevReminder.filter((item) => item !== value.value));
      } else {
        await dispatch(
          ReminderAPIAction({
            id: singleInvoice._id,
            obj: {
              scheduled_period: value.value,
              action: "CREATE",
            },
          })
        );
        setInvoiceReminder((prevReminder) => [...prevReminder, value.value]);
      }
    } catch (error) {
      toast.error("Error occurred:", error);
    }
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue <= singleInvoice?.total_price - `${singleInvoice?.paid_amount || 0}`) {
      setPaymentSate(inputValue);
      const floatRegExp = /^-?\d*(\.\d*)?$/;
      if (floatRegExp.test(inputValue) || inputValue === "") {
        setRecordPayment({ ...recordPayment, paid_amount: inputValue });
      }
    }
  };
  // eslint-disable-next-line no-shadow
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {singleInvoice.status === "draft" && (
        <NotificationItem
          title="Edit"
          onClick={() => navigate(`/edit_invoice/${singleInvoice._id}`)}
        />
      )}
      <NotificationItem
        title="Duplicate"
        onClick={() =>
          navigate(`/edit_invoice/${singleInvoice?._id}`, { state: { Duplicate: true } })
        }
      />
      {(singleInvoice.status === "sent" || singleInvoice.status === "partial") && (
        <NotificationItem title="Record Payment" onClick={handleClickOpen} />
      )}
      <NotificationItem
        title="Resend"
        onClick={() => {
          dispatch(
            sendInvoicAction({
              obj: {
                invoice_id: singleInvoice?._id,
                number: [toNumber],
                email: singleInvoice?.customer_id?.email,
              },
              id: singleInvoice?._id,
            })
          );
          setOpenMenu(false);
        }}
      />
      <NotificationItem
        title="Export as PDF"
        onClick={() => {
          window.open(
            `https://projects.xcitech.in/make-it-rain-api/api/invoice/${singleInvoice?._id}/invoice-generate`
          );
          setOpenMenu(false);
        }}
      />
      <NotificationItem
        title="Print"
        onClick={() => {
          window.open(
            `https://projects.xcitech.in/make-it-rain-api/api/invoice/${singleInvoice?._id}/invoice-generate`
          );
          setOpenMenu(false);
        }}
      />
      <NotificationItem
        title="Delete"
        onClick={() => {
          setOpenMenu(false);
          Swal.fire({
            title: "Do you want to delete?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#a5bd4b",
            denyButtonText: `No`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              dispatch(
                deleteInvoiceAction({ id: singleInvoice?._id, status: singleInvoice.tabStatus })
              );
              navigate("/invoices");
            }
          });
        }}
      />
    </Menu>
  );

  const handleApproveStatus = () => {
    dispatch(
      updateInvoiceAction({
        id: singleInvoice._id,
        obj: { ...singleInvoice, status: "unsent" },
      })
    );
  };
  const handleDownload = async () => {
    window.open(
      `https://projects.xcitech.in/make-it-rain-api/api/invoice/${paramsId.id}/invoice-generate`
    );
  };
  useEffect(() => {
    // if (paramsId.id) {
    if (!singleInvoice._id) {
      dispatch(getSingleInvoiceAction(paramsId?.id));
    } else if (paymentSuccess) {
      dispatch(getSingleInvoiceAction(paramsId?.id));
      setPaymentSuccess(false);
    }
    // }
  }, [paramsId, paymentSuccess]);
  useEffect(() => {
    if (location?.state === true) {
      dispatch(getSingleInvoiceAction(paramsId?.id));
      const updatedLocation = { ...location, state: undefined };
      navigate(updatedLocation, { replace: true });
    } else if (!singleInvoice.status) {
      dispatch(getSingleInvoiceAction(paramsId?.id));
    }
  }, [paramsId, location, singleInvoice]);
  useEffect(() => {
    if (singleInvoice.invoice_date) {
      const createdAt = new Date(singleInvoice?.createdAt);
      const elapsedTime = formatDistanceToNow(createdAt, { addSuffix: true });
      setDayAgo(elapsedTime);
      const invoiceDate = new Date(singleInvoice?.invoice_date);
      const formattedInvoiceDate = new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(invoiceDate);
      const paymentDue = new Date(singleInvoice?.payment_due_date);
      const formattedPaymentDueDate = new Intl.DateTimeFormat("en-Us", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(paymentDue);
      setDateInvoice({
        invoice_date: formattedInvoiceDate,
        payment_due_date: formattedPaymentDueDate,
      });
    }
  }, [singleInvoice]);
  useEffect(() => {
    if (successPop) {
      if (successInvoiceSend.message === "Invoice send sucessfully") {
        setOpenSuccess(true);
        dispatch(
          updateInvoiceAction({
            id: singleInvoice._id,
            obj: { ...singleInvoice, status: "sent" },
          })
        );
      }
      setSuccessPop(false);
    }
  }, [successInvoiceSend]);
  useEffect(() => {
    if (singleInvoice?.reminders?.length) {
      const scheduledPeriodArray = singleInvoice?.reminders.map((reminder) =>
        Number(reminder.scheduled_period)
      );
      setInvoiceReminder(scheduledPeriodArray);
    }
    if (singleInvoice?.total_price) {
      setPaymentSate(singleInvoice?.total_price - `${singleInvoice.paid_amount || 0}`);
      setRecordPayment({
        ...recordPayment,
        paid_amount: singleInvoice?.total_price - `${singleInvoice.paid_amount || 0}`,
      });
    }
  }, [singleInvoice]);
  useEffect(() => {
    if (singleInvoice?.customer_id?.phone.length > 0) {
      const number = singleInvoice?.customer_id?.phone;
      const formatedNumber = number.replace(/[^\d+]/g, "");
      if (formatedNumber.startsWith("+91")) {
        setToNumber(formatedNumber);
      } else {
        setToNumber(`+91${formatedNumber}`);
      }
    }
  }, [singleInvoice?.customer_id]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <DashboardLayout>
      <MDBox
        display="flex"
        justifyContent="space-between"
        marginBottom={windowWidth <= 768 && "1rem"}
      >
        <MDTypography variant="h2" display="flex" alignItems="center">
          <KeyboardReturnRoundedIcon
            fontSize="large"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/invoices")}
          />
          Invoice {singleInvoice?.invoice_number}
        </MDTypography>
        <SideNavActionBtn />
      </MDBox>
      {loading || !singleInvoice ? (
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CustomSpinner />
        </MDBox>
      ) : (
        <MDBox>
          <MDBox
            display="flex"
            gap="1rem"
            width="100%"
            justifyContent={windowWidth <= 768 ? "space-between" : "flex-end"}
          >
            <MDButton onClick={handleOpenMenu}>More Action</MDButton>
            {renderMenu()}
            <MDButton
              onClick={() =>
                navigate("/create_invoice", {
                  state: { item: singleInvoice },
                })
              }
            >
              Create Another Invoice
            </MDButton>
          </MDBox>
          <Card sx={{ marginTop: "5px" }}>
            <MDBox style={viewInvoiceHeadWrapper}>
              <MDBox display="flex" width={windowWidth <= 768 ? "100%" : "25%"}>
                <MDBox>
                  {windowWidth >= 768 && (
                    <MDTypography fontWeight="medium" color="text" fontSize="1rem">
                      Status
                    </MDTypography>
                  )}
                  <Chip
                    label={singleInvoice?.status}
                    style={{ borderRadius: "0.375rem", fontSize: ".765rem", height: "1.6rem" }}
                    variant="outlined"
                  />
                </MDBox>
                {windowWidth >= 768 && (
                  <MDBox width="100%">
                    <MDTypography fontWeight="medium" color="text" fontSize="1rem">
                      Customer
                    </MDTypography>
                    <MDTypography fontSize="1rem">
                      {singleInvoice?.customer_id?.first_name}{" "}
                      {singleInvoice?.customer_id?.last_name}
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
              {windowWidth <= 768 && (
                <MDTypography
                  style={{ fontWeight: "400", marginTop: "5px" }}
                  display="flex"
                  alignItems="center"
                  variant="h6"
                  fontWeight="medium"
                >
                  {singleInvoice?.customer_id?.first_name} {singleInvoice?.customer_id?.last_name}
                </MDTypography>
              )}
              <MDBox
                display="flex"
                width={windowWidth <= 768 ? "100%" : "25%"}
                marginTop={windowWidth <= 768 && "1rem"}
              >
                <MDBox>
                  <MDTypography fontWeight="medium" color="text" fontSize="1rem">
                    Amount Due
                  </MDTypography>
                  <MDTypography fontSize="1rem">
                    {singleInvoice && singleInvoice.total_price !== undefined
                      ? singleInvoice.total_price.toLocaleString(undefined, {
                          style: "currency",
                          currency: "USD",
                        })
                      : 0}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography fontWeight="medium" color="text" fontSize="1rem">
                    Due on
                  </MDTypography>
                  <MDTypography fontSize="1rem">{dateInvoice?.payment_due_date}</MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox padding="3%" marginTop="4%">
              {!singleInvoice.status === "draft" && (
                <Alert severity="warning" sx={{ display: "flex", justifyContent: "center" }}>
                  This is a DRAFT invoice. You can take further actions once you approve it.
                </Alert>
              )}
              <MDBox
                border={
                  singleInvoice?.status === "draft" ? "solid 3px orange" : "solid 3px lightgreen"
                }
                style={{
                  boxShadow:
                    singleInvoice?.status === "draft"
                      ? "2px 2px 12px #ffe1ab"
                      : "2px 2px 12px #d8f5d8",
                }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="0 2% 0 2%"
              >
                <MDBox display="flex" alignItems="center" gap="5px">
                  <AiFillFileText size={100} />
                  <MDBox>
                    {windowWidth >= 768 && <MDTypography color="text">Create invoice</MDTypography>}
                    <MDTypography fontSize="0.85rem">Created: {dayAgo}</MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox display="flex" justifyContent="end" gap="1rem">
                  {singleInvoice.status === "draft" && (
                    <div style={createInvoiceCard}>
                      <MDButton color="dark" onClick={handleApproveStatus}>
                        Approve
                      </MDButton>
                      <MDButton
                        variant="outlined"
                        color="dark"
                        onClick={() => navigate(`/edit_invoice/${singleInvoice._id}`)}
                      >
                        Edit
                      </MDButton>
                    </div>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                style={{
                  boxShadow:
                    singleInvoice?.status === "unsent"
                      ? "2px 2px 12px #ffe1ab"
                      : singleInvoice?.status === "draft"
                      ? ""
                      : "2px 2px 12px #d8f5d8",
                }}
                border={
                  singleInvoice.status === "unsent"
                    ? "solid 3px orange"
                    : singleInvoice.status === "draft"
                    ? "solid 1px rgb(213, 213, 213)"
                    : "solid 3px lightgreen"
                }
                padding="0 2% 0 2%"
                marginTop="2%"
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection={windowWidth <= 768 && "column"}
                >
                  <MDBox
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    mt={1}
                  >
                    <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                      <BsFillSendFill size={60} />
                    </div>
                    <div>
                      <MDTypography fullWidth color="text">
                        Send invoice
                      </MDTypography>
                    </div>
                  </MDBox>
                  {singleInvoice.status === "unsent" && (
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "5px" }}>
                      <MDButton fullWidth color="dark" onClick={() => setOpen(true)}>
                        Send Invoice
                      </MDButton>
                      <MDButton
                        fullWidth
                        variant="outlined"
                        color="dark"
                        onClick={handleSkipSending}
                      >
                        Skip Sending
                      </MDButton>
                    </div>
                  )}
                </MDBox>
                <SentInvoiceDialog
                  open={open}
                  setOpen={setOpen}
                  singleInvoice={singleInvoice}
                  setSuccessPop={setSuccessPop}
                />
                <BootstrapDialog
                  fullWidth
                  onClose={() => setOpenSuccess(false)}
                  aria-labelledby="customized-dialog-title"
                  open={openSuccess}
                >
                  <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={() => setOpenSuccess(false)}
                  >
                    Send this invoice
                  </BootstrapDialogTitle>
                  <DialogContent dividers>
                    <MDBox display="flex" justifyContent="center">
                      <MDBox fullWidth>
                        <MDBox display="flex" justifyContent="center">
                          <img
                            style={{ objectFit: "contain" }}
                            src={greenCheckMark}
                            loading="lazy"
                            alt=""
                          />
                        </MDBox>
                        <MDBox display="flex" justifyContent="center">
                          <MDTypography fullWidth variant="h3">
                            This invoice was sent
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </DialogContent>
                  <DialogActions>
                    <MDButton autoFocus onClick={() => setOpenSuccess(false)}>
                      Close
                    </MDButton>
                  </DialogActions>
                </BootstrapDialog>
                {singleInvoice.status === "unsent" && (
                  <>
                    <hr style={{ marginTop: windowWidth <= 768 && "1rem" }} />
                    <MDBox padding="2%">
                      <MDTypography
                        display="flex"
                        alignItems="center"
                        fontSize="0.95rem"
                        color="text"
                      >
                        <NotificationsIcon /> Schedule reminders after due date:
                      </MDTypography>
                      <MDBox
                        style={{ display: "flex", flexDirection: windowWidth <= 768 && "column" }}
                      >
                        {item?.map((value, key) => (
                          <FormGroup key={key.id} style={{ minWidth: "100px" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={invoiceReminder.includes(value.value)}
                                  onChange={() => handleChecked(value)}
                                />
                              }
                              label={value.label}
                            />
                          </FormGroup>
                        ))}
                      </MDBox>
                    </MDBox>
                  </>
                )}
              </MDBox>
              <MDBox
                style={{
                  boxShadow:
                    singleInvoice?.status === "paid"
                      ? "2px 2px 12px #d8f5d8"
                      : singleInvoice?.status === "paid"
                      ? "2px 2px 12px #ffe1ab"
                      : "",
                }}
                border={
                  singleInvoice.status === "paid"
                    ? "solid 3px lightgreen"
                    : singleInvoice.status === "sent"
                    ? "solid 3px orange"
                    : "solid 1px rgb(213, 213, 213)"
                }
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="0 2% 0 2%"
                marginTop="2%"
              >
                <MDBox display="flex" alignItems="center" gap="10px">
                  <FaCcStripe size={100} />
                  <MDBox>
                    <MDTypography color="text">Get paid</MDTypography>
                    <MDTypography fontSize="0.85rem">
                      Amount Due:{" "}
                      {singleInvoice && singleInvoice?.total_price !== undefined
                        ? singleInvoice?.total_price.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })
                        : 0}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox padding="3%" ref={pdfRef}>
              <MDBox
                marginTop="3%"
                display="flex"
                justifyContent="space-between"
                flexDirection={windowWidth <= 768 && "column"}
              >
                <MDBox
                  display={windowWidth <= 768 && "flex"}
                  justifyContent={windowWidth <= 768 && "center"}
                  width={windowWidth <= 768 ? "100%" : "35%"}
                >
                  <img
                    style={{ paddingLeft: "0", objectFit: "contain" }}
                    src={makeitrainlogo}
                    loading="lazy"
                    alt=""
                  />
                </MDBox>
                {windowWidth >= 768 && (
                  <MDBox display="flex" justifyContent="center">
                    <MDBox width="100%">
                      <MDTypography fontWeight="medium" variant="h3">
                        INVOICE
                      </MDTypography>
                      <MDTypography marginTop="0.5%" fontWeight="medium" variant="h4">
                        Make it Rain, LLC
                      </MDTypography>
                      <MDTypography>11 Sunset Drive</MDTypography>
                      <MDTypography>Shelton CT 06484</MDTypography>
                      <MDTypography marginTop="3%">203-962-1618</MDTypography>
                      <MDTypography>makeitrainct@gmail.com</MDTypography>
                    </MDBox>
                  </MDBox>
                )}
              </MDBox>
              <hr style={{ marginTop: "3%" }} />
              <MDBox
                display="flex"
                marginTop="3%"
                justifyContent="space-between"
                flexDirection={windowWidth <= 768 && "column"}
              >
                <MDBox width={windowWidth <= 768 ? "100%" : "35%"} paddingLeft="1%">
                  <MDTypography fontWeight="medium" color="text" fontSize="1rem">
                    Bill to
                  </MDTypography>
                  <MDBox padding={windowWidth >= 768 && "5%"}>
                    <MDTypography variant="h5">
                      {singleInvoice?.customer_id?.first_name}{" "}
                      {singleInvoice?.customer_id?.last_name}
                    </MDTypography>
                    <MDTypography>{singleInvoice?.customer_id?.property?.address} </MDTypography>
                    <MDTypography>
                      {singleInvoice?.customer_id?.property?.city}{" "}
                      {singleInvoice?.customer_id?.property?.state}{" "}
                      {singleInvoice?.customer_id?.property?.zip}
                    </MDTypography>
                    <MDTypography marginTop="3%">
                      {singleInvoice?.customer_id?.phone &&
                        formatPhoneNumber(singleInvoice?.customer_id?.phone)}
                    </MDTypography>
                    <MDTypography>{singleInvoice?.customer_id?.email}</MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDBox width="100%">
                    <MDBox className="view_invoice_details_text">
                      <MDTypography variant="h5" color="text">
                        Invoice number
                      </MDTypography>
                      <MDTypography className="view_invoice_details_text_child_value">
                        {singleInvoice?.invoice_number}
                      </MDTypography>
                    </MDBox>
                    <MDBox className="view_invoice_details_text">
                      <MDTypography variant="h5" color="text">
                        Invoice date
                      </MDTypography>
                      <MDTypography className="view_invoice_details_text_child_value">
                        {dateInvoice?.invoice_date}
                      </MDTypography>
                    </MDBox>
                    <MDBox className="view_invoice_details_text">
                      <MDTypography variant="h5" color="text">
                        Payment due
                      </MDTypography>
                      <MDTypography className="view_invoice_details_text_child_value">
                        {dateInvoice?.payment_due_date}
                      </MDTypography>
                    </MDBox>
                    <MDBox className="view_invoice_details_text">
                      <MDTypography variant="h5" color="text">
                        Amount due
                      </MDTypography>
                      <MDTypography className="view_invoice_details_text_child_value">
                        {singleInvoice && singleInvoice?.total_price !== undefined
                          ? singleInvoice?.total_price.toLocaleString(undefined, {
                              style: "currency",
                              currency: "USD",
                            })
                          : 0}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox marginTop="2rem">
                <TableContainer style={{ boxShadow: "none" }}>
                  <Table>
                    {windowWidth >= 768 && (
                      <TableRow>
                        <TableCell className="item_table_header" style={{ width: "40%" }}>
                          Item
                        </TableCell>
                        <TableCell className="item_table_header" style={{ width: "10%" }}>
                          Hours or Quantity
                        </TableCell>
                        <TableCell className="item_table_header" style={{ width: "10%" }}>
                          Price
                        </TableCell>
                        <TableCell className="item_table_header" style={{ width: "10%" }}>
                          Amount
                        </TableCell>
                      </TableRow>
                    )}
                    <TableBody>
                      {singleInvoice?.items?.map((item, index) => (
                        <>
                          {windowWidth >= 768 ? (
                            <TableRow key={index.id}>
                              <TableCell>
                                {item.item_name}
                                {item.description}
                              </TableCell>
                              <TableCell>{item.hours_or_qty || 0} Hour</TableCell>
                              <TableCell>
                                {item?.price?.toLocaleString(undefined, {
                                  style: "currency",
                                  currency: "USD",
                                }) || 0}
                              </TableCell>
                              <TableCell>
                                {item?.amount?.toLocaleString(undefined, {
                                  style: "currency",
                                  currency: "USD",
                                }) || 0}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <Card
                              style={{
                                padding: "3%",
                                border: "1px solid black",
                                marginTop: "1rem",
                                gap: "1rem",
                              }}
                            >
                              <div>
                                <MDTypography variant="h6" component="h1" color="text">
                                  Item
                                </MDTypography>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  value={item.item_name}
                                  label=""
                                  variant="outlined"
                                  type="text"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div>
                                <MDTypography variant="h6" component="h1" color="text">
                                  Hours or Quantity
                                </MDTypography>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  value={item.hours_or_qty}
                                  label=""
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div>
                                <MDTypography variant="h6" component="h1" color="text">
                                  Price
                                </MDTypography>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  value={item.price}
                                  label=""
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "1rem" }}>
                                <MDTypography variant="h6" component="h1" color="text">
                                  Amount
                                </MDTypography>
                                <MDTypography variant="text">
                                  {item.hours_or_qty && item.price !== null
                                    ? (item.hours_or_qty * item.price).toLocaleString(undefined, {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                    : "0.00"}
                                </MDTypography>
                              </div>
                            </Card>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MDBox>
              <hr style={{ marginTop: "3%" }} />
              <MDBox
                marginTop="4%"
                display="flex"
                justifyContent="space-between"
                flexDirection={windowWidth <= 768 && "column"}
                width="100%"
              >
                <MDBox
                  sx={{
                    height: "30%",
                    width: windowWidth <= 768 ? "100%" : "70%",
                    padding: "0 3% 3% 0",
                  }}
                >
                  <MDTypography variant="h6" component="h1" color="text">
                    Notes/Terms
                  </MDTypography>
                  <TextField
                    value={singleInvoice?.note}
                    fullWidth
                    id="outlined-multiline-static"
                    multiline
                    rows={6}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </MDBox>
                <MDBox width={windowWidth <= 768 ? "100%" : "35%"}>
                  <MDBox className="view_invoice_details_text">
                    <MDTypography variant="h5" color="text">
                      Subtotal
                    </MDTypography>
                    <MDTypography className="view_invoice_details_text_child_value">
                      {singleInvoice && singleInvoice?.sub_total_price !== undefined
                        ? singleInvoice?.sub_total_price.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })
                        : 0}
                    </MDTypography>
                  </MDBox>
                  {singleInvoice?.discount?.map((item, index) => {
                    const key = Object.keys(item)[0];
                    const value = item[key];
                    return (
                      <MDBox className="view_invoice_details_text">
                        <MDTypography key={index.id}>{key}</MDTypography>
                        <MDTypography className="view_invoice_details_text_child_value">
                          -{" "}
                          {value?.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          }) || 0}
                        </MDTypography>
                      </MDBox>
                    );
                  })}
                  {singleInvoice?.tax?.map((item, index) => {
                    const key = Object.keys(item)[0];
                    const value = item[key];
                    return (
                      <MDBox className="view_invoice_details_text">
                        <MDTypography key={index.id}>{key}</MDTypography>
                        <MDTypography className="view_invoice_details_text_child_value">
                          +{" "}
                          {value?.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          }) || 0}
                        </MDTypography>
                      </MDBox>
                    );
                  })}
                  <hr style={{ marginTop: "3%" }} />
                  <MDBox display="flex" justifyContent="space-between">
                    <MDTypography variant="h5" color="text">
                      Total
                    </MDTypography>
                    <MDTypography className="view_invoice_details_text_child_value">
                      {singleInvoice && singleInvoice?.total_price !== undefined
                        ? singleInvoice?.total_price.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })
                        : 0}
                    </MDTypography>
                  </MDBox>
                  <hr />
                  <MDBox className="view_invoice_details_text">
                    <MDTypography variant="h5" color="text">
                      Amount due
                    </MDTypography>
                    <MDTypography className="view_invoice_details_text_child_value">
                      {singleInvoice && singleInvoice?.total_price !== undefined
                        ? singleInvoice?.total_price.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })
                        : 0}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox padding="3%" display="flex" justifyContent="flex-end">
              <MDButton color="dark" onClick={handleDownload}>
                Print Invoice
              </MDButton>
            </MDBox>
          </Card>
        </MDBox>
      )}
      <Dialog
        component="form"
        role="form"
        onSubmit={handleSubmit}
        open={dilogOpen}
        onClose={handleCloseDilog}
      >
        <div>
          <DialogTitle>Record Payment</DialogTitle>
          <DialogContent>
            <Autocomplete
              required
              style={{ marginTop: "5px", width: "250px" }}
              id="payment-method"
              options={paymentOptions}
              onChange={(e, v) => setRecordPayment({ ...recordPayment, payment_type: v.value })}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <MenuItem {...props}>
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <Typography variant="inherit">{option.label}</Typography>
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Payment Type" required variant="outlined" />
              )}
            />
            <TextField
              required
              style={{ marginTop: "5%", width: "250px" }}
              variant="outlined"
              margin="dense"
              id="name"
              label="Amount"
              type="number"
              fullWidth
              value={paymentSate} // Set the value to the current state value
              onChange={handleAmountChange} // Use the custom function to handle the change
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDilog}>Cancel</Button>
            <Button type="submit">Update</Button>
          </DialogActions>
        </div>
      </Dialog>
    </DashboardLayout>
  );
}

export default ViewInvoice;
