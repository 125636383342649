import {
  CREATE_STAFF_FAILED,
  CREATE_STAFF_SUCCESS,
  DELETE_EMPLOYEE_FAILED,
  DELETE_EMPLOYEE_SUCCESS,
  GET_STAFF_FAILED,
  GET_STAFF_SUCCESS,
  LIST_STAFF_SUCCESS,
  LIST__STAFF_FAILED,
  SET_STAFF_LOADING,
} from "./actionTypes";

const initialState = {
  staffLoading: false,
  error: false,
  errorMessage: "",
  staffData: [],
  singleStaffData: {},
};

const StaffReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFF_LOADING:
      return {
        ...state,
        staffLoading: true,
        errorMessage: "",
      };
    case LIST_STAFF_SUCCESS:
      return {
        ...state,
        staffLoading: false,
        error: false,
        staffData: action.payload,
      };
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        error: false,
        staffLoading: false,
      };
    case DELETE_EMPLOYEE_FAILED:
    case CREATE_STAFF_FAILED:
    case LIST__STAFF_FAILED:
    case GET_STAFF_FAILED:
      return {
        ...state,
        staffLoading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    case CREATE_STAFF_SUCCESS:
      return {
        ...state,
        staffLoading: false,
        error: false,
      };
    case GET_STAFF_SUCCESS:
      return {
        ...state,
        staffLoading: false,
        error: false,
        singleStaffData: action.payload,
      };
    default:
      return state;
  }
};

export default StaffReducer;
