/* eslint-disable import/prefer-default-export */
export const SET_CITY_LOADING = "SET_CITY_LOADING";
export const CLEAR_SINGLE_CITY_DATA = "CLEAR_SINGLE_CITY_DATA";

export const LIST_CITY = "LIST_CITY";
export const LIST_CITY_SUCCESS = "LIST_CITY_SUCCESS";
export const LIST_CITY_FAILED = "LIST_CITY_FAILED";

export const ADD_CITY = "ADD_CITY";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_FAILED = "ADD_CITY_FAILED";

export const GET_CITY_BY_ID = "GET_CITY_BY_ID";
export const GET_CITY_BY_ID_SUCCESS = "GET_CITY_BY_ID_SUCCESS";
export const GET_CITY_BY_ID_FAILED = "GET_CITY_BY_ID_FAILED";

export const EDIT_CITY = "EDIT_CITY";
export const EDIT_CITY_SUCCESS = "EDIT_CITY_SUCCESS";
export const EDIT_CITY_FAILED = "EDIT_CITY_FAILED";

export const DELETE_CITY = "DELETE_CITY";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_FAILED = "DELETE_CITY_FAILED";
