/* eslint-disable */

import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import { deleteHolidaysAction } from "redux/Holidays/actions";
import Swal from "sweetalert2";

function TableDelete({ id }) {
  const dispatch = useDispatch();
  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#a5bd4b",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteHolidaysAction({ id }));
      }
    });
  };
  return (
    <>
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        <Tooltip title="Delete" placement="top">
          <DeleteIcon onClick={() => handleDelete(id)} fontSize="medium" color="error" />
        </Tooltip>
      </MDTypography>
    </>
  );
}

export default TableDelete;
