/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign, no-underscore-dangle */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import Actions from "../Actions";
import ViewProfile from "../ViewProfile";
import StatusAction from "../StatusAction";

export const employeeTypeOptions = [
  { value: "admin", label: "Admin" },
  { value: "billing_admin", label: "Billing Admin" },
  { value: "employee", label: "Field Employee" },
];

export function getRoleLabel(roleName) {
  return roleName === "owner"
    ? "Global Admin"
    : employeeTypeOptions.find((option) => option.value === roleName)?.label;
}

export default function data(tableData) {
  return {
    columnData: [
      { Header: "Name", accessor: "first_name", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "crew(s)", accessor: "creaws", align: "left" },
      { Header: "Role Name", accessor: "role_name", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "left" },
    ],

    rowData: tableData.map((d) => ({
      first_name: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          <ViewProfile first_name={d?.first_name} last_name={d?.last_name} id={d?._id} />
        </MDTypography>
      ),
      email: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d?.email}
        </MDTypography>
      ),
      creaws: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d?.crewTitle}
        </MDTypography>
      ),
      role_name: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {getRoleLabel(d?.role_name)}
        </MDTypography>
      ),
      status: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          <StatusAction status={d?.status} obj={d} />
        </MDTypography>
      ),
      action: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <Actions id={d?._id} employees={d} options={employeeTypeOptions} />
        </MDTypography>
      ),
    })),
  };
}
