import API from "services/axiosapi";
import { toast } from "react-toastify";
import { put, takeLatest, all } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";

function* getInvoiceListSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.get("/api/invoice", {
      params: { page: payload.page, limit: payload.pageLimit },
    });
    if (data.success) {
      yield put({ type: actionTypes.GET_INVOICE_LIST_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_INVOICE_LIST_FAILED,
        errorMessage: "Unable to fetch data!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_INVOICE_LIST_FAILED, errorMessage: "Unable to fetch data!" });
    toast.error(error.response.data.message);
  }
}

// eslint-disable-next-line no-unused-vars
function* getSingleInvoiceSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.get(`/api/invoice/${id}`);
    if (data.success) {
      yield put({ type: actionTypes.GET_SINGLE_INVOICE_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_SINGLE_INVOICE_FAILED,
        errorMessage: "Unable to fetch data!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_INVOICE_FAILED,
      errorMessage: "Unable to fetch data!",
    });
    toast.error(error.response.data.message);
  }
}

function* updateInvoiceStatusSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.put(`/api/invoice/${id}/viewed`);
    if (data.success) {
      yield put({ type: actionTypes.STATUS_UPDATE_SUCCESS });
    } else {
      yield put({
        type: actionTypes.STATUS_UPDATE_FAILED,
        errorMessage: "Unable to update status!",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.STATUS_UPDATE_FAILED,
      errorMessage: "Unable to fetch data!",
    });
  }
}

function* createInvoiceSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.post("/api/invoice", { ...obj, status: "draft" });
    if (data.success) {
      // eslint-disable-next-line no-underscore-dangle
      yield put({ type: actionTypes.CREATE_INVOICE_SUCCESS, payload: data.data });
      toast.success(data.message);
    } else {
      yield put({
        type: actionTypes.CREATE_INVOICE_FAILED,
        errorMessage: "Unable to create invoice",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_INVOICE_FAILED,
      errorMessage: "Unable to create invoice",
    });
    toast.error(error.response.data.message);
  }
}

function* filterInvoiceSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.get("/api/invoice/filter", {
      params: { ...payload },
    });
    if (data.success) {
      yield put({ type: actionTypes.FILTER_INVOICE_SUCCESS, payload: data });
    } else {
      yield put({ type: actionTypes.FILTER_INVOICE_FAILED, errorMessage: "Unable to filter!" });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.FILTER_INVOICE_FAILED, errorMessage: "Unable to filter!" });
    toast.error(error.response.data.message);
  }
}

function* updateInvoiceSaga({ payload }) {
  const { id } = payload;
  const { obj } = payload;
  // const { invoice } = payload;
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.put(`/api/invoice/${id}/update`, obj);
    if (data.success) {
      yield put({ type: actionTypes.UPDATE_INVOICE_SUCCESS });
      yield put({ type: actionTypes.GET_SINGLE_INVOICE, id });
      toast.success("Invoice updated!");
    } else {
      yield put({
        type: actionTypes.UPDATE_INVOICE_FAILED,
        errorMessage: "Unable to update invoice!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_INVOICE_FAILED,
      errorMessage: "Unable to update invoice!",
    });
    toast.error(error.response.data.message);
  }
}

function* deleteInvoiceSaga({ payload }) {
  const { id } = payload;
  const { status } = payload;
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.delete(`/api/invoice/${id}/invoice-delete`);
    if (data.success) {
      yield put({ type: actionTypes.DELETE_INVOICE_SUCCESS });
      yield put({
        type: actionTypes.FILTER_INVOICE,
        payload: { status, page: 1, pageLimit: 10 },
      });
      toast.success(data.message);
    } else {
      yield put({
        type: actionTypes.DELETE_INVOICE_FAILED,
        errorMessage: "Unable to delete this invoice!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_INVOICE_FAILED,
      errorMessage: "Unable to delete this invoice!",
    });
    toast.error(error.response.data.message);
  }
}

function* sendInvoiceSaga({ sendDetails }) {
  const { obj } = sendDetails;
  const { id } = sendDetails;
  const { allDetails } = sendDetails;
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.post("/api/invoice/send-invoice", obj);
    if (data.success) {
      yield put({ type: actionTypes.SEND_INVOICE_SUCCESS, payload: data });
      if (!obj.message && !obj.subject && allDetails !== undefined) {
        toast.success("Send Reminder and Update status!");
        yield put({
          type: actionTypes.UPDATE_INVOICE,
          payload: { obj: { ...allDetails, status: "sent" }, id },
        });
      } else {
        toast.success("Send Reminder!");
      }
    } else {
      yield put({ type: actionTypes.SEND_INVOICE_FAILED, errorMessage: "Unable to send invoice!" });
      yield put({ type: actionTypes.GET_SINGLE_INVOICE, id });
      yield put({ type: actionTypes.PAYMENT_CHECK_OUT, obj: { invoice_id: id } });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.SEND_INVOICE_FAILED, errorMessage: "Unable to send invoice!" });
    toast.error(error.response.data.message);
  }
}

function* getLastInvoiceNumberSaga() {
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.get("api/invoice/find-last-invoice-number");
    if (data.success) {
      yield put({ type: actionTypes.GET_LAST_INVOICE_NUMBER_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_LAST_INVOICE_NUMBER_FAILED,
        errorMessage: "Unable to get this data!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_LAST_INVOICE_NUMBER_FAILED,
      errorMessage: "Unable to get this data!",
    });
    toast.error(error.response.data.message);
  }
}

function* paymentCheckOutSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.post("/api/payment/create-checkout-session", obj);
    if (data.success) {
      localStorage.setItem("session_id", data.data?.session_id);
      yield put({ type: actionTypes.PAYMENT_CHECK_OUT_SUCCESS, payload: { ...data, ...obj } });
    } else {
      yield put({
        type: actionTypes.PAYMENT_CHECK_OUT_FAILED,
        errorMessage: "Unable to checkout!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.PAYMENT_CHECK_OUT_FAILED, errorMessage: "Unable to checkout!" });
    toast.error(error.response.data.message);
  }
}

function* successCheckOutUpdateSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.post("api/payment/success", obj);
    if (data.success) {
      yield put({ type: actionTypes.SUCCESS_CHECK_OUT_UPDATION_SUCCESS });
    } else {
      yield put({
        type: actionTypes.SUCCESS_CHECK_OUT_UPDATION_FAILED,
        errorMessage: "Unable to update!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUCCESS_CHECK_OUT_UPDATION_FAILED,
      errorMessage: "Unable to update!",
    });
    toast.error(error.response.data.message);
  }
}

function* getOverDueAndDueWithSaga() {
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.get("/api/invoice/find-overdues");
    if (data.success) {
      yield put({ type: actionTypes.GET_OVERDUE_AND_DUEWITH_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_OVERDUE_AND_DUEWITH_FAILED,
        errorMessage: "Unable to get!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_OVERDUE_AND_DUEWITH_FAILED,
      errorMessage: "Unable to get!",
    });
    toast.error(error.response.data.message);
  }
}

function* updateInvoicePaymentSaga({ payload }) {
  const { obj } = payload;
  const { id } = payload;
  const { tabStatus } = payload;
  try {
    yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.put(`/api/invoice/${id}/record-payment`, obj);
    if (data.success) {
      yield put({ type: actionTypes.UPDATE_PAYMENT_METHOD_SUCCESS });
      if (tabStatus === null) {
        yield put({ type: actionTypes.GET_SINGLE_INVOICE, id });
      } else {
        yield put({
          type: actionTypes.FILTER_INVOICE,
          payload: { status: tabStatus, page: 1, pageLimit: 10 },
        });
      }
      toast.success(data.message);
    } else {
      yield put({
        type: actionTypes.UPDATE_PAYMENT_METHOD_FAILED,
        errorMessage: "Unable to update payment!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_PAYMENT_METHOD_FAILED,
      errorMessage: "Unable to update payment!",
    });
    toast.error(error.response.data.message);
  }
}

function* setReminderSaga({ payload }) {
  const { id } = payload;
  const { obj } = payload;
  try {
    // yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.post(`/api/invoice/schedule-reminder/${id}`, obj);
    if (data.success) {
      yield put({ type: actionTypes.SET_REMINDER_SUCCESS });
      toast.success(data.message);
    } else {
      yield put({ type: actionTypes.SET_REMINDER_FAILED, errorMessage: "Unable to set reminder" });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.SET_REMINDER_FAILED, errorMessage: "Unable to set reminder" });
    toast.error(error.response.data.message);
  }
}

function* deleteRemainderSaga({ payload }) {
  const { id } = payload;
  const { obj } = payload;
  try {
    // yield put({ type: actionTypes.SET_INVOICE_LOADING });
    const { data } = yield API.delete(`/api/invoice/schedule-reminder/${id}`, { data: obj });
    if (data.success) {
      yield put({ type: actionTypes.DELETE_REMINDER_SUCCESS });
      toast.success(data.message);
    } else {
      yield put({
        type: actionTypes.DELETE_REMINDER_FAILED,
        errorMessage: "Unable to set reminder",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.DELETE_REMINDER_FAILED, errorMessage: "Unable to set reminder" });
    toast.error(error.response.data.message);
  }
}

function* InvoiceSaga() {
  yield all([yield takeLatest(actionTypes.GET_INVOICE_LIST, getInvoiceListSaga)]);
  yield all([yield takeLatest(actionTypes.GET_SINGLE_INVOICE, getSingleInvoiceSaga)]);
  yield all([yield takeLatest(actionTypes.CREATE_INVOICE, createInvoiceSaga)]);
  yield all([yield takeLatest(actionTypes.FILTER_INVOICE, filterInvoiceSaga)]);
  yield all([yield takeLatest(actionTypes.UPDATE_INVOICE, updateInvoiceSaga)]);
  yield all([yield takeLatest(actionTypes.DELETE_INVOICE, deleteInvoiceSaga)]);
  yield all([yield takeLatest(actionTypes.SEND_INVOICE, sendInvoiceSaga)]);
  yield all([yield takeLatest(actionTypes.GET_LAST_INVOICE_NUMBER, getLastInvoiceNumberSaga)]);
  yield all([yield takeLatest(actionTypes.PAYMENT_CHECK_OUT, paymentCheckOutSaga)]);
  yield all([yield takeLatest(actionTypes.SUCCESS_CHECK_OUT_UPDATION, successCheckOutUpdateSaga)]);
  yield all([yield takeLatest(actionTypes.GET_OVERDUE_AND_DUEWITH, getOverDueAndDueWithSaga)]);
  yield all([yield takeLatest(actionTypes.UPDATE_PAYMENT_METHOD, updateInvoicePaymentSaga)]);
  yield all([yield takeLatest(actionTypes.SET_REMINDER, setReminderSaga)]);
  yield all([yield takeLatest(actionTypes.DELETE_REMINDER, deleteRemainderSaga)]);
  yield all([yield takeLatest(actionTypes.STATUS_UPDATE, updateInvoiceStatusSaga)]);
}

export default InvoiceSaga;
