/* eslint-disable */

import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import { getServiceAction } from "redux/RouteManagement/Services/actions";

function TableEdit({ id, data }) {
  const dispatch = useDispatch();
  return (
    <>
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        <Tooltip title="Edit" placement="top">
          <EditIcon
            fontSize="small"
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(getServiceAction(data))}
          />
        </Tooltip>
      </MDTypography>
    </>
  );
}

export default TableEdit;
