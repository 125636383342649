import {
  LOGIN_SUCCESS,
  SET_LOADING,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_FAILED,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
} from "./actionTypes";

const initialState = {
  isUserAuthenticated: false,
  loading: false,
  error: false,
  errorMessage: undefined,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isUserAuthenticated: true,
        error: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isUserAuthenticated: false,
        message: action.payload,
      };

    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case FORGET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case LOGOUT_USER_SUCCESS:
      return {
        // ...initialState,
        // loading: false,
        ...state,
        isUserAuthenticated: state.isUserAuthenticated ? false : "loggedOut",
      };
    case API_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default login;
