/* eslint-disable import/prefer-default-export */
import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_ROUTE_TABLE,
  UPDATE_APPOINTMENT_NOTE,
  UPDATE_YEARLY_ACTIVATION,
} from "./actionTypes";

export const getDashboardDataAction = () => ({
  type: GET_DASHBOARD_DATA,
});

export const getDashboardRouteTableAction = (payload) => ({
  type: GET_DASHBOARD_ROUTE_TABLE,
  payload,
});

export const updateAppointmentNoteAction = (obj) => ({
  type: UPDATE_APPOINTMENT_NOTE,
  obj,
});
export const updateYearlyActivationAction = (obj) => ({
  type: UPDATE_YEARLY_ACTIVATION,
  obj,
});
