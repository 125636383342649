/* eslint-disable import/prefer-default-export */
export const SET_CREW_LOADING = "SET_CREW_LOADING";

export const LIST_CREW = "LIST_CREW";
export const LIST_CREW_SUCCESS = "LIST_CREW_SUCCESS";
export const LIST_CREW_FAILED = "LIST_CREW_FAILED";

export const CREATE_CREW = "CREATE_CREW";
export const CREATE_CREW_SUCCESS = "CREATE_CREW_SUCCESS";
export const CREATE_CREW_FAILED = "CREATE_CREW_FAILED";

export const DELETE_CREW = "DELETE_CREW";
export const DELETE_CREW_SUCCESS = "DELETE_CREW_SUCCESS";
export const DELETE_CREW_FAILED = "DELETE_CREW_FAILED";

export const GET_CREW = "GET_CREW";
export const GET_CREW_SUCCESS = "GET_CREW_SUCCESS";
export const GET_CREW_FAILED = "GET_CREW_FAILED";

export const UPDATE_CREW = "UPDATE_CREW";
export const UPDATE_CREW_SUCCESS = "UPDATE_CREW_SUCCESS";
export const UPDATE_CREW_FAILED = "UPDATE_CREW_FAILED";
