import { takeEvery, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import API from "../../../services/axiosapi";

// import { GET_USER } from "../../user/actionTypes";
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
  SET_LOADING,
  LOGIN_FAILED,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from "./actionTypes";

function* loginUserSaga({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const response = yield API.post("/api/login", payload.credentials);
    if (response.data.success === true) {
      localStorage.setItem("userData", response.data.email);
      localStorage.setItem("role", response.data.role_name);
      yield put({ type: LOGIN_SUCCESS, payload: response.data });
    } else {
      yield put({ type: LOGIN_FAILED, payload: response.data.message });
      toast.error(response.data.message);
    }
  } catch (error) {
    yield put({
      type: API_ERROR,
      payload: { errorMessage: "Invalid Credentials" },
    });
    toast.error(error.response?.data?.message);
  }
}

// eslint-disable-next-line no-empty-pattern
function* logoutUserSaga({ payload: {} }) {
  try {
    // yield put({ type: SET_LOADING });
    // // yield API.get("admin/auth/logout");
    yield put({ type: LOGOUT_USER_SUCCESS });

    // history.push("/login");
  } catch (error) {
    yield put({
      type: API_ERROR,
      payload: { errorMessage: "Unable to perform operation" },
    });
  }
}

function* forgetPasswordSaga({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const response = yield API.post("api/forget-password", { email: payload.email });
    if (response.data.success === true) {
      toast.success(response.data.message);
      yield put({ type: FORGET_PASSWORD_SUCCESS, payload: response.data.message });
      setTimeout(() => {
        payload.navigate("/reset");
      }, 2000);
    } else {
      toast.error(response.data.message);
      yield put({ type: FORGET_PASSWORD_FAILED, payload: response.data.message });
    }
  } catch (error) {
    yield put({
      type: API_ERROR,
      payload: { errorMessage: "Invalid Params" },
    });
    toast.error(error.response.data.message);
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const response = yield API.post("api/reset-password", {
      email: payload.email,
      code: payload.code,
      new_password: payload.newPassword,
      confirm_password: payload.confirmPassword,
    });
    if (response.data.success === true) {
      toast.success(response.data.message);
      yield put({ type: RESET_PASSWORD_SUCCESS, payload: response.data.message });
      setTimeout(() => {
        payload.navigate("/login");
      }, 2000);
    } else {
      toast.error(response.data.message);
      yield put({ type: RESET_PASSWORD_FAILED, payload: response.data.message });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: API_ERROR,
      payload: { errorMessage: "Invalid Params" },
    });
  }
}

function* authSaga() {
  yield all([
    yield takeEvery(LOGIN_USER, loginUserSaga),
    yield takeEvery(LOGOUT_USER, logoutUserSaga),
    yield takeEvery(FORGET_PASSWORD, forgetPasswordSaga),
    yield takeEvery(RESET_PASSWORD, resetPasswordSaga),
  ]);
}

export default authSaga;
