// import { BASE_URL } from "../services/axiosapi";
/* eslint-disable prettier/prettier */
export const debounce = (func, wait) => {
  let timeout;
  // eslint-disable-next-line func-names
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const formatPhoneNumber = (number) => {
  const numberStr = number?.toString() || "";
  const formattedNumber = `${numberStr?.slice(0, 3)}-${numberStr?.slice(3, 6)}-${numberStr?.slice(
    6
  )}`;
  return formattedNumber;
};

export const stringifyErrorMessage = (error) => {
  const message = error?.response?.data?.message;
  if (message) {
    if (Array.isArray(message)) {
      return message.reduce(
        (acc, err) => (acc === "" ? err.errorMessage : `${acc}, ${err.errorMessage}`),
        ""
      );
    }
    if (typeof message === "string") {
      return message;
    }
  }
  if (error?.message) {
    if (typeof error.message === "string") {
      return error.message;
    }
  }
  return error;
};
