/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import {
  //   Autocomplete,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Tooltip } from "chart.js";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCityDataAction } from "redux/RouteManagement/City/actions";
import {
  createRouteAction,
  getSingleRouteAction,
  editRouteAction,
  getRoutesListAction,
} from "redux/RouteManagement/Route/actions";

function AddAndEditRoutes() {
  const navigete = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { cityData } = useSelector((state) => state.CityReducer);
  const { singleRouteData, routeData } = useSelector((state) => state.RouteReducer);
  const [filterCity, setFilterCity] = useState([]);
  const [state, setState] = useState("black");
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [routeName, setRouteName] = useState("");
  const [editCodes, setEditCodes] = useState([]);

  const handleChangeComplete = (color) => {
    setState(color.hex);
  };
  const handleFilter = (e) => {
    if (e.target.value !== "") {
      const { value } = e.target;
      const regex = new RegExp(`^${value}`, "i");
      const filteredCities = filterCity?.filter((city) => regex.test(city.city_name));
      setFilterCity(filteredCities);
    } else {
      setFilterCity(cityData?.data);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      dispatch(
        editRouteAction({
          obj: {
            route_title: routeName,
            color: state,
            city_codes: selectedCodes,
          },
          Id: id,
        })
      );
      navigete("/route-management", { state: { tab: "routes" } });
    } else if (selectedCodes.length > 0) {
      dispatch(
        createRouteAction({
          route_title: routeName,
          color: state,
          city_codes: selectedCodes,
        })
      );
      navigete("/route-management", { state: { tab: "routes" } });
    }
  };
  const handleChecked = (value) => {
    if (selectedCodes.includes(value)) {
      const index = selectedCodes.indexOf(value);
      const newArray = [...selectedCodes];
      newArray.splice(index, 1);
      setSelectedCodes(newArray);
    } else {
      setSelectedCodes([...selectedCodes, value]);
    }
  };
  useEffect(() => {
    if (!cityData.data) {
      dispatch(getCityDataAction());
    }
    setFilterCity(cityData?.data);
  }, [cityData]);
  useEffect(() => {
    if (!routeData.length) {
      dispatch(getRoutesListAction());
    }
  }, [routeData]);
  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleRouteAction(id));
    }
  }, []);
  useEffect(() => {
    if (id) {
      setState(singleRouteData.color);
      setRouteName(singleRouteData.route_title);
      setSelectedCodes(singleRouteData.city_codes);
      setEditCodes(singleRouteData.city_codes);
    }
  }, [singleRouteData]);
  return (
    <DashboardLayout>
      <MDBox mb={3}>
        <Card>
          <Grid component="form" role="form" onSubmit={handleSubmit} md={12} padding={6}>
            {id ? (
              <Typography variant="h4" component="h2">
                Edit Route
              </Typography>
            ) : (
              <Typography variant="h4" component="h2">
                Create New Route
              </Typography>
            )}
            <br />
            <MDBox>
              <Typography fontSize="0.875rem" component="p">
                Route Name:
              </Typography>
              <TextField
                style={{ width: "30%" }}
                id="outlined-basic"
                variant="outlined"
                value={routeName}
                required
                onChange={(e) => {
                  setRouteName(e.target.value);
                }}
              />
            </MDBox>
            <br />
            <Typography fontSize="0.875rem" component="p">
              Select Route Color:
            </Typography>
            <MDBox>
              <TextField
                style={{ width: "30%", marginBottom: "30px" }}
                id="outlined-basic"
                variant="outlined"
                value={state}
                required
              />
              <SketchPicker color={state} onChange={handleChangeComplete} />
            </MDBox>
            <br />
            <MDBox>
              <TextField
                style={{ width: "30%" }}
                id="outlined-basic"
                label="Enter city name to filter"
                onChange={handleFilter}
                variant="outlined"
              />
            </MDBox>
            <MDBox>
              {/* <TableContainer> */}
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableBody>
                  {filterCity?.map((row, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: "0.875rem", fontWeight: 700 }}>
                        {row.city_name}
                      </TableCell>
                      <TableCell style={{ display: "flex" }}>
                        {row?.city_codes?.map((value, key) => {
                          const isSelected = selectedCodes?.includes(value);
                          const isEditCodes = editCodes?.includes(value);
                          const selectedColor = isSelected ? state : "black";
                          const routeFound = routeData.some((route) =>
                            route.city_codes.includes(value)
                          );
                          const editRouteFound = routeData?.some(
                            (route) =>
                              route.city_codes?.includes(value) &&
                              !editCodes?.some((code) => route.city_codes?.includes(code))
                          );
                          // const newRouteFound = isEditCodes ? false : routeFound;
                          if (id) {
                            return (
                              <FormGroup key={key.id} style={{ minWidth: "100px" }}>
                                {editRouteFound ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isSelected || editRouteFound}
                                        onChange={() => handleChecked(value)}
                                        style={{ color: "#4a4a4a" }}
                                        disabled={editRouteFound}
                                      />
                                    }
                                    label={<span style={{ color: "#4a4a4a" }}>{value}</span>}
                                    title="Already selected this city code!"
                                  />
                                ) : (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isSelected || editRouteFound}
                                        onChange={() => handleChecked(value)}
                                        style={{ color: selectedColor }}
                                        disabled={editRouteFound}
                                      />
                                    }
                                    label={<span style={{ color: selectedColor }}>{value}</span>}
                                  />
                                )}
                              </FormGroup>
                            );
                          }

                          return (
                            <FormGroup key={key.id} style={{ minWidth: "100px" }}>
                              {routeFound ? (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isSelected || routeFound}
                                      onChange={() => handleChecked(value)}
                                      style={{ color: "#4a4a4a" }}
                                      disabled={routeFound}
                                    />
                                  }
                                  label={<span style={{ color: "#4a4a4a" }}>{value}</span>}
                                  title="Already selected this city code!"
                                />
                              ) : (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isSelected || routeFound}
                                      onChange={() => handleChecked(value)}
                                      style={{ color: selectedColor }}
                                      disabled={routeFound}
                                    />
                                  }
                                  label={<span style={{ color: selectedColor }}>{value}</span>}
                                />
                              )}
                            </FormGroup>
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* </TableContainer> */}
            </MDBox>
            <br />
            <MDBox style={{ display: "flex", justifyContent: "center" }}>
              <MDButton style={{ width: "30%" }} type="submit" variant="gradient" color="info">
                {id ? "Update" : "Save"}
              </MDButton>
            </MDBox>
          </Grid>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddAndEditRoutes;
