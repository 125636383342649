/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CustomSpinner from "examples/CustomLoader";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchServices,
  addService,
  clearServiceData,
  editServiceAction,
} from "redux/RouteManagement/Services/actions";

import authorsTableData from "./data/authorsTableData";

function Services({ tab }) {
  const dispatch = useDispatch();
  const { loading, servicesData, service } = useSelector((state) => state.RouteManagement);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [addServiceData, setAddServiceData] = useState({});

  const handleInputChange = (e) => {
    setAddServiceData({ service_title: e.target.value });
  };
  const handleSubmit = () => {
    if (addServiceData === "") {
      toast.error("Service Shouldn't be empty");
    } else if (service?.service_title) {
      dispatch(editServiceAction({ ...service, service_title: addServiceData.service_title }));
    } else {
      dispatch(addService(addServiceData));
    }
  };

  useEffect(() => {
    if (Array.isArray(servicesData?.data)) {
      const { columnData, rowData } = authorsTableData(servicesData);
      setColumns(columnData);
      setRows(rowData);
    }
  }, [servicesData]);
  useEffect(() => {
    dispatch(fetchServices(tab));
  }, [tab]);

  useEffect(() => {
    if (service?.service_title) {
      setAddServiceData({ service_title: service?.service_title });
    } else {
      setAddServiceData({});
    }
  }, [service]);
  return (
    <MDBox sx={{ minWidth: "70%", minHeight: "70%" }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomSpinner />
        </div>
      ) : (
        <>
          <Card>
            <p style={{ padding: "20px 20px 0 20px" }}>
              <b>{service?.service_title ? "Edit" : "Add"} Service</b>
            </p>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <Grid
                container
                component="form"
                role="form"
                onSubmit={handleSubmit}
                rowSpacing={1}
                columnSpacing={{ xs: 0, sm: 2, md: 3 }}
                sx={{ width: "100%", display: "flex", justifyContent: "center" }}
              >
                <Grid xs={12} sm={9} md={9} sx={{ display: "flex", justifyContent: "center" }}>
                  <TextField
                    sx={{ width: "100%" }}
                    value={addServiceData?.service_title || ""}
                    id="outlined-basic"
                    label="Service Title"
                    variant="outlined"
                    onChange={handleInputChange}
                    required
                    autoFocus={!addServiceData?.service_title}
                  />
                </Grid>
                <Grid
                  display="flex"
                  gap="3px"
                  xs={12}
                  sm={2}
                  md={2}
                  sx={{
                    marginLeft: { xs: "0rem", sm: "1rem", md: "3rem" },
                    marginTop: { xs: "0.5rem", sm: "0rem" },
                  }}
                >
                  {service?.service_title && (
                    <MDButton
                      onClick={() => {
                        dispatch(clearServiceData());
                      }}
                      variant="gradient"
                      style={{
                        background: "rgb(237 77 73)",
                        color: "#fff",
                        width: "100%",
                      }}
                    >
                      Cancel
                    </MDButton>
                  )}
                  <MDButton type="submit" sx={{ width: "100%" }} variant="gradient" color="info">
                    {service?.service_title ? "Update" : "Add"}
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
          <Card>
            <p style={{ padding: "20px 20px 0 20px" }}>
              <b>Services</b>
            </p>
            <DataTable
              table={{ columns: columns || [], rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
              rowData={servicesData}
              onRowClick={(id) => {}}
            />
          </Card>
        </>
      )}
    </MDBox>
  );
}

export default Services;
