import {
  CREATE_CUSTOMERS,
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
  GET_CUSTOMER,
  LIST_CUSTOMERS,
  LIST_CUSTOMERS_MODULE,
  SEARCH_CUSTOMER,
} from "./actionTypes";

// eslint-disable-next-line import/prefer-default-export
export const listCustomers = (payload) => ({
  type: LIST_CUSTOMERS,
  payload,
});

export const listCustomersModule = (payload) => ({
  type: LIST_CUSTOMERS_MODULE,
  payload,
});

export const createCustomer = (data) => ({
  type: CREATE_CUSTOMERS,
  data,
});

export const getCustomer = (id) => ({
  type: GET_CUSTOMER,
  id,
});

export const editCustomer = (id, data) => ({
  type: EDIT_CUSTOMER,
  data,
  id,
});

export const searchCustomer = (payload) => ({
  type: SEARCH_CUSTOMER,
  payload,
});

export const deleteCustomerAction = (payload) => ({
  type: DELETE_CUSTOMER,
  payload,
});
