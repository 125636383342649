/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import CancelIcon from "@mui/icons-material/Cancel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteCustomerAction } from "redux/actions";

function Header({
  customerId,
  firstName,
  lastName,
  children,
  location,
  isEditableModeOn,
  onClickEdit,
  tab,
  unpaidAmount,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserRole = localStorage.getItem("role");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const headerWrapper = { display: "flex", justifyContent: "space-between" };
  const totalUnpaidStyle = { width: "100%", display: "flex", justifyContent: "end" };
  if (windowWidth <= 768) {
    headerWrapper.flexDirection = "column";
    totalUnpaidStyle.justifyContent = "start";
  }

  const handleDelete = () => {
    Swal.fire({
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#a5bd4b",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCustomerAction({ customerId, navigate }));
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <MDBox position="relative">
      {windowWidth >= 768 ? (
        <Card
          sx={{
            position: "relative",
            display: "flex",
            p: "1rem",
          }}
        >
          <div style={headerWrapper}>
            <MDBox width="100%" container spacing={3}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {firstName} {lastName}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {location}
                </MDTypography>
              </MDBox>
            </MDBox>

            {tab === 1 && (
              <MDBox style={totalUnpaidStyle} mt={0.5} lineHeight={1}>
                <div>
                  <MDTypography variant="h6" fontWeight="medium">
                    Total Unpaid Amount
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {unpaidAmount?.toLocaleString(undefined, {
                      style: "currency",
                      currency: "USD",
                    }) || 0}
                  </MDTypography>
                </div>
              </MDBox>
            )}

            {tab === 0 && (
              <>
                {UserRole === "owner" && (
                  <>
                    {isEditableModeOn ? (
                      <MDButton
                        variant={"gradient"}
                        onClick={onClickEdit}
                        style={{
                          background: !isEditableModeOn ? "#333745" : "rgb(237 77 73)",
                          color: "#fff",
                          height: "1px",
                        }}
                      >
                        Cancel
                      </MDButton>
                    ) : (
                      <EditIcon
                        style={{ cursor: "pointer", marginInline: "0.2rem" }}
                        onClick={onClickEdit}
                      />
                    )}
                  </>
                )}
                {UserRole === "owner" && (
                  <>
                    {!isEditableModeOn && (
                      <Tooltip title="Delete" placement="top">
                        <DeleteIcon
                          style={{ cursor: "pointer" }}
                          color="error"
                          onClick={() => handleDelete()}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {children}
        </Card>
      ) : (
        <>
          <div style={headerWrapper}>
            <MDBox width="100%" container spacing={3}>
              <MDBox
                height="100%"
                mt={0.5}
                lineHeight={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDBox width={"50%"}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {firstName} {lastName}
                  </MDTypography>
                </MDBox>
                <MDBox width={"50%"} display="flex" justifyContent="end">
                  {tab === 0 && (
                    <>
                      {UserRole === "owner" && (
                        <button
                          onClick={onClickEdit}
                          style={{
                            background: !isEditableModeOn
                              ? "rgba(169, 187, 90, 1)"
                              : "rgb(237 77 73)",
                            color: "#fff",
                            border: "none",
                            padding: "5px",
                            borderRadius: "6px",
                          }}
                        >
                          {isEditableModeOn ? (
                            <CancelIcon style={{ cursor: "pointer" }} fontSize="small" />
                          ) : (
                            <EditIcon style={{ cursor: "pointer" }} fontSize="small" />
                          )}
                        </button>
                      )}
                    </>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>

            {tab === 1 && (
              <MDBox style={totalUnpaidStyle} mt={0.5} lineHeight={1}>
                <div>
                  <MDTypography variant="h6" fontWeight="medium">
                    Total Unpaid Amount
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {unpaidAmount?.toLocaleString(undefined, {
                      style: "currency",
                      currency: "USD",
                    }) || 0}
                  </MDTypography>
                </div>
              </MDBox>
            )}
          </div>
          {children}
        </>
      )}
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
