import {
  LIST_SERVICES,
  ADD_SERVICES,
  DELETE_SERVICES,
  EDIT_SERVICES,
  GET_SERVICE,
  CLEAR_SERVICE,
} from "./actionTypes";

// eslint-disable-next-line import/prefer-default-export
export const fetchServices = () => ({
  type: LIST_SERVICES,
});

export const addService = (obj) => ({
  type: ADD_SERVICES,
  obj,
});

export const deleteService = (Id) => ({
  type: DELETE_SERVICES,
  Id,
});

export const editServiceAction = (obj) => ({
  type: EDIT_SERVICES,
  obj,
});

export const getServiceAction = (obj) => ({
  type: GET_SERVICE,
  obj,
});

export const clearServiceData = () => ({
  type: CLEAR_SERVICE,
});
