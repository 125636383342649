/* eslint-disable prefer-const */
/* eslint-disable prefer-template */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-constant-condition */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Autocomplete,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { useEffect, useState } from "react";
import NotificationItem from "examples/Items/NotificationItem";
import { useDispatch, useSelector } from "react-redux";
import { listCustomers } from "redux/actions";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getSingleInvoiceAction,
  createInoviceAction,
  updateInvoiceAction,
  getLastInvoiceNumberAction,
  updateInvoicePaymentAction,
  sendInvoicAction,
} from "redux/invoices/actions";
import dayjs from "dayjs";
import { fetchServices } from "redux/RouteManagement/Services/actions";
import DeleteIcon from "@mui/icons-material/Delete";
import { BsCashCoin, BsCashStack } from "react-icons/bs";
import { FaMoneyCheckAlt } from "react-icons/fa";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import { formatPhoneNumber } from "utils";

function addNewLabel(array, keyName1, keyName2) {
  return array?.map((obj) => {
    const newObj = { ...obj };
    newObj.label = `${newObj[keyName1]} ${newObj[keyName2]}`;
    return newObj;
  });
}

// import { useDispatch } from "react-redux";
const paymentOptions = [
  { label: "Cash", value: "cash", icon: <BsCashStack /> },
  { label: "Cheque", value: "cheque", icon: <FaMoneyCheckAlt /> },
  { label: "Credit Card", value: "credit_card", icon: <BsCashCoin /> },
];

const showToast = (message) => {
  toast.error(message);
};

const validateData = (data, validationErrors) => {
  const requiredFields = [
    "customer_id",
    "invoice_date",
    "invoice_number",
    "items",
    "payment_due_date",
    "sub_total_price",
    "total_price",
  ];
  const missingField = requiredFields.find((field) => !data[field]);
  if (missingField) {
    if (missingField === "sub_total_price") {
      const updatedValidationErrors = {
        ...Object.fromEntries(Object.keys(validationErrors).map((field) => [field, false])),
        items: true,
      };
      return {
        validationErrors: updatedValidationErrors,
        errorMessage: "All fields are required. Add at least one service!",
      };
    }
    const updatedValidationErrors = {
      ...Object.fromEntries(Object.keys(validationErrors).map((field) => [field, false])),
      [missingField]: true,
    };
    return {
      validationErrors: updatedValidationErrors,
      errorMessage: `${missingField.replace("_", " ")} is required!`,
    };
  }

  return null; // No validation errors
};
const spanStyle = { color: "red", fontSize: "16px" };

function AddAndEditInvoice() {
  const paramsId = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { singleInvoice, lastInvoiceNumber, newInvoiceId } = useSelector(
    (state) => state.InvoiceReducer
  );
  const { originalCustomerList } = useSelector((state) => state.Customers);
  const { servicesData } = useSelector((state) => state.RouteManagement);
  const [openCustomerMenu, setOpenCustomerMenu] = useState(false);
  const [openSaveMenu, setOpenSaveMenu] = useState(false);
  const [addedItems, setAddedItems] = useState([]);
  const [customer, setCustomer] = useState({});
  const [subTotalValue, setSubTotalValue] = useState(0);
  const [selectedTax, setSelectedTax] = useState([]);
  const [showDisText, setShowDisText] = useState(false);
  const [discountText, setDiscountText] = useState({});
  const [discount, setDiscount] = useState([]);
  const [allTotal, setAllTotal] = useState(0);
  const [data, setData] = useState({});
  const [itemInputOpen, setItemInputOpen] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [hourOrQty, setHourOrQty] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [serviceDetails, setServicesDetails] = useState([]);
  const [dueGap, setDueGap] = useState(15);
  const [open, setOpen] = React.useState(false);
  const [saveAndSend, setSaveAndSend] = useState(false);
  const [navToView, setNavToView] = useState(false);
  const [toNumber, setToNumber] = useState("");
  const [paymentSate, setPaymentSate] = useState();
  const [recordPayment, setRecordPayment] = useState({ payment_method: "manually" });
  const [validationErrors, setValidationErrors] = useState({
    customer_id: false,
    invoice_date: false,
    invoice_number: false,
    items: false,
    sub_total_price: false,
    total_price: false,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const createInvoiceHeadWrapper = {
    display: "flex",
    justifyContent: "space-between",
    padding: "3%",
  };
  const selectCustBox = { width: "30%" };
  const noteAndTotalWrapper = {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "1rem",
    width: "100%",
    padding: "3%",
  };
  if (windowWidth <= 768) {
    createInvoiceHeadWrapper.flexDirection = "column";
    selectCustBox.width = "100%";
    noteAndTotalWrapper.flexDirection = "column";
  }
  const isEndDateDisabled = (date) => {
    const paymentDue = new Date(data.payment_due_date);
    return date > paymentDue;
  };
  const isStartDateDisabled = (date) => {
    const invoiceDate = new Date(data.invoice_date);
    return date < invoiceDate;
  };
  const handleCloseSaveMenu = () => setOpenSaveMenu(false);
  const handleOpenCustomerMenu = () => setOpenCustomerMenu(true);
  const handleOpenSaveMenu = (event) => setOpenSaveMenu(event.currentTarget);
  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue <= data?.total_price - `${data?.paid_amount || 0}`) {
      setPaymentSate(inputValue);
      const floatRegExp = /^-?\d*(\.\d*)?$/;
      if (floatRegExp.test(inputValue) || inputValue === "") {
        setRecordPayment({ ...recordPayment, paid_amount: inputValue });
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (event, value) => {
    if (value !== null) {
      setValidationErrors({ ...validationErrors, customer_id: false });
      setData({ ...data, customer_id: value._id });
      setCustomer(value);
    }
  };

  const saveMenu = () => (
    <Menu
      anchorEl={openSaveMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openSaveMenu)}
      onClose={handleCloseSaveMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem title="Save and Send" onClick={handleSaveAndSend} />
      <NotificationItem title="Save and record a payment" onClick={handleSaveAndRecord} />
    </Menu>
  );

  const handleSaveAndRecord = (e) => {
    e.preventDefault();
    const validation = validateData(data, validationErrors);
    if (!validation) {
      if (paramsId.id && !state?.Duplicate) {
        if (data?.items.length > 0) {
          dispatch(updateInvoiceAction({ id: paramsId.id, obj: data }));
          setOpen(true);
        } else {
          toast.error("Add atleast one service!");
        }
      } else if (data?.items.length > 0) {
        dispatch(createInoviceAction(data));
        setOpen(true);
      } else {
        toast.error("Add atleast one service!");
      }
    } else {
      setValidationErrors(validation.validationErrors);
      showToast(validation.errorMessage);
    }
  };

  const generateTransactionId = () => {
    const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    const randomNumber = Math.floor(Math.random() * 100000);
    const transactionId = `${currentDate}${randomNumber.toString().padStart(5, "0")}`;
    return transactionId;
  };
  const randomNumber = generateTransactionId();
  const paymentSubmit = (e) => {
    e.preventDefault();
    if (recordPayment.payment_type && recordPayment.paid_amount) {
      if (newInvoiceId._id) {
        dispatch(
          updateInvoicePaymentAction({
            tabStatus: "unpaid",
            id: newInvoiceId._id,
            obj: {
              payment: [
                {
                  ...recordPayment,
                  payment_date: new Date().toISOString().substring(0, 10),
                  transaction_id: randomNumber,
                },
              ],
            },
          })
        );
        navigate(`/view_invoice/${newInvoiceId._id}`, { state: true });
      } else {
        dispatch(
          updateInvoicePaymentAction({
            tabStatus: "unpaid",
            id: singleInvoice._id,
            obj: {
              payment: [
                {
                  ...recordPayment,
                  payment_date: new Date().toISOString().substring(0, 10),
                  transaction_id: randomNumber,
                },
              ],
            },
          })
        );
        navigate(`/view_invoice/${singleInvoice._id}`, { state: true });
      }
      setOpen(false);
    } else {
      toast.error("Complete the form!");
    }
  };

  const handleSelectItem = (event) => {
    if (addedItems.length === 0) {
      setAddedItems([
        ...addedItems,
        {
          item_name: "",
          hours_or_qty: null,
          price: null,
          amount: Number(itemPrice) * Number(hourOrQty),
        },
      ]);
    } else if (addedItems[addedItems.length - 1]?.item_name !== "") {
      setAddedItems([
        ...addedItems,
        {
          item_name: "",
          hours_or_qty: null,
          price: null,
          amount: Number(itemPrice) * Number(hourOrQty),
        },
      ]);
    }
    event.preventDefault();
    if (serviceName && hourOrQty && itemPrice) {
      const newRow = {
        item_name: serviceName,
        hours_or_qty: Number(hourOrQty),
        price: Number(itemPrice),
        amount: Number(itemPrice) * Number(hourOrQty),
      };
      setAddedItems([...addedItems, newRow]);
      setServiceName("");
      setHourOrQty("");
      setItemPrice("");
    }
  };
  const MAX_INPUT_LENGTH = 10;
  const handleInputChange = (index, field, value) => {
    if (value === null) {
      return;
    }
    // Input limit and validation for "hours_or_qty" field
    if (field !== "item_name") {
      // Limit the input length
      if (value.toString().length > MAX_INPUT_LENGTH) {
        value = Number(value.toString().slice(0, MAX_INPUT_LENGTH));
      }
    }
    // eslint-disable-next-line valid-typeof, no-undef
    if (typeof value === "object") {
      const updatedTableData = [...addedItems];
      updatedTableData[index][field] = value.label;
      updatedTableData[index].amount =
        updatedTableData[index].hours_or_qty * updatedTableData[index].price;
      setAddedItems(updatedTableData);
    } else {
      const updatedTableData = [...addedItems];
      updatedTableData[index][field] = value;
      updatedTableData[index].amount =
        updatedTableData[index].hours_or_qty * updatedTableData[index].price;
      setAddedItems(updatedTableData);
    }
  };
  const handleTax = (e, v) => {
    if (v == null) {
      setSelectedTax([]);
      setData({
        ...data,
        tax: [],
      });
    } else {
      const existingIndex = selectedTax.findIndex((obj) => {
        const key = Object.keys(obj)[0];
        return key === v.label;
      });

      if (existingIndex !== -1) {
        const updatedTax = [...selectedTax];
        updatedTax.splice(existingIndex, 1);
        setSelectedTax(updatedTax);
        setData({ ...data, tax: updatedTax });
      } else {
        setSelectedTax([...selectedTax, { [v.label]: (subTotalValue * v.value) / 100 }]);
        setData({ ...data, tax: [...selectedTax, { [v.label]: (subTotalValue * v.value) / 100 }] });
      }
    }
  };
  const handleDiscount = () => {
    if (showDisText === false) {
      setShowDisText(true);
    } else {
      setShowDisText(false);
    }
  };
  const handleSubmitDis = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-prototype-builtins
    const isObjectExists = discount.some((obj) => obj.hasOwnProperty(discountText.discount));
    if (isObjectExists === false) {
      setDiscount([...discount, { [discountText.discount]: Number(discountText.amount) }]);
      setData({
        ...data,
        discount: [...discount, { [discountText.discount]: Number(discountText.amount) }],
      });
      setDiscountText({ discount: "", amount: "" });
      setShowDisText(false);
    } else {
      toast.error("already added");
    }
  };
  const handleRemoDisc = (item) => {
    const result = discount.filter((obj) => obj !== item);
    setDiscount(result);
  };
  const handleRemoveItem = (ItemName) => {
    const result = addedItems.filter((item) => item.item_name !== ItemName);
    setAddedItems(result);
  };
  const updatOrSubmit = async (e) => {
    e.preventDefault();
    if (paramsId.id && !state?.Duplicate) {
      if (
        data.customer_id &&
        data.invoice_date &&
        data.invoice_number &&
        data.items.length > 0 &&
        data.payment_due_date &&
        data.sub_total_price &&
        data.total_price
      ) {
        await dispatch(updateInvoiceAction({ id: paramsId.id, obj: data, invoice: true }));
        navigate(`/view_invoice/${paramsId.id}`, { state: true });
      } else if (data?.items?.length <= 0) {
        toast.error("Add atleast one service!");
      } else {
        const requiredFields = [
          "customer_id",
          "invoice_date",
          "invoice_number",
          "items",
          "payment_due_date",
          "sub_total_price",
          "total_price",
        ];
        const missingField = requiredFields.find((field) => !data[field]);
        if (missingField) {
          if (missingField === "sub_total_price") {
            const updatedValidationErrors = {
              ...Object.fromEntries(Object.keys(validationErrors).map((field) => [field, false])),
              items: true,
            };
            setValidationErrors(updatedValidationErrors);
            toast.error("All fields are required Add at least one service!!");
          } else {
            const updatedValidationErrors = {
              ...Object.fromEntries(Object.keys(validationErrors).map((field) => [field, false])),
              [missingField]: true,
            };
            setValidationErrors(updatedValidationErrors);
            toast.error(`${missingField.replace("_", " ")} is required!`);
          }
        } else {
          toast.error("something went wrong!");
        }
      }
    } else if (
      data.customer_id &&
      data.invoice_date &&
      data.invoice_number &&
      data.items.length > 0 &&
      data.payment_due_date &&
      data.sub_total_price &&
      data.total_price
    ) {
      await dispatch(createInoviceAction(data));
      setNavToView(true);
    } else {
      const requiredFields = [
        "customer_id",
        "invoice_date",
        "invoice_number",
        "items",
        "payment_due_date",
        "sub_total_price",
        "total_price",
      ];
      const missingField = requiredFields.find((field) => !data[field]);
      if (missingField) {
        if (missingField === "sub_total_price") {
          const updatedValidationErrors = {
            ...Object.fromEntries(Object.keys(validationErrors).map((field) => [field, false])),
            items: true,
          };
          setValidationErrors(updatedValidationErrors);
          toast.error("All fields are required Add at least one service!!");
        } else {
          const updatedValidationErrors = {
            ...Object.fromEntries(Object.keys(validationErrors).map((field) => [field, false])),
            [missingField]: true,
          };
          setValidationErrors(updatedValidationErrors);
          toast.error(`${missingField.replace("_", " ")} is required!`);
        }
      } else {
        toast.error("something went wrong!");
      }
    }
  };
  const handleSaveAndSend = async () => {
    if (
      data.customer_id &&
      data.invoice_date &&
      data.invoice_number &&
      data.items.length > 0 &&
      data.payment_due_date &&
      data.sub_total_price &&
      data.total_price
    ) {
      if (paramsId.id && !state?.Duplicate) {
        try {
          await dispatch(updateInvoiceAction({ id: paramsId.id, obj: data }));
          dispatch(
            sendInvoicAction({
              obj: {
                invoice_id: paramsId.id,
                number: [toNumber],
                email: singleInvoice?.customer_id?.email,
              },
              id: singleInvoice?._id,
              allDetails: singleInvoice,
            })
          );
          navigate(`/view_invoice/${paramsId.id}`, { state: true });
        } catch (error) {
          toast.error(error);
        }
      } else {
        await dispatch(createInoviceAction(data));
        setSaveAndSend(true);
      }
    } else {
      const requiredFields = [
        "customer_id",
        "invoice_date",
        "invoice_number",
        "items",
        "payment_due_date",
        "sub_total_price",
        "total_price",
      ];
      const missingField = requiredFields.find((field) => !data[field]);
      if (missingField) {
        if (missingField === "sub_total_price") {
          const updatedValidationErrors = {
            ...Object.fromEntries(Object.keys(validationErrors).map((field) => [field, false])),
            items: true,
          };
          setValidationErrors(updatedValidationErrors);
          toast.error("All fields are required Add at least one service!!");
        } else {
          const updatedValidationErrors = {
            ...Object.fromEntries(Object.keys(validationErrors).map((field) => [field, false])),
            [missingField]: true,
          };
          setValidationErrors(updatedValidationErrors);
          toast.error(`${missingField.replace("_", " ")} is required!`);
        }
      } else {
        toast.error("something went wrong!");
      }
    }
  };
  useEffect(() => {
    if (state?.item) {
      setCustomer({
        _id: state?.item?.customer_id?._id,
        first_name: state?.item?.customer_id?.first_name,
        last_name: state?.item?.customer_id?.last_name,
        property: state?.item?.customer_id?.property,
        phone: state?.item?.customer_id?.phone,
        email: state?.item?.customer_id?.email,
      });
      setData({ ...data, customer_id: state?.item?.customer_id._id });
    }
  }, [state]);
  useEffect(() => {
    if (newInvoiceId?._id) {
      if (!singleInvoice._id) {
        dispatch(getSingleInvoiceAction(newInvoiceId?._id));
      }
    }
  }, [newInvoiceId]);
  useEffect(() => {
    if (saveAndSend && newInvoiceId._id) {
      dispatch(
        sendInvoicAction({
          obj: {
            invoice_id: newInvoiceId?._id,
            number: [customer?.phone],
            email: customer?.email,
          },
          id: newInvoiceId?._id,
          allDetails: newInvoiceId,
        })
      );
      navigate(`/view_invoice/${newInvoiceId?._id}`, { state: true });
    } else if (navToView && newInvoiceId._id) {
      navigate(`/view_invoice/${newInvoiceId?._id}`, { state: true });
    }
  }, [singleInvoice, newInvoiceId, saveAndSend]);
  useEffect(() => {
    dispatch(listCustomers({ page: 1, pageSize: 10000 }));
    dispatch(fetchServices());
  }, []);
  useEffect(() => {
    if (addedItems.length > 0) {
      // eslint-disable-next-line prettier/prettier
      const totalCost = addedItems.reduce((sum, item) => sum + item.hours_or_qty * item.price, 0);
      setSubTotalValue(totalCost);
    } else {
      setSubTotalValue(0);
      setDiscount([]);
    }
  }, [addedItems]);
  useEffect(() => {
    // eslint-disable-next-line prettier/prettier
    const totalAmount = discount.reduce((acc, item) => {
      const value = Object.values(item)[0];
      return acc + value;
    }, 0);
    const totalTex = selectedTax?.reduce(
      (accumulator, taxData) => {
        const value = Object.values(taxData)[0];
        return accumulator + value;
      },
      0
      // Number(accumulator) + (subTotalValue * taxData.value) / 100,
    );
    setAllTotal(subTotalValue - totalAmount + totalTex || 0);
    setData({
      ...data,
      sub_total_price: subTotalValue,
      total_price: subTotalValue - totalAmount + totalTex,
      items: addedItems,
    });
  }, [discount, addedItems, subTotalValue, selectedTax]);
  useEffect(() => {
    if (paramsId.id) {
      if (!singleInvoice._id) {
        dispatch(getSingleInvoiceAction(paramsId.id));
      }
    }
  }, [paramsId.id]);
  useEffect(() => {
    if (paramsId.id) {
      setCustomer({
        _id: singleInvoice?.customer_id?._id,
        first_name: singleInvoice?.customer_id?.first_name,
        last_name: singleInvoice?.customer_id?.last_name,
        property: singleInvoice?.customer_id?.property,
      });
      setData({
        _id: singleInvoice._id,
        customer_id: singleInvoice?.customer_id?._id,
        discount: singleInvoice?.discount,
        invoice_date: singleInvoice?.invoice_date,
        invoice_number: singleInvoice?.invoice_number,
        items: singleInvoice?.items,
        note: singleInvoice?.note,
        payment_due_date: singleInvoice?.payment_due_date,
        po_so: singleInvoice?.po_so,
        status: singleInvoice?.status,
        sub_total_price: singleInvoice?.sub_total_price,
        tax: singleInvoice?.tax,
        total_price: singleInvoice?.total_price,
      });
      if (singleInvoice?.items?.length > 0) {
        setAddedItems(singleInvoice.items);
      }
      if (singleInvoice.discount?.length > 0) {
        setDiscount(singleInvoice?.discount);
      }
      if (singleInvoice?.tax?.length > 0) {
        setSelectedTax([...singleInvoice?.tax]);
      }
    }
  }, [singleInvoice, paramsId]);
  useEffect(() => {
    if (servicesData.data) {
      const transformedArray = servicesData?.data?.map((obj) => ({
        label: obj.service_title,
        // Keep other fields as-is
        _id: obj._id,
      }));
      setServicesDetails(transformedArray);
    }
  }, [servicesData]);
  useEffect(() => {
    if (data?.invoice_date && data?.payment_due_date) {
      const daysBetween = dayjs(data?.payment_due_date).diff(dayjs(data?.invoice_date), "day");
      setDueGap(daysBetween);
    }
    setOpenCustomerMenu(false);
  }, [data]);
  useEffect(() => {
    if (!paramsId.id) {
      dispatch(getLastInvoiceNumberAction());
    } else if (state?.Duplicate) {
      dispatch(getLastInvoiceNumberAction());
    }
  }, [state, singleInvoice]);
  // here genaroting invoice number
  useEffect(() => {
    if (lastInvoiceNumber.data) {
      const value = Number(lastInvoiceNumber.data);
      const newValue = value + 1;
      const newInvoiceNumber = newValue
        .toString()
        .padStart(Math.max(6, newValue.toString().length), "0");
      setData({ ...data, invoice_number: newInvoiceNumber });
    } else {
      setData({ ...data, invoice_number: "000001" });
    }
  }, [lastInvoiceNumber]);

  useEffect(() => {
    if (validationErrors.sub_total_price || validationErrors.items) {
      if (data.sub_total_price > 0) {
        setValidationErrors({ ...validationErrors, items: false });
      }
    }
  }, [data]);
  useEffect(() => {
    if (singleInvoice?.customer_id?.phone.length > 0) {
      const number = singleInvoice?.customer_id?.phone;
      const formatedNumber = number.replace(/[^\d+]/g, "");
      if (formatedNumber.startsWith("+91")) {
        setToNumber(formatedNumber);
      } else {
        setToNumber(`+91${formatedNumber}`);
      }
    }
  }, [singleInvoice?.customer_id]);
  useEffect(() => {
    if (data?.total_price) {
      setPaymentSate(data?.total_price - `${data.paid_amount || 0}`);
      setRecordPayment({
        ...recordPayment,
        paid_amount: data?.total_price - `${data.paid_amount || 0}`,
      });
    }
  }, [data]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <DashboardLayout>
      <MDBox display="flex" justifyContent="space-between" mb={3}>
        <MDTypography variant="h2">{paramsId.id ? "Edit Invoice" : "New Invoice"}</MDTypography>
        <SideNavActionBtn />
      </MDBox>
      <MDBox component="form" role="form" onSubmit={updatOrSubmit}>
        <Card style={{ marginTop: windowWidth <= 768 && "1rem" }}>
          {windowWidth >= 768 && (
            <MDBox
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <MDBox />
              <MDBox style={{ display: "flex", justifyContent: "flex-end", gap: "0.5em" }}>
                <MDButton type="submit" color="info">
                  {paramsId.id && state?.Duplicate !== true
                    ? "Update invoice"
                    : "Save and Continue"}
                </MDButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenSaveMenu}
                  style={{ padding: "1px" }}
                >
                  <MDButton color="info">
                    <ArrowDropDownIcon />
                  </MDButton>
                </IconButton>
                {saveMenu()}
              </MDBox>
            </MDBox>
          )}
          <MDBox style={createInvoiceHeadWrapper}>
            {!customer.first_name ? (
              <MDBox style={selectCustBox}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenCustomerMenu}
                  style={{
                    border: validationErrors.customer_id ? "solid 1px red" : "solid 1px #d5d5d5",
                    minHeight: "100%",
                    minWidth: "100%",
                    borderRadius: "5px",
                    flexDirection: "column",
                  }}
                >
                  {openCustomerMenu && (
                    <Autocomplete
                      sx={{ minWidth: "60%" }}
                      disablePortal
                      id="combo-box-demo"
                      options={addNewLabel(originalCustomerList?.data, "first_name", "last_name")}
                      onChange={handleSearchChange}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Customer" />
                      )}
                    />
                  )}
                  <MDBox style={{ display: "flex", justifyContent: "center" }}>
                    <MDBox display="flex" alignItems="center" gap="1rem">
                      <PersonAddIcon
                        style={{ color: validationErrors.customer_id && "red" }}
                        fontSize="medium"
                      />
                      <MDTypography style={{ color: validationErrors.customer_id && "red" }}>
                        Add a customer <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </IconButton>
                {validationErrors.customer_id && (
                  <span style={spanStyle}>Customer is required!</span>
                )}
              </MDBox>
            ) : (
              <MDBox style={selectCustBox}>
                <MDBox
                  style={{
                    display: "flex",
                    border: "solid 1px #d5d5d5",
                    minHeight: "100%",
                    minWidth: "100%",
                    borderRadius: "5px",
                    alignItems: "start",
                  }}
                >
                  <MDBox style={{ display: "flex", flexDirection: "column" }}>
                    <MDBox padding="3%">
                      {openCustomerMenu && (
                        <Autocomplete
                          sx={{ minWidth: "150px" }}
                          disablePortal
                          id="combo-box-demo"
                          options={addNewLabel(
                            originalCustomerList?.data,
                            "first_name",
                            "last_name"
                          )}
                          onChange={handleSearchChange}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Select Customer" />
                          )}
                        />
                      )}
                      <MDTypography style={{ fontWeight: "700" }}>
                        {customer.first_name} {customer.last_name}
                      </MDTypography>
                      <br />
                      <MDTypography className="text_in_selected_customer_create_invoice">
                        {customer.property.address},
                      </MDTypography>
                      <MDTypography className="text_in_selected_customer_create_invoice">
                        {customer.property.city} {customer.property.state} {customer.property.zip}
                      </MDTypography>
                      <MDTypography className="text_in_selected_customer_create_invoice">
                        {customer?.phone && formatPhoneNumber(customer.phone)}
                      </MDTypography>
                      <MDTypography className="text_in_selected_customer_create_invoice">
                        {customer.email}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      style={{
                        padding: "3%",
                        display: "flex",
                        gap: "5px",
                        marginTop: "15%",
                      }}
                    >
                      <MDButton
                        style={{ fontSize: "0.65rem" }}
                        color="primary"
                        // eslint-disable-next-line no-underscore-dangle
                        onClick={() => navigate(`/profile/${customer?._id}`)}
                      >
                        Edit
                      </MDButton>
                      <MDButton
                        style={{ fontSize: "0.65rem" }}
                        color="info"
                        onClick={handleOpenCustomerMenu}
                      >
                        Choose a diferent customer
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            )}
            <MDBox
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: windowWidth <= 768 ? "100%" : "30%",
                marginTop: windowWidth <= 768 && "1rem",
              }}
            >
              <MDBox>
                <Typography variant="h6" component="h1">
                  Invoice Number <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  required
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  id="outlined-basic"
                  value={data?.invoice_number}
                  onChange={(e) => setData({ ...data, invoice_number: e.target.value })}
                />
              </MDBox>
              <MDBox>
                <Typography variant="h6" component="h1">
                  P.O./S.O.#
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  value={data?.po_so}
                  onChange={(e) => setData({ ...data, po_so: e.target.value })}
                />
              </MDBox>
              <Typography variant="h6" component="h1" style={{ marginBottom: "-15px" }}>
                Invoice Date <span style={{ color: "red" }}>*</span>
              </Typography>
              {paramsId.id ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    shouldDisableDate={isEndDateDisabled}
                    required
                    value={dayjs(data?.invoice_date)}
                    onChange={(date) => {
                      const dateValue = new Date(date);
                      const convertedDate = dateValue.toISOString();
                      setData({ ...data, invoice_date: convertedDate });
                    }}
                  />
                </LocalizationProvider>
              ) : (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    required
                    shouldDisableDate={isEndDateDisabled}
                    onChange={(date) => {
                      const dateValue = new Date(date);
                      const year = dateValue.getFullYear();
                      const month = (dateValue.getMonth() + 1).toString().padStart(2, "0");
                      const day = dateValue.getDate().toString().padStart(2, "0");
                      const convertedDate = `${year}-${month}-${day}`;
                      // const convertedDate = dateValue.toISOString();
                      const paymentDueDate = new Date(
                        dateValue.getTime() + 15 * 24 * 60 * 60 * 1000
                      ); // Add 15 days
                      const years = paymentDueDate.getFullYear();
                      const months = (paymentDueDate.getMonth() + 1).toString().padStart(2, "0");
                      const days = paymentDueDate.getDate().toString().padStart(2, "0");
                      const convertedpaymentDueDate = `${years}-${months}-${days}`;
                      // const convertedpaymentDueDate = paymentDueDate.toISOString();
                      setValidationErrors({ ...validationErrors, invoice_date: false });
                      if (customer._id) {
                        setData({
                          ...data,
                          invoice_date: convertedDate,
                          payment_due_date: convertedpaymentDueDate,
                          customer_id: customer._id,
                        });
                      } else {
                        setData({
                          ...data,
                          invoice_date: convertedDate,
                          payment_due_date: convertedpaymentDueDate,
                        });
                      }
                    }}
                  />
                </LocalizationProvider>
              )}
              {validationErrors.invoice_date && (
                <span style={spanStyle}>Invoice date is required!</span>
              )}
              <Typography variant="h6" component="h1" style={{ marginBottom: "-15px" }}>
                Payment due <span style={{ color: "red" }}>*</span>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  fullWidth
                  required={!paramsId.id}
                  shouldDisableDate={isStartDateDisabled}
                  value={data?.payment_due_date ? dayjs(data.payment_due_date) : null}
                  onChange={(date) => {
                    const dateValue = new Date(date);
                    const year = dateValue.getFullYear();
                    const month = (dateValue.getMonth() + 1).toString().padStart(2, "0");
                    const day = dateValue.getDate().toString().padStart(2, "0");
                    const convertedDate = `${year}-${month}-${day}`;
                    setData({ ...data, payment_due_date: convertedDate });
                  }}
                />
              </LocalizationProvider>
              {data?.payment_due_date && (
                <MDTypography variant="button" fontWeight="medium" color="text">
                  within {dueGap} days
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
          <MDBox sx={{ padding: "3% 3% 0% 3%" }}>
            <TableContainer style={{ boxShadow: "none" }}>
              <Table>
                {windowWidth >= 768 && (
                  <TableRow>
                    <TableCell className="item_table_header" style={{ width: "30%" }}>
                      Item <span style={{ color: "red" }}>*</span>
                    </TableCell>
                    <TableCell className="item_table_header" style={{ width: "20%" }}>
                      Hours or Quantity <span style={{ color: "red" }}>*</span>
                    </TableCell>
                    <TableCell className="item_table_header" style={{ width: "20%" }}>
                      Price <span style={{ color: "red" }}>*</span>
                    </TableCell>
                    <TableCell className="item_table_header" style={{ width: "20%" }}>
                      Amount
                    </TableCell>
                  </TableRow>
                )}
                <TableBody>
                  {addedItems?.map((item, index) => (
                    <>
                      {windowWidth >= 768 ? (
                        <TableRow key={index.id}>
                          <TableCell
                            fullWidth
                            style={{
                              fontSize: "0.875rem",
                              color: "#344767",
                              fontWeight: "600",
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <DeleteIcon
                              // eslint-disable-next-line no-shadow
                              onClick={() => handleRemoveItem(item.item_name)}
                              fontSize="medium"
                              color="error"
                            />
                            <Autocomplete
                              clearOnBlur={false}
                              value={item.item_name}
                              inputValue={item.item_name}
                              creat
                              fullWidth
                              disablePortal
                              id="combo-box-demo"
                              options={serviceDetails}
                              sx={{ width: "100%" }}
                              renderInput={(params) => <TextField {...params} label="" />}
                              onChange={(e, v) => handleInputChange(index, "item_name", v)}
                              onInputChange={(e, v) => handleInputChange(index, "item_name", v)}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.875rem",
                              color: "#344767",
                              fontWeight: "600",
                            }}
                          >
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              value={item.hours_or_qty}
                              onChange={(e) =>
                                handleInputChange(index, "hours_or_qty", Number(e.target.value))
                              }
                              label=""
                              variant="outlined"
                              type="number"
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.875rem",
                              color: "#344767",
                              fontWeight: "600",
                            }}
                          >
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              value={item.price}
                              onChange={(e) =>
                                handleInputChange(index, "price", Number(e.target.value))
                              }
                              label=""
                              variant="outlined"
                              type="number"
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.875rem",
                              color: "#344767",
                              fontWeight: "600",
                            }}
                          >
                            {item.hours_or_qty && item.price !== null
                              ? (item.hours_or_qty * item.price).toLocaleString(undefined, {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : "0.00"}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <Card
                          style={{
                            padding: "3%",
                            border: "1px solid black",
                            marginTop: "1rem",
                            gap: "1rem",
                          }}
                        >
                          <div
                            style={{ display: "flex", justifyContent: "end", marginRight: "5px" }}
                          >
                            <CloseIcon
                              // eslint-disable-next-line no-shadow
                              onClick={() => handleRemoveItem(item.item_name)}
                              fontSize="medium"
                              color="error"
                            />
                          </div>
                          <div>
                            <MDTypography variant="h6" component="h1">
                              Item <span style={{ color: "red" }}>*</span>
                            </MDTypography>
                            <Autocomplete
                              clearOnBlur={false}
                              value={item.item_name}
                              inputValue={item.item_name}
                              creat
                              fullWidth
                              disablePortal
                              id="combo-box-demo"
                              options={serviceDetails}
                              sx={{ width: "100%" }}
                              renderInput={(params) => <TextField {...params} label="" />}
                              onChange={(e, v) => handleInputChange(index, "item_name", v)}
                              onInputChange={(e, v) => handleInputChange(index, "item_name", v)}
                            />
                          </div>
                          <div>
                            <MDTypography variant="h6" component="h1">
                              Hours or Quantity <span style={{ color: "red" }}>*</span>
                            </MDTypography>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              value={item.hours_or_qty}
                              onChange={(e) =>
                                handleInputChange(index, "hours_or_qty", Number(e.target.value))
                              }
                              label=""
                              variant="outlined"
                              type="number"
                            />
                          </div>
                          <div>
                            <MDTypography variant="h6" component="h1">
                              Price <span style={{ color: "red" }}>*</span>
                            </MDTypography>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              value={item.price}
                              onChange={(e) =>
                                handleInputChange(index, "price", Number(e.target.value))
                              }
                              label=""
                              variant="outlined"
                              type="number"
                            />
                          </div>
                          <div style={{ marginBottom: "1rem" }}>
                            <MDTypography variant="h6" component="h1">
                              Amount
                            </MDTypography>
                            <MDTypography variant="text">
                              {item.hours_or_qty && item.price !== null
                                ? (item.hours_or_qty * item.price).toLocaleString(undefined, {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                : "0.00"}
                            </MDTypography>
                          </div>
                        </Card>
                      )}
                    </>
                  ))}
                  {itemInputOpen && (
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "0.875rem",
                          color: "#344767",
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <DeleteIcon
                          // eslint-disable-next-line no-shadow
                          onClick={() => setItemInputOpen(false)}
                          fontSize="medium"
                          color="error"
                        />
                        <Autocomplete
                          clearOnBlur={false}
                          value={serviceName}
                          creat
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={serviceDetails}
                          sx={{ width: "100%" }}
                          renderInput={(params) => <TextField {...params} label="" />}
                          onInputChange={(e, v) => {
                            if (v === null) {
                              return;
                            }
                            setServiceName(v);
                          }}
                          onChange={(e, v) => {
                            // eslint-disable-next-line no-empty
                            if (v === null) {
                              return;
                            }
                            setServiceName(v.label);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "0.875rem",
                          color: "#344767",
                          fontWeight: "600",
                        }}
                      >
                        <TextField
                          fullWidth
                          // onKeyDown={handleSelectItem}
                          value={hourOrQty}
                          type="number"
                          onChange={(e) => setHourOrQty(Number(e.target.value))}
                          style={{ marginRight: "1rem" }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "0.875rem",
                          color: "#344767",
                          fontWeight: "600",
                        }}
                      >
                        <TextField
                          // onKeyDown={handleSelectItem}
                          fullWidth
                          value={itemPrice}
                          type="number"
                          onChange={(e) => setItemPrice(Number(e.target.value))}
                          style={{ marginRight: "1rem" }}
                        />
                      </TableCell>
                      {/* {itemPrice && hourOrQty ? ( */}
                      <TableCell
                        style={{
                          fontSize: "0.875rem",
                          color: "#344767",
                          fontWeight: "600",
                        }}
                      >
                        <TextField
                          // onKeyDown={handleSelectItem}
                          fullWidth
                          value={itemPrice}
                          type="number"
                          onChange={(e) => setItemPrice(Number(e.target.value))}
                          style={{ marginRight: "1rem" }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {validationErrors.items && (
              <span style={spanStyle}>All fields are required Add at least one service!</span>
            )}
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center", padding: "0 3%", color: "#333745" }}>
            <IconButton
              style={{ gap: "0.1rem", fontWeight: "700" }}
              size="small"
              disableRipple
              color="inherit"
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleSelectItem}
            >
              <Icon>
                <AddCircleIcon />
              </Icon>
              Add a service <span style={{ color: "red" }}>*</span>
            </IconButton>
          </MDBox>
          <MDBox style={noteAndTotalWrapper}>
            <MDBox
              sx={{
                height: "30%",
                width: windowWidth <= 768 ? "100%" : "70%",
                padding: "0 3% 3% 0",
              }}
            >
              <MDTypography variant="h6" component="h1">
                Notes/Terms
              </MDTypography>
              <TextField
                value={data?.note}
                fullWidth
                onChange={(e) => setData({ ...data, note: e.target.value })}
                id="outlined-multiline-static"
                multiline
                rows={6}
              />
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                width: windowWidth <= 768 ? "100%" : "30%",
                gap: "1rem",
                marginTop: windowWidth <= 768 && "1rem",
              }}
            >
              <MDBox sx={{ display: "flex", gap: "1rem", justifyContent: "space-between" }}>
                <MDTypography>subtotal</MDTypography>
                <MDTypography>
                  {subTotalValue.toLocaleString(undefined, {
                    style: "currency",
                    currency: "USD",
                  })}
                </MDTypography>
              </MDBox>
              {discount.length > 0 && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {discount?.map((item, index) => {
                    const key = Object.keys(item)[0];
                    const value = item[key];
                    return (
                      <MDBox
                        sx={{ display: "flex", gap: "1rem", justifyContent: "space-between" }}
                        key={index.id}
                      >
                        <MDTypography>
                          {" "}
                          {key}
                          <MDButton onClick={() => handleRemoDisc(item)}>
                            <CloseIcon fontSize="large" color="error" />
                          </MDButton>
                        </MDTypography>
                        <MDTypography>
                          -
                          {value.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })}
                          {/* {value.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })} */}
                        </MDTypography>
                      </MDBox>
                    );
                  })}
                </>
              )}
              {showDisText && (
                <MDBox sx={{ display: "flex", gap: "1rem", justifyContent: "space-between" }}>
                  <TextField
                    value={discountText?.discount}
                    onChange={(e) => setDiscountText({ ...discountText, discount: e.target.value })}
                    id="outlined-basic"
                    label="Discount"
                    variant="outlined"
                    required
                  />
                  <TextField
                    value={discountText?.amount}
                    onChange={(e) => {
                      if (subTotalValue !== 0) {
                        // Limit the input length
                        let amount = e.target.value;
                        if (amount.toString().length > MAX_INPUT_LENGTH) {
                          amount = Number(amount.toString().slice(0, MAX_INPUT_LENGTH));
                        }
                        setDiscountText({ ...discountText, amount });
                      }
                    }}
                    type="number"
                    id="outlined-basic"
                    label="Amount"
                    variant="outlined"
                    required
                  />
                  {discountText.discount && discountText.amount && (
                    <MDButton onClick={handleSubmitDis} color="primary">
                      <DoneIcon fontSize="large" />
                    </MDButton>
                  )}
                </MDBox>
              )}
              <MDBox sx={{ display: "flex", gap: "1rem", minWidth: "200px", color: "#333745" }}>
                <IconButton
                  style={{ gap: "0.1rem", fontWeight: "700" }}
                  size="small"
                  disableRipple
                  color="inherit"
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleDiscount}
                >
                  <Icon>{showDisText ? <RemoveCircleIcon /> : <AddCircleIcon />}</Icon>
                  Add a discount
                </IconButton>
              </MDBox>
              <MDBox sx={{ display: "flex", gap: "1rem" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={handleTax}
                  options={[{ label: "CT 6.35%", value: 6.35 }]}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Select a tax" />}
                />
              </MDBox>
              {selectedTax.length > 0 && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {selectedTax?.map((item, index) => {
                    const key = Object.keys(item)[0];
                    const value = item[key];
                    return (
                      <MDBox
                        key={index.id}
                        sx={{ display: "flex", gap: "1rem", justifyContent: "space-between" }}
                      >
                        <MDTypography>{key}</MDTypography>
                        <MDTypography>
                          {value.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })}
                        </MDTypography>
                      </MDBox>
                    );
                  })}
                </>
              )}
              <MDBox
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                }}
              >
                <MDTypography sx={{ fontWeight: "700" }}>Total</MDTypography>
                <MDTypography sx={{ fontWeight: "700" }}>
                  {allTotal.toLocaleString(undefined, {
                    style: "currency",
                    currency: "USD",
                  })}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox style={{ padding: "3%" }}>
            <hr />
            <br />
          </MDBox>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "3rem",
            }}
          >
            <MDBox
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "0.5em",
                marginRight: "2rem",
              }}
            >
              <MDButton type="submit" color="info">
                {paramsId.id && state?.Duplicate !== true ? "Update invoice" : "Save and Continue"}
              </MDButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenSaveMenu}
                style={{ padding: "1px" }}
              >
                <MDButton color="info">
                  <ArrowDropDownIcon />
                </MDButton>
              </IconButton>
              {saveMenu()}
              <Dialog open={open} onClose={handleClose}>
                <div style={{ width: "500px" }}>
                  <DialogTitle>Record Payment</DialogTitle>
                  <DialogContent>
                    <Autocomplete
                      required
                      style={{ marginTop: "5px" }}
                      id="payment-method"
                      options={paymentOptions}
                      onChange={(e, v) =>
                        setRecordPayment({ ...recordPayment, payment_type: v.value })
                      }
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <MenuItem {...props}>
                          <ListItemIcon>{option.icon}</ListItemIcon>
                          <Typography variant="inherit">{option.label}</Typography>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Payment Type" variant="outlined" />
                      )}
                    />
                    <TextField
                      required
                      style={{ marginTop: "5%" }}
                      variant="outlined"
                      margin="dense"
                      id="name"
                      label="Amount"
                      type="number"
                      fullWidth
                      value={paymentSate} // Set the value to the current state value
                      onChange={handleAmountChange} // Use the custom function to handle the change
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={paymentSubmit}>Update</Button>
                  </DialogActions>
                </div>
              </Dialog>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddAndEditInvoice;
