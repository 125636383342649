// eslint-disable-next-line import/prefer-default-export
export const SET_STAFF_LOADING = "SET_STAFF_LOADING";

export const LIST_STAFF = "LIST__STAFF";
export const LIST_STAFF_SUCCESS = "LIST__STAFF_SUCCESS";
export const LIST__STAFF_FAILED = "LIST__STAFF_FAILED";

export const GET_STAFF = "GET_STAFF";
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS";
export const GET_STAFF_FAILED = "GET_STAFF_FAILED";

export const CREATE_STAFF = "ADD_EMPLOYEE";
export const CREATE_STAFF_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const CREATE_STAFF_FAILED = "ADD_EMPLOYEE_FAILED";

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAILED = "UPDATE_EMPLOYEE_FAILED";

export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAILED = "DELETE_EMPLOYEE_FAILED";
