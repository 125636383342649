/* eslint-disable react/prop-types */
import { Icon, IconButton } from "@mui/material";
import React from "react";
import { setMiniSidenav, useMaterialUIController } from "context";

function SideNavActionBtn({ color }) {
  const [controller, dispatched] = useMaterialUIController();
  const { miniSidenav } = controller;
  const handleMiniSidenav = () => {
    setMiniSidenav(dispatched, !miniSidenav);
  };
  return (
    <IconButton
      size="small"
      disableRipple
      color={color || "inherit"}
      style={{ padding: "0", margin: "0" }}
      onClick={handleMiniSidenav}
    >
      <Icon fontSize="medium">{miniSidenav ? "menu" : "menu_open"}</Icon>
    </IconButton>
  );
}

export default SideNavActionBtn;
