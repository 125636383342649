const {
  SET_APPOINTMENT_LOADING,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_FAILED,
  GET_SLOTS_SUCCESS,
  GET_TABLE_DATA_SUCCESS,
  GET_TABLE_DATA_FAILED,
  GET_SINGLE_APPOINTMENT_SUCCESS,
  GET_SINGLE_APPOINTMENT_FAILED,
  CLEAR_SINGLE_DATA,
} = require("./actionTypes");

const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  appointmentData: [],
  singleAppointmentData: {},
  slotsData: {},
  // tableData: [],
};

const AppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPOINTMENT_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        appointmentData: action.payload,
      };
    case GET_APPOINTMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    case GET_SLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        slotsData: action.payload,
      };
    case GET_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        tableData: action.payload,
      };
    case GET_TABLE_DATA_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case GET_SINGLE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        singleAppointmentData: action.payload,
      };
    case GET_SINGLE_APPOINTMENT_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case CLEAR_SINGLE_DATA:
      return {
        ...state,
        loading: false,
        singleAppointmentData: {},
      };
    default:
      return state;
  }
};

export default AppointmentReducer;
