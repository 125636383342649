/* eslint-disable react/prop-types */
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import MDTypography from "components/MDTypography";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
// eslint-disable-next-line import/no-duplicates

import { Autocomplete, Dialog, DialogContent, Grid, TextField, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// eslint-disable-next-line import/no-duplicates
import Swal from "sweetalert2";
import { updateEmployeeAction, deleteEmployeeAction } from "redux/Staff/actions";

// eslint-disable-next-line react/prop-types
const INITIAL_EMPLOYEE = {
  role_name: "",
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
};

const options = [
  { value: "owner", label: "Global Admin", hide: true },
  { value: "admin", label: "Global Admin" },
  { value: "billing_admin", label: "Billing Admin" },
  { value: "employee", label: "Field Employee" },
];

function Actions({ id, employees }) {
  const dispatch = useDispatch();

  const [employee, setEmployee] = useState(INITIAL_EMPLOYEE);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);

  const handleEdit = () => {
    setEmployee({
      role_name: employees.role_name,
      email: employees.email,
      first_name: employees.first_name,
      last_name: employees.last_name,
      phone_number: employees.phone,
      status: "true",
    });
    setOpen(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateEmployeeAction(employee, id));
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setView(false);
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#a5bd4b",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteEmployeeAction(id));
      }
    });
  };
  return (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      <Tooltip title="Edit" placement="top">
        <EditIcon fontSize="small" color="info" onClick={handleEdit} />
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <MDBox>
            <MDBox
              component="form"
              role="form"
              onSubmit={handleSubmit}
              mt={2}
              mb={3}
              style={{ padding: "20px", display: "flex", justifyContent: "center" }}
            >
              <div>
                <Grid
                  container
                  spacing={3}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {view ? (
                    <MDTypography variant="h2" fontWeight="medium" mb={2}>
                      Employee Profile
                    </MDTypography>
                  ) : (
                    <MDTypography variant="h2" fontWeight="medium" mb={2}>
                      Edit Employee
                    </MDTypography>
                  )}
                  <MDTypography
                    variant="h2"
                    fontWeight="medium"
                    mb={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Tooltip title="Close" placement="top">
                      <CloseIcon onClick={handleClose} />
                    </Tooltip>
                  </MDTypography>{" "}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      mb={2}
                      mt={3}
                      style={{ marginBottom: "0" }}
                    >
                      First Name
                    </MDTypography>
                    <TextField
                      fullWidth
                      id="outlined-size-normal"
                      // eslint-disable-next-line react/prop-types
                      value={employee.first_name}
                      disabled={view}
                      onChange={(e) => {
                        setEmployee({ ...employee, first_name: e.target.value });
                      }}
                      required
                    />{" "}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      mb={2}
                      mt={3}
                      style={{ marginBottom: "0" }}
                    >
                      Last Name
                    </MDTypography>
                    <TextField
                      fullWidth
                      value={employee.last_name}
                      disabled={view}
                      onChange={(e) => {
                        setEmployee({ ...employee, last_name: e.target.value });
                      }}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      mb={2}
                      mt={3}
                      style={{ marginBottom: "0" }}
                    >
                      Email Address
                    </MDTypography>
                    <TextField
                      fullWidth
                      value={employee.email}
                      onChange={(e) => {
                        setEmployee({ ...employee, email: e.target.value });
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      mb={2}
                      mt={3}
                      style={{ marginBottom: "0" }}
                    >
                      Employee Type
                    </MDTypography>
                    <Autocomplete
                      disabled={view}
                      style={{ height: "40%" }}
                      options={options.filter((option) => !option.hide)}
                      fullWidth
                      value={options.find((option) => option.value === employee?.role_name) || null}
                      onChange={(event, newValue) => {
                        setEmployee({ ...employee, role_name: newValue?.value || "" });
                      }}
                      required
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      mb={2}
                      mt={3}
                      style={{ marginBottom: "0" }}
                    >
                      Phone Number
                    </MDTypography>
                    <TextField
                      fullWidth
                      value={employee?.phone_number}
                      disabled={view}
                      onChange={(e) => {
                        setEmployee({ ...employee, phone_number: e.target.value });
                      }}
                    />
                  </Grid>
                  {view ? (
                    ""
                  ) : (
                    <Grid item xs={12} md={6}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        mb={2}
                        mt={3}
                        style={{ display: "flex", marginBottom: "0" }}
                      >
                        Password
                      </MDTypography>
                      <TextField
                        fullWidth
                        type="password"
                        onChange={(e) => {
                          setEmployee({ ...employee, password: e.target.value });
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                {!view ? (
                  <>
                    <br />
                    <Grid
                      container
                      spacing={3}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        style={{ marginTop: "24px" }}
                      >
                        {view ? "Save" : "Update"}
                      </MDButton>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </div>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
      <Tooltip title="Delete" placement="top">
        <DeleteIcon fontSize="small" color="error" onClick={handleDelete} />
      </Tooltip>
    </MDTypography>
  );
}

export default Actions;
