/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import { useNavigate } from "react-router-dom";

function ViewMessage({ number, userId }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/profile/${userId}`, { state: { tab: 3 } });
  };
  return (
    <MDBox>
      <Tooltip title="Click here" placement="top">
        <MDButton onClick={handleClick} style={{ color: "#344767" }}>
          {number}
        </MDButton>
      </Tooltip>
    </MDBox>
  );
}

export default ViewMessage;
