/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import MDTypography from "components/MDTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  deleteCrewAction,
  singleCrewDetailsAction,
  updateCrewAction,
  // getCrewListAction,
} from "redux/Crew/actions";
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { listStaffAction } from "redux/Staff/actions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// eslint-disable-next-line react/prop-types
function Actions({ id }) {
  const dispatch = useDispatch();
  const { crewDetail, crewsData } = useSelector((state) => state.CrewReducer);
  const { staffData } = useSelector((state) => state.StaffReducer);
  const [open, setOpen] = useState(false);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [newData, setNewData] = useState();
  const [employeeOnCrew, setEmployeeOnCrew] = useState([]);
  const handleDelete = () => {
    Swal.fire({
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#a5bd4b",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteCrewAction(id));
      }
    });
  };
  const handleOpen = () => {
    setOpen(true);
    dispatch(singleCrewDetailsAction(id));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCrewAction(newData, id));
    setTimeout(() => {
      dispatch(listStaffAction());
    }, 2000);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedObjects([]);
  };
  const handleChange = (event) => {
    const { value } = event.target;
    const freqObj = value.reduce((acc, obj) => {
      const key = obj.id + obj.name;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});
    const uniqueArray = value.filter((obj) => {
      const key = obj.id + obj.name;
      return freqObj[key] === 1;
    });
    setSelectedObjects(uniqueArray, "uniqueArray");
    const updatedValue = uniqueArray.map((obj) => obj.id);
    setNewData({ ...newData, employee_ids: [...updatedValue] });
  };
  useEffect(() => {
    setNewData({ crew_title: crewDetail?.crew_title });
    const newArray = staffData?.data
      ?.filter(({ _id }) => crewDetail?.employee_ids?.includes(_id))
      .map(({ _id, first_name }) => ({ id: _id, name: first_name }));
    setSelectedObjects(newArray);
    if (!staffData.data) {
      dispatch(listStaffAction());
    }
    if (crewsData.data) {
      const allEmployeeIds = crewsData?.data.map((employee) => employee.employee_ids).flat();
      const employeeId = allEmployeeIds?.filter((id) => !crewDetail?.employee_ids?.includes(id));
      setEmployeeOnCrew(employeeId);
    }
  }, [crewDetail]);
  return (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      <Tooltip title="Edit" placement="top">
        <EditIcon fontSize="small" color="info" onClick={handleOpen} />
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ width: "100%" }}>
          <MDBox style={{ width: "100%" }}>
            <MDBox
              component="form"
              role="form"
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <MDBox
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <MDTypography variant="h4" fontWeight="medium">
                  Edit Crew
                </MDTypography>{" "}
                <MDTypography
                  variant="h2"
                  fontWeight="medium"
                  mb={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Tooltip title="Close" placement="top">
                    <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                  </Tooltip>
                </MDTypography>{" "}
              </MDBox>
              <Grid container spacing={3}>
                <Grid item style={{ paddingTop: "1.5rem" }} xs={12} md={6}>
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    style={{ display: "flex", marginBottom: "0" }}
                  >
                    Crew Title <p style={{ color: "red" }}>*</p>
                  </MDTypography>
                  <TextField
                    fullWidth
                    id="outlined-size-normal"
                    value={newData?.crew_title}
                    required
                    onChange={(e) => setNewData({ ...newData, crew_title: e.target.value })}
                  />{" "}
                </Grid>
                <Grid item style={{ paddingTop: "1.5rem" }} xs={12} md={6}>
                  <MDTypography variant="h6" fontWeight="medium" style={{ display: "flex" }}>
                    Select Members <p style={{ color: "red" }}>*</p>
                  </MDTypography>
                  <FormControl style={{ flexDirection: "column-reverse", width: "100%" }}>
                    {selectedObjects && (
                      <Select
                        style={{ padding: "12px", flexDirection: "column" }}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedObjects}
                        onChange={handleChange}
                        renderValue={(selected) => (
                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip
                                key={value._id}
                                label={value.name}
                                color="success"
                                style={{
                                  marginBottom: "3px",
                                  fontSize: "12px",
                                  maxHeight: "20px",
                                  color: "#fff",
                                }}
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                        required
                      >
                        {staffData.data
                          ?.filter((obj) => !employeeOnCrew?.includes(obj._id))
                          .map((obj) => (
                            // eslint-disable-next-line no-underscore-dangle
                            <MenuItem key={obj._id} value={{ id: obj._id, name: obj.first_name }}>
                              <Checkbox
                                // eslint-disable-next-line no-underscore-dangle
                                checked={selectedObjects.some((o) => o.id === obj._id)}
                              />
                              <ListItemText primary={obj.first_name} />
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <MDBox
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "1rem",
                  width: "100%",
                }}
              >
                <MDButton fullWidth variant="gradient" color="info" type="submit">
                  Update
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
      <Tooltip title="Delete" placement="top">
        <DeleteIcon fontSize="small" color="error" onClick={handleDelete} />
      </Tooltip>
    </MDTypography>
  );
}

export default Actions;
