const {
  SET_CREW_LOADING,
  LIST_CREW_SUCCESS,
  LIST_CREW_FAILED,
  GET_CREW_SUCCESS,
  GET_CREW_FAILED,
} = require("./actionTypes");

const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  crewsData: [],
  crewDetail: [],
};

const CrewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CREW_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case LIST_CREW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        crewsData: action.payload,
      };
    case LIST_CREW_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    case GET_CREW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        crewDetail: action.payload,
      };
    case GET_CREW_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default CrewReducer;
