import API from "services/axiosapi";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";

const { takeLatest, all, put } = require("redux-saga/effects");

function* getDashboardDataSaga() {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield API.post("/api/dashboard");
    if (data.success) {
      yield put({ type: actionTypes.GET_DASHBOARD_DATA_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_DASHBOARD_DATA_FAILED,
        errorMessage: "Unable to fetch Dashboard Data!",
      });
      toast.error("Unable to fetch Dashboard Data!");
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_DATA_FAILED,
      errorMessage: "Unable to fetch Dashboard Data!",
    });
    toast.error("Unable to fetch Dashboard Data!");
  }
}
function* getDashboardRouteTableSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield API.get("/api/dashboard/routewise", {
      params: { page: payload?.page || 1, limit: payload?.limit || 5 },
    });
    if (data.success) {
      yield put({ type: actionTypes.GET_DASHBOARD_ROUTE_TABLE_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_DASHBOARD_ROUTE_TABLE_FAILED,
        errorMessage: "Unable to fetch Dashboard Table!",
      });
      toast.error("Unable to fetch Dashboard Table!");
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_ROUTE_TABLE_FAILED,
      errorMessage: "Unable to fetch Dashboard Table!",
    });
    toast.error("Unable to fetch Dashboard Table!");
  }
}

function* updateAppointmentNoteSaga({ obj }) {
  const { dataSet } = obj;
  const { id } = obj;
  yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
  try {
    const { data } = yield API.put(`/api/appointment/${id}/update`, dataSet);
    if (data.success) {
      yield put({ type: actionTypes.UPDATE_APPOINTMENT_NOTE_SUCCESS });
      yield put({ type: actionTypes.GET_DASHBOARD_DATA });
      toast.success("Appointment updated!");
    } else {
      yield put({
        type: actionTypes.UPDATE_APPOINTMENT_NOTE_FAILED,
        errorMessage: "Unable to update appointment note!",
      });
      toast.error("Unable to update appointment note!");
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_APPOINTMENT_NOTE_FAILED,
      errorMessage: "Unable to update appointment note!",
    });
    toast.error("Unable to update appointment note!");
  }
}

function* updateYearlyActivationSaga({ obj }) {
  try {
    // yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield API.post("/api/yearly-activation/store-update", obj);
    if (data.success) {
      yield put({ type: actionTypes.UPDATE_YEARLY_ACTIVATION_SUCCESS });
      yield put({ type: actionTypes.GET_DASHBOARD_ROUTE_TABLE });
      toast.success("Yearly Activation Updated!");
    } else {
      yield put({
        type: actionTypes.UPDATE_YEARLY_ACTIVATION_FAILED,
        errorMessage: "Unable to update yearly activation!",
      });
      toast.error("Unable to update yearly activation!");
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_YEARLY_ACTIVATION_FAILED,
      errorMessage: "Unable to update yearly activation!",
    });
    toast.error("Unable to update yearly activation!");
  }
}

function* DashboardSaga() {
  yield all([yield takeLatest(actionTypes.GET_DASHBOARD_DATA, getDashboardDataSaga)]);
  yield all([yield takeLatest(actionTypes.GET_DASHBOARD_ROUTE_TABLE, getDashboardRouteTableSaga)]);
  yield all([yield takeLatest(actionTypes.UPDATE_APPOINTMENT_NOTE, updateAppointmentNoteSaga)]);
  yield all([yield takeLatest(actionTypes.UPDATE_YEARLY_ACTIVATION, updateYearlyActivationSaga)]);
}

export default DashboardSaga;
