const {
  SET_ROUTE_MANAGEMENT_LOADING,
  LIST_SERVICES_SUCCESS,
  LIST_SERVICES_FAILED,
  ADD_SERVICES_SUCCESS,
  ADD_SERVICES_FAILED,
  GET_SERVICE_SUCCESS,
  CLEAR_SERVICE,
  EDIT_SERVICES_FAILED,
} = require("./actionTypes");

const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  servicesData: [],
  service: {},
};
const RouteManagement = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTE_MANAGEMENT_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case LIST_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        servicesData: action.payload,
        service: {},
      };
    case LIST_SERVICES_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    case ADD_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ADD_SERVICES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.errorMessage,
      };
    case GET_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        service: action.payload,
      };
    case CLEAR_SERVICE:
      return {
        ...state,
        service: {},
      };
    case EDIT_SERVICES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.errorMessage,
      };
    default:
      return state;
  }
};

export default RouteManagement;
