/* eslint-disable import/prefer-default-export */
import {
  CREATE_ROUTE,
  DELETE_ROUTE,
  EDIT_ROUTE,
  GET_SINGLE_ROUTE,
  LIST_ROUTES,
} from "./actionTypes";

export const getRoutesListAction = () => ({
  type: LIST_ROUTES,
});

export const createRouteAction = (obj) => ({
  type: CREATE_ROUTE,
  obj,
});

export const deleteRouteAction = (Id) => ({
  type: DELETE_ROUTE,
  Id,
});

export const getSingleRouteAction = (Id) => ({
  type: GET_SINGLE_ROUTE,
  Id,
});

export const editRouteAction = (obj) => ({
  type: EDIT_ROUTE,
  obj,
});
