/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postMessageAction } from "redux/Messages/actions";

function SendMessageBtn({ details }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [smsData, setSmsData] = useState({
    ...details,
    phone: extractNumbersAsSingleString(details.phone),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  function extractNumbersAsSingleString(str) {
    // Use regular expression to match numbers in the string
    const numbersArray = str.match(/\d+/g);
    // If there are no numbers in the string, return an empty string
    if (!numbersArray) {
      return "";
    }
    // Combine the matched numbers into a single string
    const numbersOnly = numbersArray.join("");

    return numbersOnly;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postMessageAction({ toNumbers: [smsData.phone], message }));
    setMessage("");
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <MDButton
        onClick={handleClickOpen}
        variant="gradient"
        color="dark"
        style={{ padding: "5px" }}
      >
        Send Message
      </MDButton>
      <Dialog
        component="form"
        role="form"
        onSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
      >
        <div style={{ width: "500px" }}>
          <DialogTitle>Send Message</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Customer Name"
              type="text"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              value={`${smsData?.first_name} ${smsData?.last_name}`}
              required
            />
            <TextField
              style={{ marginTop: "1rem" }}
              margin="dense"
              id="name"
              label="Customer Phone"
              type="text"
              fullWidth
              value={smsData?.phone}
              onChange={(e) => setSmsData({ ...smsData, phone: e.target.value })}
              required
            />
            <TextField
              autoFocus
              style={{ marginTop: "1rem" }}
              mt={2}
              fullWidth
              id="outlined-multiline-static"
              label="Message"
              multiline
              rows={4}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose}>Cancel</MDButton>
            <MDButton type="submit">Send</MDButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

export default SendMessageBtn;
