import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";
import API from "services/axiosapi";
import * as actionTypes from "./actionTypes";

function* fetchAllCity() {
  try {
    yield put({ type: actionTypes.SET_CITY_LOADING });
    const { data } = yield API.get("api/city");
    if (data.success) {
      yield put({ type: actionTypes.LIST_CITY_SUCCESS, payload: data });
    } else {
      yield put({ type: actionTypes.LIST_CITY_FAILED, errorMessage: "Unable to fetch data!" });
    }
  } catch (error) {
    toast.error("Unable to fetch data!");
    yield put({
      type: actionTypes.LIST_CITY_FAILED,
      errorMessage: "Unable to Fetch data!",
    });
  }
}

function* addCitySaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_CITY_LOADING });
    const { data } = yield API.post("/api/city", payload);
    if (data.success) {
      yield put({ type: actionTypes.ADD_CITY_SUCCESS });
      yield put({ type: actionTypes.LIST_CITY });
      toast.success(data.message);
    } else {
      yield put({ type: actionTypes.ADD_CITY_FAILED, errorMessage: data.message });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.ADD_CITY_FAILED, errorMessage: error.response.data.message });
    toast.error(error.response.data.message);
  }
}

function* getSingleCityDataSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_CITY_LOADING });
    const { data } = yield API.get(`/api/city/${id}`);
    if (data.success) {
      yield put({ type: actionTypes.GET_CITY_BY_ID_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_CITY_BY_ID_FAILED,
        errorMessage: "Unable to fetch this data!",
      });
      toast.error("Unable to fetch this data!");
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_CITY_BY_ID_FAILED,
      errorMessage: "Unable to fetch this data!",
    });
    toast.error("Unable to fetch this data!");
  }
}

// eslint-disable-next-line no-unused-vars
function* editCitySaga(payload) {
  try {
    yield put({ type: actionTypes.SET_CITY_LOADING });
    // /api/city/64534fdf8a556494c9e66b72/city-update-with-route
    const { data } = yield API.put(`/api/city/${payload.obj.Id}/city-update-with-route`, {
      city_name: payload.obj.city_name,
      city_codes: payload.obj.city_codes,
    });
    if (data.success) {
      yield put({ type: actionTypes.EDIT_CITY_SUCCESS });
      yield put({ type: actionTypes.LIST_CITY });
      toast.success(data.message);
    } else {
      yield put({ type: actionTypes.EDIT_CITY_FAILED, errorMessage: "Unable to edit city!" });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.EDIT_CITY_FAILED, errorMessage: "Unable to edit city!" });
    toast.error(error.response.data.message);
  }
}

function* deleteCitySaga({ Id }) {
  try {
    yield put({ type: actionTypes.SET_CITY_LOADING });
    const { data } = yield API.delete(`/api/city/${Id}/city-delete-with-route`);
    if (data.success) {
      yield put({ type: actionTypes.DELETE_CITY_SUCCESS });
      yield put({ type: actionTypes.LIST_CITY });
      toast.success(data.message);
    } else {
      yield put({ type: actionTypes.DELETE_CITY_FAILED, errorMessage: "Unable to Delete!" });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.DELETE_CITY_FAILED, errorMessage: "Unable to Delete!" });
    toast.error(error.response.data.message);
  }
}

function* CitySaga() {
  yield all([
    yield takeLatest(actionTypes.LIST_CITY, fetchAllCity),
    yield takeLatest(actionTypes.ADD_CITY, addCitySaga),
    yield takeLatest(actionTypes.GET_CITY_BY_ID, getSingleCityDataSaga),
    yield takeLatest(actionTypes.EDIT_CITY, editCitySaga),
    yield takeLatest(actionTypes.DELETE_CITY, deleteCitySaga),
  ]);
}

export default CitySaga;
