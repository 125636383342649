/* eslint-disable import/prefer-default-export */
import { GET_PERMISSION_EMPLOYEE_LIST, UPDATE_EMPLOYEE_PERMISSION_ROLE } from "./actionTypes";

export const permissionEmployeeListAction = () => ({
  type: GET_PERMISSION_EMPLOYEE_LIST,
});

export const updatePermissionEmployeeRole = (obj) => ({
  type: UPDATE_EMPLOYEE_PERMISSION_ROLE,
  obj,
});
