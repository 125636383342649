/* eslint-disable */

import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import API from "../../services/axiosapi";
import * as actionTypes from "./actionTypes";

function* listHolidaysSaga({ payload }) {
  const { path } = payload;
  const { paginationData } = payload;
  try {
    yield put({
      type: actionTypes.SET_SETTINGS_LOADING,
    });
    const { data } = yield API.get(`api/${path.tab}`, { params: { ...paginationData } });
    if (data.success) {
      yield put({
        type: actionTypes.LIST_HOLIDAYS_SUCCESS,
        payload: data,
      });
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.LIST_HOLIDAYS_FAILED,
        errorMessage: "Unable to Fetch data!",
      });
    }
  } catch (error) {
    toast.error("Unable to Fetch data!");
    yield put({
      type: actionTypes.LIST_HOLIDAYS_FAILED,
      errorMessage: "Unable to Fetch data!",
    });
  }
}

function* weekOffListSaga() {
  try {
    const { data } = yield API.get("api/days-off");
    if (data.success) {
      yield put({
        type: actionTypes.WEEK_OFF_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable to get week off details!");
      yield put({
        type: actionTypes.WEEK_OFF_LIST_FAILED,
        errorMessage: "Unable to get week off details!",
      });
    }
  } catch (error) {
    toast.error("Unable to get week off details!");
    yield put({
      type: actionTypes.WEEK_OFF_LIST_FAILED,
      errorMessage: "Unable to get week off details!",
    });
  }
}

function* addHolidaysSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_SETTINGS_LOADING });
    const { data } = yield API.post("api/holiday", { ...obj });
    if (data.success) {
      toast.success("success");
      yield put({ type: actionTypes.ADD_HOLIDAYS_SUCCESS });
      yield put({ type: actionTypes.LIST_HOLIDAYS, payload: { path: { tab: "holiday" } } });
    } else {
      toast(data.message);
      yield put({ type: actionTypes.ADD_HOLIDAYS_FAILED, errorMessage: "Unable to add Holiday!" });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({ type: actionTypes.ADD_HOLIDAYS_FAILED, errorMessage: "Unable to add Holiday!" });
  }
}

function* updateWeekOffSaga({ obj }) {
  try {
    // yield put({ type: actionTypes.SET_SETTINGS_LOADING });
    const { data } = yield API.post("/api/days-off", { ...obj });
    if (data.success) {
      yield put({ type: actionTypes.UPDATE_WEEK_OFF_SUCCESS });
      yield put({ type: actionTypes.WEEK_OFF_LIST });
      toast.success(data.message);
    } else {
      toast.error(data.message);
      yield put({ type: actionTypes.UPDATE_WEEK_OFF_FAILED, errorMessage: "Unable to update!" });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({ type: actionTypes.UPDATE_WEEK_OFF_FAILED, errorMessage: "Unable to update!" });
  }
}

function* deleteHolidaysSaga(id) {
  try {
    yield put({ type: actionTypes.SET_SETTINGS_LOADING });
    const { data } = yield API.delete(`/api/holiday/${id.Id.id}`);
    if (data.success) {
      yield put({ type: actionTypes.DELETE_HOLIDAYS_SUCCESS });
      yield put({ type: actionTypes.LIST_HOLIDAYS, payload: { path: { tab: "holiday" } } });
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.DELETE_HOLIDAYS_FAILED,
        errorMessage: "Unable to delete Holiday",
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: actionTypes.DELETE_HOLIDAYS_FAILED,
      errorMessage: "Unable to delete Holiday",
    });
  }
}

function* HolidaysSaga() {
  yield all([
    yield takeLatest(actionTypes.LIST_HOLIDAYS, listHolidaysSaga),
    yield takeLatest(actionTypes.WEEK_OFF_LIST, weekOffListSaga),
    yield takeLatest(actionTypes.ADD_HOLIDAYS, addHolidaysSaga),
    yield takeLatest(actionTypes.UPDATE_WEEK_OFF, updateWeekOffSaga),
    yield takeLatest(actionTypes.DELETE_HOLIDAYS, deleteHolidaysSaga),
  ]);
}

export default HolidaysSaga;
