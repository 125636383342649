/* eslint-disable import/prefer-default-export */
import {
  CLEAR_NEW_INVOICE,
  CLEAR_SESSION_DATA,
  CREATE_INVOICE,
  DELETE_INVOICE,
  DELETE_REMINDER,
  FILTER_INVOICE,
  GET_INVOICE_LIST,
  GET_LAST_INVOICE_NUMBER,
  GET_OVERDUE_AND_DUEWITH,
  GET_SINGLE_INVOICE,
  PAYMENT_CHECK_OUT,
  SEND_INVOICE,
  SET_REMINDER,
  STATUS_UPDATE,
  SUCCESS_CHECK_OUT_UPDATION,
  UPDATE_INVOICE,
  UPDATE_PAYMENT_METHOD,
} from "./actionTypes";

export const getInvoiceListAction = (payload) => ({
  type: GET_INVOICE_LIST,
  payload,
});

export const getSingleInvoiceAction = (id) => ({
  type: GET_SINGLE_INVOICE,
  id,
});

export const createInoviceAction = (obj) => ({
  type: CREATE_INVOICE,
  obj,
});

export const filterInvoiceAction = (payload) => ({
  type: FILTER_INVOICE,
  payload,
});

export const updateInvoiceAction = (payload) => ({
  type: UPDATE_INVOICE,
  payload,
});

export const deleteInvoiceAction = (payload) => ({
  type: DELETE_INVOICE,
  payload,
});

export const sendInvoicAction = (sendDetails) => ({
  type: SEND_INVOICE,
  sendDetails,
});

export const getLastInvoiceNumberAction = () => ({
  type: GET_LAST_INVOICE_NUMBER,
});

export const paymentCheckOutAction = (obj) => ({
  type: PAYMENT_CHECK_OUT,
  obj,
});

export const successCheckOutUpdateAction = (obj) => ({
  type: SUCCESS_CHECK_OUT_UPDATION,
  obj,
});

export const clearSessionDataAction = () => ({
  type: CLEAR_SESSION_DATA,
});

export const clearNewInvoiceDataAction = () => ({
  type: CLEAR_NEW_INVOICE,
});

export const getOverDueAndDueWithAction = () => ({
  type: GET_OVERDUE_AND_DUEWITH,
});

export const updateInvoicePaymentAction = (payload) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload,
});

export const ReminderAPIAction = (payload) => ({
  type: SET_REMINDER,
  payload,
});

export const deleteRemainderAction = (payload) => ({
  type: DELETE_REMINDER,
  payload,
});

export const updateInvoiceSatausAction = (id) => ({
  type: STATUS_UPDATE,
  id,
});
