/* eslint-disable import/prefer-default-export */
import {
  ADD_CITY,
  CLEAR_SINGLE_CITY_DATA,
  DELETE_CITY,
  EDIT_CITY,
  GET_CITY_BY_ID,
  LIST_CITY,
} from "./actionTypes";

export const getCityDataAction = () => ({
  type: LIST_CITY,
});

export const addCityAction = (payload) => ({
  type: ADD_CITY,
  payload,
});

export const getCityByIdAction = (id) => ({
  type: GET_CITY_BY_ID,
  id,
});

export const editCityAction = (obj) => ({
  type: EDIT_CITY,
  obj,
});

export const clearCitySingleData = () => ({
  type: CLEAR_SINGLE_CITY_DATA,
});

export const deleteCityAction = (Id) => ({
  type: DELETE_CITY,
  Id,
});
