/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign, no-underscore-dangle */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import { Chip, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Action from "./Action";
// import Customer from "./Customer";

function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export default function data(tableData) {
  return {
    columnData: [
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Number", accessor: "number", align: "left" },
      { Header: "Invoice Date", accessor: "invoice_date", align: "left" },
      { Header: "Customer", accessor: "customer", align: "left" },
      { Header: "Total", accessor: "total", align: "left" },
      { Header: "Balance", accessor: "balance", align: "left" },
      { Header: "", accessor: "action", align: "left" },
    ],

    rowData: tableData?.data?.map((d) => ({
      status: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          <Chip
            style={{
              color: "#fff",
              borderRadius: "0.375rem",
              fontSize: ".765rem",
              height: "1.6rem",
              background:
                d.status === "draft"
                  ? "linear-gradient(45deg, rgb(91 91 91), rgb(137 137 137))"
                  : d.status === "sent"
                  ? "linear-gradient(45deg, #0089ff, rgb(118 184 255))"
                  : d.status === "paid"
                  ? "linear-gradient(45deg, #5db568, #52af3b)"
                  : d.status === "viewed"
                  ? "linear-gradient(45deg, #d57700, #e3b87f);"
                  : d.status === "overdue"
                  ? "linear-gradient(45deg, #e31000, #ff8989)"
                  : d.status === "partial"
                  ? "linear-gradient(45deg, #d0ff1e, #b5dd00)"
                  : "linear-gradient(45deg, rgb(52, 71, 103), rgb(117 117 117))",
            }}
            label={<span style={{ textTransform: "capitalize" }}>{d.status}</span>}
          />
        </MDTypography>
      ),
      number: (
        <Link to={`/view_invoice/${d._id}`}>
          <Tooltip title="Invoice Number" placement="top">
            <MDTypography display="block" variant="button" fontWeight="medium">
              {d.invoice_number}
            </MDTypography>
          </Tooltip>
        </Link>
      ),
      invoice_date: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {dateFormatted(d.invoice_date)}
        </MDTypography>
      ),
      customer: (
        <Link to={`/view_invoice/${d._id}`}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {d.customer_id.first_name} {d.customer_id.last_name}
          </MDTypography>
        </Link>
      ),
      total: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d.total_price.toLocaleString(undefined, {
            style: "currency",
            currency: "USD",
          })}
        </MDTypography>
      ),
      balance: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d.balance.toLocaleString(undefined, {
            style: "currency",
            currency: "USD",
          })}
        </MDTypography>
      ),
      action: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          <Action status={d.status} id={d._id} singleInvoice={d} />
        </MDTypography>
      ),
    })),
  };
}
