import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect } from "react";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { paymentCheckOutAction } from "redux/invoices/actions";
import { useParams } from "react-router-dom";
import redFailedMark from "../assets/failed_mark.png";

function PaymentFiled() {
  const dispatch = useDispatch();
  const paramsId = useParams();
  const { checkOutDetails } = useSelector((state) => state.InvoiceReducer);

  useEffect(() => {
    if (paramsId.invoice_id) {
      dispatch(paymentCheckOutAction({ invoice_id: paramsId.invoice_id }));
    }
  }, [paramsId]);
  return (
    <MDBox padding="3% 15% 3% 15%">
      <Card style={{ height: "80vh" }}>
        <MDBox
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <MDTypography
              color="text"
              variant="h4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {checkOutDetails?.data?.session_id}
            </MDTypography>
            <MDTypography
              color="text"
              variant="h4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              Payment failed!
            </MDTypography>
            <MDBox style={{ display: "flex", justifyContent: "center", objectFit: "contain" }}>
              <img style={{ objectFit: "contain" }} src={redFailedMark} loading="lazy" alt="" />
            </MDBox>
          </div>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default PaymentFiled;
