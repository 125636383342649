/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign, no-underscore-dangle */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
// import Customer from "./Customer";

function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export default function data(tableData) {
  return {
    columnData: [
      { Header: "To Number", accessor: "to_number", align: "left" },
      { Header: "Message", accessor: "message", align: "left" },
      { Header: "Timestamp", accessor: "timestamp", align: "left" },
    ],

    rowData: tableData?.data?.map((d) => ({
      to_number: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d.toNumber}
        </MDTypography>
      ),
      message: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d.message}
        </MDTypography>
      ),
      timestamp: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {dateFormatted(d?.updatedAt)}
        </MDTypography>
      ),
      twilio_response: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d.twillio_response}
        </MDTypography>
      ),
    })),
  };
}
