import {
  GET_PERMISSION_EMPLOYEE_LIST_FAILED,
  GET_PERMISSION_EMPLOYEE_LIST_SUCCESS,
  SET_PERMISSION_LOADING,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  permissionEmployeeList: [],
};

const PermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERMISSION_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case GET_PERMISSION_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        permissionEmployeeList: action.payload,
      };
    case GET_PERMISSION_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default PermissionReducer;
