/* eslint-disable prettier/prettier */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { Autocomplete, Card, Dialog, DialogContent, Grid, TextField, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomSpinner from "examples/CustomLoader";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { debounce } from "utils";
import { CreateStaffAction, listStaffAction, deleteEmployeeAction } from "redux/Staff/actions";
import { getCrewListAction } from "redux/Crew/actions";
import Swal from "sweetalert2";
import CustomTablePagination from "examples/CustomTablePagination/CustomTablePagination";
import authorsTableData, { getRoleLabel } from "./list/data/authorsTableData";
import Actions from "./list/Actions";
import StatusAction from "./list/StatusAction";

function addNewLabel(array, keyName) {
  return array?.map((obj) => {
    const newObj = { ...obj };
    newObj.label = newObj[keyName];
    return newObj;
  });
}

const employeeTypeOptions = [
  { value: "admin", label: "Global Admin" },
  { value: "billing_admin", label: "Billing Admin" },
  { value: "employee", label: "Field Employee" },
];

function Staff() {
  const { staffData, staffLoading } = useSelector((state) => state.StaffReducer);
  const { crewsData } = useSelector((state) => state.CrewReducer);
  const formatCrewOption = addNewLabel(crewsData?.data || [], "crew_title");
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [employee, setEmployee] = useState({ status: true });
  const [open, setOpen] = React.useState(false);
  const [crewSelect, setCrewSelect] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState({ search: "" });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [cardData, setCardData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    password: false,
  });

  const validateFirstName = (firstName) => firstName?.trim() !== "";

  const validateLastName = (lastName) => lastName?.trim() !== "";

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex?.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex?.test(phone);
  };

  const validatePassword = (password) => password?.length >= 4;

  const handleBlur = (fieldName, value) => {
    let isValid = true;

    switch (fieldName) {
      case "first_name":
        isValid = validateFirstName(value);
        setValidationErrors((prevErrors) => ({ ...prevErrors, first_name: !isValid }));
        break;
      case "last_name":
        isValid = validateLastName(value);
        setValidationErrors((prevErrors) => ({ ...prevErrors, last_name: !isValid }));
        break;
      case "email":
        isValid = validateEmail(value);
        setValidationErrors((prevErrors) => ({ ...prevErrors, email: !isValid }));
        break;
      case "phone":
        isValid = validatePhone(value);
        setValidationErrors((prevErrors) => ({ ...prevErrors, phone: !isValid }));
        break;
      case "password":
        isValid = validatePassword(value);
        setValidationErrors((prevErrors) => ({ ...prevErrors, password: !isValid }));
        break;
      default:
        break;
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    dispatch(listStaffAction({ ...searchQuery, page: value, limit: pageSize }));
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#a5bd4b",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteEmployeeAction(id));
      }
    });
  };
  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(1);
    dispatch(listStaffAction({ ...searchQuery, page: 1, limit: event.target.value }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setValidationErrors({
      first_name: false,
      last_name: false,
      email: false,
      phone: false,
      password: false,
    });
  };

  // const handleSelect = (e) => {
  //   setCrewSelect(e.target.value);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFirstNameValid = validateFirstName(employee.first_name);
    const isLastNameValid = validateLastName(employee.last_name);
    const isEmailValid = validateEmail(employee.email);
    const isPhoneValid = validatePhone(employee.phone);
    const isPasswordValid = validatePassword(employee.password);

    setValidationErrors({
      first_name: !isFirstNameValid,
      last_name: !isLastNameValid,
      email: !isEmailValid,
      phone: !isPhoneValid,
      password: !isPasswordValid,
    });
    if (isFirstNameValid && isLastNameValid && isEmailValid && isPhoneValid && isPasswordValid) {
      dispatch(CreateStaffAction(employee));
      setOpen(false);
    }
  };
  const onChangeInput = debounce((e) => {
    if (e.target.value.length >= 3) {
      dispatch(listStaffAction({ search: e.target.value }));
      setSearchQuery({ search: e.target.value, page, limit: pageSize });
    } else if (e.target.value.length === 0) {
      dispatch(listStaffAction({ search: e.target.value, page, limit: pageSize }));
      setSearchQuery({ search: e.target.value });
    }
  }, 500);
  useEffect(() => {
    const staffWithCrew = [];
    // eslint-disable-next-line react/prop-types
    if (Array.isArray(staffData?.data)) {
      if (crewsData?.data !== undefined) {
        const dataCrews = crewsData.data;
        const dataStaff = staffData.data;
        for (let i = 0; i < dataStaff?.length; i++) {
          const staff = dataStaff[i];
          let foundCrew = false;
          let crewTitle = "Not in Crew";

          for (let j = 0; j < dataCrews?.length; j++) {
            const crew = dataCrews[j];
            if (crew?.employee_ids?.includes(staff._id)) {
              foundCrew = true;
              crewTitle = crew.crew_title;
              break;
            }
          }
          staffWithCrew.push({
            ...staff,
            crewTitle,
          });
        }
      }
      const filteredStaffWithCrews = staffWithCrew.filter(
        (staff) => staff.crewTitle === crewSelect
      );
      setCardData(staffWithCrew);
      if (crewSelect === undefined) {
        const { columnData, rowData } = authorsTableData(staffWithCrew);
        setColumns(columnData);
        setRows(rowData);
      } else {
        const { columnData, rowData } = authorsTableData(filteredStaffWithCrews);
        setColumns(columnData);
        setRows(rowData);
      }
    }
  }, [staffData, crewsData, crewSelect]);
  useEffect(() => {
    // if (!crewsData.data) {
    dispatch(getCrewListAction());
    // }
    // if (!staffData.data) {
    dispatch(listStaffAction({ searchQuery: "", page, limit: pageSize }));
    // }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              pt={2}
              pb={2}
              px={2}
              bgColor="black"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              justify-content="space-between"
              align-items="center"
              // width="100%"
            >
              <Grid
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: windowWidth <= 768 && "column",
                }}
              >
                <Grid
                  md={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    flexDirection: windowWidth <= 768 && "column",
                    width: "100%",
                  }}
                >
                  {/* <Grid item md={2}>
                  </Grid> */}
                  <Grid
                    item
                    md={6}
                    style={{
                      width: windowWidth <= 768 && "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: windowWidth <= 768 && "column",
                    }}
                  >
                    <MDTypography
                      variant="div"
                      color="white"
                      style={{ marginBottom: windowWidth <= 768 && "0.5rem" }}
                    >
                      Employee
                    </MDTypography>
                    <TextField
                      fullWidth
                      placeholder="search by name or email"
                      type="search"
                      style={{
                        backgroundColor: "#ffff",
                        borderRadius: "7px",
                        marginLeft: windowWidth >= 768 && "1rem",
                      }}
                      onChange={onChangeInput}
                    />
                  </Grid>
                  <Grid style={{ width: windowWidth <= 768 && "100%" }}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="combo-box-demo"
                      options={formatCrewOption}
                      onChange={(e, v) => setCrewSelect(v ? v.crew_title : undefined)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Crew"
                          style={{
                            backgroundColor: "#fff",
                            color: "black",
                            minWidth: "150px",
                            borderRadius: "5px",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid md={2} sx={{ width: "100%" }} mt={windowWidth <= 768 && "1rem"}>
                  <MDButton fullWidth variant="gradient" color="info" onClick={handleClickOpen}>
                    Add Employee
                  </MDButton>{" "}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <MDBox>
                        <MDBox
                          component="form"
                          role="form"
                          onSubmit={handleSubmit}
                          mt={2}
                          mb={3}
                          style={{ padding: "20px", display: "flex", justifyContent: "center" }}
                        >
                          <div style={{ width: "600px" }}>
                            <Grid
                              container
                              spacing={3}
                              style={{ display: "flex", justifyContent: "space-between" }}
                            >
                              <MDTypography variant="h3" fontWeight="medium" mb={2}>
                                New Employee
                              </MDTypography>
                              <MDTypography
                                variant="h2"
                                fontWeight="medium"
                                mb={2}
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Tooltip title="Close" placement="top">
                                  <CloseIcon onClick={handleClose} />
                                </Tooltip>
                              </MDTypography>{" "}
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  mb={2}
                                  mt={3}
                                  style={{ display: "flex", marginBottom: "0" }}
                                >
                                  First Name <p style={{ color: "red" }}>*</p>
                                </MDTypography>
                                <TextField
                                  fullWidth
                                  id="outlined-size-normal"
                                  onChange={(e) => {
                                    setEmployee({ ...employee, first_name: e.target.value });
                                  }}
                                  onBlur={() => handleBlur("first_name", employee.first_name)}
                                  required
                                  error={validationErrors.first_name}
                                  helperText={
                                    validationErrors.first_name
                                      ? "Please enter a valid first name."
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  mb={2}
                                  mt={3}
                                  style={{ display: "flex", marginBottom: "0" }}
                                >
                                  Last Name <p style={{ color: "red" }}>*</p>
                                </MDTypography>
                                <TextField
                                  fullWidth
                                  onChange={(e) => {
                                    setEmployee({ ...employee, last_name: e.target.value });
                                  }}
                                  required
                                  onBlur={() => handleBlur("last_name", employee.last_name)}
                                  error={validationErrors.last_name}
                                  helperText={
                                    validationErrors.last_name
                                      ? "Please enter a valid last name."
                                      : ""
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  mb={2}
                                  mt={3}
                                  style={{ display: "flex", marginBottom: "0" }}
                                >
                                  Email Address<p style={{ color: "red" }}>*</p>
                                </MDTypography>
                                <TextField
                                  fullWidth
                                  onChange={(e) => {
                                    setEmployee({ ...employee, email: e.target.value });
                                  }}
                                  onBlur={() => handleBlur("email", employee.email)}
                                  required
                                  error={validationErrors.email}
                                  helperText={
                                    validationErrors.email
                                      ? "Please enter a valid email address."
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  mb={2}
                                  mt={3}
                                  style={{ display: "flex", marginBottom: "0" }}
                                >
                                  Employee Type<p style={{ color: "red" }}>*</p>
                                </MDTypography>

                                <Autocomplete
                                  style={{ height: "40%" }}
                                  fullWidth
                                  onChange={(event, newValue) => {
                                    setEmployee({ ...employee, role_name: newValue?.value || "" });
                                  }}
                                  required
                                  options={employeeTypeOptions}
                                  getOptionLabel={(option) => option.label}
                                  renderInput={(params) => (
                                    <TextField {...params} placeholder="Select employee type" />
                                  )}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  mb={2}
                                  mt={3}
                                  style={{ display: "flex", marginBottom: "0" }}
                                >
                                  Phone Number <p style={{ color: "red" }}>*</p>
                                </MDTypography>
                                <TextField
                                  fullWidth
                                  onChange={(e) => {
                                    setEmployee({ ...employee, phone: e.target.value });
                                  }}
                                  onBlur={() => handleBlur("phone", employee.phone)}
                                  required
                                  error={validationErrors.phone}
                                  helperText={
                                    validationErrors.phone
                                      ? "Please enter a valid phone number with at least 10 digits."
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  mb={2}
                                  mt={3}
                                  style={{ display: "flex", marginBottom: "0" }}
                                >
                                  Password <p style={{ color: "red" }}>*</p>
                                </MDTypography>
                                <TextField
                                  type="password"
                                  fullWidth
                                  onChange={(e) => {
                                    setEmployee({ ...employee, password: e.target.value });
                                  }}
                                  onBlur={() => handleBlur("password", employee.password)}
                                  required
                                  error={validationErrors.password}
                                  helperText={
                                    validationErrors.password
                                      ? "Password should be at least 4 characters long."
                                      : ""
                                  }
                                />
                              </Grid>
                            </Grid>
                            <br />
                            <Grid
                              container
                              spacing={3}
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              <MDButton
                                variant="gradient"
                                color="info"
                                style={{ marginTop: "24px" }}
                                type="submit"
                              >
                                Save
                              </MDButton>
                            </Grid>

                            <ToastContainer style={{ fontSize: "15px" }} />
                          </div>
                        </MDBox>
                      </MDBox>
                    </DialogContent>
                  </Dialog>
                </Grid>
              </Grid>
            </MDBox>
            {staffLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomSpinner />
              </div>
            ) : (
              <MDBox pt={3}>
                {windowWidth >= 768 ? (
                  <DataTable
                    table={{ columns: columns || [], rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    rowData={staffData}
                    onRowClick={(id) => {}}
                  />
                ) : (
                  <div>
                    {cardData?.map((row) => (
                      <MDBox p="8px 16px 16px">
                        <Card
                          style={{
                            padding: "3%",
                            border: "1px solid black",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                marginRight: "5px",
                              }}
                            >
                              <Actions id={row?._id} employees={row} />
                            </div>
                            <MDTypography
                              display="flex"
                              alignItems="center"
                              variant="h6"
                              fontWeight="medium"
                            >
                              {row.first_name} {row.last_name}
                            </MDTypography>
                            <MDTypography
                              display="block"
                              variant="button"
                              fontWeight="medium"
                              color="text"
                              fontSize=".70rem"
                            >
                              {row.email}
                            </MDTypography>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div>
                                <MDTypography
                                  style={{ fontSize: "12px", marginTop: "1rem" }}
                                  display="flex"
                                  alignItems="center"
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  CREW(S)
                                </MDTypography>
                                <MDTypography
                                  display="block"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                >
                                  {row?.crewTitle}
                                </MDTypography>
                              </div>
                              <div>
                                <MDTypography
                                  style={{ fontSize: "12px", marginTop: "1rem" }}
                                  display="flex"
                                  alignItems="center"
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  ROLE
                                </MDTypography>
                                <MDTypography
                                  display="block"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                >
                                  {/* {row?.role_name}
                                   */}
                                  {getRoleLabel(row?.role_name)}
                                </MDTypography>
                              </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <StatusAction status={row?.status} obj={row} />
                            </div>
                          </div>
                        </Card>
                      </MDBox>
                    ))}
                  </div>
                )}
                <CustomTablePagination
                  count={staffData?.totalPages}
                  page={page}
                  onChangePage={handleChange}
                  value={pageSize}
                  onChangeRowPerPage={handleChangePageSize}
                  pageSizeOptions={[10, 20, 30, 40, 50]}
                />
              </MDBox>
            )}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Staff;
