export const API_ERROR = "LOGIN_API_ERROR";
export const SET_LOADING = "SET_LOADING";

export const LIST_CUSTOMERS = "LIST_CUSTOMERS";
export const LIST_CUSTOMERS_SUCCESS = "LIST_CUSTOMERS_SUCCESS";
export const LIST_CUSTOMERS_FAILED = "LIST_CUSTOMERS_FAILED";

export const LIST_CUSTOMERS_MODULE = "LIST_CUSTOMERS_MODULE";
export const LIST_CUSTOMERS_SUCCESS_MODULE = "LIST_CUSTOMERS_SUCCESS_MODULE";
export const LIST_CUSTOMERS_FAILED_MODULE = "LIST_CUSTOMERS_FAILED_MODULE";

export const CREATE_CUSTOMERS = "CREATE_CUSTOMERS";
export const CREATE_CUSTOMERS_SUCCESS = "CREATE_CUSTOMERS_SUCCESS";
export const CREATE_CUSTOMERS_FAILED = "CREATE_CUSTOMERS_FAILED";

export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILED = "GET_CUSTOMER_FAILED";

export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_FAILED = "EDIT_CUSTOMER_FAILED";

export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
export const SEARCH_CUSTOMER_SUCCESS = "SEARCH_CUSTOMER_SUCCESS";
export const SEARCH_CUSTOMER_FAILED = "SEARCH_CUSTOMER_FAILED";

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILED = "SEARCH_CUSTOMER_FAILED";
