/* eslint-disable */
// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// @mui material components
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import Services from "./services/Services";
import { useEffect, useState } from "react";
import Citites from "./citites/Citites";
import Route from "./route/Route";
import { useLocation, useNavigate } from "react-router-dom";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import MDTypography from "components/MDTypography";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Settings() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState(location.state?.tab || "cities");

  useEffect(() => {
    if (location.state?.tab) {
      const { tab, ...updatedState } = location.state;
      setTab(tab);
      navigate({ ...location, state: updatedState });
    }
  }, [location.state, navigate]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <DashboardLayout>
      {" "}
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <MDTypography variant="h2">Route Management</MDTypography>
        <SideNavActionBtn />
      </MDBox>
      <TabContext mt={3} value={tab}>
        <MDBox>
          <Tabs
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                transition: "none",
              },
            }}
            value={tab}
          >
            <Tab
              sx={{
                backgroundColor: tab === "cities" ? "#333745" : "white",
              }}
              label="Cities"
              value={"cities"}
            />{" "}
            <Tab
              sx={{
                backgroundColor: tab === "routes" ? "#333745" : "white",
              }}
              label="Routes"
              value={"routes"}
            />{" "}
            <Tab
              sx={{
                backgroundColor: tab === "services" ? "#333745" : "white",
              }}
              label="Services"
              value={"services"}
            />{" "}
          </Tabs>{" "}
        </MDBox>{" "}
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={0}
              direction="column"
              style={{
                minHeight: "77vh",
                minWidth: "70%",
              }}
            >
              <TabPanel value={"services"} style={{ padding: "0" }}>
                <Services tab={tab} />
              </TabPanel>{" "}
              <TabPanel value={"routes"} style={{ padding: "0" }}>
                {/* <MDBox mt={2} mb={3}> */}
                <Route />
                {/* </MDBox>{" "} */}
              </TabPanel>{" "}
              <TabPanel value={"cities"} style={{ padding: "0", width: "100%" }}>
                <Citites />
              </TabPanel>{" "}
            </Grid>{" "}
          </Grid>{" "}
        </Grid>{" "}
      </TabContext>{" "}
    </DashboardLayout>
  );
}

export default Settings;
