/* eslint-disable */

import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import API from "services/axiosapi";
import * as actionTypes from "./actionTypes";

function* fetchServices() {
  try {
    yield put({
      type: actionTypes.SET_ROUTE_MANAGEMENT_LOADING,
    });
    const { data } = yield API.get("api/services");
    if (data.success) {
      yield put({
        type: actionTypes.LIST_SERVICES_SUCCESS,
        payload: data,
      });
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.LIST_SERVICES_FAILED,
        errorMessage: "Unable to Fetch data!",
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: actionTypes.LIST_SERVICES_FAILED,
      errorMessage: "Unable to Fetch data!",
    });
  }
}
function* addService({ obj }) {
  try {
    yield put({ type: actionTypes.SET_ROUTE_MANAGEMENT_LOADING });
    const { data } = yield API.post("api/services", { ...obj });
    if (data.success) {
      toast.success(data.message);
      yield put({ type: actionTypes.ADD_SERVICES_SUCCESS });
      yield put({ type: actionTypes.LIST_SERVICES, path: "services" });
    } else {
      toast.error(data.message);
      yield put({ type: actionTypes.ADD_SERVICES_FAILED, errorMessage: "Unable to add Service!" });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({ type: actionTypes.ADD_SERVICES_FAILED, errorMessage: "Unable to add Service!" });
  }
}

function* deleteService(id) {
  try {
    yield put({ type: actionTypes.SET_ROUTE_MANAGEMENT_LOADING });
    const { data } = yield API.delete(`/api/services/${id.Id.id}`);
    if (data.success) {
      toast.success(data.message);
      yield put({ type: actionTypes.DELETE_SERVICES_SUCCESS });
      yield put({ type: actionTypes.LIST_SERVICES, path: "services" });
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.DELETE_SERVICES_FAILED,
        errorMessage: "Unable to delete Service",
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: actionTypes.DELETE_SERVICES_FAILED,
      errorMessage: "Unable to delete Service",
    });
  }
}

function* editServiceSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_ROUTE_MANAGEMENT_LOADING });
    const { data } = yield API.put(`/api/services/${obj._id}/update`, obj);
    if (data.success) {
      yield put({ type: actionTypes.EDIT_SERVICES_SUCCESS });
      toast.success("Service updated successfully!");
      yield put({ type: actionTypes.LIST_SERVICES, path: "services" });
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.EDIT_SERVICES_FAILED,
        errorMessage: "Unable to edit Service",
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: actionTypes.EDIT_SERVICES_FAILED,
      errorMessage: "Unable to edit Service",
    });
  }
}

function* getServiceSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_ROUTE_MANAGEMENT_LOADING });
    if (obj._id) {
      yield put({ type: actionTypes.GET_SERVICE_SUCCESS, payload: obj });
    } else {
      toast.error("Unable to edit Service");
      yield put({
        type: actionTypes.GET_SERVICE_FAILED,
        errorMessage: "Unable to get Service",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SERVICE_FAILED,
      errorMessage: "Unable to edit Service",
    });
  }
}

function* ServicesSaga() {
  yield all([
    yield takeLatest(actionTypes.LIST_SERVICES, fetchServices),
    yield takeLatest(actionTypes.ADD_SERVICES, addService),
    yield takeLatest(actionTypes.DELETE_SERVICES, deleteService),
    yield takeLatest(actionTypes.EDIT_SERVICES, editServiceSaga),
    yield takeLatest(actionTypes.GET_SERVICE, getServiceSaga),
  ]);
}

export default ServicesSaga;
