/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentListAction, clearSingleDataAction } from "redux/Appointment/actions";
import MDTypography from "components/MDTypography";
import CustomSpinner from "examples/CustomLoader";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { updateAppointmentNoteAction } from "redux/Dashboard/actions";
import StatusChip from "./StatusChip";
import PropertyButton from "./PropertyButton";
import EditAppointment from "../upcomingAppointments/EditAppointment";

function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

function TodaysAppointments() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, appointmentData } = useSelector((state) => state.AppointmentReducer);
  const [finalData, setFinalData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [note, setNote] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const today = new Date().toISOString().slice(0, 10);
  useEffect(() => {
    dispatch(getAppointmentListAction());
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (appointmentData?.data?.length > 0) {
      const { data } = appointmentData;
      const result = data.reduce((acc, { crew_id, ...rest }) => {
        if (crew_id && crew_id.crew_title) {
          const { crew_title } = crew_id;
          const existingCrew = acc.find((item) => item?.crew_id?.crew_title === crew_title);
          if (existingCrew) {
            existingCrew.appointmentData.push(rest);
          } else {
            acc.push({ crew_id, crewName: crew_title, appointmentData: [rest] });
          }
        }
        return acc;
      }, []);
      setFinalData(result);
    }
  }, [appointmentData]);
  function formatTimeSlot(timeSlot) {
    if (!timeSlot) {
      return "";
    }
    const [startTime, endTime] = timeSlot.split(" to ");
    const [startHours, startMinutes] = startTime.split(":");
    const [endHours, endMinutes] = endTime.split(":");

    const startTotalMinutes = parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);
    const endTotalMinutes = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10);

    const timeDifference = endTotalMinutes - startTotalMinutes;

    if (timeDifference === 30) {
      const startHour = parseInt(startHours, 10) % 12 || 12;
      const endHour = parseInt(endHours, 10) % 12 || 12;

      const startPeriod = parseInt(startHours, 10) < 12 ? "AM" : "PM";
      const endPeriod = parseInt(endHours, 10) < 12 ? "AM" : "PM";

      const formattedStartTime = `${startHour}${
        startMinutes === "00" ? "" : `:${startMinutes}`
      } ${startPeriod}`;
      const formattedEndTime = `${endHour}${
        endMinutes === "00" ? "" : `:${endMinutes}`
      } ${endPeriod}`;

      return `${formattedStartTime} to ${formattedEndTime}`;
    }

    const startHour = parseInt(startTime, 10) % 12 || 12;
    const endHour = parseInt(endTime, 10) % 12 || 12;

    const startPeriod = parseInt(startTime, 10) < 12 ? "AM" : "PM";
    const endPeriod = parseInt(endTime, 10) < 12 ? "AM" : "PM";

    const formattedStartTime = `${startHour}${
      startMinutes === "00" ? "" : `:${startMinutes}`
    } ${startPeriod}`;
    const formattedEndTime = `${endHour}${
      endMinutes === "00" ? "" : `:${endMinutes}`
    } ${endPeriod}`;

    return `${formattedStartTime} to ${formattedEndTime}`;
  }
  useEffect(() => {
    dispatch(clearSingleDataAction());
  }, []);
  // ********************
  useEffect(() => {
    if (finalData.length > 0) {
      const newData = finalData[0]?.appointmentData.filter((data) => data.date === today);
      if (newData && newData.length > 0) {
        setNote(newData[0].note);
      } else {
        setNote("");
      }
    } else {
      setNote("");
    }
  }, [finalData, today]);

  const handleSubmit = (row, crew_id) => {
    // e.preventDefault();
    dispatch(
      updateAppointmentNoteAction({
        dataSet: {
          customer_id: row.customer_id._id,
          crew_id: crew_id._id,
          service_type_id: row.service_type_id._id,
          time_window: row.time_window,
          date: row.date,
          slot: row.slot,
          note,
        },
        id: row._id,
      })
    );
  };
  const handleBlur = () => {
    setTimeout(() => {
      setShowUpdateBtn(false);
    }, 200);
  };
  // *********************

  return (
    <MDBox style={{ width: "100%" }}>
      {loading ? (
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CustomSpinner />
        </MDBox>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {appointmentData?.data <= 0 ? (
            <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
              <img
                style={{ height: "50%", width: "50%", objectFit: "contain" }}
                src="https://img.freepik.com/free-vector/empty-concept-illustration_114360-1253.jpg?w=2000"
                loading="lazy"
                alt=""
              />
            </MDBox>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {finalData?.map((item, index) => {
                const todayData = item.appointmentData.filter((data) => data.date === today);
                if (todayData.length > 0) {
                  return (
                    <MDBox key={index._id}>
                      <MDTypography style={{ color: "gray" }} p={2}>
                        {item.crewName}
                      </MDTypography>
                      {windowWidth >= 768 ? (
                        <TableContainer style={{ boxShadow: "none" }}>
                          <Table>
                            <TableRow style={{ borderBottom: "solid 1px #cbcbcb" }}>
                              <TableCell
                                style={{
                                  minWidth: "120px",
                                  fontSize: "0.65rem",
                                  fontWeight: "700",
                                  color: "#7b809a",
                                }}
                              >
                                ROUTE
                              </TableCell>
                              <TableCell
                                style={{
                                  minWidth: "120px",
                                  fontSize: "0.65rem",
                                  fontWeight: "700",
                                  color: "#7b809a",
                                }}
                              >
                                DATE
                              </TableCell>
                              <TableCell
                                style={{
                                  minWidth: "120px",
                                  fontSize: "0.65rem",
                                  fontWeight: "700",
                                  color: "#7b809a",
                                }}
                              >
                                TIME
                              </TableCell>
                              <TableCell
                                style={{
                                  minWidth: "120px",
                                  fontSize: "0.65rem",
                                  fontWeight: "700",
                                  color: "#7b809a",
                                }}
                              >
                                SERVICE ADDRESS
                              </TableCell>
                              <TableCell
                                style={{
                                  minWidth: "120px",
                                  fontSize: "0.65rem",
                                  fontWeight: "700",
                                  color: "#7b809a",
                                }}
                              >
                                SERVICE TYPE
                              </TableCell>
                              <TableCell
                                style={{
                                  minWidth: "120px",
                                  fontSize: "0.65rem",
                                  fontWeight: "700",
                                  color: "#7b809a",
                                }}
                              >
                                JOB STATUS
                              </TableCell>
                              <TableCell
                                style={{
                                  minWidth: "120px",
                                  fontSize: "0.65rem",
                                  fontWeight: "700",
                                  color: "#7b809a",
                                }}
                              >
                                CUSTOMER
                              </TableCell>
                            </TableRow>
                            <TableBody>
                              {todayData.map((row) => (
                                <>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#344767",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {row.routeName}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#344767",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <EditAppointment
                                        id={row._id}
                                        text={dateFormatted(row.date)}
                                        color="#344767"
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#344767",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <EditAppointment
                                        id={row._id}
                                        text={formatTimeSlot(row.slot)}
                                        color="#344767"
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#344767",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <PropertyButton
                                        id={row.customer_id._id}
                                        text={`${row.customer_id.property.address} ${row.customer_id.property.city}, ${row.customer_id.property.state} ${row.customer_id.property.zip}, `}
                                        color="#344767"
                                      />
                                      {/* {row.customer_id.property.address}{" "}
                                      {row.customer_id.property.city},{" "}
                                      {row.customer_id.property.state}{" "}
                                      {row.customer_id.property.zip} */}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#344767",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <EditAppointment
                                        id={row._id}
                                        text={row.serviceType}
                                        color="#344767"
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#344767",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <StatusChip status={row.appointment_status} id={row._id} />
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#344767",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <PropertyButton
                                        id={row.customer_id._id}
                                        text={`${row.customer_id.first_name} ${row.customer_id.last_name} `}
                                        color="blue"
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ padding: "0px 1rem" }} colSpan={7}>
                                      <MDBox
                                        style={{
                                          border: "solid 1px #cbcbcb",
                                          padding: "5px",
                                          marginBottom: "1rem",
                                          // backgroundColor: "#f0f2f5",
                                        }}
                                      >
                                        <MDTypography
                                          style={{
                                            minWidth: "120px",
                                            fontSize: "0.65rem",
                                            fontWeight: "600",
                                            color: "#7b809a",
                                          }}
                                        >
                                          Note
                                        </MDTypography>
                                        {/* <MDTypography
                                          style={{
                                            fontSize: "0.875rem",
                                            color: "#344767",
                                            fontWeight: "600",
                                            padding: "0 1rem",
                                          }}
                                        >
                                          {row.note[row.note.length - 1]}
                                        </MDTypography> */}

                                        <MDBox>
                                          <MDBox>
                                            <TextField
                                              fullWidth
                                              id="filled-multiline-static"
                                              multiline
                                              rows={3}
                                              value={note}
                                              onChange={(e) => setNote(e.target.value)}
                                              fontSize={30}
                                              onFocus={() => {
                                                setShowUpdateBtn(true);
                                              }}
                                              onBlur={handleBlur}
                                            />
                                          </MDBox>
                                          {showUpdateBtn && (
                                            <MDBox paddingTop={1}>
                                              <MDButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                  handleSubmit(row, item.crew_id);
                                                }}
                                              >
                                                Update Note
                                              </MDButton>
                                            </MDBox>
                                          )}
                                        </MDBox>
                                      </MDBox>
                                    </TableCell>
                                    <TableCell />
                                  </TableRow>
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <div style={{ padding: "8px 16px 16px" }}>
                          {todayData.map((row) => (
                            <MDBox mb={3}>
                              <Card
                                style={{
                                  padding: "3%",
                                  border: "1px solid black",
                                }}
                              >
                                <div>
                                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                      <MDTypography
                                        style={{ fontSize: "12px" }}
                                        display="flex"
                                        alignItems="center"
                                        variant="h6"
                                        fontWeight="medium"
                                      >
                                        ROUTE
                                      </MDTypography>
                                      <MDTypography
                                        display="flex"
                                        alignItems="center"
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                        fontSize=".70rem"
                                      >
                                        {row.routeName}
                                      </MDTypography>
                                    </div>
                                    <div>
                                      <MDTypography
                                        style={{ fontSize: "12px" }}
                                        display="flex"
                                        alignItems="center"
                                        variant="h6"
                                        fontWeight="medium"
                                      >
                                        DATE-TIME
                                      </MDTypography>
                                      <MDTypography
                                        display="flex"
                                        alignItems="center"
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                        fontSize=".70rem"
                                      >
                                        <EditAppointment
                                          id={row._id}
                                          text={dateFormatted(row.date)}
                                          color="#344767"
                                        />
                                        <br />

                                        <EditAppointment
                                          id={row._id}
                                          text={formatTimeSlot(row.slot)}
                                          color="#344767"
                                        />
                                      </MDTypography>
                                    </div>
                                  </div>
                                  <MDTypography
                                    style={{ marginTop: "1rem", fontWeight: "400" }}
                                    display="flex"
                                    alignItems="center"
                                    variant="h6"
                                    fontWeight="medium"
                                    onClick={() => navigate(`/profile/${row.customer_id._id}`)}
                                  >
                                    <PropertyButton
                                      id={row.customer_id._id}
                                      text={`${row.customer_id.first_name} ${row.customer_id.last_name} `}
                                      color="blue"
                                    />
                                  </MDTypography>
                                  <MDTypography
                                    style={{ fontSize: "12px" }}
                                    display="flex"
                                    alignItems="center"
                                    variant="h6"
                                    fontWeight="medium"
                                  >
                                    SERVICE ADDRESS
                                  </MDTypography>
                                  <MDTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="medium"
                                    color="text"
                                    fontSize=".70rem"
                                  >
                                    <PropertyButton
                                      id={row.customer_id._id}
                                      text={`${row.customer_id.property.address} ${row.customer_id.property.city}, ${row.customer_id.property.state} ${row.customer_id.property.zip}, `}
                                      color="#344767"
                                    />
                                  </MDTypography>
                                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                      <MDTypography
                                        style={{ fontSize: "12px", marginTop: "1rem" }}
                                        display="flex"
                                        alignItems="center"
                                        variant="h6"
                                        fontWeight="medium"
                                      >
                                        SERVICE TYPE
                                      </MDTypography>
                                      <MDTypography
                                        display="block"
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                        fontSize=".70rem"
                                      >
                                        <EditAppointment
                                          id={row._id}
                                          text={row.serviceType}
                                          color="#344767"
                                        />
                                      </MDTypography>
                                    </div>
                                    <div>
                                      <MDTypography
                                        style={{ fontSize: "12px", marginTop: "1rem" }}
                                        display="flex"
                                        alignItems="center"
                                        variant="h6"
                                        fontWeight="medium"
                                      >
                                        JOB STATUS
                                      </MDTypography>
                                      <StatusChip
                                        isCard
                                        status={row.appointment_status}
                                        id={row._id}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <MDTypography
                                  style={{ fontSize: "12px", marginTop: "1rem" }}
                                  display="flex"
                                  alignItems="center"
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  NOTE
                                </MDTypography>

                                <MDBox>
                                  <MDBox>
                                    <TextField
                                      fullWidth
                                      id="filled-multiline-static"
                                      multiline
                                      rows={3}
                                      value={note}
                                      onChange={(e) => setNote(e.target.value)}
                                      fontSize={30}
                                      onFocus={() => {
                                        setShowUpdateBtn(true);
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </MDBox>
                                  {showUpdateBtn && (
                                    <MDBox paddingTop={1}>
                                      <MDButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          handleSubmit(row, item.crew_id);
                                        }}
                                      >
                                        Update Note
                                      </MDButton>
                                    </MDBox>
                                  )}
                                </MDBox>
                              </Card>
                            </MDBox>
                          ))}
                        </div>
                      )}
                    </MDBox>
                  );
                }

                return (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <img
                      style={{ height: "50%", width: "50%", objectFit: "contain" }}
                      src="https://img.freepik.com/free-vector/empty-concept-illustration_114360-1253.jpg?w=2000"
                      loading="lazy"
                      alt=""
                    />
                  </MDBox>
                );
              })}
            </>
          )}
        </>
      )}
    </MDBox>
  );
}

export default TodaysAppointments;
