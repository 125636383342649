/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import {
  // Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { sendInvoicAction } from "redux/invoices/actions";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function SentInvoiceDialog({ setOpen, open, singleInvoice, setSuccessPop }) {
  const dispatch = useDispatch();
  const [sendDetails, setSendDetails] = useState({});
  const [toNumber, setToNumber] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const labelAndInput = {
    display: "flex",
    justifyContent: "space-between",
  };
  const textStyle = { width: "60%", marginRight: "20%" };
  if (windowWidth <= 768) {
    labelAndInput.flexDirection = "column";
    textStyle.width = "100%";
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (toNumber.length) {
      dispatch(
        sendInvoicAction({
          obj: {
            ...sendDetails,
            invoice_id: singleInvoice?._id,
            number: [toNumber],
            email: singleInvoice?.customer_id?.email,
          },
          id: singleInvoice?._id,
        })
      );
      setSuccessPop(true);
      setOpen(false);
    } else {
      toast.error("Customer has no phone number!");
    }
  };
  useEffect(() => {
    if (singleInvoice?.customer_id?.phone.length > 0) {
      const number = singleInvoice?.customer_id?.phone;
      const formatedNumber = number.replace(/[^\d+]/g, "");
      if (formatedNumber.startsWith("+91")) {
        setToNumber(formatedNumber);
      } else {
        setToNumber(`+91${formatedNumber}`);
      }
    }
  }, [singleInvoice?.customer_id]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <MDBox>
      <BootstrapDialog
        fullWidth
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          Send this invoice
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <MDBox component="form" role="form" onSubmit={handleSubmit} padding="4%">
            <MDBox style={labelAndInput}>
              <MDTypography>From</MDTypography>
              <TextField
                sx={textStyle}
                id="outlined-basic"
                variant="outlined"
                value="Make it rain"
              />
            </MDBox>
            <br />
            <MDBox style={labelAndInput}>
              <MDTypography>To</MDTypography>
              <TextField
                sx={textStyle}
                id="outlined-basic"
                variant="outlined"
                value={`${singleInvoice?.customer_id?.phone} - ${singleInvoice?.customer_id?.first_name} ${singleInvoice.customer_id?.last_name}`}
              />
              {/* <Autocomplete
                sx={{ width: "60%", marginRight: "20%" }}
                disablePortal
                id="combo-box-demo"
                options={customerDetails}
                onChange={(e, v) => {
                  v !== null && setSendDetails({ ...sendDetails, number: v.phone, email: v.email });
                }}
                renderInput={(params) => <TextField label="Customer" required {...params} />}
              /> */}
            </MDBox>
            <br />
            <MDBox style={labelAndInput}>
              <MDTypography>Subject</MDTypography>
              <TextField
                sx={textStyle}
                id="outlined-basic"
                variant="outlined"
                required
                onChange={(e) => setSendDetails({ ...sendDetails, subject: e.target.value })}
              />
            </MDBox>
            <br />
            <MDBox style={labelAndInput}>
              <MDTypography>Message</MDTypography>
              <TextField
                multiline
                sx={textStyle}
                id="outlined-multiline-static"
                variant="outlined"
                rows={6}
                onChange={(e) => setSendDetails({ ...sendDetails, message: e.target.value })}
                required
              />
            </MDBox>
            <br />
            <MDBox style={labelAndInput}>
              <MDTypography />
              <MDBox
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    disabled
                    control={<Checkbox defaultChecked />}
                    title="Already selected this city code!"
                  />
                </FormGroup>
                <span style={{ color: "#4a4a4a" }}>
                  Send a copy to myself at makeitrainct@gmail.com
                </span>
              </MDBox>
            </MDBox>
            <br />
            <MDBox display="flex" justifyContent="space-around" gap="5px">
              <MDButton variant="outlined" color="dark" onClick={() => setOpen(false)}>
                Cancel
              </MDButton>
              <MDBox display="flex" gap="5px">
                <MDButton
                  fullWidth
                  variant="outlined"
                  color="dark"
                  onClick={() => window.open(`/invoice/view/${singleInvoice._id}`, "_blank")}
                >
                  Preview as customer
                </MDButton>
                <MDButton color="dark" type="submit">
                  Send
                </MDButton>
              </MDBox>
            </MDBox>
            <br />
            <br />
          </MDBox>
        </DialogContent>
      </BootstrapDialog>
    </MDBox>
  );
}

export default SentInvoiceDialog;
