/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { Button, Dialog, DialogContent, Grid, TextField, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStaffAction } from "redux/Staff/actions";

// eslint-disable-next-line react/prop-types
function ViewProfile({ first_name, last_name, id }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const { singleStaffData } = useSelector((state) => state.StaffReducer);
  const handleView = () => {
    setOpen(true);
    dispatch(getStaffAction(id));
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MDTypography display="block" variant="button" fontWeight="medium">
      <Tooltip title="Click here" placement="top">
        <Button onClick={handleView} style={{ color: "#344767" }}>
          <u>
            {first_name} {last_name}
          </u>
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <MDBox>
            <MDBox
              component="form"
              role="form"
              mt={2}
              mb={3}
              style={{ padding: "20px", display: "flex", justifyContent: "center" }}
            >
              <div>
                <Grid
                  container
                  spacing={3}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MDTypography variant="h3" fontWeight="medium" mb={2}>
                    Employee Profile
                  </MDTypography>
                  <MDTypography
                    variant="h2"
                    fontWeight="medium"
                    mb={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Tooltip title="Close" placement="top">
                      <CloseIcon onClick={handleClose} />
                    </Tooltip>
                  </MDTypography>{" "}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      mb={2}
                      mt={3}
                      style={{ display: "flex", marginBottom: "0" }}
                    >
                      First Name <p style={{ color: "red" }}>*</p>
                    </MDTypography>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      value={singleStaffData.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      mb={2}
                      mt={3}
                      style={{ display: "flex", marginBottom: "0" }}
                    >
                      Last Name <p style={{ color: "red" }}>*</p>
                    </MDTypography>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      value={singleStaffData.last_name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      mb={2}
                      mt={3}
                      style={{ display: "flex", marginBottom: "0" }}
                    >
                      Email Address<p style={{ color: "red" }}>*</p>
                    </MDTypography>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      value={singleStaffData.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      mb={2}
                      mt={3}
                      style={{ display: "flex", marginBottom: "0" }}
                    >
                      Employee Type<p style={{ color: "red" }}>*</p>
                    </MDTypography>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      value={singleStaffData.role_name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      mb={2}
                      mt={3}
                      style={{ display: "flex", marginBottom: "0" }}
                    >
                      Phone Number
                    </MDTypography>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      value={singleStaffData.phone}
                    />
                  </Grid>
                </Grid>
              </div>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </MDTypography>
  );
}

export default ViewProfile;
