/* eslint-disable */
import { useRef, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ButtonBase from "@mui/material/ButtonBase";
import MDButton from "components/MDButton";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const FileInput = ({ label, onChange, error }) => {
  const ref = useRef();
  const theme = useTheme();
  const classes = useStyles();
  const [attachment, setAttachment] = useState(null);

  const handleChange = (event) => {
    const files = Array.from(event.target.files);
    const [file] = files;
    setAttachment(file);
    if (!!onChange) onChange({ target: { value: event.target.files[0] } });
  };

  return (
    <Box
      position="relative"
      height={70}
      width={200}
      color={!!error ? theme.palette.error.main : theme.palette.background.paper}
    >
      <Box position="absolute" top={0} bottom={0} left={0} right={0}>
        <TextField
          // required
          variant="standard"
          margin="dense"
          disabled
          label={label}
          fullWidth
          value={attachment?.name || ""}
          error={!!error}
          style={{ display: "none" }}
          // helperText={error?.message || " "}
        />
        <label htmlFor="file-input">
          <MDButton
             variant="gradient"
            color="dark"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload Photo
          </MDButton>
        </label>
      </Box>
      <ButtonBase
        className={classes.button}
        component="label"
        onKeyDown={(e) => e.keyCode === 32 && ref.current?.click()}
      >
        <input
          ref={ref}
          type="file"
          accept=".png, .jpg, .jpeg, .pdf, .webp"
          hidden
          onChange={handleChange}
        />
      </ButtonBase>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    "& .MuiFormLabel-root.Mui-disabled": {
      color: theme.palette.text.secondary,
    },
  },
  button: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
}));

export default FileInput;
