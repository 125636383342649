import {
  CREATE_STAFF,
  DELETE_EMPLOYEE,
  GET_STAFF,
  LIST_STAFF,
  UPDATE_EMPLOYEE,
} from "./actionTypes";

// eslint-disable-next-line import/prefer-default-export
export const listStaffAction = (payload) => ({
  type: LIST_STAFF,
  payload,
});

export const getStaffAction = (id) => ({
  type: GET_STAFF,
  id,
});

export const CreateStaffAction = (obj) => ({
  type: CREATE_STAFF,
  obj,
});

export const updateEmployeeAction = (obj, id) => ({
  type: UPDATE_EMPLOYEE,
  obj,
  id,
});

export const deleteEmployeeAction = (id) => ({
  type: DELETE_EMPLOYEE,
  id,
});
