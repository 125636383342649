const {
  SET_SETTINGS_LOADING,
  LIST_HOLIDAYS_SUCCESS,
  LIST_HOLIDAYS_FAILED,
  WEEK_OFF_LIST_SUCCESS,
  WEEK_OFF_LIST_FAILED,
  DELETE_CREW_SUCCESS,
  SEARCH_STAFF_SUCCESS,
  ADD_HOLIDAYS_SUCCESS,
  ADD_HOLIDAYS_FAILED,
  DELETE_HOLIDAYS_SUCCESS,
  DELETE_HOLIDAYS_FAILED,
} = require("./actionTypes");

const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  settingsData: [],
  weekOffData: {},
};
const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case LIST_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        settingsData: action.payload,
      };
    case LIST_HOLIDAYS_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    case ADD_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ADD_HOLIDAYS_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case DELETE_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case WEEK_OFF_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        weekOffData: action.payload,
      };
    case DELETE_HOLIDAYS_FAILED:
    case WEEK_OFF_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    case DELETE_CREW_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case SEARCH_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        settingsData: action.payload,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
