/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import List from "layouts/customer/list";
import ComingSoon from "layouts/coming-soon";
import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/customer/profile";
import Create from "layouts/customer/profile/Create";
  
import Login from "layouts/authentication/login";
import Forget from "layouts/authentication/forget-password";
import ResetPassword from "layouts/authentication/reset-password/cover";

// @mui icons
import Icon from "@mui/material/Icon";
import Settings from "layouts/settings";
import RouteManagement from "layouts/routeManagement";
import AddAndEditRoutes from "layouts/routeManagement/route/AddAndEditRoutes";
import Appointments from "layouts/appointments/Appointments";
import Invoices from "layouts/invoices/Invoices";
import AddAndEditAppointments from "layouts/appointments/addAppointment/AddAndEditAppointments";
import AddAndEditInvoice from "layouts/invoices/addAndEditInvoice/AddAndEditInvoice";
import ViewInvoice from "layouts/invoices/viewInvoice/ViewInvoice";
import PublicInvoiceView from "layouts/invoices/publicInvoiceView/PublicInvoiceView";
import PaymentSuccess from "layouts/invoices/paymentStatusPage/PaymentSuccess";
import PaymentFiled from "layouts/invoices/paymentStatusPage/PaymentFiled";
import Messages from "layouts/messages/Messages";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/customers",
    component: <List />,
  },
  {
    key: "profile",
    route: "/profile/:id",
    component: <Profile />,
  },
  {
    key: "create_profile",
    route: "/create-customer",
    component: <Create />,
  },
  {
    key: "coming_soon",
    route: "/coming-soon",
    component: <ComingSoon />,
  },
  {
    type: "collapse",
    name: "Appointments",
    key: "appointment",
    icon: <Icon fontSize="small">calendar_month</Icon>,
    route: "/appointment",
    component: <Appointments />
  },
  {
    key: "create_appointment",
    route: "/appointment/create-appointment",
    component: <AddAndEditAppointments />,
  }, 
  {
    key: "edit_appointment",
    route: "/appointment/edit-appointment/:id",
    component: <AddAndEditAppointments />,
  },
  {
    type: "collapse",
    name: "Messages",
    key: "messages",
    icon: <Icon fontSize="small">message</Icon>,
    route: "/messages",
    component: <Messages />,
  },
  {
    type: "collapse",
    name: "Invoices",
    key: "invoices",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/invoices",
    component: <Invoices />,
  },
  {
    key: "create_invoice",
    route: "/create_invoice",
    component: <AddAndEditInvoice />
  },
  {
    key: "edit_invoice",
    route: "/edit_invoice/:id",
    component: <AddAndEditInvoice />
  },
  {
    key: "view_invoice",
    route: "/view_invoice/:id",
    component: <ViewInvoice />
  },
  {
    key: "invoice_view",
    route: "/invoice/view/:id",
    component: <PublicInvoiceView />
  },
  {
    key: "check_out_success",
    route: "/payment/success/:invoice_id",
    component: <PaymentSuccess />
  },
  {
    key: "check_out_success",
    route: "/payment/failed/:invoice_id",
    component: <PaymentFiled />
  },
  {
    type: "collapse",
    name: "Route Management",
    key: "route-management",
    icon: <Icon fontSize="small">route</Icon>,
    route: "/route-management",
    component: <RouteManagement />,
  },
  {
    key: "create_routes",
    route: "/create-routes",
    component: <AddAndEditRoutes />,
  },
  {
    key: "edit_routes",
    route: "/create-routes/:id",
    component: <AddAndEditRoutes />
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Settings />,
  },
  {
    key: "login",
    route: "/login",
    component: <Login />,
  },

  {
    key: "forget",
    route: "/forget",
    component: <Forget />,
  },

  {
    key: "reset",
    route: "/reset",
    component: <ResetPassword />,
  },
];

export default routes;
