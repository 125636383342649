/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import React from "react";
import { useNavigate } from "react-router-dom";

function Outstanding({ data }) {
  const navigate = useNavigate();
  return (
    <Tooltip title="Check Invoice" placement="top">
      <MDTypography
        onClick={() => navigate(`/profile/${data?._id}`, { state: { tab: 1 } })}
        display="block"
        variant="button"
        fontWeight="medium"
        style={{ cursor: "pointer" }}
      >
        <u>${data?.balance || 0}</u>
      </MDTypography>
    </Tooltip>
  );
}

export default Outstanding;
