/* eslint-disable import/prefer-default-export */
import { GET_MESSAGES, POST_MESSAGE } from "./actionTypes";

export const getSendMessageAction = (payload) => ({
  type: GET_MESSAGES,
  payload,
});

export const postMessageAction = (obj) => ({
  type: POST_MESSAGE,
  obj,
});
