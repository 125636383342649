import { Card, Grid, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import authorsTableData from "layouts/appointments/list/data/authorsTableData";
import { TabContext, TabPanel } from "@mui/lab";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import UpcomingAppointments from "./upcomingAppointments/UpcomingAppointments";
import TodaysAppointments from "./todaysAppointments/TodaysAppointments";
// eslint-disable-next-line import/no-unresolved
// import UpcomingAppointments from "./upcomingAppointments/UPcomingAppointments";
import "./style.css";

function Appointments() {
  const location = useLocation();
  // eslint-disable-next-line no-unsafe-optional-chaining
  const navigete = useNavigate();
  const [tab, setTab] = useState("todayAppointment");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const headerWrapper = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  };
  if (windowWidth <= 768) {
    headerWrapper.flexDirection = "column";
  }
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleNavigete = () => {
    navigete("/appointment/create-appointment");
  };
  useEffect(() => {
    if (location?.state) {
      const { path } = location.state;
      setTab(path);
    }
  }, [location.state]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <DashboardLayout>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <MDTypography variant="h2">Appointments</MDTypography>
        <SideNavActionBtn />
      </MDBox>
      <TabContext value={tab} mt={1}>
        <MDBox>
          <Tabs
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                transition: "none",
              },
            }}
            value={tab}
          >
            <Tab
              sx={{
                backgroundColor: tab === "todayAppointment" ? "#333745" : "white",
              }}
              label="Today's"
              value="todayAppointment"
            />{" "}
            <Tab
              sx={{
                backgroundColor: tab === "upcomingAppointment" ? "#333745" : "white",
              }}
              label="Upcoming"
              value="upcomingAppointment"
            />{" "}
          </Tabs>{" "}
        </MDBox>{" "}
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={0}
              direction="column"
              style={{
                minHeight: "77vh",
                minWidth: "70%",
              }}
            >
              <MDBox>
                <Card style={{ marginTop: "1.3rem" }}>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    bgColor="black"
                    borderRadius="lg"
                    coloredShadow="info"
                    display="flex"
                    justify-content="space-between"
                    align-items="center"
                  >
                    <MDBox style={headerWrapper}>
                      <MDTypography color="light">Appointments</MDTypography>
                      <div style={{ paddingTop: "0.5rem" }}>
                        <MDButton color="primary" onClick={handleNavigete}>
                          Schedule Appointment
                        </MDButton>
                      </div>
                    </MDBox>
                  </MDBox>
                  <TabPanel value="todayAppointment" style={{ padding: "0" }}>
                    <MDBox mt={2} mb={3}>
                      <TodaysAppointments />
                    </MDBox>{" "}
                  </TabPanel>{" "}
                  <TabPanel value="upcomingAppointment" style={{ padding: "0", width: "100%" }}>
                    <MDBox mt={2} mb={3}>
                      <UpcomingAppointments />
                    </MDBox>
                  </TabPanel>{" "}
                </Card>
              </MDBox>
            </Grid>{" "}
          </Grid>{" "}
        </Grid>{" "}
      </TabContext>{" "}
    </DashboardLayout>
  );
}

export default Appointments;
