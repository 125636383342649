const {
  SET_CITY_LOADING,
  LIST_CITY_SUCCESS,
  LIST_CITY_FAILED,
  GET_CITY_BY_ID_SUCCESS,
  GET_CITY_BY_ID_FAILED,
  CLEAR_SINGLE_CITY_DATA,
} = require("./actionTypes");

const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  cityData: [],
  singleCityData: {},
};

const CityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CITY_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case LIST_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        cityData: action.payload,
      };
    case LIST_CITY_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    case GET_CITY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        singleCityData: action.payload.data,
      };
    case GET_CITY_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    case CLEAR_SINGLE_CITY_DATA:
      return {
        ...state,
        loading: false,
        error: false,
        singleCityData: {},
      };
    default:
      return state;
  }
};

export default CityReducer;
