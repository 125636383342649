/* eslint-disable */

import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import API from "services/axiosapi";
import * as actionTypes from "./actionTypes";

function* listRoutesSaga() {
  try {
    yield put({ type: actionTypes.SET_LOADING_ROUTES });
    const { data } = yield API.get("/api/route");
    if (data.success) {
      yield put({ type: actionTypes.LIST_ROUTES_SUCCESS, payload: data });
    } else {
      toast.error("Unabel to fetch routes!");
      yield put({ type: actionTypes.LIST_ROUTES_FAILED, errorMessage: "Unable to fetch routes!" });
    }
  } catch (error) {
    toast.error("Unabel to fetch routes!");
    yield put({ type: actionTypes.LIST_ROUTES_FAILED, errorMessage: "Unable to fetch routes!" });
  }
}

function* createRouteSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_LOADING_ROUTES });
    const { data } = yield API.post("api/route", obj);
    if (data.success) {
      yield put({ type: actionTypes.CREATE_ROUTE_SUCCESS });
      yield put({ type: actionTypes.LIST_ROUTES });
      toast.success("Route Created!");
    } else {
      yield put({
        type: actionTypes.LIST_ROUTES_FAILED,
        errorMessage: "Unabel to create new route!",
      });
      toast.error("Unable to create new route");
    }
  } catch (error) {
    yield put({
      type: actionTypes.LIST_ROUTES_FAILED,
      errorMessage: "Unabel to create new route!",
    });
    toast.error("Unable to create new route");
  }
}

function* deleteRouteSaga({ Id }) {
  try {
    yield put({ type: actionTypes.SET_LOADING_ROUTES });
    const { data } = yield API.delete(`api/route/${Id}`);
    if (data.success) {
      yield put({ type: actionTypes.DELETE_ROUTE_SUCCESS });
      yield put({ type: actionTypes.LIST_ROUTES });
      toast.success("Deleted success!");
    } else {
      yield put({
        type: actionTypes.DELETE_ROUTE_FAILED,
        errorMessage: "Unabel to delete this route!",
      });
      toast.error("Unable to delete this route!");
    }
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_ROUTE_FAILED,
      errorMessage: "Unabel to delete this route!",
    });
    toast.error("Unable to delete this route!");
  }
}

function* getSingleRouteSaga({ Id }) {
  try {
    yield put({ type: actionTypes.SET_LOADING_ROUTES });
    const { data } = yield API.get(`/api/route/${Id}`);
    if (data.success) {
      yield put({ type: actionTypes.GET_SINGLE_ROUTE_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_SINGLE_ROUTE_FAILED,
        errorMessage: "Unable to fetch this data!",
      });
      toast.error("Unable to fetch this data!");
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_ROUTE_FAILED,
      errorMessage: "Unable to fetch this data!",
    });
    toast.error("Unable to fetch this data!");
  }
}

function* editRouteSaga({ obj }) {
  const Id = obj.Id;
  const editObj = obj.obj;
  try {
    yield put({ type: actionTypes.SET_LOADING_ROUTES });
    const { data } = yield API.put(`/api/route/${Id}/update`, editObj);
    if (data.success) {
      yield put({ type: actionTypes.EDIT_ROUTE_SUCCESS });
      yield put({ type: actionTypes.LIST_ROUTES });
      toast.success("Route Updated!");
    } else {
      yield put({
        type: actionTypes.EDIT_ROUTE_FAILED,
        errorMessage: "Unable to edit this route!",
      });
      toast.error("Unable to edit this route!");
    }
  } catch (error) {
    yield put({ type: actionTypes.EDIT_ROUTE_FAILED, errorMessage: "Unable to edit this route!" });
    toast.error("Unable to edit this route!");
  }
}

function* RoutesSaga() {
  yield all([
    yield takeLatest(actionTypes.LIST_ROUTES, listRoutesSaga),
    yield takeLatest(actionTypes.CREATE_ROUTE, createRouteSaga),
    yield takeLatest(actionTypes.DELETE_ROUTE, deleteRouteSaga),
    yield takeLatest(actionTypes.GET_SINGLE_ROUTE, getSingleRouteSaga),
    yield takeLatest(actionTypes.EDIT_ROUTE, editRouteSaga),
  ]);
}

export default RoutesSaga;
