import { CREATE_CREW, DELETE_CREW, GET_CREW, LIST_CREW, UPDATE_CREW } from "./actionTypes";

// eslint-disable-next-line import/prefer-default-export
export const createCrewAction = (obj) => ({
  type: CREATE_CREW,
  obj,
});

export const getCrewListAction = () => ({
  type: LIST_CREW,
});

export const deleteCrewAction = (id) => ({
  type: DELETE_CREW,
  id,
});

export const singleCrewDetailsAction = (id) => ({
  type: GET_CREW,
  id,
});

export const updateCrewAction = (obj, id) => ({
  type: UPDATE_CREW,
  obj,
  id,
});
