export const SET_APPOINTMENT_LOADING = "SET_APPOINTMENT_LOADING";

export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const CREATE_APPOINTMENT_FAILED = "CREATE_APPOINTMENT_FAILED";

export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const GET_APPOINTMENT_SUCCESS = "GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_FAILED = "GET_APPOINTMENT_FAILED";

export const GET_SLOTS = "GET_SLOTS";
export const GET_SLOTS_SUCCESS = "GET_SLOTS_SUCCESS";
export const GET_SLOTS_FAILED = "GET_SLOTS_FAILED";

export const GET_TABLE_DATA = "GET_TABLE_DATA";
export const GET_TABLE_DATA_SUCCESS = "GET_TABLE_DATA_SUCCESS";
export const GET_TABLE_DATA_FAILED = "GET_TABLE_DATA_FAILED";

export const GET_SINGLE_APPOINTMENT = "GET_SINGLE_APPOINTMENT";
export const GET_SINGLE_APPOINTMENT_SUCCESS = "GET_SINGLE_APPOINTMENT_SUCCESS";
export const GET_SINGLE_APPOINTMENT_FAILED = "GET_SINGLE_APPOINTMENT_FAILED";

export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const UPDATE_APPOINTMENT_SUCCESS = "UPDATE_APPOINTMENT_SUCCESS";
export const UPDATE_APPOINTMENT_FAILED = "UPDATE_APPOINTMENT_FAILED";

export const UPDATE_APPOINTMENT_STATUS = "UPDATE_APPOINTMENT_STATUS";
export const UPDATE_APPOINTMENT_STATUS_SUCCESS = "UPDATE_APPOINTMENT_STATUS_SUCCESS";
export const UPDATE_APPOINTMENT_STATUS_FAILED = "UPDATE_APPOINTMENT_STATUS_FAILED";

export const CLEAR_SINGLE_DATA = "CLEAR_SINGLE_CITY_DATA";
