/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { Autocomplete, Card, FormControl, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getSendMessageAction } from "redux/Messages/actions";
import CustomSpinner from "examples/CustomLoader";
import { listCustomers } from "redux/actions";
import CustomTablePagination from "examples/CustomTablePagination/CustomTablePagination";
import authorsTableData from "../dataList/outgoing/authorsTableData";

function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

function SendStatus() {
  const dispatch = useDispatch();
  const { originalCustomerList } = useSelector((state) => state.Customers);
  const { sendMessageData, loading } = useSelector((state) => state.MessageReducer);
  const [messageData, setMessageData] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [query, setQuery] = useState({ type: "Outgoing", limit: pageSize, page });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const outgoingFilterWrapper = { display: "flex", justifyContent: "space-between" };
  const filterAutoCom = { width: "300px" };

  if (windowWidth <= 768) {
    outgoingFilterWrapper.flexDirection = "column";
    filterAutoCom.width = "";
    filterAutoCom.marginBottom = "1rem";
  }

  const handleSelectCustomer = (event, value) => {
    if (value !== null) {
      dispatch(getSendMessageAction({ ...query, customer_id: value.custmerId }));
      setQuery({ ...query, customer_id: value.custmerId });
    } else {
      dispatch(getSendMessageAction({ ...query, customer_id: "" }));
    }
  };

  const handlePageChange = (event, value) => {
    setQuery({ ...query, page: value });
    dispatch(getSendMessageAction({ ...query, page: value }));
    setPage(value);
  };

  const handlePageLimit = (e) => {
    setPageSize(e.target.value);
    dispatch(getSendMessageAction({ ...query, limit: e.target.value }));
  };

  function handleSearch(event) {
    const newQuery = event.target.value;
    if (newQuery === "") {
      setMessageData(sendMessageData);
    } else if (newQuery.length >= 3) {
      const results = search(newQuery);
      setMessageData({ ...sendMessageData, data: results });
    } else {
      setMessageData([]);
    }
  }
  function search(text) {
    const regexPattern = new RegExp(text, "i");
    const results = sendMessageData.data?.filter((item) => {
      const matchToNumber = regexPattern.test(item.toNumber);
      const matchMessage = regexPattern.test(item.message);
      const matchDate = regexPattern.test(new Date(item?.updatedAt).toISOString().slice(0, 10));
      const matchTwilioResponse = regexPattern.test(item.twillio_response);
      const matchCustomer =
        item.customer_id &&
        (regexPattern.test(item.customer_id.first_name) ||
          regexPattern.test(item.customer_id.last_name) ||
          regexPattern.test(item.customer_id.email));

      return matchMessage || matchToNumber || matchCustomer || matchDate || matchTwilioResponse;
    });
    return results;
  }

  useEffect(() => {
    dispatch(getSendMessageAction(query));
    dispatch(listCustomers({ page: 1, pageSize: 10000 }));
  }, []);
  useEffect(() => {
    if (Array.isArray(messageData.data)) {
      const { columnData, rowData } = authorsTableData(messageData);
      setColumns(columnData);
      setRows(rowData);
    }
  }, [messageData]);
  useEffect(() => {
    // eslint-disable-next-line camelcase
    const settingCustomerList = originalCustomerList?.data?.map(
      // eslint-disable-next-line camelcase
      ({ _id, first_name, last_name, phone }) => ({
        // eslint-disable-next-line camelcase
        label: `${first_name} ${last_name}`,
        custmerId: _id,
        phoneNumber: phone,
      })
    );
    setCustomerDetails(settingCustomerList);
  }, [originalCustomerList]);
  useEffect(() => {
    setMessageData(sendMessageData);
  }, [sendMessageData]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <MDBox>
      <Card style={{ marginTop: "1.3rem" }}>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          bgColor="black"
          borderRadius="lg"
          coloredShadow="info"
          display="flex"
          justify-content="space-between"
          align-items="center"
        >
          <MDBox style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <MDTypography style={{ textTransform: "capitalize" }} color="light">
              Outgoing Messages
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="h6">Filter By Phone Number</MDTypography>
          <MDBox style={outgoingFilterWrapper}>
            {customerDetails && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerDetails}
                onChange={handleSelectCustomer}
                // sx={{ width: 300 }}
                style={filterAutoCom}
                renderInput={(params) => <TextField {...params} placeholder="Select Customer" />}
              />
            )}
            <FormControl>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Search"
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="medium" />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </MDBox>
        </MDBox>
        {loading ? (
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CustomSpinner />
          </MDBox>
        ) : (
          <MDBox>
            {messageData?.data?.length === 0 ? (
              <MDBox style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{ height: "20%", width: "20%", objectFit: "contain" }}
                  src="https://img.freepik.com/free-vector/empty-concept-illustration_114360-1253.jpg?w=2000"
                  loading="lazy"
                  alt=""
                />
              </MDBox>
            ) : (
              <>
                {windowWidth >= 768 ? (
                  <DataTable
                    table={{ columns: columns || [], rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    rowData={messageData}
                  />
                ) : (
                  <div>
                    {sendMessageData?.data?.map((row) => (
                      <MDBox p="8px 16px 16px">
                        <Card
                          style={{
                            padding: "3%",
                            border: "1px solid black",
                          }}
                        >
                          <div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div>
                                <MDTypography
                                  style={{ fontSize: "12px", margin: "0" }}
                                  display="flex"
                                  alignItems="center"
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  ToNumber
                                </MDTypography>
                                <MDTypography
                                  display="block"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                >
                                  {row.toNumber}
                                </MDTypography>
                              </div>
                              <div>
                                <MDTypography
                                  display="flex"
                                  justifyContent="end"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                >
                                  {dateFormatted(row?.updatedAt)}
                                </MDTypography>
                              </div>
                            </div>
                            <MDTypography
                              style={{ fontSize: "12px", marginTop: "1rem" }}
                              display="flex"
                              alignItems="center"
                              variant="h6"
                              fontWeight="medium"
                            >
                              Message
                            </MDTypography>
                            <MDTypography
                              display="block"
                              variant="button"
                              fontWeight="medium"
                              color="text"
                              fontSize=".70rem"
                            >
                              {row.message}
                            </MDTypography>
                            {row?.twillio_response && (
                              <>
                                <MDTypography
                                  style={{ fontSize: "12px", marginTop: "1rem" }}
                                  display="flex"
                                  alignItems="center"
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  Twilio Response
                                </MDTypography>
                                <MDTypography
                                  display="block"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                >
                                  {row.twillio_response}
                                </MDTypography>
                              </>
                            )}
                          </div>
                        </Card>
                      </MDBox>
                    ))}
                  </div>
                )}
                <CustomTablePagination
                  count={messageData.pagination?.totalPages}
                  page={page}
                  onChangePage={handlePageChange}
                  value={pageSize}
                  onChangeRowPerPage={handlePageLimit}
                  pageSizeOptions={[10, 20, 30, 40, 50]}
                />
              </>
            )}
          </MDBox>
        )}
      </Card>
    </MDBox>
  );
}

export default SendStatus;
