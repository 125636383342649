import API from "services/axiosapi";
import { toast } from "react-toastify";
import { put, takeLatest, all } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";

function* createAppointmentSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_APPOINTMENT_LOADING });
    const { data } = yield API.post("/api/appointment", { ...obj });
    if (data.success) {
      yield put({ type: actionTypes.CREATE_APPOINTMENT_SUCCESS });
      yield put({ type: actionTypes.GET_APPOINTMENT });
      toast.success(data.message);
    } else {
      yield put({
        type: actionTypes.CREATE_APPOINTMENT_FAILED,
        errorMessage: "Unable to create appointment!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_APPOINTMENT_FAILED,
      errorMessage: "Unable to create appointment!",
    });
    toast.error(error.response.data.message);
  }
}

function* getAppointmentListSaga() {
  try {
    yield put({ type: actionTypes.SET_APPOINTMENT_LOADING });
    const { data } = yield API.get("/api/appointment");
    if (data.success) {
      yield put({ type: actionTypes.GET_APPOINTMENT_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_APPOINTMENT_FAILED,
        errorMessage: "Unable to fetch appointment data!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_APPOINTMENT_FAILED,
      errorMessage: "Unable to fetch appointment data!",
    });
    toast.error(error.response.data.message);
  }
}

function* getSlotsSaga({ obj }) {
  try {
    const { data } = yield API.get("/api/appointment/get-available-slots", { params: obj });
    if (data.success) {
      yield put({ type: actionTypes.GET_SLOTS_SUCCESS, payload: data });
    } else {
      yield put({ type: actionTypes.GET_SLOTS_FAILED, errorMessage: "Unable to fetch slots!" });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_SLOTS_FAILED, errorMessage: "Unable to fetch slots!" });
    toast.error(error.response.data.message);
  }
}

// function* getTableDataSaga(obj) {
//   try {
//     yield put({ type: actionTypes.SET_APPOINTMENT_LOADING });
//     const { data } = yield API.get("/api/appointment/GetAppointmentsList/for-table", {
//       params: obj,
//     });
//     if (data.success) {
//       yield put({ type: actionTypes.GET_TABLE_DATA_SUCCESS, payload: data });
//     } else {
//       yield put({
//         type: actionTypes.GET_TABLE_DATA_FAILED,
//         errorMessage: "Unable to fetch table data!",
//       });
//       toast.error(data.message);
//     }
//   } catch (error) {
//     yield put({
//       type: actionTypes.GET_TABLE_DATA_FAILED,
//       errorMessage: "Unable to fetch table data!",
//     });
//     toast.error(error.response.data.message);
//   }
// }

function* getSingleAppointment({ obj }) {
  try {
    yield put({ type: actionTypes.SET_APPOINTMENT_LOADING });
    const { data } = yield API.post("/api/appointment/GetAppointmentsList-new/for-table", obj);
    if (data.success) {
      yield put({ type: actionTypes.GET_SINGLE_APPOINTMENT_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_SINGLE_APPOINTMENT_FAILED,
        errorMessage: "Unable to fetch table data!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_APPOINTMENT_FAILED,
      errorMessage: "Unable to fetch table data!",
    });
    toast.error(error.response.data.message);
  }
}

// function* getSingleAppointment({ id }) {
//   try {
//     yield put({ type: actionTypes.SET_APPOINTMENT_LOADING });
//     const { data } = yield API.get(`/api/appointment/${id}`);
//     if (data.success) {
//       yield put({ type: actionTypes.GET_SINGLE_APPOINTMENT_SUCCESS, payload: data });
//     } else {
//       yield put({
//         type: actionTypes.GET_SINGLE_APPOINTMENT_FAILED,
//         errorMessage: "Unable to fetch data!",
//       });
//       toast.error("Unable to fetch data!");
//     }
//   } catch (error) {
//     yield put({
//       type: actionTypes.GET_SINGLE_APPOINTMENT_FAILED,
//       errorMessage: "Unable to fetch data!",
//     });
//     toast.error("Unable to fetch data!");
//   }
// }

function* updateAppointmentStatus({ obj }) {
  yield put({ type: actionTypes.SET_APPOINTMENT_LOADING });
  try {
    const { data } = yield API.put(`/api/appointment/${obj.id}/update-status`, {
      appointment_status: obj.status,
      // customer_id: obj.customer_id,
    });
    if (data.success) {
      yield put({ type: actionTypes.UPDATE_APPOINTMENT_STATUS_SUCCESS });
      yield put({ type: actionTypes.GET_APPOINTMENT });
      toast.success(data.message);
    } else {
      yield put({
        type: actionTypes.UPDATE_APPOINTMENT_STATUS_FAILED,
        errorMessage: "Unable to update status!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_APPOINTMENT_STATUS_FAILED,
      errorMessage: "Unable to update status!",
    });
    toast.error(error.response.data.message);
  }
}

function* updateAppointmentSaga({ obj }) {
  const { dataSet } = obj;
  const { id } = obj;
  yield put({ type: actionTypes.SET_APPOINTMENT_LOADING });
  try {
    const { data } = yield API.put(`/api/appointment/${id}/update`, dataSet);
    if (data.success) {
      yield put({ type: actionTypes.UPDATE_APPOINTMENT_SUCCESS });
      yield put({ type: actionTypes.GET_APPOINTMENT });
      toast.success(data.message);
    } else {
      yield put({
        type: actionTypes.UPDATE_APPOINTMENT_FAILED,
        errorMessage: "Unable to update appointment!",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_APPOINTMENT_FAILED,
      errorMessage: "Unable to update appointment!",
    });
    toast.error(error.response.data.message);
  }
}

function* AppointmentSaga() {
  yield all([
    yield takeLatest(actionTypes.CREATE_APPOINTMENT, createAppointmentSaga),
    yield takeLatest(actionTypes.GET_APPOINTMENT, getAppointmentListSaga),
    yield takeLatest(actionTypes.GET_SLOTS, getSlotsSaga),
    // yield takeLatest(actionTypes.GET_TABLE_DATA, getTableDataSaga),
    yield takeLatest(actionTypes.GET_SINGLE_APPOINTMENT, getSingleAppointment),
    yield takeLatest(actionTypes.UPDATE_APPOINTMENT_STATUS, updateAppointmentStatus),
    yield takeLatest(actionTypes.UPDATE_APPOINTMENT, updateAppointmentSaga),
  ]);
}

export default AppointmentSaga;
