/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
import { Autocomplete, Card, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postMessageAction } from "redux/Messages/actions";
import { listCustomers } from "redux/actions";
import messageImg from "../../../assets/images/unnamed.jpg";

function SingleSms() {
  const dispatch = useDispatch();
  const { originalCustomerList } = useSelector((state) => state.Customers);
  const [number, setNumber] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedCust, setSelectedCust] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const inputWrapper = {
    width: "50%",
  };

  if (windowWidth <= 768) {
    inputWrapper.width = "100%";
  }

  const handleSelectCustomer = (event, value) => {
    if (value !== null) {
      setNumber([value.phoneNumber]);
      setSelectedCust(value);
    } else {
      setNumber([]);
      setSelectedCust();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (number.length - 1 >= 0) {
      dispatch(postMessageAction({ toNumbers: number, message }));
      setSelectedCust([]);
      setMessage("");
      setNumber([]);
    }
  };
  useEffect(() => {
    dispatch(listCustomers({ page: 1, pageSize: 10000 }));
  }, []);
  useEffect(() => {
    const settingCustomerList = originalCustomerList?.data?.map(
      ({ _id, first_name, last_name, phone }) => ({
        label: `${first_name} ${last_name}`,
        custmerId: _id,
        phoneNumber: phone,
      })
    );
    const filterdCustomer = settingCustomerList?.filter((item) => item.phoneNumber !== "");
    setCustomerDetails(filterdCustomer);
  }, [originalCustomerList]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Card style={{ marginTop: "1.3rem" }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        bgColor="black"
        borderRadius="lg"
        coloredShadow="info"
        display="flex"
        justify-content="space-between"
        align-items="center"
      >
        <MDBox style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <MDTypography style={{ textTransform: "capitalize" }} color="light">
            Single SMS
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox width="100%" display="flex" p={3} component="form" role="form" onSubmit={handleSubmit}>
        <MDBox style={inputWrapper}>
          <MDBox fullWidth>
            <MDTypography variant="h6">Select Customer</MDTypography>
            {customerDetails && (
              <Autocomplete
                fullWidth
                disablePortal
                id="combo-box-demo"
                options={customerDetails}
                value={selectedCust}
                onChange={handleSelectCustomer}
                renderInput={(params) => <TextField {...params} placeholder="Select Customer" />}
              />
            )}
          </MDBox>
          <br />
          <MDBox fullWidth>
            <MDTypography variant="h6">Message</MDTypography>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              placeholder="Add text"
              multiline
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={6}
              required
            />
          </MDBox>
          <br />
          <MDButton type="submit" variant="gradient" color="info" ml={2}>
            Send Message
          </MDButton>
        </MDBox>
        {windowWidth >= 768 && (
          <MDBox width="50%" display="flex" justifyContent="center">
            <img
              style={{ objectFit: "contain", width: "50%" }}
              src={messageImg}
              loading="lazy"
              alt=""
            />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

export default SingleSms;
