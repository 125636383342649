/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardDataAction,
  getDashboardRouteTableAction,
  updateAppointmentNoteAction,
} from "redux/Dashboard/actions";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RouteIcon from "@mui/icons-material/Route";
import CustomSpinner from "examples/CustomLoader";
import MDBadge from "components/MDBadge";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import CustomTablePagination from "examples/CustomTablePagination/CustomTablePagination";
import DashboardDataTable from "./components/dashboardDataTable/DashboardDataTable";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
// import { Button } from "@mui/material";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dashboardData, dashboardTable, loading } = useSelector((state) => state.DashboardReducer);
  const perPageCount = { minWidth: 50, marginRight: "3rem" };
  const [pageSize, setPageSize] = useState(5);
  const [pageCount, setPageCount] = useState(1);
  const [toDay, setToDay] = useState("");
  const [dashboardList, setDashboardList] = useState([]);
  const [note, setNote] = useState([]);
  const [open, setOpen] = useState(false);
  const [tempStore, setTempStore] = useState();

  const handleInvoice = (item) => {
    navigate("/create_invoice", { state: { item } });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (item) => {
    // e.preventDefault();
    dispatch(
      updateAppointmentNoteAction({
        dataSet: {
          customer_id: item.customer_id._id,
          crew_id: item.crew_id,
          service_type_id: item.service_type_id._id,
          time_window: item.time_window,
          date: item.date,
          slot: item.slot,
          // note: [...tempStore.note, ...note],
          note,
        },
        id: item._id,
      })
    );
    setNote("");
    setTempStore("");
    setOpen(false);
  };
  // const handleAddNot = (item) => {
  //   setTempStore(item);
  //   // setOpen(true);
  //   handleSubmit();
  // };
  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPageCount(1);
    dispatch(getDashboardRouteTableAction({ page: 1, limit: event.target.value }));
  };

  const handleChange = (event, value) => {
    dispatch(getDashboardRouteTableAction({ page: value, limit: pageSize }));
    setPageCount(value);
  };

  useEffect(() => {
    dispatch(getDashboardDataAction());
    dispatch(getDashboardRouteTableAction({ page: pageCount, limit: pageSize }));
  }, []);
  useEffect(() => {
    // Get current date
    const currentDate = new Date();

    // Format the date string
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    setToDay(formattedDate);
  }, []);

  useEffect(() => {
    if (dashboardData?.data) {
      const formattedData = dashboardData.data.map((item) => {
        const timeSlot = item.slot;
        const [start, end] = timeSlot.split(" to ");
        const startTime = parseInt(start, 10);
        const endTime = parseInt(end, 10);

        const [startHours, startMinutes] = start.split(":");
        const [endHours, endMinutes] = end.split(":");

        const startTotalMinutes = parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);
        const endTotalMinutes = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10);

        const timeDifference = endTotalMinutes - startTotalMinutes;

        let label = "";
        if (timeDifference === 30) {
          let formattedStartHour = String(startTime);
          const formattedStartMinutes = String(startMinutes);
          let formattedEndHour = String(endTime);
          const formattedEndMinutes = String(endMinutes);
          const endPeriodEnd = endTime >= 12 ? "pm" : "am";
          const endPeriodStart = startTime >= 12 ? "pm" : "am";
          formattedStartHour = formattedStartHour % 12 || 12;
          formattedEndHour = formattedEndHour % 12 || 12;
          label = `${formattedStartHour}${
            formattedStartMinutes === "00" ? "" : `:${formattedStartMinutes}`
          }${endPeriodStart} to ${formattedEndHour}${
            formattedEndMinutes === "00" ? "" : `:${formattedEndMinutes}`
          }${endPeriodEnd}`;
        } else if (startTime >= 6 && endTime < 12) {
          label = `${startTime}am to ${endTime}am`;
        } else if (startTime >= 6 && endTime === 12) {
          label = `${startTime}am to ${endTime}pm`;
        } else if (startTime >= 6 && startTime < 12 && endTime <= 18) {
          label = `${startTime}am to ${endTime - 12}pm`;
        } else if (startTime >= 12 && endTime <= 18) {
          if (startTime === 12) {
            label = `${startTime}pm to ${endTime - 12}pm`;
          } else {
            label = `${startTime - 12}pm to ${endTime - 12}pm`;
          }
        }
        const [formattedStartTime, formattedEndTime] = label.split(" to ");
        return {
          ...item,
          formattedStartTime,
          formattedEndTime,
        };
      });
      setDashboardList(formattedData);
      if (formattedData.length > 0) {
        setNote(formattedData[0].note);
      }
    }
  }, [dashboardData]);

  return (
    <>
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        <MDBox display="flex" justifyContent="space-between">
          <MDTypography variant="h6" style={{ fontSize: "28px" }}>
            Today's Appointments
          </MDTypography>
          <SideNavActionBtn />
        </MDBox>
        <MDTypography color="text" style={{ fontSize: "18px" }}>
          {toDay}
        </MDTypography>
        {loading ? (
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh",
            }}
          >
            <CustomSpinner />
          </MDBox>
        ) : (
          <MDBox>
            <MDBox mt={3}>
              <Grid container spacing={2}>
                {dashboardList?.length <= 0 && (
                  <MDBox width="100%" display="flex" justifyContent="center">
                    <MDBox>
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="40vh"
                      >
                        <img
                          style={{
                            height: "80%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          src="https://img.freepik.com/free-vector/empty-concept-illustration_114360-1253.jpg?w=2000"
                          loading="lazy"
                          alt=""
                        />
                      </MDBox>
                      <MDTypography display="flex" justifyContent="center" variant="h6" mt="0">
                        No appointment today
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                )}
                {dashboardList?.map((item, index) => (
                  <Grid key={index.id} item xs={12} md={6} lg={4}>
                    <MDBox mb={1}>
                      <Card
                        style={{
                          padding: "3%",
                          borderLeft: item.status ? "2px solid #fe6854" : "2px solid #9cb52d",
                        }}
                      >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <MDTypography
                              ml={-1}
                              display="flex"
                              alignItems="center"
                              variant="button"
                              fontWeight="medium"
                              color="text"
                            >
                              <MDBadge
                                badgeContent={`${item.formattedStartTime} to ${item.formattedEndTime}`}
                                color="secondary"
                                variant="gradient"
                                size="sm"
                              />
                            </MDTypography>
                            <MDTypography
                              style={{ marginTop: "1rem" }}
                              display="flex"
                              alignItems="center"
                              variant="h6"
                              fontWeight="medium"
                            >
                              {item?.customer_id?.first_name} {item?.customer_id?.last_name}
                            </MDTypography>
                            <MDTypography
                              display="flex"
                              alignItems="center"
                              variant="button"
                              fontWeight="medium"
                              color="text"
                              fontSize=".70rem"
                            >
                              {item?.customer_id?.property?.address}{" "}
                              {item?.customer_id?.property?.city}{" "}
                            </MDTypography>
                            <MDTypography
                              display="block"
                              variant="button"
                              fontWeight="medium"
                              color="text"
                              fontSize=".70rem"
                            >
                              {item?.customer_id?.property?.state} {item?.customer_id?.property.zip}
                            </MDTypography>
                          </div>
                          <div>
                            <div style={{ display: "flex", justifyContent: "end" }}>
                              <MDBadge
                                badgeContent={item.appointment_status}
                                color={
                                  item.appointment_status === "not-started"
                                    ? "error"
                                    : item.appointment_status === "active"
                                    ? ""
                                    : "dark"
                                }
                                variant="gradient"
                                size="sm"
                              />
                            </div>
                            <div style={{ marginTop: "1rem" }}>
                              <MDTypography display="flex" justifyContent="end" variant="h6">
                                SERVICE TYPE
                              </MDTypography>
                              <MDTypography
                                display="block"
                                variant="button"
                                fontWeight="medium"
                                color="text"
                                fontSize=".70rem"
                              >
                                {item?.service_type_id?.service_title}
                              </MDTypography>
                            </div>
                          </div>
                        </div>
                        <MDTypography variant="h6" mt={1} mb={1}>
                          NOTE
                        </MDTypography>
                        <MDBox display="flex" justifyContent="space-between">
                          <MDBox>
                            <TextField
                              fullWidth
                              id="filled-multiline-static"
                              multiline
                              rows={3}
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                              fontSize={30}
                              InputProps={
                                {
                                  // readOnly: true,
                                }
                              }
                            />
                          </MDBox>
                        </MDBox>
                        <div style={{ display: "flex", gap: ".5rem", marginTop: "1rem" }}>
                          <MDButton
                            variant="gradient"
                            color="dark"
                            onClick={() => handleInvoice(item)}
                          >
                            INVOICE
                          </MDButton>
                          <MDButton
                            fullWidth
                            onClick={() => handleSubmit(item)}
                            variant="gradient"
                            color="dark"
                          >
                            Add Note to Activity Log
                          </MDButton>
                        </div>
                      </Card>
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            </MDBox>
            <div style={{ margin: "1rem" }}>
              <hr style={{ borderTop: "1px solid #9cb52d" }} />
            </div>
            <MDTypography
              variant="h6"
              style={{ display: "flex", alignItems: "center", gap: ".21rem" }}
            >
              <RouteIcon /> Route Scheduling
            </MDTypography>
            {dashboardTable?.data
              ?.sort((a, b) => a._id.localeCompare(b._id))
              .map((item, index) => (
                <Card key={index._id} style={{ marginTop: "1rem" }}>
                  <MDBox>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDBox width="100%" display="flex" justifyContent="space-between">
                          <MDTypography variant="h6">{item._id}</MDTypography>
                          <MDTypography variant="h6">
                            #of Properties: ({item.customer_count})
                          </MDTypography>
                        </MDBox>
                      </AccordionSummary>
                      <AccordionDetails style={{ cursor: "default" }}>
                        <DashboardDataTable style={{ cursor: "default" }} tableData={item.routes} />
                      </AccordionDetails>
                    </Accordion>
                  </MDBox>
                </Card>
              ))}
            <CustomTablePagination
              count={dashboardTable?.pagination?.totalPages}
              page={pageCount}
              onChangePage={handleChange}
              value={pageSize}
              onChangeRowPerPage={handleChangePageSize}
              pageSizeOptions={[5, 10, 15, 20]}
            />
          </MDBox>
        )}
      </DashboardLayout>
      <div>
        <Dialog
          component="form"
          role="form"
          onSubmit={handleSubmit}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Add Note</DialogTitle>
          <DialogContent>
            <MDBox p={0}>
              <TextField
                required
                mt={2}
                fullWidth
                id="filled-multiline-static"
                multiline
                label="Note"
                rows={3}
                style={{ width: "250px" }}
                fontSize={30}
                onChange={(e) => setNote([e.target.value])}
              />
            </MDBox>
            {/* <DialogActions> */}
            <MDBox display="flex" justifyContent="end" mt={1}>
              <div style={{ display: "flex", gap: "5px" }}>
                <MDButton style={{ padding: "0" }} onClick={handleClose}>
                  Cancel
                </MDButton>
                <MDButton style={{ padding: "0" }} type="submit">
                  Update
                </MDButton>
              </div>
            </MDBox>
            {/* </DialogActions> */}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default Dashboard;
