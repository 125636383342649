import { TabContext, TabPanel } from "@mui/lab";
import { Grid, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import { FaMailBulk } from "react-icons/fa";
import { MdTextsms, MdOutgoingMail } from "react-icons/md";
import { RiInboxArchiveFill } from "react-icons/ri";
import SingleSms from "./singleSms/SingleSms";
import BulkSendSms from "./bulkSendSms/BulkSendSms";
import SendStatus from "./outgoingMessages/SendStatus";
import ReadMessages from "./incomingMessages/ReadMessages";

function Messages() {
  const [tab, setTab] = useState("single_sms");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <MDTypography variant="h2">Messages</MDTypography>
        <SideNavActionBtn />
      </MDBox>
      <TabContext value={tab} mt={1}>
        <MDBox>
          <Tabs
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                transition: "none",
              },
            }}
            value={tab}
          >
            <Tab
              sx={{
                backgroundColor: tab === "single_sms" ? "#333745" : "white",
              }}
              label={windowWidth >= 768 ? "Single SMS" : <MdTextsms />}
              value="single_sms"
            />{" "}
            <Tab
              sx={{
                backgroundColor: tab === "bulk_send_sms" ? "#333745" : "white",
              }}
              label={windowWidth >= 768 ? "Bulk Send SMS" : <FaMailBulk />}
              value="bulk_send_sms"
            />{" "}
            <Tab
              sx={{
                backgroundColor: tab === "outgoing_messages" ? "#333745" : "white",
              }}
              label={windowWidth >= 768 ? "Outgoing Messages" : <MdOutgoingMail />}
              value="outgoing_messages"
            />{" "}
            <Tab
              sx={{
                backgroundColor: tab === "Incoming Messages" ? "#333745" : "white",
              }}
              label={windowWidth >= 768 ? "Incoming Messages" : <RiInboxArchiveFill />}
              value="Incoming Messages"
            />{" "}
          </Tabs>{" "}
        </MDBox>{" "}
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={0}
              direction="column"
              style={{
                minWidth: "70%",
              }}
            >
              <TabPanel value="single_sms" style={{ padding: "0", width: "100%" }}>
                <MDBox mt={2} mb={3}>
                  <SingleSms />
                </MDBox>{" "}
              </TabPanel>{" "}
              <TabPanel value="bulk_send_sms" style={{ padding: "0", width: "100%" }}>
                <MDBox mt={2} mb={3}>
                  <BulkSendSms />
                </MDBox>
              </TabPanel>{" "}
              <TabPanel value="outgoing_messages" style={{ padding: "0", width: "100%" }}>
                <MDBox mt={2} mb={3}>
                  <SendStatus />
                </MDBox>
              </TabPanel>{" "}
              <TabPanel value="Incoming Messages" style={{ padding: "0", width: "100%" }}>
                <MDBox mt={2} mb={3}>
                  <ReadMessages />
                </MDBox>
              </TabPanel>{" "}
            </Grid>{" "}
          </Grid>{" "}
        </Grid>{" "}
      </TabContext>{" "}
    </DashboardLayout>
  );
}

export default Messages;
