/* eslint-disable camelcase */
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import {
  // paymentCheckOutAction,
  successCheckOutUpdateAction,
  clearSessionDataAction,
} from "redux/invoices/actions";
import { useNavigate, useParams } from "react-router-dom";
import CustomSpinner from "examples/CustomLoader";
import MDButton from "components/MDButton";
import greenCheckMark from "../assets/payment-successful.svg";

function PaymentSuccess() {
  const dispatch = useDispatch("");
  const paramsId = useParams();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.InvoiceReducer);
  const [transactionId, setTransactionId] = useState();
  // useEffect(() => {
  //   if (paramsId.invoice_id) {
  //     dispatch(paymentCheckOutAction({ invoice_id: paramsId.invoice_id }));
  //   }
  // }, [paramsId]);
  useEffect(() => {
    const transaction_id = localStorage.getItem("session_id");
    setTransactionId(transaction_id);
  }, []);
  useEffect(() => {
    if (transactionId !== undefined) {
      dispatch(successCheckOutUpdateAction({ session_id: transactionId }));
      // localStorage.removeItem("session_id");
    }
  }, [transactionId]);
  return (
    <MDBox padding="3% 15% 3% 15%">
      <Card style={{ height: "80vh" }}>
        {loading ? (
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh",
            }}
          >
            <CustomSpinner />
          </MDBox>
        ) : (
          <MDBox
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <MDTypography
                color="text"
                variant="h4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Payment Done !
              </MDTypography>
              <MDTypography
                color="text"
                variant="h6"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Thank you for completing secure online payment.
              </MDTypography>
              <MDTypography
                color="text"
                variant="h6"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Have great day!
              </MDTypography>
              <MDBox style={{ display: "flex", justifyContent: "center" }}>
                <img style={{ objectFit: "contain" }} src={greenCheckMark} loading="lazy" alt="" />
              </MDBox>
              <MDBox style={{ display: "flex", justifyContent: "center" }}>
                <MDButton
                  color="dark"
                  variant="gradient"
                  style={{ display: "flex", justifyContent: "center" }}
                  onClick={() => {
                    dispatch(clearSessionDataAction());
                    navigate(`/invoice/view/${paramsId.invoice_id}`);
                  }}
                >
                  <MDTypography
                    color="white"
                    variant="h4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    View invoice
                  </MDTypography>
                </MDButton>
              </MDBox>
            </div>
          </MDBox>
        )}
      </Card>
    </MDBox>
  );
}

export default PaymentSuccess;
