import { combineReducers } from "redux";
import Login from "./auth/login/reducer";
import Customers from "./Customers/reducer";
import SettingsReducer from "./Holidays/reducer";
import RouteManagement from "./RouteManagement/Services/reducer";
import StaffReducer from "./Staff/reducer";
import CrewReducer from "./Crew/reducer";
import PermissionReducer from "./Permission/reducer";
import CityReducer from "./RouteManagement/City/reducer";
import RouteReducer from "./RouteManagement/Route/reducer";
import AppointmentReducer from "./Appointment/reducer";
import InvoiceReducer from "./invoices/reducer";
import DashboardReducer from "./Dashboard/reducer";
import MessageReducer from "./Messages/reducer";
import ActivitysReducer from "./Activities/reducer";

const rootReducer = combineReducers({
  Login,
  Customers,
  SettingsReducer,
  RouteManagement,
  StaffReducer,
  CrewReducer,
  PermissionReducer,
  CityReducer,
  RouteReducer,
  AppointmentReducer,
  InvoiceReducer,
  DashboardReducer,
  MessageReducer,
  ActivitysReducer,
});

export default rootReducer;
