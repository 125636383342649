/* eslint-disable no-undef */
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listStaffAction } from "redux/Staff/actions";

// eslint-disable-next-line camelcase, react/prop-types
function ViewListOfEmployee({ employee_ids }) {
  const dispatch = useDispatch();
  const { staffData } = useSelector((state) => state.StaffReducer);
  const [employeeNames, setEmplyeeNames] = useState([]);

  useEffect(() => {
    if (!staffData.data) {
      dispatch(listStaffAction());
    }
    // eslint-disable-next-line camelcase
    if (staffData.data && employee_ids) {
      const names = [];
      staffData?.data.forEach((obj) => {
        // eslint-disable-next-line react/prop-types, camelcase, no-underscore-dangle
        if (employee_ids.includes(obj._id)) {
          names.push(obj.first_name);
        }
      });
      setEmplyeeNames(names.join(","));
    }
  }, [staffData]);
  return (
    <div>
      <MDTypography variant="button" fontWeight="medium">
        {employeeNames}
      </MDTypography>
    </div>
  );
}

export default ViewListOfEmployee;
