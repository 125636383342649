import {
  SET_LOADING,
  API_ERROR,
  LIST_CUSTOMERS_SUCCESS,
  LIST_CUSTOMERS_FAILED,
  CREATE_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILED,
  SEARCH_CUSTOMER_SUCCESS,
  SEARCH_CUSTOMER_FAILED,
  CREATE_CUSTOMERS_FAILED,
  GET_CUSTOMER_FAILED,
  LIST_CUSTOMERS_SUCCESS_MODULE,
  LIST_CUSTOMERS_FAILED_MODULE,
  // DELETE_CUSTOMER_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: false,
  errorMessage: undefined,
  createCustomerSuccess: false,
  customerData: [],
  editCustomerMessage: null,
  originalCustomerList: [],
  customerListModule: [],
  activityData: {},
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case LIST_CUSTOMERS_SUCCESS_MODULE:
      return {
        ...state,
        loading: false,
        error: false,
        createCustomerSuccess: false,
        customerListModule: action.payload,
        editCustomerMessage: null,
      };
    case LIST_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        createCustomerSuccess: false,
        originalCustomerList: action.payload,
        editCustomerMessage: null,
      };
    case LIST_CUSTOMERS_FAILED:
    case LIST_CUSTOMERS_FAILED_MODULE:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: action?.payload?.errorMessage || action?.payload,
      };
    case CREATE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        createCustomerSuccess: true,
        editCustomerMessage: null,
      };
    case CREATE_CUSTOMERS_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        customerData: action.payload.data,
        editCustomerMessage: null,
      };
    case EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        editCustomerMessage: action.payload,
      };

    case EDIT_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        editCustomerMessage: action.payload,
      };

    case SEARCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        customerListModule: action.payload,
      };
    case GET_CUSTOMER_FAILED:
    case SEARCH_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case API_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    // case DELETE_CUSTOMER_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: true,
    //     errorMessage: action.payload.errorMessage,
    //   };
    default:
      return state;
  }
};

export default customer;
