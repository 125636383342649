import MDButton from "components/MDButton";
import React from "react";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line react/prop-types
function EditAppointment({ id, text, color }) {
  const navigate = useNavigate();
  // eslint-disable-next-line no-shadow
  const handleEdit = (id) => {
    navigate(`/appointment/edit-appointment/${id}`);
  };
  return (
    <MDButton sx={{ color: { color }, padding: "3px" }} onClick={() => handleEdit(id)}>
      {text}
    </MDButton>
  );
}

export default EditAppointment;
