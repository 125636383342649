/* eslint-disable object-shorthand */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Divider,
  // FormControl,
  Icon,
  IconButton,
  ListItemIcon,
  // InputLabel,
  Menu,
  MenuItem,
  TextField,
  Typography,
  // MenuItem,
  // Select,
} from "@mui/material";
import MDButton from "components/MDButton";
import NotificationItem from "examples/Items/NotificationItem";
// import { Document, Page } from 'react-pdf';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  sendInvoicAction,
  updateInvoicePaymentAction,
  deleteInvoiceAction,
} from "redux/invoices/actions";
import { BsCashCoin, BsCashStack } from "react-icons/bs";
import { FaMoneyCheckAlt } from "react-icons/fa";
import Swal from "sweetalert2";
// import { useDispatch } from "react-redux";
const paymentOptions = [
  { label: "Cash", value: "cash", icon: <BsCashStack /> },
  { label: "Cheque", value: "cheque", icon: <FaMoneyCheckAlt /> },
  { label: "Credit Card", value: "credit_card", icon: <BsCashCoin /> },
];

// eslint-disable-next-line react/prop-types
function Action({ status, id, singleInvoice, actionColor }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [age, setAge] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [toNumber, setToNumber] = useState("");
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  // const { successInvoiceSend } = useSelector((state) => state.InvoiceReducer);
  const handleCloseMenu = () => setOpenMenu(false);

  const [open, setOpen] = React.useState(false);

  const [recordPayment, setRecordPayment] = useState({ payment_method: "manually" });

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    const floatRegExp = /^-?\d*(\.\d*)?$/;
    if (floatRegExp.test(inputValue) || inputValue === "") {
      setRecordPayment({ ...recordPayment, paid_amount: inputValue });
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line no-shadow
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem title="View" onClick={() => navigate(`/view_invoice/${id}`)} />
      {status === "draft" && (
        <NotificationItem title="Edit" onClick={() => navigate(`/edit_invoice/${id}`)} />
      )}
      <NotificationItem
        title="Duplicate"
        onClick={() => navigate(`/edit_invoice/${id}`, { state: { Duplicate: true } })}
      />
      {(status === "sent" || status === "partial") && (
        <NotificationItem title="Record Payment" onClick={handleClickOpen} />
      )}
      <NotificationItem
        title="Resend"
        onClick={() => {
          dispatch(
            sendInvoicAction({
              obj: {
                invoice_id: singleInvoice?._id,
                number: [toNumber],
                email: singleInvoice?.customer_id?.email,
              },
              id: singleInvoice?._id,
            })
          );
        }}
      />
      <NotificationItem
        title="Export as PDF"
        onClick={() =>
          window.open(
            `https://projects.xcitech.in/make-it-rain-api/api/invoice/${id}/invoice-generate`
          )
        }
      />
      <NotificationItem
        title="Print"
        onClick={() => {
          window.open(
            `https://projects.xcitech.in/make-it-rain-api/api/invoice/${id}/invoice-generate`
          );
        }}
      />
      <NotificationItem
        title="Delete"
        onClick={() => {
          setOpenMenu(false);
          Swal.fire({
            title: "Do you want to delete?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#a5bd4b",
            denyButtonText: `No`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              dispatch(deleteInvoiceAction({ id, status: singleInvoice.tabStatus }));
            }
          });
        }}
      />
    </Menu>
  );
  useEffect(() => {
    if (singleInvoice?.customer_id?.phone.length > 0) {
      const number = singleInvoice?.customer_id?.phone;
      const formatedNumber = number.replace(/[^\d+]/g, "");
      if (formatedNumber.startsWith("+91")) {
        setToNumber(formatedNumber);
      } else {
        setToNumber(`+91${formatedNumber}`);
      }
    }
  }, [singleInvoice?.customer_id]);
  const generateTransactionId = () => {
    const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, ""); // Get the current date in the format "YYYYMMDD"
    const randomNumber = Math.floor(Math.random() * 100000); // Generate a random number between 0 and 99999
    const transactionId = `${currentDate}${randomNumber.toString().padStart(5, "0")}`; // Format the transaction ID
    return transactionId;
  };
  const randomNumber = generateTransactionId();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateInvoicePaymentAction({
        tabStatus: singleInvoice.tabStatus,
        id: id,
        obj: {
          payment: [
            {
              ...recordPayment,
              payment_date: new Date().toISOString().substring(0, 10),
              transaction_id: randomNumber,
            },
          ],
        },
      })
    );
    setOpen(false);
  };
  return (
    <MDBox dispaly="flex" justifyContent="space-between">
      {["sent", "viewed", "partial"].includes(status.toLowerCase()) ? (
        <MDButton
          color={actionColor && "info"}
          onClick={() => {
            dispatch(
              sendInvoicAction({
                obj: {
                  number: [toNumber],
                  email: singleInvoice?.customer_id?.email,
                  invoice_id: singleInvoice?._id,
                },
                id: singleInvoice?._id,
              })
            );
          }}
        >
          Send Reminder
        </MDButton>
      ) : status === "draft" ? (
        <MDButton color={actionColor && "info"} onClick={() => navigate(`/view_invoice/${id}`)}>
          View
        </MDButton>
      ) : status === "unsent" ? (
        <MDButton
          color={actionColor && "info"}
          onClick={() => {
            dispatch(
              sendInvoicAction({
                obj: {
                  invoice_id: singleInvoice?._id,
                  number: [toNumber],
                  email: singleInvoice?.customer_id?.email,
                },
                id: singleInvoice?._id,
                allDetails: singleInvoice,
              })
            );
          }}
        >
          Send Invoice
        </MDButton>
      ) : (
        <MDButton
          color={actionColor && "info"}
          onClick={() => {
            dispatch(
              sendInvoicAction({
                obj: {
                  number: [toNumber],
                  email: singleInvoice?.customer_id?.email,
                  invoice_id: singleInvoice?._id,
                },
                id: singleInvoice?._id,
              })
            );
          }}
        >
          Resend
        </MDButton>
      )}
      <IconButton
        size="small"
        disableRipple
        color="inherit"
        style={{ backgroundColor: actionColor && "#333745", marginLeft: "5px" }}
        // sx={navbarIconButton}
        aria-controls="notification-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleOpenMenu}
      >
        <Icon>
          <KeyboardArrowDownIcon style={{ color: actionColor ? "#fff" : "blue", padding: "0" }} />
        </Icon>
      </IconButton>
      {renderMenu()}
      <Dialog
        component="form"
        role="form"
        onSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
      >
        <div style={{ width: "500px" }}>
          <DialogTitle>Record Payment</DialogTitle>
          <DialogContent>
            <Autocomplete
              required
              style={{ marginTop: "5px" }}
              id="payment-method"
              options={paymentOptions}
              onChange={(e, v) => setRecordPayment({ ...recordPayment, payment_type: v.value })}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <MenuItem {...props}>
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <Typography variant="inherit">{option.label}</Typography>
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Payment Type" variant="outlined" />
              )}
            />
            <TextField
              required
              style={{ marginTop: "5%" }}
              variant="outlined"
              margin="dense"
              id="name"
              label="Amount"
              type="number"
              fullWidth
              value={recordPayment.paid_amount} // Set the value to the current state value
              onChange={handleAmountChange} // Use the custom function to handle the change
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Update</Button>
          </DialogActions>
        </div>
      </Dialog>
    </MDBox>
  );
}

export default Action;
