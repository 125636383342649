/* eslint-disable */
// @mui material components
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import workingSVG from "assets/images/svg/working.svg";
import Card from "@mui/material/Card";
import CustomSpinner from "examples/CustomLoader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/customer/profile/components/Header";
import { getCustomer } from "redux/actions";
import Messages from "./messages/Messages";
import Activitys from "./activitys/Activitys";
import Property from "./property/Property";
import ProfileInvoice from "./profileInvoice/ProfileInvoice";
import MDTypography from "components/MDTypography";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import MessageIcon from "@mui/icons-material/Message";
import DescriptionIcon from "@mui/icons-material/Description";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Overview() {
  const names = ["zone1", "South", "South1", "North", "North1", "West", "East"];
  const ITEM_HEIGHT = 20;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        fontSize: 12,
        width: 250,
      },
    },
  };

  const editor = useRef(null);
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();

  const dispatch = useDispatch();

  const { customerData, editCustomerMessage, error, loading } = useSelector(
    (state) => state.Customers
  );
  const [tab, setTab] = useState(0);
  const [isEditableModeOn, setEditableModeOn] = useState(false);
  const [propertyPic, setPropertyPic] = useState(null);
  const [customersData, setCustomersData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [irrigationData, setIrrigationData] = useState([]);
  const [proprtyData, setPropertyData] = useState([]);
  const [secondaryContact, setSecondaryContact] = useState([]);
  const [billingAddress, setBillingAddress] = useState([]);
  const [bluePrintPic, setBluePrintPic] = useState(null);
  const [note, setNote] = useState("");
  const [serviceCallTimeWindow, setServiceCallTimeWindow] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [customerDetails, setCustomerDetails] = useState([]);
  // const [zoneData, setPersonName] = useState([]);

  useEffect(() => {
    dispatch(getCustomer(id));
  }, []);
  useEffect(() => {
    if (state?.tab) {
      setTab(state.tab);
    }
  }, [state]);
  useEffect(() => {
    setOriginalData();
  }, [customerData]);
  const setOriginalData = () => {
    setCustomersData([
      {
        label: "Customer Name",
        value: customerData?.first_name + " " + customerData?.last_name,
      },
      { label: "Telephone", value: customerData?.phone },
      { label: "Email", value: customerData?.email },
      { label: "Time Window", value: customerData?.service_call_time_window, required: false },
    ]);
    setPropertyData([
      { label: "Address", value: customerData?.property?.address },

      {
        label: "City",
        value: customerData?.property?.city,
      },
      { label: "State", value: customerData?.property?.state },
      { label: "Zip", value: customerData?.property?.zip },
    ]);
    setSecondaryContact([
      {
        label: "Phone",
        value: customerData?.secondary_contact?.phone,
        required: false,
      },
      { label: "First Name", value: customerData?.secondary_contact?.first_name, required: false },
      { label: "Last Name", value: customerData?.secondary_contact?.last_name, required: false },
      { label: "Email", value: customerData?.secondary_contact?.email, required: false },
    ]);
    setBillingAddress([
      { label: "Address", value: customerData?.billing?.address },
      { label: "City", value: customerData?.billing?.city },
      { label: "State", value: customerData?.billing?.state },
      { label: "Zip", value: customerData?.billing?.zip },
    ]);
    setIrrigationData([
      {
        label: "Main Location",
        value: customerData?.irrigation_system?.main_location,
        required: false,
      },
      { label: "Controller", value: customerData?.irrigation_system?.controller, required: false },
      {
        label: "Pump / Shut-Off",
        value: customerData?.irrigation_system?.pump_or_shut_off,
        required: false,
      },
      {
        label: "Miscellaneous",
        value: customerData?.irrigation_system?.miscellaneous,
        required: false,
      },
    ]);
    setZoneData(customerData?.zones);
    setNote(customerData?.service_call_note);
    setServiceCallTimeWindow(customerData?.service_call_time_window);
  };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const onClickEdit = (e) => {
    if (isEditableModeOn) {
      setOriginalData();
      setPropertyPic(null);
      setBluePrintPic(null);
    }
    setEditableModeOn(!isEditableModeOn);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <MDTypography variant="h2">Profile</MDTypography>
        <SideNavActionBtn />
      </MDBox>
      <MDBox mb={2} />
      {loading ? (
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CustomSpinner />
        </MDBox>
      ) : (
        <Header
          customerId={customerData?._id}
          firstName={customerData?.first_name}
          lastName={customerData?.last_name}
          location={`${customerData?.property?.address} ${customerData?.property?.city} ${customerData?.property?.state} ${customerData?.property?.zip}`}
          onClickEdit={onClickEdit}
          isEditableModeOn={isEditableModeOn}
          tab={tab}
          unpaidAmount={customerData?.unpaidAmount}
        >
          <TabContext value={tab}>
            <MDBox sx={{ borderBottom: 0, borderColor: "divider" }} mt={2}>
              <Tabs
                onChange={handleChangeTab}
                TabIndicatorProps={{
                  style: { transition: "none" },
                }}
                value={tab}
              >
                <Tab
                  sx={{
                    backgroundColor: tab === 0 ? "#333745" : "white",
                    fontSize: windowWidth <= 768 && "12px",
                  }}
                  label="Property"
                  value={0}
                />
                <Tab
                  sx={{
                    backgroundColor: tab === 1 ? "#333745" : "white",
                    fontSize: windowWidth <= 768 && "12px",
                  }}
                  label="Invoices"
                  value={1}
                />
                <Tab
                  sx={{
                    backgroundColor: tab === 2 ? "#333745" : "white",
                    fontSize: windowWidth <= 768 && "12px",
                  }}
                  label="Activity"
                  value={2}
                />
                <Tab
                  sx={{
                    backgroundColor: tab === 3 ? "#333745" : "white",
                    fontSize: windowWidth <= 768 && "12px",
                  }}
                  label="Messages"
                  value={3}
                />
              </Tabs>
            </MDBox>
            <TabPanel style={{ padding: "0", margin: "0" }} value={0}>
              <Property
                customerId={id}
                isEditableModeOn={isEditableModeOn}
                setEditableModeOn={setEditableModeOn}
                propertyPic={propertyPic}
                setPropertyPic={setPropertyPic}
                customerData={customerData}
                customersData={customersData}
                setCustomersData={setCustomersData}
                zoneData={zoneData}
                setZoneData={setZoneData}
                irrigationData={irrigationData}
                setIrrigationData={setIrrigationData}
                proprtyData={proprtyData}
                setPropertyData={setPropertyData}
                secondaryContact={secondaryContact}
                setSecondaryContact={setSecondaryContact}
                billingAddress={billingAddress}
                setBillingAddress={setBillingAddress}
                bluePrintPic={bluePrintPic}
                setBluePrintPic={setBluePrintPic}
                note={note}
                setNote={setNote}
                setServiceCallTimeWindow={setServiceCallTimeWindow}
                //      serviceCallTimeWindow={serviceCallTimeWindow}
              />
            </TabPanel>
            <TabPanel style={{ padding: "0", margin: "0" }} value={1}>
              <ProfileInvoice customerId={id} />
            </TabPanel>
            <TabPanel value={2}>
              <Activitys customerId={id} />
            </TabPanel>
            <TabPanel value={3} style={{ padding: "0", margin: "0" }}>
              <Messages customerId={id} />
            </TabPanel>
          </TabContext>
        </Header>
      )}
    </DashboardLayout>
  );
}

export default Overview;
