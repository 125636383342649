/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import {
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // FormControlLabel,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import CircleIcon from "@mui/icons-material/Circle";
import { postMessageAction } from "redux/Messages/actions";
import { useDispatch } from "react-redux";
import { updateYearlyActivationAction } from "redux/Dashboard/actions";
import { useNavigate } from "react-router-dom";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import authorsTableData from "../../dataList/authorsTableData";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const date = String(currentDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${date}`;
}
function formatTimeSlot(timeSlot) {
  if (!timeSlot) {
    return "";
  }
  // const [startTime, endTime] = timeSlot.split(" to ");

  // const startHour = parseInt(startTime, 10) % 12 || 12;
  // const endHour = parseInt(endTime, 10) % 12 || 12;

  // const startPeriod = parseInt(startTime, 10) < 12 ? "AM" : "PM";
  // const endPeriod = parseInt(endTime, 10) < 12 ? "AM" : "PM";

  // const formattedStartTime = `${startHour} ${startPeriod}`;
  // const formattedEndTime = `${endHour} ${endPeriod}`;

  // return `${formattedStartTime} to ${formattedEndTime}`;
  const [start, end] = timeSlot.split(" to ");
  const startTime = parseInt(start, 10);
  const endTime = parseInt(end, 10);

  const [startHours, startMinutes] = start.split(":");
  const [endHours, endMinutes] = end.split(":");

  const startTotalMinutes = parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);
  const endTotalMinutes = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10);

  const timeDifference = endTotalMinutes - startTotalMinutes;

  let label = "";
  if (timeDifference === 30) {
    let formattedStartHour = String(startTime);
    const formattedStartMinutes = String(startMinutes);
    let formattedEndHour = String(endTime);
    const formattedEndMinutes = String(endMinutes);
    const endPeriodEnd = endTime >= 12 ? "pm" : "am";
    const endPeriodStart = startTime >= 12 ? "pm" : "am";
    formattedStartHour = formattedStartHour % 12 || 12;
    formattedEndHour = formattedEndHour % 12 || 12;
    label = `${formattedStartHour}${
      formattedStartMinutes === "00" ? "" : `:${formattedStartMinutes}`
    }${endPeriodStart} to ${formattedEndHour}${
      formattedEndMinutes === "00" ? "" : `:${formattedEndMinutes}`
    }${endPeriodEnd}`;
  } else if (startTime >= 6 && endTime < 12) {
    label = `${startTime}am to ${endTime}am`;
  } else if (startTime >= 6 && endTime === 12) {
    label = `${startTime}am to ${endTime}pm`;
  } else if (startTime >= 6 && startTime < 12 && endTime <= 18) {
    label = `${startTime}am to ${endTime - 12}pm`;
  } else if (startTime >= 12 && endTime <= 18) {
    if (startTime === 12) {
      label = `${startTime}pm to ${endTime - 12}pm`;
    } else {
      label = `${startTime - 12}pm to ${endTime - 12}pm`;
    }
  }
  return label;
}
function DashboardDataTable({ tableData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedData, setSelectedData] = useState({});

  const handleClickOpen = (data) => {
    setSelectedData(data);
    setOpen(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postMessageAction({ toNumbers: [selectedData.phone], message }));
    setMessage("");
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    // Assuming the response is stored in a variable called "response"
    let concatenatedCustomers = [];

    for (let i = 0; i < tableData.length; i++) {
      const route = tableData[i];
      if (route.matched_customers.length > 0) {
        concatenatedCustomers = concatenatedCustomers.concat(route.matched_customers);
        // route.city_codes = `${route.city_codes}, ${route.matched_customers.length}`;
      }
    }
    setDetails(concatenatedCustomers);
  }, []);
  useEffect(() => {
    if (Array.isArray(details)) {
      const { columnData, rowData } = authorsTableData(details);
      setColumns(columnData);
      setRows(rowData);
    }
  }, [details]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = (id) => {
    navigate(`/profile/${id}`);
  };
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {windowWidth >= 758 ? (
        <MDBox style={{ cursor: "default" }}>
          <DataTable
            table={{ columns: columns || [], rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
            rowData={tableData}
          />
        </MDBox>
      ) : (
        <>
          {details?.map((item, index) => {
            const winterizationActivationObj = item?.yearlyActivations?.find(
              (obj) => obj.activation_type === "winterization"
            );
            const springActivationObj = item?.yearlyActivations?.find(
              (obj) => obj.activation_type === "spring"
            );
            const isWinterChecked = winterizationActivationObj
              ? winterizationActivationObj.status
              : false;
            const isSpringChecked = springActivationObj ? springActivationObj.status : false;
            const handleWinterCheck = (details) => {
              const currentYear = new Date().getFullYear();
              if (winterizationActivationObj === undefined) {
                dispatch(
                  updateYearlyActivationAction({
                    customer_id: details?._id,
                    year: currentYear,
                    activation_type: "winterization",
                    status: true,
                  })
                );
              } else if (winterizationActivationObj.status) {
                dispatch(
                  updateYearlyActivationAction({
                    customer_id: details?._id,
                    year: currentYear,
                    activation_type: "winterization",
                    status: false,
                  })
                );
              } else {
                dispatch(
                  updateYearlyActivationAction({
                    customer_id: details?._id,
                    year: currentYear,
                    activation_type: "winterization",
                    status: true,
                  })
                );
              }
            };
            const handleSpringCheck = (details) => {
              const currentYear = new Date().getFullYear();
              if (springActivationObj === undefined) {
                dispatch(
                  updateYearlyActivationAction({
                    customer_id: details?._id,
                    year: currentYear,
                    activation_type: "spring",
                    status: true,
                  })
                );
              } else if (springActivationObj.status) {
                dispatch(
                  updateYearlyActivationAction({
                    customer_id: details?._id,
                    year: currentYear,
                    activation_type: "spring",
                    status: false,
                  })
                );
              } else {
                dispatch(
                  updateYearlyActivationAction({
                    customer_id: details?._id,
                    year: currentYear,
                    activation_type: "spring",
                    status: true,
                  })
                );
              }
            };
            return (
              <MDBox key={index.id} mb={3}>
                <Card
                  style={{
                    padding: "3%",
                    border: "1px solid rgba(56, 70, 100, 0.15)",
                  }}
                >
                  <div>
                    <MDTypography
                      display="flex"
                      alignItems="center"
                      variant="button"
                      fontWeight="medium"
                      color="text"
                      fontSize=".70rem"
                      gap=".3rem"
                    >
                      <MDTypography
                        style={{ fontSize: "12px" }}
                        display="flex"
                        alignItems="center"
                        variant="h6"
                        fontWeight="medium"
                      >
                        {" "}
                        City code{" "}
                      </MDTypography>
                      {item.property?.zip}
                    </MDTypography>
                    <MDTypography
                      style={{ fontWeight: "400", fontSize: "12px" }}
                      display="flex"
                      alignItems="center"
                      variant="h6"
                      fontWeight="medium"
                      gap=".3rem"
                    >
                      <MDTypography
                        style={{ fontSize: "12px" }}
                        display="flex"
                        alignItems="center"
                        variant="h6"
                        fontWeight="medium"
                      >
                        {" "}
                        Customer Name{" "}
                      </MDTypography>
                      <span
                        style={{ color: "#3777FF", cursor: "pointer" }}
                        onClick={() => {
                          handleNavigate(item._id);
                        }}
                      >
                        <u>
                          {item.first_name} {item.last_name}
                        </u>
                      </span>
                    </MDTypography>
                    <MDTypography
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      display="flex"
                      alignItems="center"
                      variant="h6"
                      fontWeight="medium"
                      gap=".3rem"
                    >
                      <MDTypography
                        style={{ fontSize: "12px" }}
                        display="flex"
                        alignItems="center"
                        variant="h6"
                        fontWeight="medium"
                      >
                        {" "}
                        Service Address{" "}
                      </MDTypography>
                      {/* <span
                        style={{ color: "#3777FF" }}
                        onClick={() => navigate("profile/645b730c4fa423ed3568e2dc")}
                      >
                        <u>
                          {item.property?.address} {item.property?.city} {item.property?.state}{" "}
                          {item.property?.zip}
                        </u>
                      </span> */}
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          `${item.property?.address} ${item.property?.city} ${item.property?.state} ${item.property?.zip}`
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#3777FF", textDecoration: "underline", cursor: "pointer" }}
                      >
                        <u>
                          {item.property?.address} {item.property?.city} {item.property?.state}{" "}
                          {item.property?.zip}
                        </u>
                      </a>
                    </MDTypography>
                    <MDTypography
                      display="flex"
                      alignItems="center"
                      variant="button"
                      fontWeight="medium"
                      color="text"
                      fontSize=".70rem"
                      gap=".3rem"
                      flexWrap="wrap"
                    >
                      <MDTypography
                        style={{ fontSize: "12px" }}
                        display="flex"
                        alignItems="center"
                        variant="h6"
                        fontWeight="medium"
                      >
                        Upcoming Appointment
                      </MDTypography>
                      <span>
                        {item.appointments[0]?.date === getCurrentDate() && (
                          <CircleIcon color="success" />
                        )}
                        {item.appointments[0]?.date || (
                          <span style={{ color: "#7b8aaa" }}>No Appointment</span>
                        )}
                      </span>
                      <span>{formatTimeSlot(item.appointments[0]?.slot)}</span>
                    </MDTypography>
                    <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                      <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                        <Checkbox
                          checked={isWinterChecked}
                          onClick={() => handleWinterCheck(item)}
                          style={{ margin: "0", padding: 0 }}
                        />
                        <MDTypography
                          style={{ fontSize: "12px" }}
                          display="flex"
                          alignItems="center"
                          variant="h6"
                          fontWeight="medium"
                        >
                          Winterization
                        </MDTypography>
                      </div>
                      <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                        <Checkbox
                          onClick={() => handleSpringCheck(item)}
                          checked={isSpringChecked}
                          style={{ margin: "0", padding: "0" }}
                        />
                        <MDTypography
                          style={{ fontSize: "12px" }}
                          display="flex"
                          alignItems="center"
                          variant="h6"
                          fontWeight="medium"
                        >
                          Spring Activation
                        </MDTypography>
                      </div>
                    </MDBox>
                    <MDBox display="flex" justifyContent="center" mt={2}>
                      <MDButton
                        fullWidth
                        onClick={() => handleClickOpen(item)}
                        color="primary"
                        style={{ display: "flex", gap: ".21rem" }}
                      >
                        <ChatBubbleOutlineIcon /> Send Message
                      </MDButton>
                    </MDBox>
                  </div>
                </Card>
              </MDBox>
            );
          })}
        </>
      )}
      <div>
        <Dialog
          component="form"
          role="form"
          onSubmit={handleSubmit}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Send Message</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Customer Name"
              type="text"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              value={`${selectedData?.first_name} ${selectedData?.last_name}`}
              required
            />
            <TextField
              style={{ marginTop: "1rem" }}
              margin="dense"
              id="name"
              label="Customer Phone"
              type="text"
              fullWidth
              value={selectedData?.phone}
              onChange={(e) => setSelectedData({ ...selectedData, phone: e.target.value })}
              required
            />
            <TextField
              autoFocus
              style={{ marginTop: "1rem" }}
              mt={2}
              fullWidth
              id="outlined-multiline-static"
              label="Message"
              multiline
              rows={4}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose}>Cancel</MDButton>
            <MDButton type="submit">Send</MDButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default DashboardDataTable;
