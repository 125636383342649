import API from "services/axiosapi";
import { toast } from "react-toastify";
import { put, takeLatest, all } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";

function* getActivitySaga({ customerId }) {
  try {
    yield put({ type: actionTypes.ACTIVITY_LOADING });
    const { data } = yield API.get("/api/audit/activities", {
      params: { customer_id: customerId },
    });
    if (data.status) {
      yield put({ type: actionTypes.GET_ACTIVITY_SUCCESS, payload: data });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITY_FAILED, errorMessage: "Unable to fetch slots!" });
      toast.error("Unable to fetch slots!");
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_ACTIVITY_FAILED, errorMessage: "Unable to fetch slots!" });
    toast.error("Unable to fetch slots!");
  }
}

function* ActivitiesSaga() {
  yield all([yield takeLatest(actionTypes.GET_ACTIVITY, getActivitySaga)]);
}

export default ActivitiesSaga;
