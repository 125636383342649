/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import {
  Box,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import CustomSpinner from "examples/CustomLoader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CloseIcon from "@mui/icons-material/Close";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import DataTable from "examples/Tables/DataTable";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { listStaffAction } from "redux/Staff/actions";
import { createCrewAction, getCrewListAction } from "redux/Crew/actions";
import Chip from "@mui/material/Chip";
import authorsTableData from "./list/data/authorsTableData";
import ViewListOfEmployee from "./list/ViewListOfEmployee";
import Actions from "./list/Actions";

function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
// eslint-disable-next-line react/prop-types
function Crews() {
  const dispatch = useDispatch();
  const { staffData, staffLoading } = useSelector((state) => state.StaffReducer);
  const { crewsData, loading } = useSelector((state) => state.CrewReducer);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [newData, setNewData] = useState();
  const [open, setOpen] = useState(false);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [employeeOnCrew, setEmployeeOnCrew] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const addCrew = () => {
    if (!staffData.data) {
      dispatch(listStaffAction());
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedObjects([]);
  };

  const theme = useTheme();
  const handleChange = (event) => {
    const { value } = event.target;
    const freqObj = value.reduce((acc, obj) => {
      const key = obj.id + obj.name;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});
    const uniqueArray = value.filter((obj) => {
      const key = obj.id + obj.name;
      return freqObj[key] === 1;
    });
    setSelectedObjects(uniqueArray);
    const updatedValue = value.map((obj) => obj.id);
    setNewData({ ...newData, employee_ids: [...updatedValue] });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createCrewAction({ newData }));
    setSelectedObjects([]);
    setOpen(false);
  };
  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (Array.isArray(crewsData?.data)) {
      const { columnData, rowData } = authorsTableData(crewsData);
      setColumns(columnData);
      setRows(rowData);
    }
  }, [crewsData]);
  useEffect(() => {
    if (!crewsData.data) {
      dispatch(getCrewListAction());
    }
    if (crewsData.data) {
      const allEmployeeIds = crewsData?.data.map((employee) => employee.employee_ids).flat();
      setEmployeeOnCrew(allEmployeeIds);
    }
  }, [crewsData]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              bgColor="black"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              justify-content="space-between"
              align-items="center"
            >
              <Grid
                container
                spacing={6}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item xl={2} justifyContent="center" alignItems="center">
                  <MDTypography variant="div" color="white">
                    Crew(s)
                  </MDTypography>
                </Grid>
                <Grid item xl={2}>
                  <MDButton variant="gradient" color="info" onClick={addCrew}>
                    Add Crew
                  </MDButton>{" "}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <MDBox>
                        <MDBox
                          component="form"
                          role="form"
                          onSubmit={handleSubmit}
                          mt={2}
                          mb={3}
                          style={{
                            padding: "20px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <Grid
                              container
                              spacing={3}
                              style={{ display: "flex", justifyContent: "space-between" }}
                            >
                              <MDTypography variant="h2" fontWeight="medium" mb={2}>
                                New Crew
                              </MDTypography>{" "}
                              <MDTypography
                                variant="h2"
                                fontWeight="medium"
                                mb={2}
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Tooltip title="Close" placement="top">
                                  <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                                </Tooltip>
                              </MDTypography>{" "}
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  mb={2}
                                  mt={3}
                                  style={{ display: "flex", marginBottom: "0" }}
                                >
                                  Crew Title <p style={{ color: "red" }}>*</p>
                                </MDTypography>
                                <TextField
                                  fullWidth
                                  id="outlined-size-normal"
                                  required
                                  onChange={(e) =>
                                    setNewData({ ...newData, crew_title: e.target.value })
                                  }
                                />{" "}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  mb={0}
                                  mt={3}
                                  style={{ display: "flex" }}
                                >
                                  Select Members <p style={{ color: "red" }}>*</p>
                                </MDTypography>
                                <FormControl style={{ m: 1, width: "100%" }}>
                                  <Select
                                    style={{ padding: "12px" }}
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={selectedObjects}
                                    onChange={handleChange}
                                    renderValue={(selected) => (
                                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                        {selected.map((value) => (
                                          <Chip
                                            key={value._id}
                                            label={value.name}
                                            color="success"
                                            style={{
                                              marginBottom: "3px",
                                              fontSize: "12px",
                                              maxHeight: "20px",
                                              color: "#fff",
                                            }}
                                          />
                                        ))}
                                      </Box>
                                    )}
                                    MenuProps={MenuProps}
                                    required
                                  >
                                    {staffData.data
                                      ?.filter((obj) => !employeeOnCrew?.includes(obj._id))
                                      .map((obj) => (
                                        // eslint-disable-next-line no-underscore-dangle
                                        <MenuItem
                                          key={obj._id}
                                          value={{ id: obj._id, name: obj.first_name }}
                                        >
                                          <Checkbox
                                            // eslint-disable-next-line no-underscore-dangle
                                            checked={selectedObjects.some((o) => o.id === obj._id)}
                                          />
                                          <ListItemText primary={obj.first_name} />
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <br />
                            <Grid
                              container
                              spacing={3}
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              <MDButton
                                variant="gradient"
                                color="info"
                                style={{ marginTop: "24px" }}
                                type="submit"
                              >
                                Save
                              </MDButton>
                            </Grid>

                            <ToastContainer style={{ fontSize: "15px" }} />
                          </div>
                        </MDBox>
                      </MDBox>
                    </DialogContent>
                  </Dialog>
                </Grid>
              </Grid>
            </MDBox>
            {staffLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomSpinner />
              </div>
            ) : (
              <MDBox pt={3}>
                {crewsData && (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {windowWidth >= 768 ? (
                      <DataTable
                        table={{ columns: columns || [], rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                        rowData={crewsData}
                        onRowClick={(id) => {}}
                      />
                    ) : (
                      <div>
                        {crewsData?.data?.map((row) => (
                          <MDBox p="8px 16px 16px">
                            <Card
                              style={{
                                padding: "3%",
                                border: "1px solid black",
                              }}
                            >
                              <div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <MDTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="medium"
                                    color="text"
                                    fontSize=".70rem"
                                  >
                                    {dateFormatted(row?.createdAt)}
                                  </MDTypography>
                                  <MDTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="medium"
                                    color="text"
                                    fontSize=".70rem"
                                  >
                                    <Actions id={row._id} staffData={row} />
                                  </MDTypography>
                                </div>
                                <MDTypography
                                  display="flex"
                                  alignItems="center"
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  {row?.crew_title}
                                </MDTypography>
                                <MDTypography
                                  display="block"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                >
                                  <ViewListOfEmployee employee_ids={row?.employee_ids} />
                                </MDTypography>
                              </div>
                            </Card>
                          </MDBox>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </MDBox>
            )}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Crews;
