/* eslint-disable import/prefer-default-export */
export const SET_DASHBOARD_LOADING = "SET_DASHBOARD_LOADING";

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILED = "GET_DASHBOARD_DATA_FAILED";

export const GET_DASHBOARD_ROUTE_TABLE = "GET_DASHBOARD_ROUTE_TABLE";
export const GET_DASHBOARD_ROUTE_TABLE_SUCCESS = "GET_DASHBOARD_ROUTE_TABLE_SUCCESS";
export const GET_DASHBOARD_ROUTE_TABLE_FAILED = "GET_DASHBOARD_ROUTE_TABLE_FAILED";

export const UPDATE_APPOINTMENT_NOTE = "UPDATE_APPOINTMENT_NOTE";
export const UPDATE_APPOINTMENT_NOTE_SUCCESS = "UPDATE_APPOINTMENT_NOTE_SUCCESS";
export const UPDATE_APPOINTMENT_NOTE_FAILED = "UPDATE_APPOINTMENT_NOTE_FAILED";

export const UPDATE_YEARLY_ACTIVATION = "UPDATE_YEARLY_ACTIVATION";
export const UPDATE_YEARLY_ACTIVATION_SUCCESS = "UPDATE_YEARLY_ACTIVATION_SUCCESS";
export const UPDATE_YEARLY_ACTIVATION_FAILED = "UPDATE_YEARLY_ACTIVATION_FAILED";

export const SET_LOADER_CHECKBOX = "SET_LOADER_CHECKBOX";
