/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign, no-underscore-dangle */

import MDTypography from "components/MDTypography";
import TableDelete from "../tableDelete";
import TableEdit from "../tableEdit";

export default function data(tableData) {
  return {
    columnData: [
      { Header: "Service Title", accessor: "service_title", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rowData: tableData?.data.map((d) => ({
      service_title: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d?.service_title}
        </MDTypography>
      ),
      action: (
        <MDTypography display="flex" variant="button" fontWeight="medium">
          <TableEdit id={d?._id} data={d} />
          <TableDelete id={d?._id} />
        </MDTypography>
      ),
    })),
  };
}
