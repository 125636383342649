/* eslint-disable import/prefer-default-export */
export const SET_PERMISSION_LOADING = "SET_PERMISSION_LOADING";

export const GET_PERMISSION_EMPLOYEE_LIST = "GET_PERMISSION_EMPLOYEE_LIST";
export const GET_PERMISSION_EMPLOYEE_LIST_SUCCESS = "GET_PERMISSION_EMPLOYEE_LIST_SUCCESS";
export const GET_PERMISSION_EMPLOYEE_LIST_FAILED = "GET_PERMISSION_EMPLOYEE_LIST_FAILED";

export const UPDATE_EMPLOYEE_PERMISSION_ROLE = "UPDATE_EMPLOYEE_PERMISSION_ROLE";
export const UPDATE_EMPLOYEE_PERMISSION_ROLE_SUCCESS = "UPDATE_EMPLOYEE_PERMISSION_ROLE_SUCCESS";
export const UPDATE_EMPLOYEE_PERMISSION_ROLE_FAILED = "UPDATE_EMPLOYEE_PERMISSION_ROLE_FAILED";
