/* eslint-disable no-nested-ternary */
import { Chip } from "@mui/material";
import MDTypography from "components/MDTypography";
import React from "react";
import { useDispatch } from "react-redux";
import { updateStatusAction } from "redux/Appointment/actions";

// eslint-disable-next-line react/prop-types
function StatusChip({ status, id, isCard }) {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-shadow
  const handleClick = (status) => {
    if (status === "not-started") {
      dispatch(updateStatusAction({ id, status: "active" }));
    } else if (status === "active") {
      dispatch(updateStatusAction({ id, status: "finished" }));
    } else if (status === "finished") {
      dispatch(updateStatusAction({ id, status: "active" }));
    }
  };
  return (
    <MDTypography display="block" variant="button" fontWeight="medium">
      <Chip
        onClick={() => handleClick(status)}
        label={status}
        color={status === "not-started" ? "error" : status === "active" ? "success" : "info"}
        style={{ color: "#fff", textTransform: "capitalize" }}
        size={isCard && "small"}
      />
    </MDTypography>
  );
}

export default StatusChip;
