export const SET_INVOICE_LOADING = "SET_INVOICE_LOADING";

export const GET_INVOICE_LIST = "GET_INVOICE_LIST";
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";
export const GET_INVOICE_LIST_FAILED = "GET_INVOICE_LIST_FAILED";

export const GET_SINGLE_INVOICE = "GET_SINGLE_INVOICE";
export const GET_SINGLE_INVOICE_SUCCESS = "GET_SINGLE_INVOICE_SUCCESS";
export const GET_SINGLE_INVOICE_FAILED = "GET_SINGLE_INVOICE_FAILED";

export const STATUS_UPDATE = "STATUS_UPDATE";
export const STATUS_UPDATE_SUCCESS = "STATUS_UPDATE_SUCCESS";
export const STATUS_UPDATE_FAILED = "STATUS_UPDATE_FAILED";

export const CREATE_INVOICE = "CREATE_INVOICE";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAILED = "CREATE_INVOICE_FAILED";

export const FILTER_INVOICE = "FILTER_INVOICE";
export const FILTER_INVOICE_SUCCESS = "FILTER_INVOICE_SUCCESS";
export const FILTER_INVOICE_FAILED = "FILTER_INVOICE_FAILED";

export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAILED = "UPDATE_INVOICE_FAILED";

export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILED = "DELETE_INVOICE_FAILED";

export const SEND_INVOICE = "SEND_INVOICE";
export const SEND_INVOICE_SUCCESS = "SEND_INVOICE_SUCCESS";
export const SEND_INVOICE_FAILED = "SEND_INVOICE_FAILED";

export const GET_LAST_INVOICE_NUMBER = "GET_LAST_INVOICE_NUMBER";
export const GET_LAST_INVOICE_NUMBER_SUCCESS = "GET_LAST_INVOICE_NUMBER_SUCCESS";
export const GET_LAST_INVOICE_NUMBER_FAILED = "GET_LAST_INVOICE_NUMBER_FAILED";

export const PAYMENT_CHECK_OUT = "PAYMENT_CHECK_OUT";
export const PAYMENT_CHECK_OUT_SUCCESS = "PAYMENT_CHECK_OUT_SUCCESS";
export const PAYMENT_CHECK_OUT_FAILED = "PAYMENT_CHECK_OUT_FAILED";

export const SUCCESS_CHECK_OUT_UPDATION = "SUCCESS_CHECK_OUT_UPDATION";
export const SUCCESS_CHECK_OUT_UPDATION_SUCCESS = "SUCCESS_CHECK_OUT_UPDATION_SUCCESS";
export const SUCCESS_CHECK_OUT_UPDATION_FAILED = "SUCCESS_CHECK_OUT_UPDATION_FAILED";

export const CLEAR_SESSION_DATA = "CLEAR_SESSION_DATA";
export const CLEAR_NEW_INVOICE = "CLEAR_NEW_INVOICE";

export const GET_OVERDUE_AND_DUEWITH = "GET_OVERDUE_AND_DUEWITH";
export const GET_OVERDUE_AND_DUEWITH_SUCCESS = "GET_OVERDUE_AND_DUEWITH_SUCCESS";
export const GET_OVERDUE_AND_DUEWITH_FAILED = "GET_OVERDUE_AND_DUEWITH_FAILED";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_FAILED = "UPDATE_PAYMENT_METHOD_FAILED";

export const SET_REMINDER = "SET_REMINDER";
export const SET_REMINDER_SUCCESS = "SET_REMINDER_SUCCESS";
export const SET_REMINDER_FAILED = "SET_REMINDER_FAILED";

export const DELETE_REMINDER = "DELETE_REMINDER";
export const DELETE_REMINDER_SUCCESS = "DELETE_REMINDER_SUCCESS";
export const DELETE_REMINDER_FAILED = "DELETE_REMINDER_FAILED";
