/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSendMessageAction } from "redux/Messages/actions";
import CustomSpinner from "examples/CustomLoader";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import CustomTablePagination from "examples/CustomTablePagination/CustomTablePagination";
import authorsTableData from "../../../messages/dataList/incoming/authorsTableData";

function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

function Messages({ customerId }) {
  const dispatch = useDispatch();
  const { sendMessageData, loading } = useSelector((state) => state.MessageReducer);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [query, setQuery] = useState({ type: "", limit: pageSize, page, customer_id: customerId });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handlePageLimit = (e) => {
    setPageSize(e.target.value);
    // setQuery({...query, })
    dispatch(getSendMessageAction({ ...query, limit: e.target.value }));
  };

  const handlePageChange = (event, value) => {
    setQuery({ ...query, page: value });
    dispatch(getSendMessageAction({ ...query, page: value }));
    setPage(value);
  };
  useEffect(() => {
    dispatch(getSendMessageAction(query));
  }, []);
  useEffect(() => {
    if (Array.isArray(sendMessageData.data)) {
      const { columnData, rowData } = authorsTableData(sendMessageData);
      setColumns(columnData);
      setRows(rowData);
    }
  }, [sendMessageData]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <MDBox>
      {loading ? (
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CustomSpinner />
        </MDBox>
      ) : (
        <>
          {sendMessageData.pagination?.totalMessages <= 0 ? (
            <MDBox
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <img
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
                src="https://img.freepik.com/free-vector/empty-concept-illustration_114360-1253.jpg?w=2000"
                loading="lazy"
                alt=""
              />
            </MDBox>
          ) : (
            <>
              {windowWidth >= 768 ? (
                <DataTable
                  table={{ columns: columns || [], rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  rowData={sendMessageData}
                />
              ) : (
                <div>
                  {sendMessageData?.data?.map((row) => (
                    <MDBox mb={3} mt={3}>
                      <Card
                        style={{
                          padding: "3%",
                          border: "1px solid black",
                        }}
                      >
                        <div>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                              <MDTypography
                                style={{ fontSize: "12px", margin: "0" }}
                                display="flex"
                                alignItems="center"
                                variant="h6"
                                fontWeight="medium"
                              >
                                ToNumber
                              </MDTypography>
                              <MDTypography
                                display="block"
                                variant="button"
                                fontWeight="medium"
                                color="text"
                                fontSize=".70rem"
                              >
                                {row.toNumber}
                              </MDTypography>
                            </div>
                            <div>
                              <MDTypography
                                display="flex"
                                justifyContent="end"
                                variant="button"
                                fontWeight="medium"
                                color="text"
                                fontSize=".70rem"
                              >
                                {dateFormatted(row?.updatedAt)}
                              </MDTypography>
                            </div>
                          </div>
                          <MDTypography
                            style={{ fontSize: "12px", marginTop: "1rem" }}
                            display="flex"
                            alignItems="center"
                            variant="h6"
                            fontWeight="medium"
                          >
                            Message
                          </MDTypography>
                          <MDTypography
                            display="block"
                            variant="button"
                            fontWeight="medium"
                            color="text"
                            fontSize=".70rem"
                          >
                            {row.message}
                          </MDTypography>
                        </div>
                      </Card>
                    </MDBox>
                  ))}
                </div>
              )}
              <CustomTablePagination
                count={sendMessageData.pagination?.totalPages}
                page={page}
                onChangePage={handlePageChange}
                value={pageSize}
                onChangeRowPerPage={handlePageLimit}
                pageSizeOptions={[10, 20, 30, 40, 50]}
              />
            </>
          )}
        </>
      )}
    </MDBox>
  );
}

export default Messages;
