import { LOGIN_USER, LOGOUT_USER, FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes";

// eslint-disable-next-line import/prefer-default-export
export const loginUser = (credentials, navigate) => ({
  type: LOGIN_USER,
  payload: { credentials, navigate },
});

export const logoutUser = (navigate) => ({
  type: LOGOUT_USER,
  payload: { navigate },
});

export const forgtePassword = (email, navigate) => ({
  type: FORGET_PASSWORD,
  payload: { email, navigate },
});

export const resetPassword = (email, code, newPassword, confirmPassword, navigate) => ({
  type: RESET_PASSWORD,
  payload: { email, code, newPassword, confirmPassword, navigate },
});
