/* eslint-disable */

import { CircularProgress } from "@mui/material";
import React from "react";

const CustomSpinner = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "60vh",
      width: "100%"
    }}
  >
    <CircularProgress />
  </div>
);

export default CustomSpinner;
