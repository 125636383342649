/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  Autocomplete,
  // Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardActionArea,
  CardActions,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import MDButton from "components/MDButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCityDataAction,
  addCityAction,
  getCityByIdAction,
  editCityAction,
  clearCitySingleData,
  deleteCityAction,
} from "redux/RouteManagement/City/actions";
import "./style.css";
import Swal from "sweetalert2";
import CustomSpinner from "examples/CustomLoader";
import { toast } from "react-toastify";
import MDTypography from "components/MDTypography";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { getRoutesListAction } from "../../../redux/RouteManagement/Route/actions";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

function Citites() {
  const dispatch = useDispatch();
  const { cityData, singleCityData, loading } = useSelector((state) => state.CityReducer);
  const { routeData } = useSelector((state) => state.RouteReducer);

  const [cityName, setCityName] = useState();
  const [editValue, setEditValue] = useState();
  const [open, setOpen] = React.useState(false);
  const [addValue, setaddValue] = useState([]);
  const [oneCode, setOneCode] = useState("");
  const [create, setCreate] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleAddCity = (e, value) => {
    setOneCode();
    const trimmedValues = value
      .join(",")
      .split(",")
      .map((val) => val.trim());
    const filteredValues = trimmedValues.filter(
      (val) =>
        // const num = Number(val);
        val !== "" && val !== null
    );
    setaddValue(filteredValues);
  };
  // eslint-disable-next-line no-unused-vars
  const handleClickOpen = (id) => {
    dispatch(getCityByIdAction(id));
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(clearCitySingleData());
  };
  const handleEditChages = (e) => {
    setEditValue({ ...editValue, city_name: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!addValue?.length <= 0) {
      const city = cityName.replace(/\s+/g, " ").trim();
      dispatch(addCityAction({ city_name: city, city_codes: addValue }));
      dispatch(getCityDataAction());
      setaddValue([]);
      setCityName("");
      setOneCode("");
    } else if (!oneCode?.length <= 0) {
      const city = cityName.replace(/\s+/g, " ").trim();
      dispatch(addCityAction({ city_name: city, city_codes: oneCode }));
      dispatch(getCityDataAction());
      setaddValue([]);
      setCityName("");
      setOneCode("");
    } else {
      toast.error("Add city code!");
    }
    setOpen(false);
  };
  const handleCityDelete = (Id) => {
    Swal.fire({
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#a5bd4b",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteCityAction(Id));
      }
    });
  };
  const handleEditChanges = (event, value) => {
    const trimmedValues = value
      .join(",")
      .split(",")
      .map((val) => val.trim());
    const filteredValues = trimmedValues.filter(
      (val) =>
        // const num = Number(val);
        val !== "" && val !== null
    );
    setEditValue({ ...editValue, city_codes: filteredValues });
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (editValue.city_codes?.length > 0) {
      dispatch(editCityAction({ ...editValue, Id: singleCityData._id }));
      dispatch(clearCitySingleData());
      setOpen(false);
    } else {
      toast.error("Add at least one city code!");
    }
  };
  const handleCityName = (e) => {
    setCityName(e.target.value);
  };
  const handleCreate = () => {
    setOpen(true);
    setCreate(true);
  };
  useEffect(() => {
    if (!cityData.data) {
      dispatch(getCityDataAction());
    }
  }, [cityData]);
  useEffect(() => {
    if (singleCityData.city_name) {
      setCreate(false);
      setOpen(true);
      setEditValue({
        city_name: singleCityData.city_name,
        city_codes: singleCityData.city_codes,
      });
    }
  }, [singleCityData]);
  useEffect(() => {
    dispatch(getRoutesListAction());
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isDesktop = windowWidth > 1024;
  return (
    // <DashboardLayout>
    <MDBox mt={2} mb={3}>
      {/* <Card style={{ padding: "24px", width: "100%" }}>
        <Grid
          component="form"
          role="form"
          onSubmit={handleSubmit}
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid xs={4}>
            <TextField
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="New City"
              variant="outlined"
              value={cityName}
              onChange={handleCityName}
              required
            />
          </Grid>
          <Grid md={4}>
            <Autocomplete
              multiple
              limitTags={3}
              id="tags-filled"
              options={[]}
              value={addValue}
              freeSolo
              onChange={handleAddCity}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    id="multiple-limit-tags"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  onChange={(e) => {
                    if (addValue.length <= 0) {
                      const inputString = e.target.value;
                      const numbersOnlyArray = inputString.match(/[a-zA-Z0-9]+/g);
                      const numbersOnly = numbersOnlyArray ? numbersOnlyArray.join("") : "";
                      setOneCode([numbersOnly]);
                    } else {
                      setOneCode();
                    }
                  }}
                  label="Enter zip code, press enter"
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid md={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <MDButton fullWidth type="submit" variant="gradient" color="info">
              Add City
            </MDButton>
          </Grid>
        </Grid>
      </Card>
      <br /> */}
      <MDBox>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            bgColor="black"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            justify-content="space-between"
            align-items="center"
          >
            <Grid
              container
              spacing={6}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xl={2} display="flex" alignItems="center">
                <MDTypography variant="div" color="white">
                  Cities
                </MDTypography>
              </Grid>
              <Grid item xl={2} display="flex" justifyContent="end">
                <MDButton variant="gradient" color="info" onClick={handleCreate}>
                  Add City
                </MDButton>{" "}
              </Grid>
            </Grid>
          </MDBox>
          <br />
          <Grid>
            {loading ? (
              <MDBox
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CustomSpinner />
              </MDBox>
            ) : (
              <>
                {isDesktop ? (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableBody>
                        {cityData?.data?.map((item, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <TableRow key={index}>
                            <TableCell
                              style={{
                                fontSize: "0.875rem",
                                fontWeight: 700,
                                display: "flex",
                                alignItems: "center",
                                width: "20vw",
                                padding: "10px",
                              }}
                            >
                              {item.city_name}
                            </TableCell>

                            <TableCell
                              style={{
                                display: "flex",
                              }}
                            >
                              {item?.city_codes?.map((value, key) => {
                                const routeFound = routeData.some((route) =>
                                  route.city_codes.includes(value)
                                );
                                const routeWithColor = routeData.find((route) =>
                                  route.city_codes.includes(value)
                                );
                                const selectedColor = routeFound ? routeWithColor.color : "black";
                                return (
                                  <FormGroup
                                    key={key.id}
                                    style={{
                                      minWidth: "100px",
                                    }}
                                  >
                                    <FormControlLabel
                                      disabled
                                      control={
                                        <Checkbox
                                          checked={routeFound}
                                          style={{ color: selectedColor }}
                                        />
                                      }
                                      label={<span style={{ color: selectedColor }}>{value}</span>}
                                    />
                                  </FormGroup>
                                );
                              })}
                            </TableCell>
                            <TableCell
                              style={{
                                width: "4rem",
                              }}
                            >
                              <MDBox style={{ display: "flex" }}>
                                <Tooltip title="Edit" placement="top">
                                  <MDBox style={{ paddingInline: "0.2rem" }}>
                                    <EditIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleClickOpen(item._id)}
                                    />
                                  </MDBox>
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                  <MDBox style={{ paddingInline: "0.2rem" }}>
                                    <DeleteIcon
                                      style={{ cursor: "pointer" }}
                                      color="error"
                                      onClick={() => handleCityDelete(item._id)}
                                    />
                                  </MDBox>
                                </Tooltip>
                              </MDBox>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div>
                    {/* <Paper>
                    {cityData?.data?.map((item) => (
                      <Accordion key={item._id} style={{ marginBlock: "0" }}>
                        <AccordionSummary
                          style={{ marginBlock: "0" }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <MDTypography>{item.city_name}</MDTypography>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginBlock: "0" }}>
                          <div>
                            <Tooltip title="Edit" placement="top">
                              <EditIcon
                                style={{ cursor: "pointer", marginRight: "0.5rem" }}
                                onClick={() => handleClickOpen(item._id)}
                              />
                            </Tooltip>
                            <Tooltip title="Delete" placement="top">
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                color="error"
                                onClick={() => handleCityDelete(item._id)}
                              />
                            </Tooltip>
                          </div>
                          <FormGroup>
                            <div>
                              {item?.city_codes?.map((value, key) => {
                                const routeFound = routeData.some((route) =>
                                  route.city_codes.includes(value)
                                );
                                const routeWithColor = routeData.find((route) =>
                                  route.city_codes.includes(value)
                                );
                                const selectedColor = routeFound ? routeWithColor.color : "black";
                                return (
                                  <FormGroup
                                    key={key.id}
                                    style={{
                                      minWidth: "100px",
                                    }}
                                  >
                                    <FormControlLabel
                                      disabled
                                      control={
                                        <Checkbox
                                          checked={routeFound}
                                          style={{ color: selectedColor }}
                                        />
                                      }
                                      label={<span style={{ color: selectedColor }}>{value}</span>}
                                    />
                                  </FormGroup>
                                );
                              })}
                            </div>
                          </FormGroup>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Paper> */}
                    <MDBox>
                      {cityData?.data?.map((item) => (
                        <Card
                          variant="outlined"
                          raised
                          sx={{ width: "100%", display: "flex" }}
                          style={{ padding: "0px" }}
                        >
                          <CardActionArea style={{ padding: "0px" }}>
                            <CardContent
                              style={{
                                paddingInline: "1rem",
                                paddingBlock: "0.4rem",
                              }}
                            >
                              <MDBox
                                bgColor="black"
                                borderRadius="lg"
                                coloredShadow="info"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <MDBox px={2} display="flex" alignItems="center">
                                  <MDTypography
                                    color="white"
                                    variant="h5"
                                    component="div"
                                    style={{ paddingBlock: "0.5rem", fontSize: "15px" }}
                                  >
                                    {item.city_name}
                                  </MDTypography>
                                </MDBox>
                                <MDBox px={2} style={{ width: "fit-content", display: "flex" }}>
                                  <Tooltip title="Edit" placement="top">
                                    <EditIcon
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "0.5rem",
                                        color: "white",
                                      }}
                                      onClick={() => handleClickOpen(item._id)}
                                    />
                                  </Tooltip>
                                  <Tooltip title="Delete" placement="top">
                                    <DeleteIcon
                                      style={{ cursor: "pointer" }}
                                      color="error"
                                      onClick={() => handleCityDelete(item._id)}
                                    />
                                  </Tooltip>
                                </MDBox>
                              </MDBox>
                              <MDBox
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  paddingInline: "0.5rem",
                                }}
                              >
                                {item?.city_codes?.map((value, key) => {
                                  const routeFound = routeData.some((route) =>
                                    route.city_codes.includes(value)
                                  );
                                  const routeWithColor = routeData.find((route) =>
                                    route.city_codes.includes(value)
                                  );
                                  const selectedColor = routeFound ? routeWithColor.color : "black";
                                  return (
                                    <FormGroup
                                      key={key.id}
                                      style={{
                                        minWidth: "100px",
                                        display: "flex",
                                      }}
                                    >
                                      <FormControlLabel
                                        disabled
                                        control={
                                          <Checkbox
                                            checked={routeFound}
                                            style={{ color: selectedColor }}
                                          />
                                        }
                                        label={
                                          <span style={{ color: selectedColor }}>{value}</span>
                                        }
                                      />
                                    </FormGroup>
                                  );
                                })}
                              </MDBox>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      ))}
                    </MDBox>
                  </div>
                )}
              </>
            )}
          </Grid>
        </Card>
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        component="form"
        role="form"
        onSubmit={create ? handleSubmit : handleEditSubmit}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem 2rem 0rem 2rem",
          }}
        >
          {create ? (
            <MDTypography variant="h4" fontWeight="medium" mb={2}>
              Add City
            </MDTypography>
          ) : (
            <MDTypography
              style={{ display: "flex", alignItems: "center" }}
              variant="h4"
              fontWeight="medium"
            >
              Edit City
            </MDTypography>
          )}
          <MDTypography
            style={{ display: "flex", alignItems: "center" }}
            variant="h2"
            fontWeight="medium"
          >
            <Tooltip title="Close" placement="top">
              <CloseIcon onClick={handleClose} />
            </Tooltip>
          </MDTypography>{" "}
        </div>
        <DialogContent sx={{ width: { xs: "80vw", md: "500px" } }}>
          <MDTypography display="block" variant="button" style={{ fontWeight: "500" }} px="1rem">
            City Name
          </MDTypography>
          <TextField
            style={{ padding: "0 1rem" }}
            type="text"
            fullWidth
            value={create ? cityName : editValue?.city_name}
            onChange={create ? handleCityName : handleEditChages}
            required
          />
          <br />
          <MDTypography
            display="block"
            variant="button"
            style={{ fontWeight: "500" }}
            px="1rem"
            py="0.5rem"
          >
            Zip Code
          </MDTypography>
          <Autocomplete
            multiple
            id="tags-filled"
            label=""
            options={[]}
            defaultValue={[]}
            value={create ? addValue : editValue?.city_codes}
            freeSolo
            style={{ padding: "0 1rem" }}
            onChange={create ? handleAddCity : handleEditChanges}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  style={{ backgroundColor: "#78c360" }}
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                onChange={(e) => {
                  if (addValue?.length <= 0) {
                    const inputString = e.target.value;
                    const numbersOnlyArray = inputString.match(/[a-zA-Z0-9]+/g);
                    const numbersOnly = numbersOnlyArray ? numbersOnlyArray.join("") : "";
                    setOneCode([numbersOnly]);
                  } else {
                    setOneCode();
                  }
                }}
                {...params}
                placeholder="Zip Code"
              />
            )}
          />
        </DialogContent>
        <br />
        <DialogActions
          style={{
            padding: "0 2rem 1rem 2rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <MDButton variant="gradient" color="error" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton variant="gradient" color="info" type="submit">
            {create ? "Save" : "Update"}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
    // </DashboardLayout>
  );
}

export default Citites;
