const {
  SET_DASHBOARD_LOADING,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAILED,
  GET_DASHBOARD_ROUTE_TABLE_SUCCESS,
  GET_DASHBOARD_ROUTE_TABLE_FAILED,
  UPDATE_YEARLY_ACTIVATION_SUCCESS,
  UPDATE_YEARLY_ACTIVATION_FAILED,
} = require("./actionTypes");

const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  dashboardData: [],
  dashboardTable: [],
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        dashboardData: action.payload,
      };
    case GET_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case GET_DASHBOARD_ROUTE_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        dashboardTable: action.payload,
      };
    case GET_DASHBOARD_ROUTE_TABLE_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case UPDATE_YEARLY_ACTIVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case UPDATE_YEARLY_ACTIVATION_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
