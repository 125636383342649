/* eslint-disable no-underscore-dangle */
import {
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getRoutesListAction, deleteRouteAction } from "redux/RouteManagement/Route/actions";
import MDButton from "components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CustomSpinner from "examples/CustomLoader";

function Route() {
  const dispatch = useDispatch();
  const navigete = useNavigate();
  const { routeData, loading } = useSelector((state) => state.RouteReducer);
  const handleCreate = () => {
    navigete("/create-routes");
  };
  const handleDelete = (Id) => {
    Swal.fire({
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#a5bd4b",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteRouteAction(Id));
      }
    });
  };
  useEffect(() => {
    dispatch(getRoutesListAction());
  }, []);
  return (
    <MDBox mt={2} mb={3}>
      <Card>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          bgColor="black"
          borderRadius="lg"
          coloredShadow="info"
          display="flex"
          justify-content="space-between"
          align-items="center"
        >
          <Grid container spacing={6} style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xl={2} display="flex" alignItems="center">
              <MDTypography variant="div" color="white">
                Routes
              </MDTypography>
            </Grid>
            <Grid item xl={2} display="flex" justifyContent="end">
              <MDButton variant="gradient" color="info" onClick={handleCreate}>
                Add Route
              </MDButton>{" "}
            </Grid>
          </Grid>
        </MDBox>
        <br />
        {loading ? (
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CustomSpinner />
          </MDBox>
        ) : (
          <TableContainer component={Paper}>
            {" "}
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableBody>
                {routeData?.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    <TableCell style={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      {item.route_title}
                    </TableCell>
                    <TableCell>
                      <MDBox style={{ display: "flex" }}>
                        {item?.city_codes?.map((value, key) => (
                          <FormGroup key={key.id} style={{ minWidth: "100px" }}>
                            <FormControlLabel
                              control={<Checkbox disabled checked style={{ color: item.color }} />}
                              label={<span style={{ color: item.color }}>{value}</span>}
                            />
                          </FormGroup>
                        ))}
                      </MDBox>
                    </TableCell>
                    <TableCell>
                      <MDBox style={{ display: "flex" }}>
                        <Tooltip title="Edit" placement="top">
                          <MDBox>
                            <EditIcon
                              onClick={() => navigete(`/create-routes/${item._id}`)}
                              style={{ cursor: "pointer" }}
                            />
                          </MDBox>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">
                          <MDBox>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              color="error"
                              onClick={() => handleDelete(item._id)}
                            />
                          </MDBox>
                        </Tooltip>
                      </MDBox>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
    </MDBox>
  );
}

export default Route;
