import {
  CLEAR_SINGLE_DATA,
  CREATE_APPOINTMENT,
  GET_APPOINTMENT,
  GET_SINGLE_APPOINTMENT,
  GET_SLOTS,
  // GET_TABLE_DATA,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_STATUS,
} from "./actionTypes";

// eslint-disable-next-line import/prefer-default-export
export const createAppointment = (obj) => ({
  type: CREATE_APPOINTMENT,
  obj,
});

export const getAppointmentListAction = () => ({
  type: GET_APPOINTMENT,
});

export const getSlotsAction = (obj) => ({
  type: GET_SLOTS,
  obj,
});

// export const getTableData = (obj) => ({
//   type: GET_TABLE_DATA,
//   obj,
// });

export const getSingleAppointmentAction = (obj) => ({
  type: GET_SINGLE_APPOINTMENT,
  obj,
});

export const updateStatusAction = (obj) => ({
  type: UPDATE_APPOINTMENT_STATUS,
  obj,
});

export const clearSingleDataAction = () => ({
  type: CLEAR_SINGLE_DATA,
});

export const updateAppointmentAction = (obj) => ({
  type: UPDATE_APPOINTMENT,
  obj,
});
