/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable new-cap */
/* eslint-disable no-underscore-dangle */
import {
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import { formatPhoneNumber } from "utils";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleInvoiceAction,
  paymentCheckOutAction,
  updateInvoiceSatausAction,
} from "redux/invoices/actions";
import MDButton from "components/MDButton";
import CustomSpinner from "examples/CustomLoader";
import makeitrainlogo from "../assets/makeitrainlogo.png";

function PublicInvoiceView() {
  const paramsId = useParams();
  const dispatch = useDispatch();
  const { singleInvoice, loading, checkOutDetails } = useSelector((state) => state.InvoiceReducer);

  const [dateInvoice, setDateInvoice] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logoStyles = {
    maxWidth: "100%",
    height: "auto",
    paddingLeft: "1%",
    objectFit: "contain",
    borderRadius: "5px",
  };
  const billTo = {
    width: "50%",
  };
  const buttonStyle = {
    marginTop: "1%",
    display: "flex",
    gap: "1rem",
    justifyContent: "flex-end",
    padding: "5%",
  };
  const container = {
    padding: "3% 15% 3% 15%",
  };
  const noteStyle = { height: "30%", width: "60%", padding: "0 3% 3% 0" };

  if (windowWidth <= 768) {
    logoStyles.width = "50%";
    noteStyle.width = "100%";
    container.padding = "0";
    buttonStyle.justifyContent = "center";
    billTo.width = "100%";
  }

  if (windowWidth <= 480) {
    logoStyles.width = "70%";
  }

  const handleDownload = async () => {
    window.open(
      `https://projects.xcitech.in/make-it-rain-api/api/invoice/${singleInvoice._id}/invoice-generate`
    );
  };
  useEffect(() => {
    if (singleInvoice.invoice_date) {
      const invoiceDate = new Date(singleInvoice?.invoice_date);
      const formattedInvoiceDate = new Intl.DateTimeFormat("en-US", {
        month: "short", // Use short month name
        day: "numeric",
        year: "numeric", // Use two-digit year
      }).format(invoiceDate);
      const paymentDue = new Date(singleInvoice?.payment_due_date);
      const formattedPaymentDueDate = new Intl.DateTimeFormat("en-US", {
        month: "short", // Use short month name
        day: "numeric",
        year: "numeric", // Use two-digit year
      }).format(paymentDue);
      setDateInvoice({
        invoice_date: formattedInvoiceDate,
        payment_due_date: formattedPaymentDueDate,
      });
    }
  }, [singleInvoice]);
  useEffect(() => {
    if (paramsId.id) {
      dispatch(getSingleInvoiceAction(paramsId.id));
      dispatch(updateInvoiceSatausAction(paramsId.id));
    }
  }, []);
  useEffect(() => {
    if (checkOutDetails.data) {
      const { url } = checkOutDetails.data;
      window.open(url);
    }
  }, [checkOutDetails]);
  return (
    <>
      <MDBox style={container}>
        <Card>
          {" "}
          {loading ? (
            <MDBox
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100vh",
              }}
            >
              <CustomSpinner />
            </MDBox>
          ) : (
            <>
              <MDBox padding="5%">
                <MDBox>
                  <Chip
                    color="info"
                    variant="outlined"
                    style={{ fontSize: "1.25rem", fontWeight: "500" }}
                    label={singleInvoice?.status === "paid" ? "Paid" : "Unpaid"}
                  />
                </MDBox>
                <MDBox
                  marginTop="3%"
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={windowWidth <= 768 && "column"}
                >
                  <MDBox
                    display={windowWidth <= 768 && "flex"}
                    justifyContent={windowWidth <= 768 && "center"}
                    width={windowWidth <= 768 ? "100%" : "35%"}
                  >
                    <img
                      style={{ paddingLeft: "0", objectFit: "contain" }}
                      src={makeitrainlogo}
                      loading="lazy"
                      alt=""
                    />
                  </MDBox>
                  {windowWidth >= 768 && (
                    <MDBox display="flex" justifyContent="center">
                      <MDBox width="100%">
                        <MDTypography fontWeight="medium" variant="h3">
                          INVOICE
                        </MDTypography>
                        <MDTypography marginTop="0.5%" fontWeight="medium" variant="h4">
                          Make it Rain, LLC
                        </MDTypography>
                        <MDTypography>11 Sunset Drive</MDTypography>
                        <MDTypography>Shelton CT 06484</MDTypography>
                        <MDTypography marginTop="3%">203-962-1618</MDTypography>
                        <MDTypography>makeitrainct@gmail.com</MDTypography>
                      </MDBox>
                    </MDBox>
                  )}
                </MDBox>
                <hr style={{ marginTop: "3%" }} />
                <MDBox
                  display="flex"
                  marginTop="3%"
                  justifyContent="space-between"
                  flexDirection={windowWidth <= 768 && "column"}
                >
                  <MDBox width={windowWidth <= 768 ? "100%" : "35%"} paddingLeft="1%">
                    <MDTypography fontWeight="medium" color="text" fontSize="1rem">
                      Bill to
                    </MDTypography>
                    <MDBox padding={windowWidth >= 768 && "5%"}>
                      <MDTypography variant="h5">
                        {singleInvoice?.customer_id?.first_name}{" "}
                        {singleInvoice?.customer_id?.last_name}
                      </MDTypography>
                      <MDTypography>{singleInvoice.customer_id?.property?.address} </MDTypography>
                      <MDTypography>
                        {singleInvoice.customer_id?.property?.city}{" "}
                        {singleInvoice?.customer_id?.property?.state}{" "}
                        {singleInvoice?.customer_id?.property?.zip}
                      </MDTypography>
                      <MDTypography marginTop="3%">
                        {formatPhoneNumber(singleInvoice?.customer_id?.phone)}
                      </MDTypography>
                      <MDTypography>{singleInvoice?.customer_id?.email}</MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="flex-end">
                    <MDBox width="100%">
                      <MDBox className="view_invoice_details_text">
                        <MDTypography variant="h5" color="text">
                          Invoice number
                        </MDTypography>
                        <MDTypography className="view_invoice_details_text_child_value">
                          {singleInvoice?.invoice_number}
                        </MDTypography>
                      </MDBox>
                      <MDBox className="view_invoice_details_text">
                        <MDTypography variant="h5" color="text">
                          Invoice date
                        </MDTypography>
                        <MDTypography className="view_invoice_details_text_child_value">
                          {dateInvoice?.invoice_date}
                        </MDTypography>
                      </MDBox>
                      <MDBox className="view_invoice_details_text">
                        <MDTypography variant="h5" color="text">
                          Payment due
                        </MDTypography>
                        <MDTypography className="view_invoice_details_text_child_value">
                          {dateInvoice?.payment_due_date}
                        </MDTypography>
                      </MDBox>
                      <MDBox className="view_invoice_details_text">
                        <MDTypography variant="h5" color="text">
                          Amount due
                        </MDTypography>
                        <MDTypography className="view_invoice_details_text_child_value">
                          {singleInvoice && singleInvoice?.total_price !== undefined
                            ? singleInvoice?.total_price.toLocaleString(undefined, {
                                style: "currency",
                                currency: "USD",
                              })
                            : 0}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox marginTop="2rem">
                  <TableContainer style={{ boxShadow: "none" }}>
                    <Table>
                      {windowWidth >= 768 && (
                        <TableRow>
                          <TableCell className="item_table_header" style={{ width: "40%" }}>
                            Item
                          </TableCell>
                          <TableCell className="item_table_header" style={{ width: "10%" }}>
                            Hours or Quantity
                          </TableCell>
                          <TableCell className="item_table_header" style={{ width: "10%" }}>
                            Price
                          </TableCell>
                          <TableCell className="item_table_header" style={{ width: "10%" }}>
                            Amount
                          </TableCell>
                        </TableRow>
                      )}
                      <TableBody>
                        {singleInvoice?.items?.map((item, index) => (
                          <>
                            {windowWidth >= 768 ? (
                              <TableRow key={index.id}>
                                <TableCell>{item.item_name}</TableCell>
                                <TableCell>{item.hours_or_qty} Hour</TableCell>
                                <TableCell>
                                  {item?.price?.toLocaleString(undefined, {
                                    style: "currency",
                                    currency: "USD",
                                  }) || 0}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  {item?.amount?.toLocaleString(undefined, {
                                    style: "currency",
                                    currency: "USD",
                                  }) || 0}
                                </TableCell>
                              </TableRow>
                            ) : (
                              <Card
                                style={{
                                  padding: "3%",
                                  border: "1px solid black",
                                  marginTop: "1rem",
                                  gap: "1rem",
                                }}
                              >
                                <div>
                                  <MDTypography variant="h6" component="h1" color="text">
                                    Item
                                  </MDTypography>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    value={item.item_name}
                                    label=""
                                    variant="outlined"
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </div>
                                <div>
                                  <MDTypography variant="h6" component="h1" color="text">
                                    Hours or Quantity
                                  </MDTypography>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    value={item.hours_or_qty}
                                    label=""
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </div>
                                <div>
                                  <MDTypography variant="h6" component="h1" color="text">
                                    Price
                                  </MDTypography>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    value={item.price}
                                    label=""
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </div>
                                <div style={{ marginBottom: "1rem" }}>
                                  <MDTypography variant="h6" component="h1" color="text">
                                    Amount
                                  </MDTypography>
                                  <MDTypography variant="text">
                                    {item.hours_or_qty && item.price !== null
                                      ? (item.hours_or_qty * item.price).toLocaleString(undefined, {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                      : "0.00"}
                                  </MDTypography>
                                </div>
                              </Card>
                            )}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
                <hr style={{ marginTop: "3%" }} />
                <MDBox
                  marginTop="4%"
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={windowWidth <= 768 && "column"}
                  width="100%"
                >
                  <MDBox
                    sx={{
                      height: "30%",
                      width: windowWidth <= 768 ? "100%" : "70%",
                      padding: "0 3% 3% 0",
                    }}
                  >
                    <MDTypography variant="h6" component="h1" color="text">
                      Notes/Terms
                    </MDTypography>
                    <TextField
                      value={singleInvoice?.note}
                      fullWidth
                      id="outlined-multiline-static"
                      multiline
                      rows={6}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </MDBox>
                  <MDBox width={windowWidth <= 768 ? "100%" : "35%"}>
                    <MDBox className="view_invoice_details_text">
                      <MDTypography variant="h5" color="text">
                        Subtotal
                      </MDTypography>
                      <MDTypography className="view_invoice_details_text_child_value">
                        {singleInvoice && singleInvoice?.sub_total_price !== undefined
                          ? singleInvoice?.sub_total_price.toLocaleString(undefined, {
                              style: "currency",
                              currency: "USD",
                            })
                          : 0}
                      </MDTypography>
                    </MDBox>
                    {singleInvoice?.discount?.map((item, index) => {
                      const key = Object.keys(item)[0];
                      const value = item[key];
                      return (
                        <MDBox className="view_invoice_details_text">
                          <MDTypography key={index.id}>{key}</MDTypography>
                          <MDTypography className="view_invoice_details_text_child_value">
                            -{" "}
                            {value?.toLocaleString(undefined, {
                              style: "currency",
                              currency: "USD",
                            }) || 0}
                          </MDTypography>
                        </MDBox>
                      );
                    })}
                    {singleInvoice?.tax?.map((item, index) => {
                      const key = Object.keys(item)[0];
                      const value = item[key];
                      return (
                        <MDBox className="view_invoice_details_text">
                          <MDTypography key={index.id}>{key}</MDTypography>
                          <MDTypography className="view_invoice_details_text_child_value">
                            +{" "}
                            {value?.toLocaleString(undefined, {
                              style: "currency",
                              currency: "USD",
                            }) || 0}
                          </MDTypography>
                        </MDBox>
                      );
                    })}
                    <hr style={{ marginTop: "3%" }} />
                    <MDBox display="flex" justifyContent="space-between">
                      <MDTypography variant="h5" color="text">
                        Total
                      </MDTypography>
                      <MDTypography className="view_invoice_details_text_child_value">
                        {singleInvoice && singleInvoice?.total_price !== undefined
                          ? singleInvoice?.total_price.toLocaleString(undefined, {
                              style: "currency",
                              currency: "USD",
                            })
                          : 0}
                      </MDTypography>
                    </MDBox>
                    <hr />
                    <MDBox className="view_invoice_details_text">
                      <MDTypography variant="h5" color="text">
                        Amount due
                      </MDTypography>
                      <MDTypography className="view_invoice_details_text_child_value">
                        {singleInvoice && singleInvoice?.total_price !== undefined
                          ? singleInvoice?.total_price.toLocaleString(undefined, {
                              style: "currency",
                              currency: "USD",
                            })
                          : 0}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox style={buttonStyle}>
                {singleInvoice?.status === "paid" ? (
                  <MDButton color="dark" onClick={handleDownload}>
                    Print Invoice
                  </MDButton>
                ) : (
                  <MDButton
                    color="dark"
                    onClick={() =>
                      dispatch(paymentCheckOutAction({ invoice_id: singleInvoice._id }))
                    }
                  >
                    Pay Bill
                  </MDButton>
                )}
              </MDBox>
            </>
          )}
        </Card>
      </MDBox>
    </>
  );
}

export default PublicInvoiceView;
