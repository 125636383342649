/* eslint-disable react/prop-types */
import { FormControl, MenuItem, Pagination, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

function CustomTablePagination({
  count,
  page,
  onChangePage,
  value,
  onChangeRowPerPage,
  pageSizeOptions,
}) {
  return (
    <MDBox id="pagination_container">
      <div className="pagination_wrapper">
        <Pagination
          size="small"
          siblingCount={0}
          count={count}
          page={page}
          onChange={onChangePage}
          variant="outlined"
          shape="rounded"
        />
      </div>
      <MDBox className="per_page_wrapper">
        <MDTypography
          sx={{ fontSize: { xs: "15px", md: "17px" } }}
          variant="body2"
          component="p"
          color="text"
        >
          Per Page:
        </MDTypography>
        <FormControl>
          <Select
            className="page_per_row"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={onChangeRowPerPage}
          >
            {pageSizeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MDBox>
    </MDBox>
  );
}

export default CustomTablePagination;
