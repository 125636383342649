/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentListAction } from "redux/Appointment/actions";
import MDTypography from "components/MDTypography";
import CustomSpinner from "examples/CustomLoader";
import EditIcon from "@mui/icons-material/Edit";
// import UpcomingTable from "../dataTable/UpcomingTable";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { updateAppointmentNoteAction } from "redux/Dashboard/actions";
import EditAppointment from "./EditAppointment";
import PropertyButton from "../todaysAppointments/PropertyButton";

function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

function formatTimeSlot(timeSlot) {
  if (!timeSlot) {
    return "";
  }
  const [startTime, endTime] = timeSlot.split(" to ");
  const [startHours, startMinutes] = startTime.split(":");
  const [endHours, endMinutes] = endTime.split(":");

  const startTotalMinutes = parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);
  const endTotalMinutes = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10);

  const timeDifference = endTotalMinutes - startTotalMinutes;

  if (timeDifference === 30) {
    const startHour = parseInt(startHours, 10) % 12 || 12;
    const endHour = parseInt(endHours, 10) % 12 || 12;

    const startPeriod = parseInt(startHours, 10) < 12 ? "AM" : "PM";
    const endPeriod = parseInt(endHours, 10) < 12 ? "AM" : "PM";

    const formattedStartTime = `${startHour}${
      startMinutes === "00" ? "" : `:${startMinutes}`
    } ${startPeriod}`;
    const formattedEndTime = `${endHour}${
      endMinutes === "00" ? "" : `:${endMinutes}`
    } ${endPeriod}`;

    return `${formattedStartTime} to ${formattedEndTime}`;
  }

  const startHour = parseInt(startTime, 10) % 12 || 12;
  const endHour = parseInt(endTime, 10) % 12 || 12;

  const startPeriod = parseInt(startTime, 10) < 12 ? "AM" : "PM";
  const endPeriod = parseInt(endTime, 10) < 12 ? "AM" : "PM";

  let formattedStartTime = `${startHour}${
    startMinutes === "00" ? "" : `:${startMinutes}`
  } ${startPeriod}`;

  let formattedEndTime = `${endHour}${
    endMinutes && endMinutes !== "00" ? `:${endMinutes}` : ""
  } ${endPeriod}`;

  if (!startMinutes) {
    formattedStartTime = `${startHour} ${startPeriod}`;
  }

  if (!endMinutes) {
    formattedEndTime = `${endHour} ${endPeriod}`;
  }

  return `${formattedStartTime} to ${formattedEndTime}`;
}

function UpcomingAppointments() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appointmentData, loading } = useSelector((state) => state.AppointmentReducer);
  const [finalData, setFinalData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const today = new Date().toISOString().slice(0, 10);
  const [note, setNote] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);

  const handleEdit = (id) => {
    navigate(`/appointment/edit-appointment/${id}`);
  };
  useEffect(() => {
    dispatch(getAppointmentListAction());
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (appointmentData?.data?.length > 0) {
      const { data } = appointmentData;
      const result = data.reduce((acc, { crew_id, ...rest }) => {
        if (crew_id && crew_id.crew_title) {
          const { crew_title } = crew_id;
          const existingCrew = acc.find((item) => item?.crew_id?.crew_title === crew_title);
          if (existingCrew) {
            existingCrew.appointmentData.push(rest);
          } else {
            acc.push({ crew_id, crewName: crew_title, appointmentData: [rest] });
          }
        }
        return acc;
      }, []);
      setFinalData(result);
    }
  }, [appointmentData]);
  const newData = finalData[0]?.appointmentData.filter((data) => data.date > today);
  // *******************************************
  useEffect(() => {
    setNote(newData && newData.length > 0 ? newData[0].note : []);
  }, [finalData]);
  const handleSubmit = (row, crew_id) => {
    // e.preventDefault();
    dispatch(
      updateAppointmentNoteAction({
        dataSet: {
          customer_id: row.customer_id._id,
          crew_id: crew_id._id,
          service_type_id: row.service_type_id._id,
          time_window: row.time_window,
          date: row.date,
          slot: row.slot,
          note,
        },
        id: row._id,
      })
    );
  };
  const handleBlur = () => {
    setTimeout(() => {
      setShowUpdateBtn(false);
    }, 200);
  };
  // *******************************************
  return (
    <MDBox>
      {loading ? (
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CustomSpinner />
        </MDBox>
      ) : (
        <>
          <div>
            {finalData.map((item, index) => {
              const upcomingData = item.appointmentData.filter((data) => data.date > today);
              if (upcomingData.length > 0) {
                return (
                  // eslint-disable-next-line no-underscore-dangle
                  <MDBox key={index._id}>
                    <MDTypography style={{ color: "gray" }} p={2}>
                      {item.crewName}
                    </MDTypography>
                    {windowWidth >= 768 ? (
                      <TableContainer style={{ boxShadow: "none" }}>
                        <Table>
                          {/* <TableHead> */}
                          <TableRow style={{ borderBottom: "solid 1px #cbcbcb" }}>
                            <TableCell
                              style={{
                                minWidth: "120px",
                                fontSize: "0.65rem",
                                fontWeight: "700",
                                color: "#7b809a",
                              }}
                            >
                              ROUTE
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: "120px",
                                fontSize: "0.65rem",
                                fontWeight: "700",
                                color: "#7b809a",
                              }}
                            >
                              DATE
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: "120px",
                                fontSize: "0.65rem",
                                fontWeight: "700",
                                color: "#7b809a",
                              }}
                            >
                              TIME
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: "120px",
                                fontSize: "0.65rem",
                                fontWeight: "700",
                                color: "#7b809a",
                              }}
                            >
                              SERVICE ADDRESS
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: "120px",
                                fontSize: "0.65rem",
                                fontWeight: "700",
                                color: "#7b809a",
                              }}
                            >
                              SERVICE TYPE
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: "120px",
                                fontSize: "0.65rem",
                                fontWeight: "700",
                                color: "#7b809a",
                              }}
                            >
                              CUSTOMER
                            </TableCell>
                            {/* <TableCell
                              style={{
                                minWidth: "120px",
                                fontSize: "0.65rem",
                                fontWeight: "700",
                                color: "#7b809a",
                                textTransform: "uppercase",
                              }}
                            >
                              Appointment Details
                            </TableCell> */}
                          </TableRow>
                          <TableBody>
                            {upcomingData.map((row) => (
                              <>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#344767",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {row.routeName}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#344767",
                                      fontWeight: "600",
                                      padding: "0px 1rem 0px 1rem",
                                    }}
                                  >
                                    <EditAppointment
                                      id={row._id}
                                      text={dateFormatted(row.date)}
                                      color="#344767"
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#344767",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <EditAppointment
                                      id={row._id}
                                      text={formatTimeSlot(row.slot)}
                                      color="#344767"
                                    />
                                    {/* {formatTimeSlot(row.slot)}s */}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#344767",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <PropertyButton
                                      id={row.customer_id._id}
                                      text={`${row.customer_id.property.address} ${row.customer_id.property.city}, ${row.customer_id.property.state} ${row.customer_id.property.zip}, `}
                                      color="#344767"
                                    />
                                    {/* {row.customer_id.property.address}{" "}
                                    {row.customer_id.property.city},{" "}
                                    {row.customer_id.property.state} {row.customer_id.property.zip} */}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#344767",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <EditAppointment
                                      id={row._id}
                                      text={row.serviceType}
                                      color="#344767"
                                    />

                                    {/* {row.serviceType} */}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#344767",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <PropertyButton
                                      id={row.customer_id._id}
                                      text={`${row.customer_id.first_name} ${row.customer_id.last_name} `}
                                      color="blue"
                                    />
                                  </TableCell>
                                  {/* <TableCell
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#344767",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <EditAppointment
                                      id={row._id}
                                      text="Modify Appointment"
                                      color="blue"
                                    />
                                  </TableCell> */}
                                </TableRow>
                                <TableRow>
                                  <TableCell colSpan={6}>
                                    <MDBox
                                      style={{
                                        border: "solid 1px #cbcbcb",
                                        padding: "5px",
                                        marginBottom: "1rem",
                                        // backgroundColor: "#f0f2f5",
                                      }}
                                    >
                                      <MDTypography
                                        style={{
                                          minWidth: "120px",
                                          fontSize: "0.65rem",
                                          fontWeight: "600",
                                          color: "#7b809a",
                                        }}
                                      >
                                        Note
                                      </MDTypography>
                                      <MDBox>
                                        <MDBox>
                                          <TextField
                                            fullWidth
                                            id="filled-multiline-static"
                                            multiline
                                            rows={3}
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                            fontSize={30}
                                            onFocus={() => {
                                              setShowUpdateBtn(true);
                                            }}
                                            onBlur={handleBlur}
                                          />
                                        </MDBox>
                                        <MDBox paddingTop={1}>
                                          {showUpdateBtn && (
                                            <MDBox paddingTop={1}>
                                              <MDButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                  handleSubmit(row, item.crew_id);
                                                }}
                                              >
                                                Update Note
                                              </MDButton>
                                            </MDBox>
                                          )}
                                        </MDBox>
                                      </MDBox>
                                    </MDBox>
                                  </TableCell>
                                  <TableCell />
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div style={{ padding: "8px 16px 16px" }}>
                        {upcomingData.map((row) => (
                          <MDBox mb={3}>
                            <Card
                              style={{
                                padding: "3%",
                                border: "1px solid black",
                              }}
                            >
                              <div>
                                <MDTypography
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="end"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                >
                                  <EditIcon
                                    style={{ color: "#333745" }}
                                    onClick={() => handleEdit(row._id)}
                                  />
                                </MDTypography>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <div>
                                    <MDTypography
                                      style={{ fontSize: "12px", margin: "0" }}
                                      display="flex"
                                      alignItems="center"
                                      variant="h6"
                                      fontWeight="medium"
                                    >
                                      ROUTE
                                    </MDTypography>
                                    <MDTypography
                                      display="flex"
                                      alignItems="center"
                                      variant="button"
                                      fontWeight="medium"
                                      color="text"
                                      fontSize=".70rem"
                                    >
                                      {row.routeName}
                                    </MDTypography>
                                  </div>
                                  <div>
                                    <MDTypography
                                      style={{ fontSize: "12px", margin: "0" }}
                                      display="flex"
                                      alignItems="center"
                                      variant="h6"
                                      fontWeight="medium"
                                    >
                                      DATE-TIME
                                    </MDTypography>
                                    <MDTypography
                                      display="flex"
                                      alignItems="center"
                                      variant="button"
                                      fontWeight="medium"
                                      color="text"
                                      fontSize=".70rem"
                                    >
                                      <EditAppointment
                                        id={row._id}
                                        text={dateFormatted(row.date)}
                                        color="#344767"
                                      />
                                      <br />
                                      <EditAppointment
                                        id={row._id}
                                        text={formatTimeSlot(row.slot)}
                                        color="#344767"
                                      />
                                    </MDTypography>
                                  </div>
                                </div>
                                <MDTypography
                                  style={{ marginTop: "1rem", fontWeight: "400" }}
                                  display="flex"
                                  alignItems="center"
                                  variant="h6"
                                  fontWeight="medium"
                                  onClick={() => navigate(`/profile/${row.customer_id._id}`)}
                                >
                                  <PropertyButton
                                    id={row.customer_id._id}
                                    text={`${row.customer_id.first_name} ${row.customer_id.last_name} `}
                                    color="blue"
                                  />
                                </MDTypography>
                                <MDTypography
                                  style={{ fontSize: "12px" }}
                                  display="flex"
                                  alignItems="center"
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  SERVICE ADDRESS
                                </MDTypography>
                                <MDTypography
                                  display="block"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                >
                                  <PropertyButton
                                    id={row.customer_id._id}
                                    text={`${row.customer_id.property.address} ${row.customer_id.property.city}, ${row.customer_id.property.state} ${row.customer_id.property.zip}, `}
                                    color="#344767"
                                  />
                                </MDTypography>
                                <MDTypography
                                  style={{ fontSize: "12px", marginTop: "1rem" }}
                                  display="flex"
                                  alignItems="center"
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  SERVICE TYPE
                                </MDTypography>
                                <MDTypography
                                  display="block"
                                  variant="button"
                                  fontWeight="medium"
                                  color="text"
                                  fontSize=".70rem"
                                >
                                  <EditAppointment
                                    id={row._id}
                                    text={row.serviceType}
                                    color="#344767"
                                  />
                                </MDTypography>
                              </div>
                              <MDTypography
                                style={{ fontSize: "12px", marginTop: "1rem" }}
                                display="flex"
                                alignItems="center"
                                variant="h6"
                                fontWeight="medium"
                              >
                                NOTE
                              </MDTypography>
                              <MDBox>
                                <MDBox>
                                  <TextField
                                    fullWidth
                                    id="filled-multiline-static"
                                    multiline
                                    rows={3}
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    fontSize={30}
                                    onFocus={() => {
                                      setShowUpdateBtn(true);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </MDBox>
                                {showUpdateBtn && (
                                  <MDBox paddingTop={1}>
                                    <MDButton
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        handleSubmit(row, item.crew_id);
                                      }}
                                    >
                                      Update Note
                                    </MDButton>
                                  </MDBox>
                                )}
                              </MDBox>
                            </Card>
                          </MDBox>
                        ))}
                      </div>
                    )}
                  </MDBox>
                );
              }
              return (
                <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                  <img
                    style={{ height: "50%", width: "50%", objectFit: "contain" }}
                    src="https://img.freepik.com/free-vector/empty-concept-illustration_114360-1253.jpg?w=2000"
                    loading="lazy"
                    alt=""
                  />
                </MDBox>
              );
            })}
          </div>
        </>
      )}
    </MDBox>
  );
}

export default UpcomingAppointments;
