/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unescaped-entities */
import { Autocomplete, Card, CardContent, TextField, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  permissionEmployeeListAction,
  updatePermissionEmployeeRole,
} from "redux/Permission/actions";
import Swal from "sweetalert2";

function Permission() {
  const dispatch = useDispatch();
  const { permissionEmployeeList } = useSelector((state) => state.PermissionReducer);
  const [selectedOptionForOwner, setSelectedOptionForOwner] = useState([]);
  const [selectedOptionForBilling, setSelectedOptionForBilling] = useState([]);
  const [selectedOptionForEmployee, setSelectedOptionForEmployee] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const loginUserEmail = localStorage.getItem("userData");

  const getOptionLabel = (option) => `${option.first_name} ${option.last_name}`;

  const handleChangeOwner = (event, value) => {
    if (value !== null && !selectedOptionForOwner.includes(value)) {
      setSelectedOptionForOwner([...selectedOptionForOwner, value]);
    }
  };
  const handleChangeBilling = (event, value) => {
    if (value !== null && !selectedOptionForBilling.includes(value)) {
      setSelectedOptionForBilling([...selectedOptionForBilling, value]);
    }
  };
  const handleChangeEmployee = (event, value) => {
    if (value !== null && !selectedOptionForEmployee.includes(value)) {
      setSelectedOptionForEmployee([...selectedOptionForEmployee, value]);
    }
  };

  const changeToEmployee = (Id) => {
    Swal.fire({
      title: "Do you want to remove?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#a5bd4b",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          updatePermissionEmployeeRole({
            role_name: "employee",
            id: Id,
          })
        );
      }
    });
  };

  useEffect(() => {
    if (selectedOptionForOwner.length === 1) {
      dispatch(
        updatePermissionEmployeeRole({
          role_name: "owner",
          id: selectedOptionForOwner[0]._id,
        })
      );
      setSelectedOptionForOwner([]);
    }
    if (selectedOptionForBilling.length === 1) {
      dispatch(
        updatePermissionEmployeeRole({
          role_name: "billing_admin",
          id: selectedOptionForBilling[0]._id,
        })
      );
      setSelectedOptionForBilling([]);
    }
    if (selectedOptionForEmployee.length === 1) {
      dispatch(
        updatePermissionEmployeeRole({
          role_name: "employee",
          id: selectedOptionForEmployee[0]._id,
        })
      );
      setSelectedOptionForEmployee([]);
    }
  }, [selectedOptionForBilling, selectedOptionForOwner, selectedOptionForEmployee]);

  useEffect(() => {
    dispatch(permissionEmployeeListAction());
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <MDBox
      pt={6}
      pb={3}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <MDBox
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "15px",
          width: "100%",
          flexDirection: windowWidth <= 768 && "column",
        }}
      >
        <PermissionFilterCart
          permissionEmployeeList={permissionEmployeeList}
          role_name="owner"
          getOptionLabel={getOptionLabel}
          handleChange={handleChangeOwner}
          label="Global Admin"
          changeToEmployee={changeToEmployee}
          loginUserEmail={loginUserEmail}
        />

        <PermissionFilterCart
          permissionEmployeeList={permissionEmployeeList}
          role_name="billing_admin"
          getOptionLabel={getOptionLabel}
          handleChange={handleChangeBilling}
          label="Billing Admin"
          changeToEmployee={changeToEmployee}
          loginUserEmail={loginUserEmail}
        />

        <PermissionFilterCart
          permissionEmployeeList={permissionEmployeeList}
          role_name="employee"
          getOptionLabel={getOptionLabel}
          handleChange={handleChangeEmployee}
          label="Field Employee"
          loginUserEmail={loginUserEmail}
        />
      </MDBox>
    </MDBox>
  );
}

export default Permission;

export function PermissionFilterCart({
  permissionEmployeeList,
  role_name,
  getOptionLabel,
  handleChange,
  label,
  changeToEmployee,
  loginUserEmail,
}) {
  return (
    <Card sx={{ minWidth: "32%" }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 18, display: "flex", justifyContent: "center" }}
          color="text.secondary"
          variant="h5"
          gutterBottom
        >
          {label}
        </Typography>
        <br />
        <Autocomplete
          inputValue=""
          options={permissionEmployeeList.filter(
            (employee) => employee.email !== loginUserEmail && employee?.role_name !== role_name
          )}
          getOptionLabel={getOptionLabel}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />}
          renderOption={(props, option) => (
            <li {...props} key={option?.id}>
              {option?.first_name} {option?.last_name}
            </li>
          )}
        />
        <br />
        {permissionEmployeeList?.map(
          (item, index) =>
            item.role_name === role_name && (
              <MDBox key={index.id}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                    padding: "12px 0px 12px 0px",
                    opacity: "1",
                  }}
                >
                  <AccountCircleIcon fontSize="medium" /> &nbsp; {item.first_name} {item.last_name}
                  {label === "Billing Admin" && (
                    <Tooltip title="Delete" placement="top">
                      <DeleteIcon
                        sx={{ marginLeft: "auto", cursor: "pointer" }}
                        color="error"
                        onClick={() => changeToEmployee(item?._id)}
                        fontSize="medium"
                      />
                    </Tooltip>
                  )}
                  {label === "Global Admin" && (
                    <div>
                      {item.email !== loginUserEmail && (
                        <Tooltip title="Delete" placement="top">
                          <DeleteIcon
                            sx={{ marginLeft: "auto", cursor: "pointer" }}
                            color="error"
                            onClick={() => changeToEmployee(item._id)}
                            fontSize="medium"
                          />
                        </Tooltip>
                      )}
                    </div>
                  )}
                </span>
                <hr />
              </MDBox>
            )
        )}
      </CardContent>
    </Card>
  );
}
