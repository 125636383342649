export const SET_LOADING_ROUTES = "SET_LOADING_ROUTES";

export const LIST_ROUTES = "LIST_ROUTES";
export const LIST_ROUTES_SUCCESS = "LIST_ROUTES_SUCCESS";
export const LIST_ROUTES_FAILED = "LIST_ROUTES_FAILED";

export const CREATE_ROUTE = "CREATE_ROUTE";
export const CREATE_ROUTE_SUCCESS = "CREATE_ROUTE_SUCCESS";
export const CREATE_ROUTE_FAILED = "CREATE_ROUTE_FAILED";

export const DELETE_ROUTE = "DELETE_ROUTE";
export const DELETE_ROUTE_SUCCESS = "DELETE_ROUTE_SUCCESS";
export const DELETE_ROUTE_FAILED = "DELETE_ROUTE_FAILED";

export const GET_SINGLE_ROUTE = "GET_SINGLE_ROUTE";
export const GET_SINGLE_ROUTE_SUCCESS = "GET_SINGLE_ROUTE_SUCCESS";
export const GET_SINGLE_ROUTE_FAILED = "GET_SINGLE_ROUTE_FAILED";

export const EDIT_ROUTE = "EDIT_ROUTE";
export const EDIT_ROUTE_SUCCESS = "EDIT_ROUTE_SUCCESS";
export const EDIT_ROUTE_FAILED = "EDIT_ROUTE_FAILED";
