/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign, no-underscore-dangle */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import Actions from "../Actions";
import ViewListOfEmployee from "../ViewListOfEmployee";
// import { Button } from "@mui/material";

// import { useDispatch } from "react-redux";
// import { deleteHolidaysAction } from "redux/Settings/actions";

function dateFormatted(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateOnlyString = inputDate.split("T")[0];
  const date = new Date(dateOnlyString);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    // Handle invalid input
    return "Invalid Date";
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export default function data(tableData) {
  return {
    columnData: [
      { Header: "Title", accessor: "crew_title", align: "left" },
      { Header: "Id of Employee", accessor: "employee_ids", align: "left" },
      { Header: "Created Date", accessor: "createdAt", align: "left" },
      { Header: "Action", accessor: "action", align: "left" },
    ],

    rowData: tableData?.data.map((d) => ({
      crew_title: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {d?.crew_title}
        </MDTypography>
      ),
      employee_ids: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <ViewListOfEmployee employee_ids={d?.employee_ids} />
        </MDTypography>
      ),
      createdAt: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {dateFormatted(d?.createdAt)}
        </MDTypography>
      ),
      action: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <Actions id={d._id} staffData={d} />
        </MDTypography>
      ),
    })),
  };
}
