/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable import/order */
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Autocomplete, Card, Grid, Tab, Tabs, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Unpaid from "./unPaid/Unpaid";
// eslint-disable-next-line import/no-named-as-default
import Draft from "./draft/Draft";
import AllInvoice from "./allInvoice/AllInvoice";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { listCustomers } from "redux/actions";
import { filterInvoiceAction, getOverDueAndDueWithAction } from "redux/invoices/actions";
import SideNavActionBtn from "examples/SideNavActionBtn/SideNavActionBtn";

const status = [
  { label: "All", value: "" },
  { label: "Draft", value: "draft" },
  { label: "Unsent", value: "unsent" },
  { label: "Sent", value: "sent" },
  // { label: "Viewed", value: "viewed" },
  { label: "Partial", value: "partial" },
  { label: "Paid", value: "paid" },
  // { label: "OverDue", value: "overdue" },
];

function Invoices() {
  const dispatch = useDispatch();
  const { loading, invoiceData, overDueAndDueWith } = useSelector((state) => state.InvoiceReducer);
  const { originalCustomerList } = useSelector((state) => state.Customers);
  const navigate = useNavigate();
  const [tab, setTab] = useState("unpaid");
  const [statusForAllinvoice, setStatusForAllinvoice] = useState("");
  const [filteringData, setFilteringData] = useState({
    customerId: "",
    invoiceDate: "",
    invoiceNumber: "",
    filterdates: "startDate:&endDate:",
  });
  // for list customers for filter
  const [customerList, setCustomerList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customer, setCustomer] = useState("");
  const [invoice, setInvoice] = useState("");
  const [invoiceNumverList, setInvoiceNumberList] = useState([]);
  const [overdue, setOverdue] = useState("0.00");
  const [dueWithNext, setDueWithNext] = useState("0.00");
  const [avgTime, setAvgTime] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const invoiceHeadWrapper = { width: "100%", display: "flex", justifyContent: "space-between" };
  const subWrap = {
    display: "flex",
    flexDirection: "column",
  };
  const filterWrapper = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    gap: "1rem",
  };
  // const overdueStyle = { padding: 3, display: "flex", flexDirection: "column" };
  if (windowWidth <= 768) {
    invoiceHeadWrapper.flexDirection = "column";
    subWrap.marginTop = "1rem";
    filterWrapper.flexDirection = "column";
  }
  // Disable dates after the specific date
  const specificEndDate = new Date(endDate);
  const specificSatrtDate = new Date(startDate);
  const isStartDateDisabled = (date) => date < specificSatrtDate;
  const isEndDateDisabled = (date) => date > specificEndDate;
  // Disable dates after the specific date
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const filteringWithStatus = (e, v) => {
    if (v !== null) {
      setStatusForAllinvoice(v.value);
    } else {
      setStatusForAllinvoice("");
    }
  };
  const handleStartDate = (e) => {
    const inputDate = new Date(e.$d);
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    const outputDate = `${month}/${day}/${year}`;
    setStartDate(outputDate);
    setFilteringData({
      ...filteringData,
      filterdates: `startDate=${outputDate}&endDate=${endDate}`,
    });
  };
  const handleEndDate = (e) => {
    const inputDate = new Date(e.$d);
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    const outputDate = `${month}/${day}/${year}`;
    setEndDate(outputDate);
    setFilteringData({
      ...filteringData,
      filterdates: `startDate=${startDate}&endDate=${outputDate}`,
    });
  };
  useEffect(() => {
    if (invoiceData?.data) {
      const settingInvoiceNumberList = invoiceData?.data?.map(
        // eslint-disable-next-line camelcase
        ({ _id, invoice_number }) => ({
          // eslint-disable-next-line camelcase
          label: invoice_number,
          _id,
        })
      );
      setInvoiceNumberList(settingInvoiceNumberList);
    }
  }, [invoiceData]);
  useEffect(() => {
    dispatch(listCustomers({ page: 1, pageSize: 10000 }));
    dispatch(filterInvoiceAction({ ...filteringData, status: "unpaid", page: 1, limit: 10 }));
    dispatch(getOverDueAndDueWithAction());
  }, []);
  useEffect(() => {
    if (overDueAndDueWith?.totalOverdue) {
      setOverdue(
        overDueAndDueWith?.totalOverdue.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })
      );
      setDueWithNext(
        overDueAndDueWith?.next30DaysOverdue.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })
      );
      setAvgTime(Math.floor(overDueAndDueWith.averageDays));
    }
  }, [overDueAndDueWith]);
  useEffect(() => {
    if (originalCustomerList.data) {
      const settingCustomerList = originalCustomerList?.data?.map(
        ({ first_name, last_name, _id }) => ({
          label: `${first_name} ${last_name}`,
          _id,
        })
      );
      setCustomerList(settingCustomerList);
    }
  }, [originalCustomerList]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox display="flex" justifyContent="space-between">
        <MDTypography variant="h2">Invoices</MDTypography>
        <SideNavActionBtn />
      </MDBox>
      <MDBox mt={3} pt={2.6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                bgColor="black"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                align-items="center"
                flexWrap="wrap"
              >
                <MDTypography variant="div" color="white">
                  Invoices
                </MDTypography>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => navigate("/create_invoice")}
                >
                  Create Invoice
                </MDButton>{" "}
              </MDBox>
              <MDBox p={3} style={invoiceHeadWrapper}>
                <MDBox style={subWrap}>
                  <MDTypography variant="div" sx={{ fontWeight: "700" }}>
                    Overdue
                  </MDTypography>
                  {loading ? "Loading..." : <MDTypography variant="div">{overdue}</MDTypography>}
                </MDBox>
                <MDBox style={subWrap}>
                  <MDTypography variant="div" sx={{ fontWeight: "700" }}>
                    Due Within Next 30 Days
                  </MDTypography>
                  {loading ? (
                    "Loading..."
                  ) : (
                    <MDTypography variant="div">{dueWithNext}</MDTypography>
                  )}
                </MDBox>
                <MDBox style={subWrap}>
                  <MDTypography variant="div" sx={{ fontWeight: "700" }}>
                    Avg time to pay
                  </MDTypography>
                  {loading ? (
                    "Loading..."
                  ) : (
                    <MDTypography variant="div">{avgTime} days</MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </Card>
            <Card style={{ marginTop: "3rem" }}>
              <MDBox style={filterWrapper}>
                <MDTypography variant="h6" color="text">
                  {windowWidth <= 768 ? "FILTER" : <FilterAltIcon />}
                </MDTypography>
                <div style={{ width: "100%" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    value={customer}
                    onChange={(e, v) => {
                      setCustomer(v);
                      v !== null
                        ? setFilteringData({ ...filteringData, customerId: v._id })
                        : setFilteringData({ ...filteringData, customerId: "" });
                    }}
                    renderInput={(params) => <TextField {...params} label="All Customers" />}
                  />
                </div>
                {tab === "allinvoices" && (
                  <div style={{ width: "100%" }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={status}
                      onChange={filteringWithStatus}
                      renderInput={(params) => <TextField {...params} label="All Status" />}
                    />
                  </div>
                )}
                <div style={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      shouldDisableDate={isEndDateDisabled}
                      onChange={handleStartDate}
                      label="From"
                      required
                    />
                  </LocalizationProvider>
                </div>
                <div style={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: "100%" }}>
                    <DatePicker
                      style={{ width: "100%" }}
                      shouldDisableDate={isStartDateDisabled}
                      onChange={handleEndDate}
                      label="To"
                      required
                    />
                  </LocalizationProvider>
                </div>
                <div style={{ width: "100%" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={invoiceNumverList}
                    value={invoice}
                    onChange={(e, v) => {
                      setInvoice(v);
                      v !== null
                        ? setFilteringData({ ...filteringData, invoiceNumber: v.label })
                        : setFilteringData({ ...filteringData, invoiceNumber: "" });
                    }}
                    renderInput={(params) => <TextField {...params} label="Enter Invoice #" />}
                  />
                </div>
              </MDBox>
            </Card>
            <MDBox style={{ marginTop: "3rem" }}>
              <Card style={{ marginTop: "1.3rem" }}>
                <TabContext value={tab} mt={3}>
                  <MDBox>
                    <Tabs
                      onChange={handleChangeTab}
                      TabIndicatorProps={{
                        style: {
                          transition: "none",
                        },
                      }}
                      value={tab}
                    >
                      <Tab
                        sx={{
                          backgroundColor: tab === "unpaid" ? "#333745" : "white",
                        }}
                        label="Unpaid"
                        value="unpaid"
                      />{" "}
                      <Tab
                        sx={{
                          backgroundColor: tab === "draft" ? "#333745" : "white",
                        }}
                        label="Draft"
                        value="draft"
                      />{" "}
                      <Tab
                        sx={{
                          backgroundColor: tab === "allinvoices" ? "#333745" : "white",
                        }}
                        label="All Invoices"
                        value="allinvoices"
                      />{" "}
                    </Tabs>{" "}
                  </MDBox>{" "}
                  <br />
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        style={{
                          minWidth: "70%",
                        }}
                      >
                        <TabPanel value="unpaid" style={{ padding: "0", width: "100%" }}>
                          <MDBox mt={2} mb={3}>
                            <Unpaid filteringData={filteringData} />
                          </MDBox>{" "}
                        </TabPanel>{" "}
                        <TabPanel value="draft" style={{ padding: "0", width: "100%" }}>
                          <MDBox mt={2} mb={3}>
                            <Draft filteringData={filteringData} />
                          </MDBox>
                        </TabPanel>{" "}
                        <TabPanel value="allinvoices" style={{ padding: "0", width: "100%" }}>
                          <MDBox mt={2} mb={3}>
                            <AllInvoice
                              status={statusForAllinvoice}
                              filteringData={filteringData}
                            />
                          </MDBox>
                        </TabPanel>{" "}
                      </Grid>{" "}
                    </Grid>{" "}
                  </Grid>{" "}
                </TabContext>{" "}
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Invoices;
